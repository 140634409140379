import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import styled from "styled-components";
import GlobalModal from "../../../components/Modal/modal";
import CelebrationIcon from "@mui/icons-material/Celebration";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CommunicationLog from "../../../components/CommunicationLog/CommunicationLog";
import { useGetStageListing } from "../service";
import { LiaEdit } from "react-icons/lia";
import CheckIcon from "@mui/icons-material/Check";
import RenewInsurancePolicyModalForm from "../../Renew-Policies/RenewInsurancePolicyModalForm";

const ClaimCard = ({ claimDataListing }) => {
  const { claimAmount, totalClaimamount } = claimDataListing || {};
  const navigate = useNavigate();
  const location = useLocation();
  const isActivity = location.pathname === "/activity";
  const [modalOpen, setModalOpen] = useState({
    open: false,
    data: "",
  });
  const [renewModalOpen, setRenewModalOpen] = useState({
    open: false,
    data: "",
  });

  const [policyNo, setPolicyNo] = useState("");
  const [id, setId] = useState("");

  const handleClose = () => {
    setModalOpen({
      open: false,
      data: "",
    });
  };
  const handleRenewClose = () => {
    setRenewModalOpen({
      open: false,
      data: "",
    });
  };

  const { data: stageData } = useGetStageListing(id);
  const stageList = stageData?.data?.data;

  const handleDocClick = (status) => {
    if (status === "Approved" || status === "Rejected") {
      window.open(claimDataListing?.letterUpload, "_blank");
    } else window.open(claimDataListing?.authorizationLetter, "_blank");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid date"; // Check for valid date
    const day = String(date.getDate()).padStart(2, "0"); // Pad day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`; // Return formatted date
  };

  const getDayLabel = (claimDataListing) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const createdDate = new Date(claimDataListing.created_at);
    if (isNaN(createdDate)) return "Invalid date"; // Check for valid date

    if (createdDate.toDateString() === today.toDateString()) {
      return "Today";
    } else if (createdDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return formatDate(claimDataListing.created_at);
    }
  };

  const handleDownload = () => {
    if (claimDataListing && claimDataListing.letterUpload) {
      window.open(claimDataListing?.letterUpload, "_blank");
    }
  };

  return (
    <>
      {!isActivity ? (
        <Card
          variant="outlined"
          sx={{
            marginBottom: "10px",
            borderRadius: "10px",
            boxShadow: " 0px 0px 10px 0px #0000001F",
          }}
        >
          {isActivity && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "1px",
              }}
            >
              <IconWrapper>
                <CheckIcon
                  style={{
                    background: "#27db9f",
                    borderRadius: "57px",
                    color: "#fff",
                    fontSize: "1.8rem",
                    marginLeft: "14px",
                  }}
                />
                <Message>Your claim has been settled.</Message>
              </IconWrapper>
              {claimDataListing?.letterUpload && (
                <ActionButton onClick={handleDownload}>
                  Get Settlement Document
                </ActionButton>
              )}
            </div>
          )}
          {isActivity && <hr style={{ width: "96%" }} />}
          <CardContent
            style={{
              fontSize: ".75rem",
              paddingBottom: "16px",
            }}
          >
            <Grid container gap={"10px"}>
              <StyledDivWrapper>
                <Grid item>
                  <StyledTag variant="1">Claim Id: </StyledTag>
                  <StyledTagName variant="1" style={{ marginRight: "20px" }}>
                    {claimDataListing?.claimIdupdate || "N/A"}
                  </StyledTagName>
                  <StyledTag variant="1">LOB: </StyledTag>
                  <StyledTagName variant="1">
                    {claimDataListing?.lobName || "N/A"}
                  </StyledTagName>
                </Grid>
                <Grid item>
                  <StyledTag variant="1">Date Submitted: </StyledTag>
                  <StyledTagName variant="1">
                    {claimDataListing?.created_at || "N/A"}
                  </StyledTagName>
                </Grid>
              </StyledDivWrapper>
              <StyledDiv>
                <StyledBoxContainer>
                  <Grid item sm={4}>
                    <StyledBox>
                      <StyledImgWrapper>
                        <StyledImg
                          src={claimDataListing?.companyLogo}
                          alt="Company Logo"
                        />
                      </StyledImgWrapper>
                      <div>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "12px",
                            lineHeight: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {/* {policy["Ic Name"] || "- - -"} */}
                          {claimDataListing?.lobName} Insurance
                        </Typography>
                        <Typography
                          sx={{
                            color: "#777",
                            fontSize: "12px",
                            lineHeight: "18px",
                          }}
                        >
                          Policy No: {claimDataListing?.policyNo}
                        </Typography>
                      </div>
                    </StyledBox>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">
                      {claimDataListing?.lobId === "Car" ? "IDV" : "Coverage"}
                    </StyledTag>
                    <StyledTagName variant="2">
                      {(claimDataListing?.coverage &&
                        `₹ ${claimDataListing?.coverage}`) ||
                        `N/A`}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Balance Cover</StyledTag>
                    <StyledTagName variant="2">
                      {claimDataListing?.balanceSuminsured
                        ? `₹ ${claimDataListing.balanceSuminsured}`
                        : claimDataListing?.coverage
                        ? `₹ ${claimDataListing.coverage}`
                        : "N/A"}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Claim Amount</StyledTag>
                    <StyledTagName variant="2">
                      {claimAmount || totalClaimamount
                        ? `₹ ${claimAmount || totalClaimamount}`
                        : "N/A"}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Settled Amount</StyledTag>
                    <StyledTagName variant="2">
                      {(claimDataListing?.settledAmount &&
                        `${claimDataListing?.settledAmount}`) ||
                        "N/A"}
                    </StyledTagName>
                  </Grid>
                </StyledBoxContainer>
                <Grid
                  item
                  xs={1.5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <StatusContainer claimStatus={claimDataListing?.claimStatus}>
                    <StatusDot
                      status={claimDataListing?.claimStatus}
                    ></StatusDot>
                    {claimDataListing?.claimStatus || "N/A"}
                  </StatusContainer>
                </Grid>
              </StyledDiv>
              <StyledDiv>
                <Grid
                  item
                  xs={"auto"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {Object.entries(claimDataListing?.additionalKeys || {}).map(
                    ([key, value]) => (
                      <StyledBox
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <StyledTag variant="3">
                            {` ${key}:` || "N/A"}
                          </StyledTag>
                          <StyledTagName variant="3">
                            {`${value} ` || "N/A"}
                          </StyledTagName>
                        </div>
                      </StyledBox>
                    )
                  )}
                </Grid>
                {((claimDataListing?.letterUpload &&
                  ["approved", "rejected"].includes(
                    claimDataListing?.claimStatus?.toLowerCase()
                  )) ||
                  (claimDataListing?.authorizationLetter &&
                    claimDataListing?.claimStatus?.toLowerCase() ===
                      "al issued")) && (
                  <Grid item xs={"auto"}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        onClick={() => {
                          handleDocClick(claimDataListing?.claimStatus);
                        }}
                        style={{
                          marginRight: "5px",
                          fontWeight: "700",
                          color: "#4373eb",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {`${
                          claimDataListing?.claimStatus === "Approved"
                            ? "Approved"
                            : claimDataListing?.claimStatus === "AL issued"
                            ? "Authorization"
                            : "Rejected"
                        }`}{" "}
                        Document
                      </span>
                    </div>
                  </Grid>
                )}
              </StyledDiv>
              {!isActivity && (
                <StyledDiv>
                  <div style={{ width: "100%" }}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          fontWeight: "600",
                          marginBottom: "6px",
                        }}
                      >
                        Recent Communication
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <SendRoundedIcon
                        style={{
                          color: "#4373eb",
                          height: "20px",
                          width: "20px",
                          margin: "5px",
                        }}
                      />
                      <div
                        style={{
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        Your Claim Stage is{" "}
                        {claimDataListing?.claimStatus || "N/A"}
                        <br />
                        {moment(claimDataListing?.updated_at).format(
                          "DD/MM/YYYY hh:mm A" // git issue #9180
                        )}
                        <br />
                        <Box>{claimDataListing?.stage}</Box>
                      </div>
                    </Grid>
                  </div>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "#4373EB",
                        cursor: "pointer",
                        textTransform: "none",
                        padding: "5px 8px",
                      }}
                      onClick={() => {
                        setModalOpen({
                          open: true,
                          data: "",
                        });
                        setId(claimDataListing?._id);
                        setPolicyNo(claimDataListing?.policyNo);
                      }}
                    >
                      View Communication History
                    </Button>
                  </Grid>
                </StyledDiv>
              )}
            </Grid>
          </CardContent>

          <GlobalModal
            title={
              <StyledHeader>
                <div
                  style={{
                    width: "32px !important",
                    height: "32px !important",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#35C17B",
                    borderRadius: "15px",
                    marginRight: "10px",
                  }}
                >
                  <SendRoundedIcon
                    style={{
                      color: "white",
                      height: "20px",
                      width: "20px",
                      margin: "5px",
                    }}
                  />
                </div>

                <TitleSpan>Claim Communication for Policy</TitleSpan>
                <TitleSpan small={true}>&nbsp;{policyNo}</TitleSpan>
              </StyledHeader>
            }
            width={"60%"}
            color={"#444444"}
            noPadding={true}
            open={modalOpen.open}
            onClose={handleClose}
          >
            <CommunicationLog
              setModalOpen={setModalOpen}
              data={stageList}
              popup={true}
            />
          </GlobalModal>
        </Card>
      ) : isActivity &&
        claimDataListing?.letterUpload &&
        claimDataListing?.claimStatus === "Approved" ? (
        <Card
          variant="outlined"
          sx={{
            marginBottom: "10px",
            borderRadius: "10px",
            boxShadow: " 0px 0px 10px 0px #0000001F",
          }}
        >
          {isActivity && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "1px",
              }}
            >
              <IconWrapper>
                <CheckIcon
                  style={{
                    background: "#27db9f",
                    borderRadius: "57px",
                    color: "#fff",
                    fontSize: "1.8rem",
                    marginLeft: "14px",
                  }}
                />
                <Message>Your claim has been settled.</Message>
              </IconWrapper>
              {claimDataListing?.letterUpload && (
                <ActionButton onClick={handleDownload}>
                  Get Settlement Document
                </ActionButton>
              )}
            </div>
          )}
          {isActivity && <hr style={{ width: "96%" }} />}
          <CardContent
            style={{
              fontSize: ".75rem",
              paddingBottom: "16px",
            }}
          >
            <Grid container gap={"10px"}>
              <StyledDivWrapper>
                <Grid item>
                  <StyledTag variant="1">LOB: </StyledTag>
                  <StyledTagName variant="1">
                    {claimDataListing?.lobName || "N/A"}
                  </StyledTagName>
                </Grid>
                <Grid item>
                  <StyledTag variant="1">Date Submitted: </StyledTag>
                  <StyledTagName variant="1">
                    {claimDataListing?.created_at || "N/A"}
                  </StyledTagName>
                </Grid>
              </StyledDivWrapper>
              <StyledDiv>
                <StyledBoxContainer>
                  <Grid item sm={4}>
                    <StyledBox>
                      <StyledImgWrapper>
                        <StyledImg
                          src={claimDataListing?.companyLogo}
                          alt="Company Logo"
                        />
                      </StyledImgWrapper>
                      <div>
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "12px",
                            lineHeight: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {/* {policy["Ic Name"] || "- - -"} */}
                          {claimDataListing?.lobName} Insurance
                        </Typography>
                        <Typography
                          sx={{
                            color: "#777",
                            fontSize: "12px",
                            lineHeight: "18px",
                          }}
                        >
                          Policy No: {claimDataListing?.policyNo}
                        </Typography>
                      </div>
                    </StyledBox>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">
                      {claimDataListing?.lobId === "Car" ? "IDV" : "Coverage"}
                    </StyledTag>
                    <StyledTagName variant="2">
                      {(claimDataListing?.coverage &&
                        `₹ ${claimDataListing?.coverage}`) ||
                        `N/A`}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Balance Cover</StyledTag>
                    <StyledTagName variant="2">
                      {claimDataListing?.balanceSuminsured
                        ? `₹ ${claimDataListing.balanceSuminsured}`
                        : claimDataListing?.coverage
                        ? `₹ ${claimDataListing.coverage}`
                        : "N/A"}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Claim Amount</StyledTag>
                    <StyledTagName variant="2">
                      {claimAmount || totalClaimamount
                        ? `₹ ${claimAmount || totalClaimamount}`
                        : "N/A"}
                    </StyledTagName>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <StyledTag variant="2">Settled Amount</StyledTag>
                    <StyledTagName variant="2">
                      {(claimDataListing?.settledAmount &&
                        `${claimDataListing?.settledAmount}`) ||
                        "N/A"}
                    </StyledTagName>
                  </Grid>
                </StyledBoxContainer>
                <Grid
                  item
                  xs={1.5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <StatusContainer claimStatus={claimDataListing?.claimStatus}>
                    <StatusDot
                      status={claimDataListing?.claimStatus}
                    ></StatusDot>
                    {claimDataListing?.claimStatus || "N/A"}
                  </StatusContainer>
                </Grid>
              </StyledDiv>
              <StyledDiv>
                <Grid
                  item
                  xs={"auto"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  {Object.entries(claimDataListing?.additionalKeys || {}).map(
                    ([key, value]) => (
                      <StyledBox
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <StyledTag variant="3">
                            {` ${key}:` || "N/A"}
                          </StyledTag>
                          <StyledTagName variant="3">
                            {`${value} ` || "N/A"}
                          </StyledTagName>
                        </div>
                      </StyledBox>
                    )
                  )}
                </Grid>
                {((claimDataListing?.letterUpload &&
                  ["approved", "rejected"].includes(
                    claimDataListing?.claimStatus?.toLowerCase()
                  )) ||
                  (claimDataListing?.authorizationLetter &&
                    claimDataListing?.claimStatus?.toLowerCase() ===
                      "al issued")) && (
                  <Grid item xs={"auto"}>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <span
                        onClick={() => {
                          handleDocClick(claimDataListing?.claimStatus);
                        }}
                        style={{
                          marginRight: "5px",
                          fontWeight: "700",
                          color: "#4373eb",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {`${
                          claimDataListing?.claimStatus === "Approved"
                            ? "Approved"
                            : claimDataListing?.claimStatus === "AL issued"
                            ? "Authorization"
                            : "Rejected"
                        }`}{" "}
                        Document
                      </span>
                    </div>
                  </Grid>
                )}
              </StyledDiv>
              {!isActivity && (
                <StyledDiv>
                  <div style={{ width: "100%" }}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          fontWeight: "600",
                          marginBottom: "6px",
                        }}
                      >
                        Recent Communication
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      <SendRoundedIcon
                        style={{
                          color: "#4373eb",
                          height: "20px",
                          width: "20px",
                          margin: "5px",
                        }}
                      />
                      <div
                        style={{
                          fontWeight: "600",
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        Your Claim Stage is{" "}
                        {claimDataListing?.claimStatus || "N/A"}
                        <br />
                        {moment(claimDataListing?.updated_at).format(
                          "DD/MM/YYYY hh:mm A" // git issue #9180
                        )}
                        <br />
                        <Box>{claimDataListing?.stage}</Box>
                      </div>
                    </Grid>
                  </div>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        fontSize: "11px",
                        fontWeight: "600",
                        color: "#4373EB",
                        cursor: "pointer",
                        textTransform: "none",
                        padding: "5px 8px",
                      }}
                      onClick={() => {
                        setModalOpen({
                          open: true,
                          data: "",
                        });
                        setId(claimDataListing?._id);
                        setPolicyNo(claimDataListing?.policyNo);
                      }}
                    >
                      View Communication History
                    </Button>
                  </Grid>
                </StyledDiv>
              )}
            </Grid>
          </CardContent>

          <GlobalModal
            title={
              <StyledHeader>
                <div
                  style={{
                    width: "32px !important",
                    height: "32px !important",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#35C17B",
                    borderRadius: "15px",
                    marginRight: "10px",
                  }}
                >
                  <SendRoundedIcon
                    style={{
                      color: "white",
                      height: "20px",
                      width: "20px",
                      margin: "5px",
                    }}
                  />
                </div>

                <TitleSpan>Claim Communication for Policy</TitleSpan>
                <TitleSpan small={true}>&nbsp;{policyNo}</TitleSpan>
              </StyledHeader>
            }
            width={"60%"}
            color={"#444444"}
            noPadding={true}
            open={modalOpen.open}
            onClose={handleClose}
          >
            <CommunicationLog
              // filePreview={trailOpen.data}
              setModalOpen={setModalOpen}
              data={stageList}
              popup={true}
            />
          </GlobalModal>
        </Card>
      ) : isActivity && claimDataListing?.expireIN ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Card
            sx={{
              marginBottom: "10px",
              borderRadius: "10px",
              boxShadow: " 0px 0px 10px 0px #0000001F",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "1px",
                gap: "10px",
                padding: "0 10px 0 0",
              }}
            >
              <IconWrapper>
                <WarningAmberIcon
                  style={{
                    background: "#f32341",
                    borderRadius: "57px",
                    color: "#fff",
                    fontSize: "1.8rem",
                    marginLeft: "14px",
                  }}
                />
                <Message>
                  {`Policy Number ${claimDataListing?.policyNo} is about to expire in ${claimDataListing?.expireIN} days.`}
                </Message>
              </IconWrapper>
              <RenewButton
                onClick={() => {
                  setRenewModalOpen({
                    open: true,
                    data: claimDataListing,
                  });
                }}
              >
                Renew Now
              </RenewButton>
            </div>
          </Card>
        </div>
      ) : (
        isActivity &&
        claimDataListing?.birthday && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Card
              sx={{
                marginBottom: "10px",
                borderRadius: "10px",
                boxShadow: " 0px 0px 10px 0px #0000001F",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  marginLeft: "1px",
                  gap: "10px",
                }}
              >
                <CelebrationIcon
                  style={{
                    background: "#798de8",
                    borderRadius: "57px",
                    color: "#fff",
                    fontSize: "1.8rem",
                    marginLeft: "14px",
                  }}
                />
                <Message>
                  {`Today is ${claimDataListing?.policyNo} Birthday, wish him a Happy Birthday!`}
                </Message>
              </div>
            </Card>
          </div>
        )
      )}
      <GlobalModal
        title={"Renew Insurance Policy"}
        open={renewModalOpen.open}
        onClose={handleRenewClose}
        width={490}
        style={{
          borderRadius: "8px",
        }}
      >
        <RenewInsurancePolicyModalForm
          policyData={renewModalOpen?.data}
          setModalOpen={setRenewModalOpen}
        />
      </GlobalModal>
    </>
  );
};

export default ClaimCard;

const StyledDivWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 5px 10px;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledBoxContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledImgWrapper = styled.div`
  width: 110px;
  height: 55px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledTag = styled.span`
  font-weight: 600;
  color: #999;
  letter-spacing: 0.5px;
  line-height: 15px;
  ${({ variant }) =>
    variant === "2"
      ? `
      text-align: center;
      font-size: 12px;
`
      : variant === "3"
      ? `font-size: 11px;`
      : `font-size: 12px;`}
`;

const StyledTagName = styled.span`
  font-weight: 600;
  font-size: 12px;
  ${({ variant }) =>
    variant === "2"
      ? `text-align: center;`
      : variant === "3"
      ? `
       font-size: 11px;
       margin-left: 5px;
      `
      : `
    margin-left: 3px;
  `}
`;

const StyledButton = styled.button`
  font-size: 11px;
  border: 1px solid rgb(67, 115, 235);
  border-radius: 5px;
  color: rgb(67, 115, 235);
  cursor: pointer;
  background-color: white;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  gap: 3px;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 700;
  width: max-content;
  padding: 7px 13px;
  // margin-left: ${({ isMargin }) => (isMargin ? "30px" : "100px")};
  // margin-bottom: ${({ isMargin }) => (isMargin ? "0px" : "10px")};
  // margin-top: ${({ isMargin }) => (isMargin ? "10px" : "8px")};
  text-align: center;
  gap: 6px;
  border-radius: 5px;
  font-size: 11px;
  letter-spacing: 0.5px;
  opacity: 0px;
  background-color: ${({ claimStatus }) => {
    switch (claimStatus) {
      case "Approved":
        return "#beff9b";
      case "Intimated":
        return "#ffe3a6";
      case "In Progress":
        return "#EDEDFC";
      case "In Process":
        return "#EDEDFC";
      case "Pending":
        return "#95d4ffab";
      case "Rejected":
        return "rgb(255 201 203)";
      case "AL issued":
        return "rgb(223 161 227 / 63%)";
      case "Out Standing":
        return "#00000013";
      case "Query Raised":
        return "#ffe3a6";
      case "Query Resolved":
        return "#ededfc";
      default:
        return "rgb(213 213 213)";
    }
  }};
  color: ${({ claimStatus }) => {
    switch (claimStatus) {
      case "Approved":
        return "#1a9a5d";
      case "Intimated":
        return "#ffbc00";
      case "In Progress":
        return "#5E5ADB";
      case "In Process":
        return "#5E5ADB";
      case "Pending":
        return "rgb(67 113 228)";
      case "Rejected":
        return "rgb(246 9 9)";
      case "AL issued":
        return "rgb(161 59 211)";
      case "Out Standing":
        return "#000";
      case "Query Raised":
        return "#ffbc00";
      case "Query Resolved":
        return "#5e5adb";
      default:
        return "grey";
    }
  }};
`;

const StatusDot = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    switch (status) {
      case "Approved":
        return "#1a9a5d";
      case "Intimated":
        return "#ffbc00";
      case "In Progress":
        return "#5E5ADB";
      case "In Process":
        return "#5E5ADB";
      case "Pending":
        return "rgb(67 113 228)";
      case "Rejected":
        return "rgb(246 9 9)";
      case "AL issued":
        return "rgb(161 59 211)";
      case "Out Standing":
        return "#000";
      case "Query Raised":
        return "#ffbc00";
      case "Query Resolved":
        return "#5e5adb";

      default:
        return "grey";
    }
  }};
`;

export const Heading = styled(Box)`
  font-size: ${({ small }) => (small ? "14px" : "24px")};
  color: #011340;
  width: 100%;
  font-weight: ${({ small }) => (small ? "400" : "600")};
  margin-bottom: ${({ small }) => small && "15px"};
`;

export const MainCard = styled(Card)`
  padding: 14px;
  border-radius: 15px !important;
`;

export const FilterTagContainer = styled.div`
  display: flex;
`;

export const FilterHR = styled.hr`
  margin: 0px 0px 15px 0;
  border: 1px solid #3300ff;
  opacity: 30%;
`;

export const StyledMainDiv = styled.div`
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  // padding-bottom: 10px;
  // margin-bottom: 10px;
`;

export const TitleSpan = styled.span`
  font-size: 20px;
  font-weight: ${({ small }) => (small ? "700" : "400")};
  line-height: 19.53px;
  letter-spacing: 0.02em;
  color: "#444444";
`;

export const StyledStepper = styled.div`
  // border:1px solid yellow;
  padding: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActionButton = styled.span`
  // background-color: #007bff;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;
const RenewButton = styled.span`
  color: #007bff;
  border: 1px solid #007bff;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;

const Message = styled.p`
  margin-bottom: 10px;
  text-align: center;
`;

export const CommsButton = styled(Button)`
  // font-weight: 600;
  // color: #4373eb;
  // cursor: pointer;
  // // font-size: 10px;
  // height: fit-content;
  // background-color: transparent;
  // border: none;
  // border-radius: 5px;
  // opacity: 0.9;
  // &:hover {
  //   background-color: #2283c3;
  //   color: #fff;
  // }
`;
