import React, { useState, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme CSS file
import moment from "moment";
import GlobalModal from "../Modal/modal";
import { StyledBg } from "../../styles/StyledComponents";
import { Button } from "..";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { CustomLabel, TextInput } from "../form-components/input/style";
import styled from "styled-components";

const DateRangePickerInput = ({
  control,
  name,
  errors,
  label,
  dateFormat = "YYYY-MM-DD",
  months = 2,
  readOnly,
  placeholder = "Select Date Range",
  isRequired = false,
}) => {
  const { theme } = useSelector((state) => state.theme);
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <InputContainer>
      {label && (
        <CustomLabel title={label}>
          {label}
          {isRequired ? <span style={{ color: "red" }}> *</span> : ""}
        </CustomLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const startDateFormatted = field.value?.startDate
            ? moment(field.value.startDate).format("DD/MM/YYYY")
            : "";
          const endDateFormatted = field.value?.endDate
            ? moment(field.value.endDate).format("DD/MM/YYYY")
            : "";

          const handleClear = () => {
            field.onChange({ startDate: "", endDate: "" });
            setOpen(false);
          };

          const handleDateChange = (range) => {
            // Format the dates as needed before storing
            const formattedStartDate = moment(range.selection.startDate).format(
              dateFormat
            );
            const formattedEndDate = moment(range.selection.endDate).format(
              dateFormat
            );

            field.onChange({
              startDate: formattedStartDate,
              endDate: formattedEndDate,
            });
          };

          return (
            <>
              <div style={{ position: "relative" }}>
                <TextInput
                  style={{
                    ...(errors[name]?.message
                      ? { border: "1px solid red" }
                      : {}),
                  }}
                  type="text"
                  readOnly={readOnly}
                  value={
                    startDateFormatted && endDateFormatted
                      ? `${startDateFormatted} - ${endDateFormatted}`
                      : ""
                  }
                  onClick={() => setOpen(!open)}
                  // style={{
                  //   padding: "8px",
                  //   width: "100%",
                  //   border: "1px solid #ccc",
                  //   borderRadius: "4px",
                  //   cursor: "pointer",
                  //   backgroundColor: "white",
                  //   outline: "none", // Remove default focus outline
                  // }}
                  placeholder={placeholder}
                  onFocus={(e) =>
                    (e.target.style.border = `1px solid ${
                      theme?.primaryColor || "green"
                    }`)
                  } // Remove border on focus
                  onBlur={(e) => (e.target.style.border = "1px solid grey ")} // Restore border on blur
                />
                {field.value?.startDate && field.value?.endDate ? (
                  <ClearIcon
                    onClick={handleClear}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#999",
                      fontSize: "20px",
                    }}
                  />
                ) : (
                  <CalendarMonthIcon
                    onClick={() => !readOnly && setOpen(!open)}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      color: "#999",
                      fontSize: "20px",
                    }}
                  />
                )}
              </div>
              <GlobalModal
                ref={ref}
                open={open}
                onClose={() => setOpen(false)}
                // width={"6"}
              >
                <StyledBg>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: field.value?.startDate
                          ? new Date(field.value.startDate)
                          : new Date(),
                        endDate: field.value?.endDate
                          ? new Date(field.value.endDate)
                          : new Date(),
                        key: "selection",
                      },
                    ]}
                    inputRanges={[]} // #9371
                    onChange={handleDateChange}
                    moveRangeOnFirstSelection={false}
                    rangeColors={[theme?.buttons_color || "red"]}
                    showSelectionPreview={true}
                    editableDateInputs={true}
                    months={months}
                    direction="horizontal"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={() => setOpen(false)}>Submit</Button>
                  </div>
                </StyledBg>
              </GlobalModal>
            </>
          );
        }}
      />
      {/* {errors[name]?.startDate && (
        <p style={{ color: "red", marginTop: "5px" }}>
          {errors[name].startDate.message}
        </p>
      )}
      {errors[name]?.endDate && (
        <p style={{ color: "red", marginTop: "5px" }}>
          {errors[name].endDate.message}
        </p>
      )} */}
      {errors[name]?.message && (
        <p
          style={{
            fontSize: "10px",
            textAlign: "left",
            color: "red",
            fontWeight: "500",
            bottom: "0px",
            margin: "0px",
          }}
        >
          {errors[name].message}
        </p>
      )}
    </InputContainer>
  );
};
const InputContainer = styled.div`
  position: relative;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "75px")};
  width: ${({ Width }) => (Width ? Width && `${Width}px` : "100%")};
`;

export default DateRangePickerInput;
