import { Box, Button, Card, CardContent, Container } from "@mui/material";
import React, { useEffect } from "react";
import { Typography } from "../../components";
import { useGetClaimListing } from "./service";
import ClaimCard from "./Components/ClaimCard";
import { useNavigate } from "react-router-dom";
import CardSkeleton from "../../utils/sharedComponent/PolicyCardSkeleton";
import styled from "styled-components";
import NoDataFound from "../../utils/sharedComponent/NoDataFound";
import PolicyCardComponent from "../All-Policies/Components/PolicyCardComponent";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";

const NewClaim = () => {
  const { Text, StyledH1 } = Typography;
  const {
    data: claimData,
    mutate: claimMutate,
    isLoading,
  } = useGetClaimListing();

  const claimDataList = claimData?.data?.data;
  console.log({ claimDataList });
  const payload = {
    page: 0,
    size: 0,
    sortOrder: "",
    searchValues: {
      policynumber: "",
      insurnacecompanyid: 0,
      policyholdername: "",
      mobilenumber: "",
      claimintimationdate: "",
      claimstage: "",
      tat: 0,
      lob: "",
    },
    outputFormat: "",
  };

  useEffect(() => {
    claimMutate(payload);
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Container
        style={{
          backgroundColor: "#fff",
          margin: "15px 0",
          padding: "30px 20px 20px 20px",
          borderRadius: ".8rem",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <StyledH1
              style={{
                fontSize: "24px",
                color: "#011340",
                width: "100%",
                fontWeight: "600",
                padding: "0px",
                lineHeight: "1rem",
              }}
            >
              Claims
              <Text style={{ fontSize: ".8rem" }}>
                Information of requested claims is listed here.
              </Text>
            </StyledH1>
          </Box>
          <Box>
            <Button
              variant="outlined"
              style={{ borderRadius: "30px" }}
              onClick={() => {
                navigate("/all-policies");
              }}
            >
              Raise a Claim
            </Button>
          </Box>
        </Box>
        <Box>
          {isLoading ? (
            <CardSkeleton />
          ) : claimDataList?.length === 0 ? (
            <NoDataFound image={true} />
          ) : (
            Array.isArray(claimDataList) &&
            claimDataList?.map((claimDataListing) => (
              <ClaimCard claimDataListing={claimDataListing} />
            ))
          )}
        </Box>
      </Container>
    </>
  );
};

export default NewClaim;
// export const Heading = styled(Box)`
//   font-size: ${({ small }) => (small ? "14px" : "24px")};
//   color: #011340;
//   width: 100%;
//   font-weight: ${({ small }) => (small ? "400" : "600")};
//   margin-bottom: ${({ small }) => small && "15px"};
// `;

// export const MainCard = styled(Card)`
//   padding: 14px;
//   border-radius: 15px !important;
// `;

// export const FilterTagContainer = styled.div`
//   display: flex;
// `;

// export const FilterHR = styled.hr`
//   margin: 0px 0px 15px 0;
//   border: 1px solid #3300ff;
//   opacity: 30%;
// `;

// export const StyledMainDiv = styled.div`
//   border-radius: 10px;
//   padding: 10px;
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
// `;
// export const StyledHeader = styled.div`
//   display: flex;
//   align-items: center;
//   padding-bottom: 10px;
//   margin-bottom: 10px;
// `;

// export const TitleSpan = styled.span`
//   font-size: 20px;
//   font-weight: ${({ small }) => (small ? "700" : "400")};
//   line-height: 19.53px;
//   letter-spacing: 0.02em;
//   color: "#444444";
// `;

// export const StyledStepper = styled.div`
//   // border:1px solid yellow;
//   padding: 10px;
// `;
