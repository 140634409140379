//LOBS
export const MISCELLANEOUS_INSURANCE = "miscellaneousInsurance";

// FORMS
export const PREVIOUS_POLICY_DETAILS = "Previous Policy Detail";
export const INSURANCE_COMPANY_DETAILS = "Insurance Company Detail";
export const SOURCING_DETAILS = "Sourcing Details";
export const POLICY_DETAILS = "Policy Details";
export const PROPOSER_DETAILS = "Proposer Details";
export const INSURED_MEMBER_DETAILS = "Insured Member's Details";
export const NOMINEE_DETAILS = "Nominee Details";
export const PREMIUM_DETAILS = "Premium Details";
export const PAYMENT_DETAILS = "Payment Details";
export const VEHICLE_DETAILS = "Vehicle Details";
export const CARGO_DETAILS = "Cargo Details";
export const PROPERTY_DETAILS = "Property Details";
export const EMPLOYEE_DETAILS = "Employee Details";
export const CONTRACTOR_DETAILS = "Contractor Detail"; // conditional form rendering not required, changing the value for now so that its logic does not trigger, will remove the respective logic later
export const PI_DETAILS = "PI Details";
export const LIFE_ASSURED_DETAILS = "Life Assured Details";
export const BASIC_DETAILS = "Basic Details";
export const DETAILS_OF_LOSS = "Details of Loss";
export const CLAIMANTS_DETAILS = "Claimant's details";
export const TRAVEL_INSURED_MEMBER = "Travel Insured Member's details";
// PLAN TYPES
export const PLAN_TYPE_LAGHU_UDHYAM = "Bharat Laghu Udyam Suraksha";
export const PLAN_TYPE_SOOKSHMA_UDHYAM = "Bharat Sookshma Udyam Suraksha";
export const PLAN_TYPE_STD_FIRE_SPECIAL_PERILS =
  "STANDARD FIRE AND SPECIAL PERILS POLICY";
export const PLAN_TYPE_GRIHA_RAKSHA = "Bharat Griha Raksha Policy";

// BUSINESS TYPES
export const NEW = "New";
export const RENEWAL = "Renewal";
export const RENEWAL_BREAK_IN = "Renewal Break-In";
export const RENEWAL_ROLL_OVER = "Renewal Roll-Over";
export const ROLL_OVER = "Roll-Over";

// POLICY TYPES
export const COMPREHENSIVE = "Comprehensive";

export const SAOD = "SAOD" || "OD Only";

export const SATP = "SATP" || "TP Only";

export const COMPREHENSIVE_BUNDLED_1_PLUS_3 =
  "COMPREHENSIVE BUNDLED ( 1 Year OD + 3 Year TP )";

export const COMPREHENSIVE_BUNDLED_1_PLUS_5 =
  "COMPREHENSIVE BUNDLED ( 1Year OD +5 Year TP )";

export const COMPREHENSIVE_BUNDLED_5_PLUS_5 =
  "COMPREHENSIVE BUNDLED ( 5Year OD +5 Year TP )";

export const COMPREHENSIVE_BUNDLED_3_PLUS_3 =
  "COMPREHENSIVE BUNDLED ( 3 Year OD +3 Year TP )";

export const COMPREHENSIVE_1_PLUS_1 = "COMPREHENSIVE ( 1 Year OD + 1 Year TP )";
export const COMPREHENSIVE_2_PLUS_2 = "COMPREHENSIVE ( 2 Year OD + 2 Year TP )";
export const COMPREHENSIVE_3_PLUS_3 = "COMPREHENSIVE ( 3 Year OD + 3 Year TP )";

export const TP_LONG_TERM_3_YEARS = "TP Long Term ( 3 Years )";
export const TP_LONG_TERM_5_YEARS = "TP Long Term ( 5 Years )";

export const TP_1_YEAR = "TP ( 1 Year )";

// CONSTANTS
export const unionTerritories = [
  "Andaman Nicobar",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "JAMMU & KASHMIR",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];

export const companyFields = [
  "comapnyName",
  "companyAddress",
  "pincode",
  "state",
  "city",
  "companystartdate",
];

export const individualFields = [
  "title",
  "firstName",
  "middleName",
  "lastName",
  "gender",
  "dob",
  "age",
  "anniversary",
  "education",
  "occupation",
  "aadharCardno",
  "panCardno",
  "drivingLicence",
  "addressLineone",
  "addressLinetwo",
  "sourcingPinCode",
  "sourcingState",
  "sourcingCity",
  "landmark",
];

export const floaterCoverFields = [
  "floaterCovershookshma",
  "addressLinetwooinsured",
  "pincodetwooinsured",
  "statetwoinsured",
  "citytwoinsured",
];

export const grihaRakshaFields = [
  "ownertenantGriha",
  "standaloneGriha",
  "multistoreyGriha",
  "carpetGriha",
  "rateofcostGriha",
  "suminsuredcontentGriha",
  "suminsuredhouseGriha",
];

export const commonForGrihaRakshaFields = [
  "locationofRisk",
  "addressLineoneinsured",
  "addressLinetwoinsured",
  "pincodeinsured",
  "stateinsured",
  "cityinsured",
  "installationofFire",
  "whetherSecurity",
];

export const piIndividualFields = [
  "namepi",
  "mobileNopi",
  "profession",
  "specilization",
  "titlepi",
  "dobpi",
  "addressLine",
  "emailIdpi",
  "city",
  "state",
  "pinCode",
  "insuredName",
  "territory",
  "jurisdiction",
  "numberOfprac",
  "gstDetails",
  "sInsured",
  "anyClaim",
  "limitOfIndemnity",
];

export const piCompanyFields = [
  "companyName",
  "companyMobileNo",
  "companyEmailID",
  "companyAddressLine",
  "city",
  "state",
  "pinCode",
  "territory",
  "jurisdiction",
  "registrationNumber",
  "yearOfregistration",
  "companyRegistration",
  "gstDetails",
  "sInsured",
  "limitOfIndemnity",
  "totalNumberofemployee",
  "companyTurnover",
  "industryCategory",
];

export const hidePaymentFieldsForCashPayment = [
  "micrCode",
  "chequeNo",
  "transactionId Id",
  "ifscCode",
  "accNumber",
  "accountType",
  "bankBranchname",
  "banName",
];
export const hidePaymentFieldsForOnlinePayment = ["micrCode", "chequeNo"];
export const EXPENSE_DETAILS = "Expenses Details";
export const LOSS_ITEM_DETAILS = "Loss Item Details";
export const DOCUMENTS = "Documents";
export const TRAVEL_DETAILS = "Travel Details";

export const accidentDeathOrPartialDisabilityFieldList = [
  "placeofAccident",
  "datentimeofAccident",
  "hospitalization",
  "causeofDeath",
  "natureofDisability",
  "nameofHospital",
  "durationinHospital",
];

export const medicalExpensesEmergencyDentalCareFieldList = [
  "dateofEmergency",
  "dateofAdmission",
  "dateofDischarge",
  "nameofHospital",
  "attendingDoctor",
  "describetheAccident",
  "totalExpenses",
];

export const lossOfCheckedInBaggageAndDelayOfCheckedInBaggageFieldList = [
  "dateoflossDelay",
  "placeofLoss",
  "circumstancesofLoss",
];

export const theftFieldList = [
  "datentimeofLoss",
  "intimatedPolice",
  "descriptionofLoss",
];

export const lossOfPassportFieldList = ["intimatedPolice", "datentimeofLoss"];

export const flightDelayFieldList = [
  "InformationcauseofDelay",
  "compensationReceived",
];

export const fieldLists = {
  "Accident Death or Partial Disability": [
    "placeofAccident",
    "datentimeofAccident",
    "hospitalization",
    "causeofDeath",
    "natureofDisability",
    "nameofHospital",
    "durationinHospital",
    "Claimdropdown",
  ],
  "Medical Expenses / Emergency Dental Care": [
    "dateofEmergency",
    "dateofAdmission",
    "dateofDischarge",
    "nameofHospital",
    "attendingDoctor",
    "describetheAccident",
    "totalExpenses",
    "Claimdropdown",
  ],
  "Loss of Checked in Baggage and Delay of Checked in Baggage": [
    "dateoflossDelay",
    "placeofLoss",
    "circumstancesofLoss",
    "Claimdropdown",
  ],
  Theft: [
    "datentimeofLoss",
    "intimatedPolice",
    "descriptionofLoss",
    "Claimdropdown",
  ],
  "Loss of Passport": ["intimatedPolice", "datentimeofLoss", "Claimdropdown"],
  "Flight Delay": [
    "InformationcauseofDelay",
    "compensationReceived",
    "Claimdropdown",
  ],
};
