import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller } from "react-hook-form";
import Typography from "../Typography/Typography";
import { CustomLabel, DatePickerWrapper } from "../form-components/input/style";
import { useGetConditionalErrorMessage } from "../../hooks/useGetConditionalErrorMessage";
// import { StyledValue } from "../../modules/Proposal-Journey/Proposal-Listing/ProposalListingView";
import moment from "moment";
import { useGetSummaryValue } from "../../hooks/useGetSummaryValue";
import { StyledValue } from "../form-components/dateAndTimePicker/DateAndTimePicker";

function DatePickerInput({
  name,
  label,
  id,
  control,
  proposalRead,
  readOnly,
  placeholder = "Enter date",
  minDate,
  maxDate,
  testId,
  isRequired,
  description,
  watch,
  errors,
  labelSize,
  claim,
}) {
  const { Error } = Typography;

  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id,
  });

  const { fieldValue } = useGetSummaryValue({
    watch,
    id,
  });

  const dateFieldValue = fieldValue
    ? moment(fieldValue).format("DD/MM/YYYY")
    : "- - -";

  return (
    <DatePickerWrapper
      proposalRead={proposalRead}
      errorMessage={errorMessage}
      readOnly={readOnly}
      claim={claim}
    >
      <CustomLabel htmlFor={id} title={label} claim={claim}>
        {label?.length > 35 ? `${label.slice(0, 35)}...` : label}
        {isRequired ? <span className="isRequired">*</span> : null}
        {description && !proposalRead && (
          <Tooltip title={description} arrow placement="top">
            <InfoOutlinedIcon
              sx={{
                fontSize: "12px",
                marginLeft: "3px",
                position: "relative",
                top: "1px",
              }}
              className="info"
            />
          </Tooltip>
        )}
      </CustomLabel>

      {proposalRead ? (
        <StyledValue>{dateFieldValue || "- - -"}</StyledValue>
      ) : (
        <>
          <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id={id}
                  name={name}
                  control={control}
                  minDate={minDate}
                  maxDate={maxDate}
                  readOnly={readOnly}
                  value={value}
                  onChange={onChange}
                  views={["year", "month", "day"]}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      placeholder: placeholder ? placeholder : "",
                      "data-testid": testId,
                    },
                    field: { clearable: !proposalRead },
                  }}
                  slots={{ openPickerIcon: CalendarIcon, clearIcon: ClearIcon }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      data-testid={testId}
                      helperText={params.error && "Please provide a valid date"}
                    />
                  )}
                  PopperProps={{
                    placement: "top-start",
                  }}
                />
              </LocalizationProvider>
            )}
          />
          {!!errorMessage && <Error>{errorMessage}</Error>}
        </>
      )}
    </DatePickerWrapper>
  );
}

export default DatePickerInput;

// used as icon for calendar button
export function CalendarIcon() {
  return <CalendarMonthIcon sx={{ fontSize: "15px" }} />;
}

// used as icon to clear selection
export function ClearIcon() {
  return <CloseIcon sx={{ fontSize: "15px" }} />;
}
