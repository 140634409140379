import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TableSkeleton from "../../components/SkeletonLoader/TableSkeleton";
import { formatPrice } from "../../helperFunction";
import SplitButton from "../MyDocuments/components/ButtonGroup/ButtonGroup";
import { useGetDataForMasterTable } from "./MasterDataServices";
import { interpreter } from "../../utils/interpeter";

const MasterDataTable = ({
  api,
  searchFormData,
  additionalPayloadFields,
  sort,
  payloadType,
  method,
  CustomActions,
  showActionTitles,
  removeTopMargin,
  renderTopToolbarCustomActions,
  heading,
  cellDensity,
  customPayload,
  maxHeight,
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { tableRowData, tableColumnData, isFetching, isLoading, totalRecords } =
    useGetDataForMasterTable({
      api,
      searchFormData,
      additionalPayloadFields,
      sort,
      pagination,
      payloadType,
      method,
      customPayload,
    });

  //   const { theme } = useSelector((state) => state.theme);
  const theme = {};

  function isUrl(string) {
    // Regex pattern for matching URLs
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // http:// or https://
        "((([A-Za-z0-9-]+)\\.)+[A-Za-z]{2,})" + // Domain name
        "(\\:[0-9]+)?" + // Optional port number
        "(\\/[-A-Za-z0-9%_.~+]*)*" + // Path
        "(\\?[;&A-Za-z0-9%_.~+=-]*)?" + // Query string
        "(\\#[-A-Za-z0-9_]*)?$" // Fragment locator
    );
    // Test the string against the pattern
    return urlPattern.test(string);
  }

  const columns = useMemo(() => {
    return tableColumnData
      ?.filter(({ header }) => header !== "uniqueKey")
      .map((column) => {
        let obj = {
          Cell: ({ cell, row }) => {
            if (cell?.getValue() && typeof cell?.getValue() === "string") {
              if (cell?.getValue().startsWith("f(x):")) {
                return (
                  <span>
                    {interpreter(
                      cell?.getValue()?.replace("f(x):", "").trim(),
                      row.original
                    ) || "- - -"}
                  </span>
                );
              } else {
                return <span>{cell?.getValue() || "- - -"}</span>;
              }
            }
          },
        };

        if (
          ["leadStatus", "leadPriority", "transactionStage"].includes(
            column?.accessorKey
          )
        ) {
          obj = {
            Cell: ({ cell, row }) => {
              return (
                <div
                  style={{
                    minHeight: "40px",
                    minWidth: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SplitButton
                    cellData={cell}
                    rowData={row}
                    accessorKey={column?.accessorKey}
                  />
                </div>
              );
            },
          };

          return {
            ...column,
            ...obj,
          };
        }

        switch (column.type?.toLowerCase()) {
          case "amount":
            obj = {
              Cell: ({ cell }) => {
                return <span>{formatPrice(cell?.getValue()) || "- - -"}</span>;
              },
            };
            break;

          case "date":
            obj = {
              Cell: ({ cell }) => {
                if (column?.accessorKey === "Policy Expiry date") {
                  const expiryDate = cell?.getValue();
                  let daysLeft =
                    moment(expiryDate).diff(new Date(), "days") + 1;
                  let textExpiry = `Expires in ${
                    moment(expiryDate).diff(new Date(), "days") + 1
                  } Days`;
                  let textDaysLeft = `${
                    moment(expiryDate).diff(new Date(), "days") + 1
                  } Days left`;
                  return (
                    <div>
                      <div>{moment(expiryDate).format("DD/MM/YYYY")}</div>
                      <div
                        style={{
                          color: daysLeft > 45 ? "green" : "red",
                          fontSize: "12px",
                          paddingLeft: "1.25px",
                        }}
                      >
                        {daysLeft > 45 ? textDaysLeft : textExpiry}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <span>
                      {cell?.getValue()
                        ? moment(cell?.getValue())?.format("DD-MM-YYYY")
                        : "- - -"}
                    </span>
                  );
                }
              },
            };
            break;

          case "link":
            obj = {
              Cell: ({ cell }) => {
                return (
                  <LinkContainer href={cell?.getValue()} target="_blank">
                    Click Here
                  </LinkContainer>
                );
              },
            };
            break;

          case "file":
            obj = {
              Cell: ({ cell }) => {
                return (
                  <LinkContainer
                    href={isUrl(cell?.getValue()) && cell?.getValue()}
                    target="_blank"
                    style={{
                      color: !isUrl(cell?.getValue()) && "grey",
                      textDecoration: "none",
                    }}
                  >
                    Download
                  </LinkContainer>
                );
              },
            };
            break;

          case "status":
            obj = {
              Cell: ({ cell }) => {
                const isActive = cell?.getValue() === "Y";
                return (
                  <StatusContainer isActive={isActive}>
                    {isActive ? (
                      <CheckCircleOutlineRoundedIcon />
                    ) : (
                      <CancelOutlinedIcon />
                    )}
                  </StatusContainer>
                );
              },
            };
            break;

          case "list":
            obj = {
              Cell: ({ cell }) => {
                const value = cell?.getValue();
                return (
                  <span>{Array.isArray(value) ? value.join(", ") : value}</span>
                );
              },
            };
            break;

          case "image":
            obj = {
              Cell: ({ cell }) => {
                return <img src={cell?.getValue()} alt="" width={50} />;
              },
            };
            break;

          case "color":
            obj = {
              Cell: ({ cell }) => {
                return (
                  <ColorContainer>
                    <ColorTile backgroundColor={cell?.getValue()} />
                    {cell?.getValue()}
                  </ColorContainer>
                );
              },
            };
            break;

          default:
            break;
        }

        return {
          ...column,
          ...obj,
          filterFn: "contains", // filter mode for the column filter is set to contains.
        };
      });
  }, [tableColumnData]);

  const accessorKeyData = tableColumnData?.filter(({ header }) => {
    return header === "uniqueKey";
  });

  const uniqueKey = accessorKeyData?.[0]?.accessorKey;

  const data = useMemo(() => {
    return tableRowData;
  }, [tableRowData]);

  // Dynamically generate the left array based on position and sequence
  const dynamicInitialState = useMemo(() => {
    const left = columns
      .filter((column) => column.position === "fixed")
      .sort((a, b) => a.sequence - b.sequence)
      .map((column) => column.accessorKey);

    return {
      columnPinning: {
        left,
        right: ["actions", "mrt-row-actions"], // Fixed actions column on the right
        initialState: { density: "compact" },
      },
    };
  }, [columns]);
  const table = useMaterialReactTable({
    columns,
    data,
    globalFilterFn: "contains",
    initialState: {
      ...dynamicInitialState,
      density: "compact",
    },
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnDragging: true,
    enableColumnOrdering: true,
    // layoutMode: "grid",
    // change if default width is not as required
    onPaginationChange: setPagination,
    defaultColumn: {
      minSize: 10,
      maxSize: 9001,
      size: cellDensity === "compact" ? 10 : 150,
    },
    enablePagination: false,
    enableBottomToolbar: true,
    manualPagination: true,
    rowCount: totalRecords || 10,
    state: {
      pagination,
      isLoading: isFetching || isLoading,
    },
    enableDensityToggle: false,

    muiTableContainerProps: {
      sx: {
        maxHeight: maxHeight || "500px",
        "& .MuiBox-root css-wsew38": {
          alignItems: "center !important",
        },
        "& .css-1tqmzm-MuiTableCell-root .Mui-TableHeadCell-Content ": {
          backgroundColor: "red",
        },
        "& .css-hsi95o-MuiTableRow-root td": {
          fontFamily: theme?.regularFont || "Manrope",
        },
        "&.MuiBox-root css-10gei56": {
          display: "none",
        },
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "4px",
          border: "none",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#dfdfdf",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
        },
      },
    },

    muiTopToolbarProps: {
      sx: {
        "&.MuiBox-root .MuiBox-root": {
          padding: "0",
          alignItems: "center",
        },
      },
    },

    muiTableHeadCellProps: ({ column }) => {
      const isActionColumn =
        column?.id?.toLocaleLowerCase() === "mrt-row-actions";
      return {
        //conditionally style pinned columns
        sx: {
          backgroundColor: column.getIsPinned()
            ? `${"#F3F7FA"} !important`
            : `${"#F3F7FA"} !important`,
          "&:before": {
            backgroundColor: "inherit !important",
            boxShadow: "none !important",
          },
          "&:after": {
            backgroundColor: "inherit !important",
            boxShadow: "none !important",
          },
          color: "#4373EB",
          padding: "10px !important",
          fontWeight: "600 !important",
          fontSize: "14px",
          lineHeight: "16px",
          "& .Mui-TableHeadCell-Content": {
            display: "flex",
            justifyContent: isActionColumn && "center",
            marginTop: isActionColumn && "6px",
          },
        },
      };
    },

    muiTableBodyCellProps: ({ column }) => ({
      //conditionally style pinned columns
      sx: {
        fontWeight: column.getIsPinned() ? "normal" : "normal",
        padding: "10px",
        "&:before": {
          boxShadow: "none !important",
        },
        "&:after": {
          boxShadow: "none !important",
        },
      },
    }),

    muiTablePaperProps: {
      elevation: 0, //change the mui box shadow
      //customize paper styles
      sx: {
        // border: "1px solid #e0e0e0",
        padding: "0 15px 10px 15px",
        width: "100%",
      },
    },
    renderTopToolbarCustomActions,
    enableColumnPinning: true,
    enableRowActions: tableRowData?.length && CustomActions && showActionTitles,
    renderRowActions: ({ row }) => {
      return <CustomActions row={row} uniqueKey={uniqueKey} />;
    },

    // layoutMode: "grid-no-grow", //constant column widths

    // renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],

    // initialState: {
    //   columnPinning: {
    //     left: ["state", "firstName"],
    //     right: ["actions"],
    //   },
    // },
  });

  if (isFetching || isLoading) return <TableSkeleton />;

  return <MaterialReactTable table={table} />;
};

export default MasterDataTable;

const TableContainer = styled.div`
  background: #fff;
  width: 100%;
  margin-top: ${({ removeTopMargin }) => (removeTopMargin ? "0" : "20px")};
  padding: 0 0 10px 0;
  margin-bottom: 20px;
  border-radius: 150px;
`;

const LinkContainer = styled.a`
  color: #5985ff;
`;

const StatusContainer = styled.div`
  svg {
    color: ${({ isActive }) => (isActive ? "#4BA64E" : "#E04F4F")};
  }
`;

const ColorContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const ColorTile = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;
