import DashboardIcon from "@mui/icons-material/Dashboard";
import AppsIcon from "@mui/icons-material/Apps";
import CachedIcon from "@mui/icons-material/Cached";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import FeedIcon from "@mui/icons-material/Feed";
export const icons = {
  DashboardIcon,
  AppsIcon,
  CachedIcon,
  UploadFileIcon,
  MonitorHeartIcon,
  HowToRegIcon,
  AssignmentTurnedInOutlinedIcon,
  WebAssetIcon,
  FeedIcon,
};

export const SideBarLinks = [
  {
    name: "Dashboard",
    location: "/",
    icon: "DashboardIcon",
  },
  {
    name: "All Policies",
    location: "/all-policies",
    icon: "AppsIcon",
  },
  {
    name: "Renew Policies",
    location: "/renew-policies",
    icon: "CachedIcon",
  },
  {
    name: "Claims",
    location: "/claims",
    icon: "UploadFileIcon",
  },
  {
    name: "Activity Logs",
    location: "/activity",
    icon: "MonitorHeartIcon",
  },
  {
    name: "Endorsement",
    location: "/endorsement",
    icon: "HowToRegIcon",
  },
  {
    name: "My Documents",
    location: "/my-documents",
    icon: "AssignmentTurnedInOutlinedIcon",
  },
  {
    name: "Assets",
    location: "/assets",
    icon: "WebAssetIcon",
  },
  {
    name: "Certificates",
    location: "/policy-group-certificates-list",
    icon: "FeedIcon",
  },
];
