import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import * as React from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { useState } from "react";

export default function SplitButton({ cellData, rowData, accessorKey }) {
  const location = useLocation();
  const leadID = rowData?.original?.leadId;
  const mongoID = rowData?.original?._id;

  const isLeadStatus = accessorKey?.toLowerCase() === "leadstatus";
  const isProposalTransactionStatus =
    location?.pathname === "/proposals" &&
    accessorKey?.toLowerCase() === "transactionstage";

  const summaryData = {};

  const transactionStageOptions = [];

  const options = isLeadStatus
    ? ["ACTIVE", "INACTIVE", "NEW", "IN PROGRESS", "SUCCESS"]
    : isProposalTransactionStatus
    ? transactionStageOptions
    : ["High", "Normal", "Low"];

  const currentRowStatus = cellData?.getValue();

  const { theme } = useSelector((state) => state.theme);
  const matchedIndex = options?.findIndex(
    (item) => item?.toLowerCase() === currentRowStatus?.toLowerCase()
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(matchedIndex);

  const [inputData, setInputData] = useState("");

  const handleMenuItemClick = (event, index) => {
    Swal.fire({
      text: "Do you want to update status?",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#DC004E",
      showCancelButton: true,
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: false,
      scrollbarPadding: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          [isLeadStatus ? "leadStatus" : "leadPriority"]: options[index],
        };
        setSelectedIndex(index);
        setOpen(false);
      }
    });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      {!open && (
        <ButtonGroup
          sx={{ padding: 0, minHeight: "20px" }}
          variant="contained"
          ref={anchorRef}
          aria-label="Button group with a nested menu"
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: "0 20px",
              fontSize: "10px",
              minWidth: "120px !important",
              backgroundColor: theme.secondaryColor,
              color: theme.primaryColor,
              "&:hover": {
                backgroundColor: theme.secondaryColor,
              },
              borderColor: `${theme.primaryColor} !important`,
            }}
            disableRipple
          >
            {options[selectedIndex]}
          </Button>
          <Button
            sx={{
              padding: 0,
              minWidth: "28px !important",
              backgroundColor: theme.secondaryColor,
              color: theme.primaryColor,
              "&:hover": {
                backgroundColor: theme.secondaryColor,
              },
            }}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      )}
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            id="split-button-menu"
            autoFocusItem
            sx={{
              maxHeight: "40px",
              overflow: "auto",
              backgroundColor: "white",
              "&.MuiList-root": {
                padding: 0,
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                disableGutters
                sx={{
                  padding: "3px 15px",
                  fontSize: "10px",
                  minWidth: "120px !important",
                }}
                key={option}
                selected={index === selectedIndex}
                onClick={(event) =>
                  isProposalTransactionStatus
                    ? null
                    : handleMenuItemClick(event, index)
                }
              >
                {option}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
}
