import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import styled from "styled-components";
import { Input } from "../../components";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import {
  allowOnlyNumbers,
  preventFirstZero,
} from "../../HelperFunctions/HelperFunctions";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetRiskoMeter } from "../customerDashboard/service";
import { useGetCoverageAmount, useRiskometer } from "./service";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveData, setResAction } from "./riskoMeterMember.slice";

const RiskometerCoverage = ({ handleSave, handleBack, activeTab }) => {
  const { coverageAmountPayload, resData, allData, range } = useSelector(
    (state) => state.riskoMeterMember
  );

  const isSelfAgeOnly = allData?.MembersAge?.selfAge;
  const isMoreThanOne = Object.keys(allData?.MembersAge || {}).length > 1;

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const {
    mutateAsync: ageRiskometer,
    isLoading: customerAddLoading,
    data,
  } = useRiskometer(activeTab);
  const dispatch = useDispatch();

  const [coverageAmount, setCoverageAmount] = useState(0);
  const [employerCoverage, setEmployerCoverage] = useState("");
  const [coverageAmount2, setCoverageAmount2] = useState(0);
  const [showEmployerCoverageAmount, setShowEmployerCoverageAmount] =
    useState(false);
  const bothCoverageAmount = +coverageAmount + +coverageAmount2;

  const totalCoverage =
    employerCoverage === "Yes" ? bothCoverageAmount : coverageAmount;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");
  const lobName = queryParams.get("lobName");
  const { data: policyCoverageAmount } = useGetCoverageAmount(lobId);

  const [selectedEmployerCoverage, setSelectedEmployerCoverage] =
    useState("No");

  const values = getValues();

  const onSubmit = async () => {
    const payload = {
      questionId: lobName === "Health" ? 2 : 4,
      lobId: +lobId,
      request_data: {
        coverage: String(totalCoverage),
        age: coverageAmountPayload?.request_data?.age,
        familyType: coverageAmountPayload?.request_data?.familyType,
      },
    };

    dispatch(
      saveData({
        Coverage: {
          ...values,
          selectedEmployerCoverage,
          coverageAmount,
          coverageAmount2,
        },
      })
    );

    const res = await ageRiskometer(payload);

    if (res?.success) {
      handleSave();
    }
  };

  const handleEmployerCoverageChange = (value) => {
    setEmployerCoverage(value);
    setSelectedEmployerCoverage(value);
    setShowEmployerCoverageAmount(value === "Yes");
  };

  const { theme } = useSelector(({ theme }) => theme);

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated);

    // Create chart
    let chart = am4core.create("chartdiv", am4charts.GaugeChart);
    chart.innerRadius = -15;

    let axis = chart.xAxes.push(new am4charts.ValueAxis());
    axis.min = 0;
    axis.max = 5;
    axis.strictMinMax = true;

    let gradient = new am4core.LinearGradient();
    gradient.stops.push({ color: am4core.color("red") });
    gradient.stops.push({ color: am4core.color("yellow") });
    gradient.stops.push({ color: am4core.color("green") });

    axis.renderer.line.stroke = gradient;
    axis.renderer.line.strokeWidth = 15;
    axis.renderer.line.strokeOpacity = 1;

    axis.renderer.labels.template.fontSize = 14;
    axis.renderer.labels.template.fill = am4core.color("black");
    axis.renderer.labels.template.radius = 10;

    axis.renderer.grid.template.disabled = true;

    let hand = chart.hands.push(new am4charts.ClockHand());
    hand.radius = am4core.percent(97);

    hand.showValue(
      range.reduce((acc, item) => acc + item, 0) / range.length,
      0,
      am4core.ease.cubicOut
    );

    return () => {
      chart.dispose();
    };
  }, [range]);

  useEffect(() => {
    if (policyCoverageAmount?.data?.data && policyCoverageAmount?.data?.data) {
      setCoverageAmount(policyCoverageAmount?.data?.data);
    }
  }, [policyCoverageAmount?.data?.data]);

  // function formatNumberWithCommas(number) {
  //   // Handle the case where the number is 0 or a very small number
  //   if (number === 0) return "0";

  //   let numStr = number.toString();

  //   // Handle case when the number is less than 1000 (no commas needed)
  //   if (numStr.length <= 3) return numStr;

  //   let lastThreeDigits = numStr.slice(-3);
  //   let remainingDigits = numStr.slice(0, numStr.length - 3);

  //   let formattedNumber = remainingDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  //   return formattedNumber + "," + lastThreeDigits;
  // }

  const formatNumberWithCommas = (num) => {
    num = num?.toString();

    let [integer, decimal] = num.split(".");

    if (integer.length > 3) {
      let lastThree = integer.slice(-3);
      let rest = integer.slice(0, integer.length - 3);

      rest = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      integer = rest + "," + lastThree;
    }

    return decimal ? `${integer}.${decimal}` : integer;
  };

  const numberToWords = (num) => {
    if (num === 0) return "Zero rupees only";

    const words = [];
    const belowTwenty = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    const places = ["", "thousand", "lakh", "crore"];

    const handleChunk = (n, placeValue) => {
      if (n === 0) return;

      let chunkWords = [];
      if (n >= 100) {
        chunkWords.push(belowTwenty[Math.floor(n / 100)]);
        chunkWords.push("hundred");
        n %= 100;
      }

      if (n >= 20) {
        chunkWords.push(tens[Math.floor(n / 10)]);
        if (n % 10) chunkWords.push(belowTwenty[n % 10]);
      } else if (n > 0) {
        chunkWords.push(belowTwenty[n]);
      }

      chunkWords.push(placeValue);
      words.push(chunkWords.join(" "));
    };

    let crores = Math.floor(num / 10000000);
    let lakhs = Math.floor((num % 10000000) / 100000);
    let thousands = Math.floor((num % 100000) / 1000);
    let hundreds = num % 1000;

    handleChunk(crores, "crore");
    handleChunk(lakhs, "lakh");
    handleChunk(thousands, "thousand");
    handleChunk(hundreds, "");

    return (
      words
        .join(" ")
        .trim()
        .replace(/^./, (match) => match.toUpperCase()) + " rupees only"
    );
  };

  const handleIncrement = () => {
    setCoverageAmount((prevAmount) => +prevAmount + 1000);
  };

  const handleDecrement = () => {
    setCoverageAmount((prevAmount) =>
      prevAmount - 1000 >= 0 ? +prevAmount - 1000 : 0
    );
  };

  const handleCoverageAmountChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setCoverageAmount(Number(value) || 0);
  };

  const handleCoverage2Increment = () => {
    setCoverageAmount2((prevAmount) => +prevAmount + 1000);
  };

  const handleCoverage2Decrement = () => {
    setCoverageAmount2((prevAmount) =>
      prevAmount - 1000 >= 0 ? +prevAmount - 1000 : 0
    );
  };

  const handleCoverage2AmountChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    setCoverageAmount2(Number(value) || 0);
  };

  useEffect(() => {
    if (allData?.Coverage) {
      setValue(
        "selectedEmployerCoverage",
        allData.Coverage.selectedEmployerCoverage
      );
      setValue("coverageAmount", allData.Coverage.coverageAmount);
      setValue("coverageAmount2", allData.Coverage.coverageAmount2);
      setCoverageAmount(allData.Coverage.coverageAmount);
      setCoverageAmount2(allData.Coverage.coverageAmount2);
      setSelectedEmployerCoverage(allData.Coverage.selectedEmployerCoverage);
      setShowEmployerCoverageAmount(
        allData.Coverage.selectedEmployerCoverage === "Yes"
      );
    }
  }, [allData, setValue]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <BackButton onClick={handleBack}>
            <ArrowBackIcon />
          </BackButton>
        </div>
        <Title>Policy Coverage</Title>
        <Subtitle>
          Do any member(s) have any existing illnesses for which they take
          regular medication?
        </Subtitle>
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            {/* Coverage Amount Section */}
            <div className="flex flex-col items-center">
              <RangeInput
                min="0"
                max="100000000"
                step="1"
                value={coverageAmount}
                onChange={(e) => setCoverageAmount(e.target.value)}
                title={`Coverage Amount: ₹ ${formatNumberWithCommas(
                  coverageAmount
                )}`}
              />
              <CoverAmountDisplay
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <label htmlFor="coverageAmount" style={{ fontWeight: "bold" }}>
                  Coverage Amount:
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* Increment and Decrement buttons */}
                  <button
                    type="button"
                    onClick={handleDecrement}
                    style={{
                      background: "#e5e5e5",
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                  >
                    -
                  </button>
                  <input
                    id="coverageAmount"
                    type="text"
                    value={formatNumberWithCommas(coverageAmount)}
                    onChange={handleCoverageAmountChange}
                    style={{
                      textAlign: "center",
                      padding: "5px 10px",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      width: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleIncrement}
                    style={{
                      background: "#e5e5e5",
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    +
                  </button>
                </div>
              </CoverAmountDisplay>
              <div style={{ minHeight: "60px" }}>
                Amount in Words:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                  }}
                >
                  {numberToWords(coverageAmount)}
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <label className="text-sm font-medium text-gray-700">
                Do you have a corporate/employer-provided policy?
              </label>
              <ToggleSection>
                <ToggleButton
                  type="button"
                  selected={selectedEmployerCoverage === "Yes"}
                  onClick={() => handleEmployerCoverageChange("Yes")}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  type="button"
                  selected={selectedEmployerCoverage === "No"}
                  onClick={() => handleEmployerCoverageChange("No")}
                >
                  No
                </ToggleButton>
              </ToggleSection>
            </div>

            <EmployerCoverageAmount visible={showEmployerCoverageAmount}>
              <RangeInput
                min="0"
                max="100000000"
                step="1"
                value={coverageAmount2}
                onChange={(e) => setCoverageAmount2(e.target.value)}
              />
              <CoverAmountDisplay
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <label htmlFor="coverageAmount2" style={{ fontWeight: "bold" }}>
                  Employer Coverage Amount:
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    type="button"
                    onClick={() =>
                      handleCoverage2Decrement(setCoverageAmount2, 1000)
                    }
                    style={{
                      background: "#e5e5e5",
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      marginRight: "10px",
                    }}
                  >
                    -
                  </button>
                  <input
                    id="coverageAmount2"
                    type="text"
                    value={formatNumberWithCommas(coverageAmount2)}
                    onChange={(e) =>
                      handleCoverage2AmountChange(e, setCoverageAmount2)
                    }
                    style={{
                      textAlign: "center",
                      padding: "5px 10px",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      width: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      handleCoverage2Increment(setCoverageAmount2, 1000)
                    }
                    style={{
                      background: "#e5e5e5",
                      border: "1px solid #ccc",
                      padding: "5px 10px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    +
                  </button>
                </div>
              </CoverAmountDisplay>
              <div style={{ minHeight: "60px" }}>
                Amount in Words:{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    margin: "10px 0",
                  }}
                >
                  {numberToWords(coverageAmount2)}
                </span>
              </div>
            </EmployerCoverageAmount>
            <hr></hr>
            <CoverAmountDisplay>
              Total Amount: ₹.{" "}
              <span
                style={{
                  fontWeight: "bold",
                  margin: "10px 0",
                }}
              >
                {formatNumberWithCommas(totalCoverage)}
              </span>
            </CoverAmountDisplay>
            <div style={{ minHeight: "60px" }}>
              Amount in Words:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  margin: "10px 0",
                }}
              >
                {numberToWords(totalCoverage)}
              </span>
            </div>
          </div>
          <AddButton type="submit">Continue</AddButton>
        </form>
      </Container>
      {isSelfAgeOnly && !isMoreThanOne && <ChartContainer id="chartdiv" />}
    </div>
  );
};

export default RiskometerCoverage;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
`;

const Container = styled.div`
  border-radius: 8px;
  padding: 24px;
  width: 125vw;
  max-width: 800px;
`;

const BackButton = styled.button`
  background-color: #e5e5e5;
  padding: 8px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 24px;
  top: 22px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #d0d0d0;
  }
`;

const Subtitle = styled.h3`
  text-align: center;
  font-size: 16px;
  padding-top: 30px;
`;

const AddButton = styled.button`
  padding: 10px 20px;
  margin: 10px auto;
  background-color: orange;
  background: ${({ theme }) => theme?.ascentColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
`;

const ChartContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 256px;
`;

const CoverAmountDisplay = styled.p`
  // font-weight: 500;
  // font-size: 1.25rem;
  margin-top: 0.5rem;
`;

const EmployerCoverageAmount = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  margin-top: 24px;
`;

const RangeInput = styled.input.attrs({ type: "range" })`
  width: 100%;
  margin-top: 12px; /* Consistent margin */
`;

const ToggleButton = styled.button`
  background-color: ${({ selected, theme }) =>
    selected ? theme?.ascentColor : "white"};
  color: ${({ selected, theme }) => (!selected ? theme?.ascentColor : "white")};
  border: 1px solid
    ${({ selected, theme }) =>
      !selected ? theme?.ascentColor : theme?.ascentColor};
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  min-width: 6rem;
`;

const ToggleSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
