import { yupResolver } from "@hookform/resolvers/yup";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Tooltip,
  StepButton,
  Box,
  Chip,
} from "@mui/material";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import React from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

// import {
//   useGetBankDataFromIfsc,
//   useGetBranchLocation,
//   useGetCustomer,
//   useGetCustomerById,
//   useGetICBranchDetails,
//   useGetProductsListPolicyEntry,
//   useGetProposalValues,
//   useGetStateCityFromPincode,
//   useGetUserById,
//   useUpdateTransactionStage,
// } from "../ClaimSummary/service";
// import {
//   setBrokerage,
//   setIsPrevPolicyVisibleAction,
//   setPolicyEditIndex,
//   setPolicyViewCards,
//   setProposalCreateValuesAction,
//   setUploadedFileURLAction,
// } from "../../search-and-proceed/proposal.slice";

import {
  removeFileURLAction,
  setBrokerage,
  setIsPrevPolicyVisibleAction,
  setPolicyEditIndex,
  setPolicyViewCards,
  setProposalCreateValuesAction,
  setUploadedFileURLAction,
} from "../proposal.slice";
import Swal from "sweetalert2";

import { useGetCompaniesAccordingToLOB } from "../../../hooks/useGetMasterTableData";

import {
  generateDynamicOptionalYupSchema,
  generateDynamicYupSchema,
} from "../CommonFunctions";
import {
  useGetBankDataFromIfsc,
  useGetBankDetailsByIFSC,
  useGetBranchLocation,
  useGetCustomer,
  useGetCustomerById,
  useGetICBranchDetails,
  useGetLeadById,
  useGetProductsListPolicyEntry,
  useGetProposalValues,
  useGetStateCityFromPincode,
  useGetUserById,
  useUpdateTransactionStage,
  useUploadExpenseDetailsBulkUpload,
} from "./service";
import {
  INSURED_MEMBER_DETAILS,
  NOMINEE_DETAILS,
  PAYMENT_DETAILS,
  POLICY_DETAILS,
  PREVIOUS_POLICY_DETAILS,
  PROPOSER_DETAILS,
  SOURCING_DETAILS,
  VEHICLE_DETAILS,
  PLAN_TYPE_SOOKSHMA_UDHYAM,
  PROPERTY_DETAILS,
  PLAN_TYPE_LAGHU_UDHYAM,
  PLAN_TYPE_STD_FIRE_SPECIAL_PERILS,
  PLAN_TYPE_GRIHA_RAKSHA,
  EXPENSE_DETAILS,
  CONTRACTOR_DETAILS,
  PI_DETAILS,
  grihaRakshaFields,
  commonForGrihaRakshaFields,
  unionTerritories,
  companyFields,
  floaterCoverFields,
  piIndividualFields,
  piCompanyFields,
  LIFE_ASSURED_DETAILS,
  PREMIUM_DETAILS,
  TP_1_YEAR,
  TP_LONG_TERM_5_YEARS,
  TP_LONG_TERM_3_YEARS,
  SATP,
  LOSS_ITEM_DETAILS,
  CARGO_DETAILS,
  DOCUMENTS,
  CLAIMANTS_DETAILS,
  TRAVEL_DETAILS,
  TRAVEL_INSURED_MEMBER,
  accidentDeathOrPartialDisabilityFieldList,
  flightDelayFieldList,
  lossOfPassportFieldList,
  lossOfCheckedInBaggageAndDelayOfCheckedInBaggageFieldList,
  theftFieldList,
  medicalExpensesEmergencyDentalCareFieldList,
  fieldLists,
} from "../constants";
import {
  ButtonContainerStyled,
  MemberCount,
  PolicyCreateStyled,
  ProposalCreateSummaryStyled,
} from "../policyEntryStyles";
import { renderFormFields } from "../renderFormFields";
import { useGetLobById } from "../../../hooks/useGetLOBMaster";
import { Button, Input } from "../../../components";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import InlineLoader from "../../../components/loader/InlineLoader";
import {
  useCreateCustomerThroughPolicyEntry,
  useCreateMultipleCustomerThroughPolicyEntry,
  useGetCustomerMembers,
} from "../../Customer/service";

import ProposalShrink, { ProposalShrinkStyled } from "../ProposalShrink";
import Dropdown from "../../../components/Dropdown/Dropdown";
import ViewPolicyEntrySummary from "../ViewPolicyEntrySummary";
import styled from "styled-components";
import ProgressBar from "./ProgressBar";
import { acronymFormatValue } from "../../../HelperFunctions/HelperFunctions";
import ButtonWrapper from "../../../components/ButtonWrapper";
import GlobalModal from "../../../components/Modal/modal";
import { ExpenseDetailsBulkUpload } from "../BulkUploadOfClaims/ExpenseDetailsBulkUpload";
import { themeObj } from "../../theme/theme";
// import { themeObj } from "../../theme/theme";
// import ViewPolicyEntrySummary from "../search-and-proceed/ProposalCreate/ViewPolicyEntrySummary";
// import { setProposalCreateValuesAction } from "../search-and-proceed/proposal.slice";
// import { INSURED_MEMBER_DETAILS } from "../search-and-proceed/ProposalCreate/constants";
// import { useGetBankDataFromIfsc } from "./service";
// import { generateDynamicOptionalYupSchema } from "../search-and-proceed/CommonFunctions";
// import { ButtonContainerStyled } from "../search-and-proceed/ProposalCreate/policyEntryStyles";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
function ClaimForm({
  lobId,
  extraCardTitles,
  masterTableData,
  updateExistingData,
  generateMongoId,
  proposalData,
  isLoading,
  isCreateProposalDataLoading,
  isUpdateExistingProposalDataLoading,
  getModalData,
  getVersionData,
  getFuelData,
  manufacturerData,
  modelData,
  versionData,
  fuelData,
  editCard,
  setEditCard,
  activeStep,
  setActiveStep,
  current,
  mergedSchema,
  setMergedSchema,
  r,
  mandatoryFields,
  dropdownFields,
  multiSelectFields,
  getOptionKeyByValue,
  setCurrentCardIndex,
  getMap,
  dateFields,
  checkPolicyPresent,
  isPolicyPresent,
  isPrevPolicyPresent,
  checkPrevPolicyPresent,
  fileFields,
  members,
  setMembers,
  optionalFields,
  setUserId,
  matchedObject,
  setSubmittedAt,
  extraProposalCreate,
  policyDetails,
  policyPresent,
  dateAndTimeFields,
  lobName,
  // isLastTitleInCurrentStep,
  currentURL,
  buttonRef,
  finalSubmit,
  // cardTitlesPrevPolicyToggle,
  currentStepTitle,
  setAdditionalFileFields,
  stepper,
  setStepper,
  setGoBackFromSummary,
  setUpdatedAt,
  goBackFromSummary,
}) {
  const policyCardRef = useRef(null);

  const [isMadeChanges, setIsMadeChanges] = useState(false);
  const [expenseModalState, setExpenseModalState] = useState(false);
  const [asd, setAsd] = useState(false);

  const [activeStepperIndex, setActiveStepperIndex] = useState(0);

  const [completed, setCompleted] = useState({});

  const travelMemberDetails = TRAVEL_INSURED_MEMBER && lobName === "Travel";
  const defaultCurrentStep = "Basic Details, ";
  let current_step = proposalData?.data?.data?.current_step;
  // if (current_step && !current_step.includes(defaultCurrentStep)) {
  //   current_step = defaultCurrentStep + current_step
  // }

  const expenseData = proposalData?.data?.data?.data?.expenses;

  const { data: lobData } = useGetLobById(lobId);
  const [expenseModal, setExpenseModal] = useState(false);
  const isWorkmensCompensationLob =
    lobData?.data?.data?.lob === "Workmens Compensation";
  const isCarLob = lobData?.data?.data?.lob === "Car";
  const isBikeLob = lobData?.data?.data?.lob === "Bike";
  const isHealthLob = lobData?.data?.data?.lob === "Health";
  const isPropertyInsuranceLob =
    lobData?.data?.data?.lob === "Property Insurance";
  const { mutate: uploadExpenseDetails, data: expenseDetailsData } =
    useUploadExpenseDetailsBulkUpload(setUpdatedAt, asd);
  const isBusinessTypeConditionsLobWise = [
    "Car",
    "Bike",
    "GCV",
    "PCV",
  ].includes(lobData?.data?.data?.lob);

  const [files, setFiles] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [draftPayload, setDraftPayload] = useState({});
  const [additionalDocumentLinks, setAdditionalDocumentLinks] = useState([]);
  const location = useLocation();
  const isUpdate = location.pathname === "/edit-claim";
  const isRaiseClaim = location.pathname === "/raise-claims";
  const queryParams = new URLSearchParams(location.search);
  const transactionIdParams = queryParams.get("TransactionId");
  const certificateNumber = queryParams.get("certificateNumber");
  const certificateId = queryParams.get("certificateId");

  const dispatch = useDispatch();
  const { proposalCreateValues, uploadedFileURL, policyViewCards } =
    useSelector((state) => state.proposalConfig);

  const sumInsuredAmount = policyDetails?.claim_basic?.["Sum Insured"];
  const modeofTransportationForCargo =
    policyDetails?.claim_basic?.["Mode of Transportation"];

  const modeofTransportation = modeofTransportationForCargo?.split("|");
  const expenseDetailsDefaultValues =
    matchedObject[EXPENSE_DETAILS]?.reduce((acc, curr) => {
      acc[curr?.fieldSlug] = null;
      return acc;
    }, {}) ?? {};
  let yupValidate = yup.object({});
  const otherDOcsDefaultValues = {
    documentName: "",
    otherDocu: "",
  };
  const {
    register,
    setValue,
    control,
    getValues,
    watch,
    unregister,
    formState: { errors },
    trigger,
    handleSubmit,
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(mergedSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      expenseDetails: [expenseDetailsDefaultValues],
      otherDocs: [otherDOcsDefaultValues],
    },
  });
  const {
    fields: otherDocsFields,
    append: otherDocAppend,
    remove: otherDocRemove,
  } = useFieldArray({
    control,
    name: "otherDocs",
  });

  const {
    fields: expenseDetailsFields,
    append: expenseDetailsAppend,
    remove: expenseDetailsRemove,
  } = useFieldArray({
    name: "expenseDetails",
    control,
  });
  // const { data: policyInfo, isFetching } = useGetProposalValues({
  //   lobId,
  //   userId: transactionIdParams,
  // });

  // const policyData = policyInfo?.data?.data?.[0];

  // useEffect(() => {
  //   const policyData = policyInfo?.data?.data?.[0];
  //   if (policyData) {
  //     setValue("icName", policyData?.icName);
  //     setValue(
  //       "firstName",
  //       `${policyData?.firstName} ${policyData?.middleName} ${policyData?.lastName}`,
  //     );
  //   }
  // }, [policyInfo?.data?.data]);

  // useEffect(() => {}, [policyInfo?.data?.data?.[0]]);

  // useEffect(() => {
  //   const policyData = policyInfo?.data?.data?.[0];
  //   if (policyData) {
  //     setValue("claimType", {
  //       label: policyData?.planType,
  //       value: policyData?.planType,
  //     });
  //     setValue("claimType", policyData?.planType);
  //   }
  // }, [policyInfo?.data?.data?.[0]]);

  const {
    isSameAddress,
    addresschecktwo,
    make,
    model,
    sourcingPinCode,
    pinCode,
    pincode,
    pinCoderm,
    pincodeOne,
    assuredPincode,
    pincodeInvestment,
    policystDate,
    policyExpirydate,
    icName,
    insuranceCompanyname,
    productNamepd,
    nomineeDob,
    paymentMode,
    regDate,
    odPremium,
    tpPremium,
    tpexpirydate,
    odexpirydate,
    basePremium,
    discount,
    loadingAmount,
    totalTax,
    policyStartdate,
    policyExpirydatepd,
    title,
    premiumPaidby,
    firstName,
    addressLineone,
    addressLinetwo,
    assuredAddresstwo,
    assuredAddressone,
    assuredLandmark,
    weight,
    height,
    weightImd,
    heightImd,
    sgst,
    cgst,
    ugst,
    netPremium,
    taxAmount,
    pincodeVd,
    middleNamepd,
    lastName,
    gender,
    dob,
    lifeAssureddob,
    nationality,
    nationalityAssured,
    mobileNo,
    alternateMobileno,
    emailId: proposerEmailId,
    aadharCardno,
    panCardno,
    maritialStatus,
    policyurselfornot,
    state,
    city,
    pinCodeld,
    pincodeinsured,
    pincodetwooinsured,
    pincodeInsured,
    addressCheck,
    policyNo,
    previousPolicynum,
    payment,
    userType,
    medicalHistory,
    sourcingCity,
    sourcingState,
    pastInsurance,
    occupation,
    landmark,
    titleNd,
    nomineeRelation,
    relationWithlifeassured,
    middleName,
    titlela,
    addOnpremium,
    productName,
    vehicleRegNo,
    customerRefId,
    pincodetwo,
    pinCodetwo,
    members: watchMembers,
    nominee: watchNominee,
    expenseDetails: watchExpense,
    version,
    typeofcustomer,
    planType,
    floaterCover,
    locationofRisk,
    benificiaryRelationshiptopolicyholder,
    businessType,
    fuelType,
    idProof,
    addressProof,
    incomeProof,
    expenseDetails,
    // modeofTransportation,
    modetransport,
    cargoItemDetails: watchCargoItemDetails,
    cargoItemDetails,
    modeofTranspo,
    selectdocumenttoUpload,
    insuredsumInsured,
    lob,
    expnessClaim,
    causeofLoss,
    ifscCode,
    claimRespectivetravel,
    travelDetails,
    pincodeHospital,
    accidentPincode,
    removedValue,
    lossItemDetails,
    expenseDetailss,
    otherDocs,
  } = watch();

  const shouldResetPolicyNoFields = policyNo?.length > 0;
  // using this state for nested forms (insured members, payment) for conditional field reset
  // to get exact field
  // const mongoId = queryParams.get("mongoId");
  const isSummary = location.pathname === "/view-claim";
  const leadId = queryParams.get("leadId");
  const transactionId = queryParams.get("TransactionId");
  const customerId = policyPresent?.data?.[0]?.customerId;

  const { data: leadDataById } = useGetLeadById(leadId);
  const leadData = leadDataById?.data?.data || {};
  const [currentField, setCurrentField] = useState({
    field: "",
    index: null,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      Object.keys(errors)?.map((item) => {
        if (_.isEmpty(watch(item)) || !_.isEmpty(watch(item))) {
          trigger(item);
        }
      });
    }, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [JSON.stringify(watch()), JSON.stringify(Object.keys(errors))]); // will find better way for this dependancy

  const filterEmptyObjects = (obj) => {
    const filtered = {};
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === "object") {
        // Skip empty objects
        continue;
      }
      filtered[key] = obj[key];
    }
    return filtered;
  };

  let summary = filterEmptyObjects(proposalData?.data?.data?.data) || {};
  let expenseSummary = proposalData?.data?.data?.data?.expenses || {};
  let otherDocumentSummary =
    proposalData?.data?.data?.data?.additional_documents || {};
  const additionalDocumentsFromApi =
    proposalData?.data?.data?.data?.additional_documents;
  let summaryData = filterEmptyObjects(summary);
  const isOthersSelected =
    Array.isArray(selectdocumenttoUpload) &&
    selectdocumenttoUpload.find((item) => item?.value === "Others");
  const isPrevPolicyFromApi = summaryData?.expenseDetailss === "Yes";

  const planTypeFromApi =
    summaryData[POLICY_DETAILS] && summaryData[POLICY_DETAILS]?.planType;

  const proposerDetailsFromApi =
    summaryData[PROPOSER_DETAILS] && summaryData[PROPOSER_DETAILS];

  const nomineeDetailsFromApi =
    summaryData[NOMINEE_DETAILS] && summaryData[NOMINEE_DETAILS];

  const policyDetailsFromApi =
    summaryData[POLICY_DETAILS] && summaryData[POLICY_DETAILS];

  const expenseDetailsFromApi =
    summaryData[EXPENSE_DETAILS] && summaryData[EXPENSE_DETAILS];

  const prevPolicyDetailsFromApi =
    summaryData[PREVIOUS_POLICY_DETAILS] &&
    summaryData[PREVIOUS_POLICY_DETAILS];

  const lifeAssuredDetailsFromApi =
    summaryData[LIFE_ASSURED_DETAILS] && summaryData[LIFE_ASSURED_DETAILS];

  const vehicleDetailsFromApi =
    summaryData[VEHICLE_DETAILS] && summaryData[VEHICLE_DETAILS];

  const propertyDetailsFromApi =
    summaryData[PROPERTY_DETAILS] && summaryData[PROPERTY_DETAILS];

  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const employeeDetailsFromApi =
    summaryData[EXPENSE_DETAILS] && summaryData[EXPENSE_DETAILS];

  const premiumDetailsFromApi =
    summaryData[PREMIUM_DETAILS] && summaryData[PREMIUM_DETAILS];

  const sourcingDetailsFromApi =
    summaryData[SOURCING_DETAILS] && summaryData[SOURCING_DETAILS];

  const insureYourLiabilityToContractor =
    employeeDetailsFromApi?.members?.[0]?.compensationAct;

  const missingFormInBulkUpload = Object.keys(matchedObject || {})?.filter(
    (item) => !Object.keys(summaryData)?.includes(item)
  );

  const customerIdFromApi =
    summaryData[PROPOSER_DETAILS] && summaryData[PROPOSER_DETAILS]?.customerId;

  // for bulkupload, adding empty forms in summaryData to display that particular card with edit button
  missingFormInBulkUpload?.map((i) => {
    if (i === EXPENSE_DETAILS && isPrevPolicyFromApi) {
      summaryData[EXPENSE_DETAILS] = {};
    }
    if (i === CONTRACTOR_DETAILS && insureYourLiabilityToContractor === "Yes") {
      summaryData[CONTRACTOR_DETAILS] = {};
    }
    if (
      // !_.isEmpty(summaryData[PAYMENT_DETAILS] || {}) &&
      transactionIdParams &&
      location?.pathname !== "/create-claim"
    ) {
      if (i !== EXPENSE_DETAILS && i !== CONTRACTOR_DETAILS) {
        summaryData[i] = {};
      }
    }
  });

  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    (value) => value === EXPENSE_DETAILS
  );
  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    (value) => value === CONTRACTOR_DETAILS
  );

  const mandatoryFieldsPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? mandatoryFields
      : isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? mandatoryFields?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? mandatoryFields?.filter((_, index) => index !== prevPolicyCardIndex)
      : mandatoryFields?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? mandatoryFields
    : mandatoryFields?.filter((_, index) => index !== prevPolicyCardIndex);

  const optionalFieldsPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? optionalFields
      : isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? optionalFields?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? optionalFields?.filter((_, index) => index !== prevPolicyCardIndex)
      : optionalFields?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? optionalFields
    : optionalFields?.filter((_, index) => index !== prevPolicyCardIndex);

  const cardTitlesPrevPolicyToggle = contractorDetailsExists
    ? isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraCardTitles?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraCardTitles
      : extraCardTitles?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? extraCardTitles
    : extraCardTitles?.filter((_, index) => index !== prevPolicyCardIndex);

  // Get the last title from cardTitlesPrevPolicyToggle
  const lastTitle =
    cardTitlesPrevPolicyToggle?.[cardTitlesPrevPolicyToggle?.length - 1];

  // Check if the last title is present in currentStepTitle
  const isLastTitleInCurrentStep =
    currentStepTitle && currentStepTitle.includes(lastTitle);

  const cardFieldsPrevPolicyToggle = contractorDetailsExists
    ? !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraProposalCreate?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate
      : extraProposalCreate?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? extraProposalCreate
    : extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex);

  const activeFormTitle = cardTitlesPrevPolicyToggle[activeStep - 1];

  const currentFormData = summaryData;

  const expenseDetailCreate = matchedObject?.[EXPENSE_DETAILS]?.map(
    (item, index) => {
      const modifiedString = item?.fieldName
        ? item.fieldName
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())
        : null;
      return {
        label: modifiedString,
        key: item?.fieldSlug,
        field: item?.fieldType,
        length: item?.length,
        mandatory: item?.mandatory,
        validationGroup: item?.validationGroup,
        description: item?.fieldDescription,
      };
    }
  );
  const proposerDetailsFields = (matchedObject || {})[PROPOSER_DETAILS]?.map(
    (item) => item?.field_slug
  );

  const lifeAssuredDetailsFields = (matchedObject || {})[
    LIFE_ASSURED_DETAILS
  ]?.map((item) => item?.field_slug);

  const paymentDetailsFields = (matchedObject || {})[PAYMENT_DETAILS]?.map(
    (item) => item?.field_slug
  );

  const CustomerTypeCompany =
    typeofcustomer && typeofcustomer?.value === "Company";

  const noFloaterCover = floaterCover && floaterCover?.value === "No";

  const policyDetailsPlanType =
    planType?.value?.trim() || summaryData[POLICY_DETAILS]?.planType?.trim();

  const policyDetailsBusinessType = summaryData[POLICY_DETAILS]?.businessType;

  const propertyDetailsFields =
    matchedObject[PROPERTY_DETAILS] &&
    matchedObject[PROPERTY_DETAILS]?.map((item) => item?.field_slug);

  const fieldsOtherThanGrihaRaksha = propertyDetailsFields?.filter(
    (key) =>
      !grihaRakshaFields.includes(key) &&
      !commonForGrihaRakshaFields.includes(key)
  );

  const hideGrihaFields = [
    PLAN_TYPE_SOOKSHMA_UDHYAM,
    PLAN_TYPE_LAGHU_UDHYAM,
    PLAN_TYPE_STD_FIRE_SPECIAL_PERILS,
  ];

  const current_step_array = current_step?.split(", ");

  // useEffect(
  //   () => {
  //     if (Object.keys(leadData)?.length) {
  //       if (activeFormTitle === PROPOSER_DETAILS) {
  //         setValue("firstName", leadData?.firstName);
  //         setValue("middleName", leadData?.middleName);
  //         setValue("lastName", leadData?.lastName);
  //         setValue(
  //           "gender",
  //           leadData?.gender
  //             ? { label: leadData?.gender, value: leadData?.gender }
  //             : null,
  //         );
  //         setValue("mobileNo", leadData?.mobileNo);
  //         setValue("alternateMobileno", leadData?.alternateMobileNumber);
  //         setValue("emailId", leadData?.emailId);
  //         setValue(
  //           "occupation",
  //           leadData?.occupation
  //             ? { label: leadData?.occupation, value: leadData?.occupation }
  //             : null,
  //         );
  //         setValue("annualIncome", leadData?.annualincome);
  //         setValue("addressLineone", leadData?.addressLine1);
  //         setValue("addressLinetwo", leadData?.addressLine2);
  //         setValue("sourcingPinCode", leadData?.pincode);
  //         setValue("landmark", leadData?.landmark);
  //       }
  //       setValue(
  //         "policyType",
  //         leadData?.policyType
  //           ? { label: leadData?.policyType, value: leadData?.policyType }
  //           : null,
  //       );
  //       setValue(
  //         "userType",
  //         leadData?.userTypes
  //           ? { label: leadData?.userTypes, value: leadData?.userTypes }
  //           : null,
  //       );
  //       setValue(
  //         "Name",
  //         leadData?.userName
  //           ? { label: leadData?.userName, value: leadData?.userName }
  //           : null,
  //       );
  //       setValue("userId", leadData?.userId);
  //     }
  //   },
  //   [Object.keys(leadData)?.length],
  //   activeFormTitle,
  // );

  const indexOfSelfInsured =
    Array.isArray(watchMembers) &&
    watchMembers
      ?.map((item, index) =>
        item?.relWithprop?.label === "Self" ? index : undefined
      )
      ?.filter((item) => item != undefined)?.[0];

  const indexOfSelfPayment = payment
    ?.map((item, index) =>
      item?.paymentPaid?.label === "Self" ? index : undefined
    )
    ?.filter((item) => item != undefined)?.[0];

  const indexOfSelfNominee = watchNominee
    ?.map((item, index) =>
      item?.nomineeRelation?.label === "Self" ? index : undefined
    )
    ?.filter((item) => item != undefined)?.[0];

  const isUnionTerritory = unionTerritories?.some(
    (item) =>
      item?.replace(/\s/g, "")?.toLowerCase().trim() ==
      (proposerDetailsFromApi?.sourcingState || "")
        ?.replace(/\s/g, "")
        ?.toLowerCase()
        .trim()
  );

  useEffect(() => {
    if (isUnionTerritory) {
      setValue("sgst", 0);
      if (sgst > 0) {
        setValue("sgst", 0);
      }
      setValue("cgst", 0);
      if (cgst > 0) {
        setValue("cgst", 0);
      }
    }
    if (!isUnionTerritory) {
      setValue("ugst", 0);
      if (ugst > 0) {
        setValue("ugst", 0);
      }
    }
  }, [isUnionTerritory, sgst, ugst, cgst]);

  const cargoDetailsListTitle = summaryData?.modeofTranspo?.split("|");

  useEffect(() => {
    Array.isArray(watchCargoItemDetails) &&
      watchCargoItemDetails
        ?.filter((item, i) =>
          modeofTranspo
            ?.map((item) => item?.label)
            ?.includes(item?.[i]?.modetransport)
        )
        ?.map((item, index) => {
          setValue(
            `cargoItemDetails.[${index}].modetransport`,
            cargoDetailsListTitle?.[index]
          );
        });
  }, [JSON.stringify(cargoItemDetails), JSON.stringify(cargoDetailsListTitle)]);
  // useEffect(() => {
  //   if (Array.isArray(cargoItemDetails)) {
  //     cargoItemDetails.forEach((item, index) => {
  //       if (item && modeofTranspo?.[index]?.value) {
  //         setValue(
  //           `cargoItemDetails.${index}.modetransport`,
  //           modeofTranspo[index].value
  //         );
  //       }
  //     });
  //   }
  // }, [cargoItemDetails, modeofTranspo]);
  const insuredMemberDetailsFromApi =
    summaryData[INSURED_MEMBER_DETAILS] && summaryData[INSURED_MEMBER_DETAILS];

  const mandatoryFieldSlugs =
    !CustomerTypeCompany && activeFormTitle === PROPOSER_DETAILS
      ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
          ?.map((value) => value?.key)
          ?.filter((key) => !companyFields.includes(key))
      : hideGrihaFields?.includes(policyDetailsPlanType) &&
        activeFormTitle === PROPERTY_DETAILS
      ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
          ?.map((value) => value?.key)
          ?.filter((key) => !grihaRakshaFields.includes(key))
      : policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA &&
        activeFormTitle === PROPERTY_DETAILS
      ? mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
          ?.map((value) => value?.key)
          ?.filter((key) => !fieldsOtherThanGrihaRaksha.includes(key))
      : mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.map(
          (value) => value?.key
        );

  const optionalFieldSlugs = optionalFieldsPrevPolicyToggle?.[
    activeStep - 1
  ]?.map((value) => value.key);

  useEffect(() => {
    dispatch(setProposalCreateValuesAction(filterEmptyObjects(summaryData)));
  }, [JSON.stringify(summaryData), isPrevPolicyFromApi]);

  // useEffect(() => {
  //   setValue(
  //     "pastInsurance",
  //     isPrevPolicyFromApi
  //       ? {
  //           label: "Yes",
  //           value: "Yes",
  //         }
  //       : {
  //           label: "No",
  //           value: "No",
  //         },
  //   );
  // }, [isPrevPolicyFromApi]);

  useEffect(() => {
    if (isBusinessTypeConditionsLobWise) {
      if (businessType?.label === "SAOD") {
        setValue("policyExpirydate", odexpirydate);
      } else if (
        [TP_1_YEAR, TP_LONG_TERM_5_YEARS, TP_LONG_TERM_3_YEARS, SATP]?.includes(
          businessType?.label
        )
      ) {
        setValue("odexpirydate", null);
        setValue("policyExpirydate", tpexpirydate);
      } else {
        setValue("policyExpirydate", odexpirydate || tpexpirydate);
      }
    }
  }, [
    isBusinessTypeConditionsLobWise,
    businessType?.label,
    odexpirydate,
    tpexpirydate,
  ]);

  //prefil the lob label in intimate
  useEffect(() => {
    const LOBLabel = lobData?.data?.data?.lob;
    const LOBValue = lobData?.data?.data?.lob_id;
    setValue("lobId", { label: LOBLabel, value: LOBValue });
  }, [lobData?.data?.data?.lob]);

  useEffect(() => {
    if (
      activeFormTitle === VEHICLE_DETAILS &&
      fuelType?.label &&
      fuelType?.label === "ELECTRIC"
    ) {
      setValue("pucNumber", null);
      setValue("pucExpiryDate", null);
      setValue("pucStartdate", null);
    }
  }, [fuelType?.label]);

  useEffect(() => {
    if (isBusinessTypeConditionsLobWise) {
      if (
        [TP_1_YEAR, TP_LONG_TERM_5_YEARS, TP_LONG_TERM_3_YEARS, SATP]?.includes(
          policyDetailsBusinessType
        )
      ) {
        setValue("odPremium", 0);
      }
    }
  }, [isBusinessTypeConditionsLobWise, policyDetailsBusinessType, activeStep]);
  // Multi Member Details
  useEffect(() => {
    if (activeFormTitle === INSURED_MEMBER_DETAILS) {
      const memberCount = currentFormData?.members?.length || 1;
      setMembers((prev) => ({
        ...prev,
        members: memberCount,
      }));
    }
    if (activeFormTitle === NOMINEE_DETAILS) {
      const memberCount = currentFormData?.nominee?.length || 1;
      setMembers((prev) => ({
        ...prev,
        nominee: memberCount,
      }));
    }
    if (activeFormTitle === PAYMENT_DETAILS) {
      const paymentCount = currentFormData?.payments?.length || 1;
      setMembers((prev) => ({
        ...prev,
        payment: paymentCount,
      }));
    }
    if (activeFormTitle === EXPENSE_DETAILS) {
      const expenseCount = expenseSummary?.length || 1;
      setMembers((prev) => ({
        ...prev,
        expenseDetails: expenseCount,
      }));
    }
    if (activeFormTitle === LOSS_ITEM_DETAILS) {
      const lossItemCount = expenseSummary?.length || 1;
      setMembers((prev) => ({
        ...prev,
        lossItemDetails: lossItemCount,
      }));
    }
    if (activeFormTitle === CARGO_DETAILS) {
      const cargoCount = modeofTranspo?.length || 1;

      setMembers((prev) => ({
        ...prev,
        cargoItemDetails: cargoCount,
      }));
    }
    if (activeFormTitle === TRAVEL_DETAILS) {
      const travelCount = claimRespectivetravel?.length || 1;

      setMembers((prev) => ({
        ...prev,
        travelDetails: travelCount,
      }));
    }
    // uncomment this if want multiple travel insured member in rendering
    // if (activeFormTitle === TRAVEL_INSURED_MEMBER && lobName === "Travel") {
    //   const claimantsItemCount = expenseSummary?.length || 1;
    //   setMembers((prev) => ({
    //     ...prev,
    //     claimantsDetails: claimantsItemCount,
    //   }));
    // }
  }, [JSON.stringify(currentFormData), activeFormTitle, modeofTranspo?.length]);
  const policyData = policyPresent?.data?.[0] || {};
  const policyMembers = policyData?.members;
  const policyExpiry = moment(policyExpirydate).format("YYYY-MM-DD");
  const policyStart = moment(policystDate).format("YYYY-MM-DD");
  const fieldsToAutoFillFromPolicyNo = extraProposalCreate
    ?.flat()
    ?.filter((item) => item?.isTransactionField === "Y")
    .map((item) => item?.key);
  // prefill logic here after view
  useEffect(() => {
    if (!_.isEmpty(policyData)) {
      fieldsToAutoFillFromPolicyNo?.map((item) => {
        const mValue = policyData?.members?.[0]?.[item];
        const value = policyData?.[item] || mValue;
        if (dropdownFields.includes(item)) {
          // if (item === "relWithprop") {
          //   const insuredMemberName = policyMembers?.filter(
          //     (item) => item?.relWithprop === value
          //   )[0]?.insuredmemberName;
          //   setValue(item, {
          //     label: `${value} - ${insuredMemberName}`,
          //     value: `${value} - ${insuredMemberName}`,
          //   });
          //   return;
          // }
          value &&
            setValue(item, {
              label: value,
              value: value,
            });
        } else if (dateFields?.includes(item)) {
          const isValidDate = moment(value, moment.ISO_8601, true).isValid();
          const isValidNumber = /^[0-9]+$/.test(value);
          if (isValidDate && !isValidNumber) {
            setValue(item, new Date(value));
          } else {
            setValue(item, "");
          }
        } else if (dateAndTimeFields?.includes(item)) {
          const isValidDate = moment(value).isValid();
          const isValidNumber = /^[0-9]+$/.test(value);

          if (isValidDate) {
            setValue(item, moment(value).local());
          } else {
            setValue(item, "");
          }
        } else if (multiSelectFields?.includes(item)) {
          if (value) {
            const items = value?.split("|")?.map((itemx) => ({
              label: itemx,
              value: itemx,
            }));
            setValue(item, items);
          } else {
            setValue(item, null);
          }
        } else if (fileFields?.includes(item)) {
          dispatch(
            setUploadedFileURLAction({
              [item]: value,
            })
          );
        } else {
          setValue(item, value);
        }
      });
    }
  }, [policyData, fieldsToAutoFillFromPolicyNo.length, policyNo]);

  useEffect(() => {
    if (!shouldResetPolicyNoFields) {
      fieldsToAutoFillFromPolicyNo?.forEach((item) => {
        setValue(item, null);
      });
    }
  }, [policyNo]);

  useEffect(() => {
    Object.entries(currentFormData || {})?.map(([key, value]) => {
      const section =
        (key === "members" && "members") ||
        (key === "payments" && "payment") ||
        (key === "nominee" && "nominee") ||
        (key === "expenseDetails" && "expenseDetails");
      //  ||
      // (key === "lossItemDetails" && "lossItemDetails") ||
      // (key === "cargoItemDetails" && "cargoItemDetails");
      if (
        key === "members" ||
        key === "payments" ||
        key === "nominee" ||
        key === "expenseDetails"
        //  ||
        // key === "lossItemDetails" ||
        // key === "cargoItemDetails"
      ) {
        Object.values(value || {})?.map((item, index) => {
          Object.entries(item || {})?.map(([memberKey, memberValue]) => {
            if (dropdownFields?.includes(memberKey)) {
              setValue(
                `${section}.[${index}].${memberKey}`,
                memberValue
                  ? {
                      label: memberValue,
                      value: memberValue,
                    }
                  : null
              );
            } else if (multiSelectFields?.includes(memberKey)) {
              // corrected from 'key' to 'memberKey'
              if (memberValue) {
                const items = memberValue?.split("|")?.map((item) => ({
                  label: item,
                  value: item,
                }));
                setValue(`${section}.[${index}].${memberKey}`, items);
              } else {
                setValue(`${section}.[${index}].${memberKey}`, null);
              }
            } else if (fileFields?.includes(memberKey)) {
              dispatch(
                setUploadedFileURLAction({
                  [`${section}.[${index}].${memberKey}`]: memberValue,
                })
              );
            } else if (dateFields?.includes(memberKey)) {
              const isValidDate = moment(
                memberValue,
                moment.ISO_8601,
                true
              ).isValid();
              const isValidNumber = /^[0-9]+$/.test(memberValue);
              if (isValidDate && !isValidNumber) {
                setValue(
                  `${section}.[${index}].${memberKey}`,
                  new Date(memberValue)
                );
              } else {
                setValue(`${section}.[${index}].${memberKey}`, "");
              }
            } else {
              !dateFields?.includes(memberKey) &&
                !fileFields?.includes(memberKey) &&
                setValue(`${section}.[${index}].${memberKey}`, memberValue);
            }
          });
        });
      } else {
        if (dropdownFields?.includes(key)) {
          setValue(
            key,
            value
              ? {
                  label: value,
                  value: value,
                }
              : null
          );
        } else if (dateAndTimeFields?.includes(key)) {
          const isValidDate = moment(value, moment.ISO_8601, true).isValid();
          const isValidNumber = /^[0-9]+$/.test(value);
          if (isValidDate) {
            setValue(key, moment(value).local());
          } else {
            setValue(key, "");
          }
        } else if (multiSelectFields?.includes(key)) {
          if (value) {
            const items = value?.split("|")?.map((item) => ({
              label: item,
              value: item,
            }));
            setValue(key, items);
          } else {
            setValue(key, null);
          }
        } else if (fileFields?.includes(key)) {
          dispatch(
            setUploadedFileURLAction({
              [key]: value,
            })
          );
          setValue(key, value);
        } else if (dateFields?.includes(key)) {
          const isValidDate = moment(value, moment.ISO_8601, true).isValid();
          const isValidNumber = /^[0-9]+$/.test(value);
          if (isValidDate && !isValidNumber) {
            setValue(key, new Date(value));
          } else {
            setValue(key, "");
          }
        } else {
          !fileFields?.includes(key) &&
            !dateFields?.includes(key) &&
            !dateAndTimeFields?.includes(key) &&
            setValue(key, value);
        }
      }
    });
  }, [
    JSON.stringify(summaryData),
    JSON.stringify(currentFormData),
    activeFormTitle,
    // goBackFromSummary,
  ]);

  // useEffect(() => {
  //   if (Array.isArray(expenseSummary)) {
  //     expenseSummary.map((item, index) => {
  //       Object.entries(item || {}).map(([key, value]) => {
  //         if (dropdownFields?.includes(key)) {
  //           setValue(
  //             `expenseDetails.[${index}].${key}`,
  //             value
  //               ? {
  //                   label: value,
  //                   value: value,
  //                 }
  //               : null
  //           );
  //         } else if (multiSelectFields?.includes(key)) {
  //           if (value) {
  //             const items = value.split("|").map((item) => ({
  //               label: item,
  //               value: item,
  //             }));
  //             setValue(`expenseDetails.[${index}].${key}`, items);
  //           } else {
  //             setValue(`expenseDetails.[${index}].${key}`, null);
  //           }
  //         } else if (fileFields?.includes(key)) {
  //           dispatch(
  //             setUploadedFileURLAction({
  //               [`expenseDetails.[${index}].${key}`]: value,
  //             })
  //           );
  //         } else if (dateFields?.includes(key)) {
  //           const isValidDate = moment(value, moment.ISO_8601, true).isValid();
  //           const isValidNumber = /^[0-9]+$/.test(value);
  //           if (isValidDate && !isValidNumber) {
  //             setValue(`expenseDetails.[${index}].${key}`, new Date(value));
  //           } else {
  //             setValue(`expenseDetails.[${index}].${key}`, "");
  //           }
  //         } else {
  //           !dateFields?.includes(key) &&
  //             !fileFields?.includes(key) &&
  //             setValue(`expenseDetails.[${index}].${key}`, value);
  //         }
  //       });
  //     });
  //   }
  // }, [expenseSummary?.length]);
  useEffect(() => {
    if (Array.isArray(expenseSummary) && expenseSummary?.length) {
      const expenseDetailsArr = [];
      expenseSummary?.map((item, index) => {
        let expenseDetailsObj = {};
        Object.entries(item || {}).map(([key, value]) => {
          if (dropdownFields?.includes(key)) {
            // setValue(
            //   `expenseDetails.[${index}].${key}`,
            // value
            //   ? {
            //       label: value,
            //       value: value,
            //     }
            //   : null,
            // );
            expenseDetailsObj = {
              ...expenseDetailsObj,
              [key]: value
                ? {
                    label: value,
                    value: value,
                  }
                : null,
            };
          } else if (multiSelectFields?.includes(key)) {
            if (value) {
              const items = value.split("|").map((item) => ({
                label: item,
                value: item,
              }));
              expenseDetailsObj = { ...expenseDetailsObj, [key]: items };
              // setValue(`expenseDetails.[${index}].${key}`, items);
            } else {
              expenseDetailsObj = { ...expenseDetailsObj, [key]: null };
              // setValue(`expenseDetails.[${index}].${key}`, null);
            }
          } else if (fileFields?.includes(key)) {
            dispatch(
              setUploadedFileURLAction({
                [`expenseDetails.[${index}].${key}`]: value,
              })
            );
            expenseDetailsObj = { ...expenseDetailsObj, [key]: value };
          } else if (
            [
              ...(Array.isArray(dateFields) && dateFields),
              "billDate",
            ]?.includes(key)
          ) {
            const isValidDate =
              moment(value, moment.ISO_8601, true).isValid() ||
              moment(value, "DD-MM-YYYY", true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);

            // Check if new Date is giving Invalid Date
            if (isValidDate && !isValidNumber) {
              // setValue(
              //   `expenseDetails.[${index}].${key}`,
              //   !isNaN(new Date(value))
              //     ? new Date(value)
              //     : new Date(moment(value, "DD-MM-YYYY").format("YYYY-MM-DD")),
              // );
              expenseDetailsObj = {
                ...expenseDetailsObj,
                [key]: !isNaN(new Date(value))
                  ? new Date(value)
                  : new Date(moment(value, "DD-MM-YYYY").format("YYYY-MM-DD")),
              };
            } else {
              // setValue(`expenseDetails.[${index}].${key}`, "");
              expenseDetailsObj = { ...expenseDetailsObj, [key]: "" };
            }
          } else {
            if (!dateFields?.includes(key) && !fileFields?.includes(key)) {
              //  setValue(`expenseDetails.[${index}].${key}`, value);
              expenseDetailsObj = { ...expenseDetailsObj, [key]: value };
            }
          }
        });
        expenseDetailsArr.push(expenseDetailsObj);
      });
      if (activeFormTitle === EXPENSE_DETAILS && expenseDetailsArr.length) {
        reset({
          expenseDetails: expenseDetailsArr,
        });
      }
    }
  }, [
    expenseSummary?.length,
    editCard[EXPENSE_DETAILS],
    JSON.stringify(expenseDetailsData),
    activeFormTitle,
  ]);
  useEffect(() => {
    if (Array.isArray(expenseSummary)) {
      expenseSummary.map((item, index) => {
        Object.entries(item || {}).map(([key, value]) => {
          if (dropdownFields?.includes(key)) {
            setValue(
              `lossItemDetails.[${index}].${key}`,
              value
                ? {
                    label: value,
                    value: value,
                  }
                : null
            );
          } else if (multiSelectFields?.includes(key)) {
            if (value) {
              const items = value.split("|").map((item) => ({
                label: item,
                value: item,
              }));
              setValue(`lossItemDetails.[${index}].${key}`, items);
            } else {
              setValue(`lossItemDetails.[${index}].${key}`, null);
            }
          } else if (fileFields?.includes(key)) {
            dispatch(
              setUploadedFileURLAction({
                [`lossItemDetails.[${index}].${key}`]: value,
              })
            );
          } else if (dateFields?.includes(key)) {
            const isValidDate = moment(value, moment.ISO_8601, true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);
            if (isValidDate && !isValidNumber) {
              setValue(`lossItemDetails.[${index}].${key}`, new Date(value));
            } else {
              setValue(`lossItemDetails.[${index}].${key}`, "");
            }
          } else {
            !dateFields?.includes(key) &&
              !fileFields?.includes(key) &&
              setValue(`lossItemDetails.[${index}].${key}`, value);
          }
        });
      });
    }
  }, [expenseSummary?.length]);
  useEffect(() => {
    if (Array.isArray(otherDocumentSummary) && isOthersSelected) {
      otherDocumentSummary.forEach((item, index) => {
        Object.entries(item || {}).forEach(([key, value]) => {
          // Remove '_otherDoc' suffix from key if present
          const modifiedKey = key.endsWith("_otherDoc")
            ? key.slice(0, -"_otherDoc".length)
            : key;

          if (dropdownFields?.includes(modifiedKey)) {
            setValue(
              `otherDocs.[${index}].${modifiedKey}`,
              value
                ? {
                    label: value,
                    value: value,
                  }
                : null
            );
          } else if (multiSelectFields?.includes(modifiedKey)) {
            if (value) {
              const items = value.split("|").map((item) => ({
                label: item,
                value: item,
              }));
              setValue(`otherDocs.[${index}].${modifiedKey}`, items);
            } else {
              setValue(`otherDocs.[${index}].${modifiedKey}`, null);
            }
          } else if (fileFields?.includes(modifiedKey)) {
            dispatch(
              setUploadedFileURLAction({
                [`otherDocs.[${index}].${modifiedKey}`]: value,
              })
            );
          } else if (dateFields?.includes(modifiedKey)) {
            const isValidDate = moment(value, moment.ISO_8601, true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);
            if (isValidDate && !isValidNumber) {
              setValue(`otherDocs.[${index}].${modifiedKey}`, new Date(value));
            } else {
              setValue(`otherDocs.[${index}].${modifiedKey}`, "");
            }
          } else {
            !dateFields?.includes(modifiedKey) &&
              !fileFields?.includes(modifiedKey) &&
              setValue(`otherDocs.[${index}].${modifiedKey}`, value);
          }
        });
      });
    }
  }, [otherDocumentSummary, DOCUMENTS, activeFormTitle]);
  useEffect(() => {
    if (Array.isArray(expenseSummary)) {
      expenseSummary.map((item, index) => {
        Object.entries(item || {}).map(([key, value]) => {
          if (dropdownFields?.includes(key)) {
            setValue(
              `cargoItemDetails.[${index}].${key}`,
              value
                ? {
                    label: value,
                    value: value,
                  }
                : null
            );
          } else if (multiSelectFields?.includes(key)) {
            if (value) {
              const items = value.split("|").map((item) => ({
                label: item,
                value: item,
              }));
              setValue(`cargoItemDetails.[${index}].${key}`, items);
            } else {
              setValue(`cargoItemDetails.[${index}].${key}`, null);
            }
          } else if (fileFields?.includes(key)) {
            dispatch(
              setUploadedFileURLAction({
                [`cargoItemDetails.[${index}].${key}`]: value,
              })
            );
          } else if (dateFields?.includes(key)) {
            const isValidDate = moment(value, moment.ISO_8601, true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);
            if (isValidDate && !isValidNumber) {
              setValue(`cargoItemDetails.[${index}].${key}`, new Date(value));
            } else {
              setValue(`cargoItemDetails.[${index}].${key}`, "");
            }
          } else if (dateAndTimeFields?.includes(key)) {
            const isValidDate = moment(value).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);

            if (isValidDate) {
              setValue(
                `cargoItemDetails.[${index}].${key}`,
                moment(value).local()
              );
            } else {
              setValue(`cargoItemDetails.[${index}].${key}`, "");
            }
          } else {
            !dateFields?.includes(key) &&
              !fileFields?.includes(key) &&
              !dateAndTimeFields?.includes(key) &&
              setValue(`cargoItemDetails.[${index}].${key}`, value);
          }
        });
      });
    }
  }, [expenseSummary?.length]);

  useEffect(() => {
    if (Array.isArray(expenseSummary)) {
      expenseSummary.map((item, index) => {
        Object.entries(item || {}).map(([key, value]) => {
          if (dropdownFields?.includes(key)) {
            setValue(
              `travelDetails.[${index}].${key}`,
              value
                ? {
                    label: value,
                    value: value,
                  }
                : null
            );
          } else if (multiSelectFields?.includes(key)) {
            if (value) {
              const items = value?.split("|").map((item) => ({
                label: item,
                value: item,
              }));
              setValue(`travelDetails.[${index}].${key}`, items);
            } else {
              setValue(`travelDetails.[${index}].${key}`, null);
            }
          } else if (fileFields?.includes(key)) {
            dispatch(
              setUploadedFileURLAction({
                [`travelDetails.[${index}].${key}`]: value,
              })
            );
          } else if (dateFields?.includes(key)) {
            const isValidDate = moment(value, moment.ISO_8601, true).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);
            if (isValidDate && !isValidNumber) {
              setValue(`travelDetails.[${index}].${key}`, new Date(value));
            } else {
              setValue(`travelDetails.[${index}].${key}`, "");
            }
          } else if (dateAndTimeFields?.includes(key)) {
            const isValidDate = moment(value).isValid();
            const isValidNumber = /^[0-9]+$/.test(value);

            if (isValidDate) {
              setValue(
                `travelDetails.[${index}].${key}`,
                moment(value).local()
              );
            } else {
              setValue(`travelDetails.[${index}].${key}`, "");
            }
          } else {
            !dateFields?.includes(key) &&
              !fileFields?.includes(key) &&
              !dateAndTimeFields?.includes(key) &&
              setValue(`travelDetails.[${index}].${key}`, value);
          }
        });
      });
    }
  }, [expenseSummary?.length]);

  const clearCustomerData = () => {
    proposerDetailsFields?.map((item) => {
      if (item != "customerId" && item != "typeofcustomer") {
        dropdownFields?.includes(item) || dateFields?.includes(item)
          ? setValue(item, null)
          : multiSelectFields?.includes(item)
          ? setValue(item, [])
          : setValue(item, "");
      }
    });
  };

  useEffect(() => {
    Object.values(watchMembers || {})?.forEach((item, index) => {
      Object.entries(item || {})?.map(() => {
        if (watch(`members.[${index}].relWithprop.value`) === "Self") {
          setValue(
            `members.[${indexOfSelfInsured}].insuredmemberName`,
            `${proposerDetailsFromApi?.firstName}` +
              `${
                proposerDetailsFromApi?.middleName
                  ? " " + proposerDetailsFromApi?.middleName
                  : ""
              }` +
              `${
                proposerDetailsFromApi?.lastName
                  ? " " + proposerDetailsFromApi?.lastName
                  : ""
              }`
          );
          setValue(`members.[${indexOfSelfInsured}].genderIMD`, {
            label: proposerDetailsFromApi?.gender,
            value: proposerDetailsFromApi?.gender,
          });
          setValue(
            `members.[${indexOfSelfInsured}].dobIMD`,
            new Date(proposerDetailsFromApi?.dob)
          );
          setValue(
            `members.[${indexOfSelfInsured}].insuredContactnumber`,
            proposerDetailsFromApi?.mobileNo
          );
          setValue(
            `members.[${indexOfSelfInsured}].remarkihd`,
            proposerDetailsFromApi?.remark
          );
          setValue(
            `members.[${indexOfSelfInsured}].insuredeiaNumber`,
            proposerDetailsFromApi?.eiaNumber
          );
          setValue(
            `members.[${indexOfSelfInsured}].insuredeiaRepository`,
            proposerDetailsFromApi?.eiaRepository
              ? {
                  value: proposerDetailsFromApi?.eiaRepository,
                  label: proposerDetailsFromApi?.eiaRepository,
                }
              : null
          );
        }
      });
    });
  }, [
    watchMembers?.[currentField?.index]?.relWithprop?.value,
    indexOfSelfInsured,
  ]);

  useEffect(() => {
    Object.values(payment || {})?.forEach((item, index) => {
      Object.entries(item || {})?.map(() => {
        if (
          index === indexOfSelfPayment &&
          watch(`payment[${index}].paymentPaid.value`) === "Self"
        ) {
          setValue(
            `payment[${indexOfSelfPayment}].payerName`,
            `${proposerDetailsFromApi?.firstName}` +
              `${
                proposerDetailsFromApi?.middleName
                  ? " " + proposerDetailsFromApi?.middleName
                  : ""
              }` +
              `${
                proposerDetailsFromApi?.lastName
                  ? " " + proposerDetailsFromApi?.lastName
                  : ""
              }`
          );
        }
      });
    });
  }, [JSON.stringify(payment)]);

  useEffect(() => {
    Object.values(watchNominee || {})?.forEach((item, index) => {
      Object.entries(item || {})?.map(() => {
        if (
          index === indexOfSelfNominee &&
          watch(`nominee.[${index}].nomineeRelation.value`) === "Self"
        ) {
          setValue(`nominee.[${indexOfSelfNominee}].titleNd`, {
            label: proposerDetailsFromApi?.title,
            value: proposerDetailsFromApi?.title,
          });
          setValue(`nominee.[${indexOfSelfNominee}].nomineeGender`, {
            label: proposerDetailsFromApi?.gender,
            value: proposerDetailsFromApi?.gender,
          });
          setValue(
            `nominee.[${indexOfSelfNominee}].nomineeDob`,
            new Date(proposerDetailsFromApi?.dob)
          );
          setValue(
            `members.[${indexOfSelfNominee}].nomineeRemark`,
            proposerDetailsFromApi?.remark
          );
          setValue(
            `nominee[${indexOfSelfNominee}].nomineeFullname`,
            `${proposerDetailsFromApi?.firstName}` +
              `${
                proposerDetailsFromApi?.middleName
                  ? " " + proposerDetailsFromApi?.middleName
                  : ""
              }` +
              `${
                proposerDetailsFromApi?.lastName
                  ? " " + proposerDetailsFromApi?.lastName
                  : ""
              }`
          );
        }
      });
    });
  }, [JSON.stringify(watchNominee)]);

  const vehicleDataPrefilFromVersion =
    versionData?.data?.find((item) => item?.version_name === version?.value) ||
    {};

  useEffect(() => {
    setValue(
      "cc",
      vehicleDataPrefilFromVersion?.cubic_capacity ||
        vehicleDetailsFromApi?.cc ||
        ""
    );
    setValue(
      "kwh",
      vehicleDataPrefilFromVersion?.kw || vehicleDetailsFromApi?.kwh || ""
    );
    setValue(
      "seatingCapacity",
      vehicleDataPrefilFromVersion?.seating_capacity ||
        vehicleDetailsFromApi?.seatingCapacity ||
        ""
    );
    setValue(
      "vehicleSegment",
      vehicleDataPrefilFromVersion?.segment_type
        ? {
            label: vehicleDataPrefilFromVersion?.segment_type,
            value: vehicleDataPrefilFromVersion?.segment_type,
          }
        : vehicleDetailsFromApi?.vehicleSegment
        ? {
            label: vehicleDetailsFromApi?.vehicleSegment,
            value: vehicleDetailsFromApi?.vehicleSegment,
          }
        : null
    );
    setValue(
      "fuelType",
      vehicleDataPrefilFromVersion?.fuel_type
        ? {
            label: vehicleDataPrefilFromVersion?.fuel_type,
            value: vehicleDataPrefilFromVersion?.fuel_type,
          }
        : vehicleDetailsFromApi?.fuelType
        ? {
            label: vehicleDetailsFromApi?.fuelType,
            value: vehicleDetailsFromApi?.fuelType,
          }
        : null
    );
  }, [version?.value]);

  useEffect(() => {
    if (
      summaryData[SOURCING_DETAILS] &&
      summaryData[SOURCING_DETAILS]?.userId
    ) {
      setUserId(summaryData[SOURCING_DETAILS]?.userId);
    }
  }, [JSON.stringify(summaryData[SOURCING_DETAILS])]);

  //this entire thing needs debounce
  function extractVehicleCode(inputStr) {
    // Replace any special character or multiple spaces with a single space
    const resultStr = inputStr.replace(/[^a-zA-Z0-9]+/g, " ");
    const modifiedStr =
      resultStr.slice(0, 2) +
      (resultStr[2] !== " " ? " " : "") +
      resultStr.slice(2);
    return modifiedStr.slice(0, 5).toUpperCase().replace(/\s+/g, "-");
  }

  useEffect(() => {
    if (vehicleRegNo) {
      const rtoNumber = extractVehicleCode(vehicleRegNo);

      let masterTableField = masterTableData?.find(
        (value) => value?.data?.data?.[0]?.fieldSlug === "rto"
      );

      const isGivenRtoPresent = masterTableField?.data?.data?.[0]?.data?.find(
        (value) => value.optionKey === rtoNumber
      );

      const regex = /^[a-zA-Z]{2}-\d{2}$/;
      regex.test(rtoNumber) && isGivenRtoPresent
        ? setValue("rto", { label: rtoNumber, value: rtoNumber, id: rtoNumber })
        : vehicleDetailsFromApi?.rto
        ? setValue("rto", {
            label: vehicleDetailsFromApi?.rto,
            value: vehicleDetailsFromApi?.rto,
            id: vehicleDetailsFromApi?.rto,
          })
        : setValue("rto", null);
    }
  }, [vehicleRegNo]);

  useEffect(() => {
    if (medicalHistory?.label === "No") {
      setValue("uploadDocument", null);
      const newFiles = { ...files };
      delete newFiles.uploadDocument;
      setFiles(newFiles);
      dispatch(setUploadedFileURLAction({ uploadDocument: null }));
    }
  }, [medicalHistory?.label]);

  // to remove selected file conditionally for insured members
  useEffect(() => {
    if (
      watch(`members.[${currentField?.index}].medicalHistoryim`)?.label &&
      watch(`members.[${currentField?.index}].medicalHistoryim`)?.label !==
        "Yes"
    ) {
      setValue(`members.[${currentField?.index}].uploadDocumentim`, null);
      const newFiles = { ...files };
      delete newFiles[`members.[${currentField?.index}].uploadDocumentim`];
      setFiles(newFiles);
      dispatch(
        setUploadedFileURLAction({
          [`members.[${currentField?.index}].uploadDocumentim`]: null,
        })
      );
    }
  }, [watchMembers?.[currentField?.index]?.medicalHistoryim?.value]);

  useEffect(() => {
    if (policyNo || !!policyNo?.length) {
      // checkPolicyPresent({
      //   lobId,
      //   policyNumber: policyNo,
      // });
    }
    if (previousPolicynum || !!previousPolicynum?.length) {
      checkPrevPolicyPresent({
        lobId,
        previousPolicynum: previousPolicynum,
      });
    }
  }, [policyNo, previousPolicynum]);
  useEffect(() => {
    setValue("policyNo", policyPresent?.data?.[0]?.policyNo);
    setValue("coverType", policyPresent?.data?.[0]?.coverType);
    setValue("ckycNumber", policyPresent?.data?.[0]?.ckycId);
    setValue("ckycId", policyPresent?.data?.[0]?.ckycId);

    setValue("panNo", policyPresent?.data?.[0]?.panCardno);
  }, [policyPresent]);

  const memberBMIData =
    Array.isArray(watchMembers) &&
    watchMembers.map((item) => ({
      weight: item?.weightImd,
      height: item?.heightImd,
    }));

  useMemo(() => {
    //bmi calculation
    if (weight && height) {
      const heightInMeters = height / 100;
      const bmi = (weight / (heightInMeters * heightInMeters)).toFixed(2);
      setValue("bmi", bmi);
    }
    //bmi calculation
    if (weightImd && heightImd) {
      const heightInMeters = heightImd / 100;
      const bmi = (weightImd / (heightInMeters * heightInMeters)).toFixed(2);
      setValue("bmiImd", bmi);
    }
    if (members?.members) {
      [...Array(members?.members)].forEach((member, index) => {
        if (
          watch(`members[${index}].weightImd`) &&
          watch(`members[${index}].heightImd`)
        ) {
          const heightInMeters = watch(`members[${index}].heightImd`) / 100;
          const bmi = (
            watch(`members[${index}].weightImd`) /
            (heightInMeters * heightInMeters)
          ).toFixed(2);
          setValue(`members[${index}].bmiImd`, bmi);
        }
      });
    }
  }, [weight, height, JSON.stringify(memberBMIData), weightImd, heightImd]);

  useMemo(() => {
    if (dob) {
      const today = moment();
      const birthDate = moment(dob, "YYYY-MM-DD");
      const years = today.diff(birthDate, "years");
      setValue("age", years);
    }
    if (lifeAssureddob) {
      const today = moment();
      const birthDate = moment(lifeAssureddob, "YYYY-MM-DD");
      const years = today.diff(birthDate, "years");
      setValue("lifeAssuredage", years);
    }
  }, [dob, lifeAssureddob]);

  useEffect(() => {
    if (addressCheck) {
      if (addressCheck?.label === "Yes") {
        setValue("citytwo", sourcingCity);
        setValue("addresspdtwo", addressLinetwo);
        setValue("pinCodetwo", sourcingPinCode);
        setValue("pincodetwo", sourcingPinCode);
        setValue("addresspdone", addressLineone);
        setValue("statetwo", sourcingState);
        setValue("landmarkpdtwo", landmark);
      } else {
        setValue(
          "citytwo",
          proposerDetailsFromApi?.citytwo
            ? {
                label: proposerDetailsFromApi?.citytwo,
                value: proposerDetailsFromApi?.citytwo,
              }
            : null
        );
        setValue("addresspdtwo", proposerDetailsFromApi?.addresspdtwo || null);
        setValue("pinCodetwo", proposerDetailsFromApi?.pinCodetwo || null);
        setValue("pincodetwo", proposerDetailsFromApi?.pincodetwo || null);
        setValue("addresspdone", proposerDetailsFromApi?.addresspdone || null);
        setValue(
          "statetwo",
          proposerDetailsFromApi?.statetwo
            ? {
                label: proposerDetailsFromApi?.statetwo,
                value: proposerDetailsFromApi?.statetwo,
              }
            : null
        );
        setValue(
          "landmarkpdtwo",
          proposerDetailsFromApi?.landmarkpdtwo || null
        );
      }
    }
  }, [
    addressCheck?.label,
    sourcingPinCode,
    sourcingState,
    sourcingCity,
    addressLineone,
    addressLinetwo,
    landmark,
  ]);

  useEffect(() => {
    if (locationofRisk) {
      if (locationofRisk?.label === "Yes") {
        setValue(
          "addressLineoneinsured",
          proposerDetailsFromApi?.addressLineone
        );
        setValue(
          "addressLinetwoinsured",
          proposerDetailsFromApi?.addressLinetwo
        );
        setValue("pincodeinsured", proposerDetailsFromApi?.sourcingPinCode);
        setValue("stateinsured", {
          label: proposerDetailsFromApi?.sourcingState,
          value: proposerDetailsFromApi?.sourcingState,
        });
        setValue("cityinsured", {
          label: proposerDetailsFromApi?.sourcingCity,
          value: proposerDetailsFromApi?.sourcingCity,
        });
      } else {
        setValue(
          "addressLineoneinsured",
          propertyDetailsFromApi?.addressLineoneinsured || null
        );
        setValue(
          "addressLinetwoinsured",
          propertyDetailsFromApi?.addressLinetwoinsured || null
        );
        setValue(
          "pincodeinsured",
          propertyDetailsFromApi?.pincodeinsured || null
        );
        setValue("stateinsured", propertyDetailsFromApi?.stateinsured || null);
        setValue("cityinsured", propertyDetailsFromApi?.cityinsured || null);
      }
    }
  }, [locationofRisk?.label, proposerDetailsFromApi]);

  useEffect(() => {
    if (activeFormTitle === VEHICLE_DETAILS) {
      if (isSameAddress?.label === "Yes") {
        setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
        setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
        setValue("pincodeVd", proposerDetailsFromApi?.sourcingPinCode);
        setValue("stateVd", proposerDetailsFromApi?.sourcingState);
        setValue("cityVd", proposerDetailsFromApi?.sourcingCity);
      } else {
        setValue(
          "addressLineonev",
          vehicleDetailsFromApi?.addressLineonev || null
        );
        setValue(
          "addressLinetwov",
          vehicleDetailsFromApi?.addressLinetwov || null
        );
        setValue("pincodeVd", vehicleDetailsFromApi?.pincodeVd || null);
        setValue(
          "stateVd",
          vehicleDetailsFromApi?.stateVd
            ? {
                label: vehicleDetailsFromApi?.stateVd,
                value: vehicleDetailsFromApi?.stateVd,
              }
            : null
        );
        setValue(
          "cityVd",
          vehicleDetailsFromApi?.cityVd
            ? {
                label: vehicleDetailsFromApi?.cityVd,
                value: vehicleDetailsFromApi?.cityVd,
              }
            : null
        );
      }
    }
    if (activeFormTitle === PROPOSER_DETAILS) {
      if (isSameAddress?.label === "Yes") {
        if (typeofcustomer?.label === "Company") {
          setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
          setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
          setValue("pincodeVd", pincode || proposerDetailsFromApi?.pincode);
          setValue("stateVd", state || proposerDetailsFromApi?.state);
          setValue("cityVd", city || proposerDetailsFromApi?.city);
        }
        if (typeofcustomer?.label === "Individual") {
          setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
          setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
          setValue(
            "pincodeVd",
            sourcingPinCode || proposerDetailsFromApi?.sourcingPinCode
          );
          setValue(
            "stateVd",
            sourcingState || proposerDetailsFromApi?.sourcingState
          );
          setValue(
            "cityVd",
            sourcingCity || proposerDetailsFromApi?.sourcingCity
          );
        }
      } else {
        setValue("addressLineonev", null);
        setValue("addressLinetwov", null);
        setValue(
          "pincodeVd",
          proposerDetailsFromApi?.pincodeVd
            ? proposerDetailsFromApi?.pincodeVd
            : null
        );
        setValue(
          "stateVd",
          proposerDetailsFromApi?.stateVd
            ? {
                label: proposerDetailsFromApi?.stateVd,
                value: proposerDetailsFromApi?.stateVd,
              }
            : null
        );
        setValue(
          "cityVd",
          proposerDetailsFromApi?.cityVd
            ? {
                label: proposerDetailsFromApi?.cityVd,
                value: proposerDetailsFromApi?.cityVd,
              }
            : null
        );
      }
    }
    if (
      activeFormTitle !== VEHICLE_DETAILS &&
      activeFormTitle !== PROPOSER_DETAILS
    ) {
      if (isSameAddress?.label === "Yes") {
        setValue("addressLineonev", proposerDetailsFromApi?.addressLineone);
        setValue("addressLinetwov", proposerDetailsFromApi?.addressLinetwo);
        setValue("pincodeVd", proposerDetailsFromApi?.sourcingPinCode);
        setValue("stateVd", proposerDetailsFromApi?.sourcingState);
        setValue("cityVd", proposerDetailsFromApi?.sourcingCity);
      } else {
        setValue("addressLineonev", null);
        setValue("addressLinetwov", null);
        setValue("pincodeVd", null);
        setValue("stateVd", null);
        setValue("cityVd", null);
      }
    }
  }, [isSameAddress?.label, proposerDetailsFromApi, activeFormTitle]);

  useEffect(() => {
    if (activeFormTitle === LIFE_ASSURED_DETAILS) {
      if (addresschecktwo?.label === "Yes") {
        setValue("addressAssuredone", assuredAddressone);
        setValue("addressAssuredtwo", assuredAddresstwo);
        setValue("assuredPincode", pinCode);
        setValue("assuredState", state);
        setValue("assuredCity", city);
        setValue("landmarkAssured", assuredLandmark);
      } else {
        setValue(
          "addressAssuredone",
          lifeAssuredDetailsFromApi?.addressAssuredone || null
        );
        setValue(
          "addressAssuredtwo",
          lifeAssuredDetailsFromApi?.addressAssuredtwo || null
        );
        setValue(
          "assuredPincode",
          lifeAssuredDetailsFromApi?.assuredPincode || null
        );
        setValue(
          "assuredState",
          lifeAssuredDetailsFromApi?.assuredState
            ? {
                label: lifeAssuredDetailsFromApi?.assuredState,
                value: lifeAssuredDetailsFromApi?.assuredState,
              }
            : null
        );
        setValue(
          "assuredCity",
          lifeAssuredDetailsFromApi?.assuredCity
            ? {
                label: lifeAssuredDetailsFromApi?.assuredCity,
                value: lifeAssuredDetailsFromApi?.assuredCity,
              }
            : null
        );
        setValue(
          "landmarkAssured",
          lifeAssuredDetailsFromApi?.landmarkAssured || null
        );
      }
    }
  }, [addresschecktwo?.label, activeFormTitle]);

  useEffect(() => {
    if (pastInsurance?.label === "Yes") {
      dispatch(setIsPrevPolicyVisibleAction(true));
    }
    if (pastInsurance?.label === "No" || !pastInsurance?.label) {
      dispatch(setIsPrevPolicyVisibleAction(false));
    }
  }, [pastInsurance?.label]);

  const shape = {}; //globally defined because of yup lazy multiple render bug
  const paymentShape = {}; //globally defined because of yup lazy multiple render bug

  // multi travel details
  // const travelFields = matchedObject[TRAVEL_DETAILS];

  const selectedClaim =
    (Array.isArray(claimRespectivetravel) &&
      claimRespectivetravel?.map((item) => item?.value)) ||
    [];

  // const travelfieldstwo =
  //   extraProposalCreate?.[extraCardTitles.indexOf(TRAVEL_DETAILS)];

  let travelFieldsToRender = [];

  const claimsToFieldLists = [
    {
      claimType: "Accident Death or Partial Disability",
      fieldList: accidentDeathOrPartialDisabilityFieldList,
    },
    { claimType: "Flight Delay", fieldList: flightDelayFieldList },
    { claimType: "Loss of Passport", fieldList: lossOfPassportFieldList },
    {
      claimType: "Loss of Checked in Baggage and Delay of Checked in Baggage",
      fieldList: lossOfCheckedInBaggageAndDelayOfCheckedInBaggageFieldList,
    },
    { claimType: "Theft", fieldList: theftFieldList },
    {
      claimType: "Medical Expenses / Emergency Dental Care",
      fieldList: medicalExpensesEmergencyDentalCareFieldList,
    },
    // Add more claim types and their corresponding field lists here
  ];

  // claimsToFieldLists?.forEach(({ claimType, fieldList }) => {
  //   if (Array.isArray(selectedClaim) && selectedClaim?.includes(claimType)) {
  //     let tempArray = [];
  //     tempArray.push({
  //       description: "Enter Place of Accident",
  //       field: "HIDDEN",
  //       isTransactionField: "N",
  //       isUpdatedField: "N",
  //       iseditable: "Y",
  //       key: "Claimdropdown",
  //       label: "Claim Dropdown",
  //       length: 255,
  //       mandatory: "N",
  //       validationGroup: "TEXT",
  //       formTitle: claimType,
  //     });
  //     console.log({ travelfieldstwo }, { claimsToFieldLists }, "here1");
  //     console.log({ claimType }, { fieldList }, "here2");
  //     travelfieldstwo?.forEach((item) => {
  //       if (fieldList?.includes(item?.key)) {
  //         tempArray.push(item);
  //       }
  //     });
  //     console.log({ travelfieldstwo }, { tempArray }, "here3");
  //     if (tempArray.length > 0) {
  //       travelFieldsToRender.push([...tempArray]);
  //     }
  //   }
  // });

  // console.log(travelFieldsToRender, "final");

  let dynamicYupValidate = yup.object().shape({
    bookDate: mandatoryFieldSlugs?.includes("bookDate")
      ? yup
          .date()
          .required("Policy Book Date is required")
          .typeError("Date must be a valid date")
          .test(`is-valid-bookDate`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          })
      : yup.mixed().nullable().notRequired(),
    policystDate: mandatoryFieldSlugs?.includes("policystDate")
      ? yup
          .date()
          .required("Policy Start Date is required")
          .min(yup.ref("bookDate"), "Must be after book date")
          .typeError("Date must be a valid date")
          .test(`is-valid-policyStartDate`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          })
          .test(
            `is-valid-policyStartDate`,
            "Can not be same as policy expiry date",
            function (value) {
              if (!value) return true;
              const { policyExpirydate } = this.parent;
              return !moment(value).isSame(moment(policyExpirydate));
            }
          )
      : yup.mixed().nullable().notRequired(),
    policyExpirydate: mandatoryFieldSlugs?.includes("policyExpirydate")
      ? yup
          .date()
          .required("Policy Expiry Date is required")
          .typeError("Date must be a valid date")
          .min(yup.ref("policystDate"), "Must be after start date")
          .test(`is-valid-policyExpiryDate`, (value, ctx) => {
            if (new Date(value) < new Date("1900-01-01")) {
              return ctx.createError({
                message: "Date cannot be before 01/01/1900",
              });
            }
            return true;
          })
          .test(
            `is-valid-policyStartDate`,
            "Can not be same as policy start date",
            function (value) {
              if (!value) return true;
              const { policystDate } = this.parent;
              return !moment(value).isSame(moment(policystDate));
            }
          )
      : yup.mixed().nullable().notRequired(),
    drivingLicence:
      mandatoryFieldSlugs?.includes("drivingLicence") ||
      optionalFieldSlugs?.includes("drivingLicence")
        ? yup
            .string()
            .nullable()
            .test(
              "valid-license",
              "Please enter valid driving licence number",
              function (value) {
                const regex = /^(?:[A-Z]{2}[ -]?\d{2}|\w{4}[ -]?)\d{4}\d{7}$/;
                if (value && !regex.test(value)) {
                  return false;
                }
                return true;
              }
            )
        : yup.string().notRequired(),
    passportNumber:
      mandatoryFieldSlugs?.includes("passportNumber") ||
      optionalFieldSlugs?.includes("passportNumber")
        ? yup
            .string()
            .nullable()
            .test(
              "valid-passport",
              "Please enter valid passport number",
              function (value) {
                const regex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
                if (value && !regex.test(value)) {
                  return false;
                }
                return true;
              }
            )
        : yup.string().notRequired(),
    pucExpirydate:
      mandatoryFieldSlugs?.includes("pucStartdate") ||
      optionalFieldSlugs?.includes("pucStartdate")
        ? yup
            .date()
            .typeError("Date must be a valid date")
            .min(yup.ref("pucStartdate"), "Must be after PUC start date")
            .test(`is-valid-pucExpiryDate`, (value, ctx) => {
              if (new Date(value) < new Date("1900-01-01")) {
                return ctx.createError({
                  message: "Date cannot be before 01/01/1900",
                });
              }
              return true;
            })
        : yup.mixed().nullable().notRequired(),
    odexpirydate:
      optionalFieldSlugs?.includes("odexpirydate") &&
      yup
        .date()
        .nullable()
        .typeError("Date must be a valid date")
        .test(`odexpirydate`, "OD Expiry Date is required", function (value) {
          const { businessType } = this.parent;
          if (
            isBusinessTypeConditionsLobWise &&
            ![
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(businessType?.label)
          ) {
            return value;
          } else {
            return true;
          }
        }),
    tpexpirydate:
      optionalFieldSlugs?.includes("tpexpirydate") &&
      yup
        .date()
        .nullable()
        .typeError("Date must be a valid date")
        .test(`tpexpirydate`, "TP Expiry Date is required", function (value) {
          if (isBusinessTypeConditionsLobWise) {
            return value;
          } else {
            return true;
          }
        }),
    odPremium:
      optionalFieldSlugs?.includes("odPremium") &&
      yup
        .string()
        .nullable()
        .test(`odPremium`, "OD Premium is required", function (value) {
          if (
            isBusinessTypeConditionsLobWise &&
            ![
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(policyDetailsBusinessType)
          ) {
            return value;
          } else {
            return true;
          }
        }),
    tpPremium:
      optionalFieldSlugs?.includes("tpPremium") &&
      yup
        .string()
        .nullable()
        .test(`tpPremium`, "TP Premium is required", function (value) {
          if (isBusinessTypeConditionsLobWise) {
            return value;
          } else {
            return true;
          }
        }),
    emailId: mandatoryFieldSlugs?.includes("emailId")
      ? yup
          .string()
          .email("Please enter a valid email")
          .required("Email is required")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
            "Please enter valid email id"
          )
      : yup.string().notRequired(),
    alternateId:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .email("Please enter a valid email")
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                  "Please enter valid email id"
                )
                .test(`is-email-duplicate`, function (value, ctx) {
                  const { emailId } = this.parent;

                  if (value === emailId) {
                    return ctx.createError({
                      message: "Cannot be same as email id",
                    });
                  }
                  return true;
                });
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    assuredEmailtwo:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === LIFE_ASSURED_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .email("Please enter a valid email")
                .matches(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                  "Please enter valid email id"
                )
                .test(`is-email-duplicate`, function (value, ctx) {
                  const { assuredEmail } = this.parent;

                  if (value === assuredEmail) {
                    return ctx.createError({
                      message: "Cannot be same as email id",
                    });
                  }
                  return true;
                });
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    panNo: mandatoryFieldSlugs?.includes("panNo")
      ? yup
          .string()
          .required("PAN No is required")
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          )
          ?.trim()
      : yup.string().nullable().notRequired()?.trim(),
    panNo: mandatoryFieldSlugs?.includes("panNo")
      ? yup
          .string()
          .required("PAN No is required")
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          )
          ?.trim()
      : yup.string().nullable().notRequired()?.trim(),
    // pincodeHospital: mandatoryFieldSlugs?.includes("pincodeHospital")
    //   ? yup
    //       .string()
    //       .required("Hospital Pincode is required")
    //       .matches(/^[1-9][0-9]{5}$/, "Invalid Hospital Pincode")
    //   : yup.string().nullable().notRequired()?.trim(),
    nomineePanno: mandatoryFieldSlugs?.includes("nomineePanno")
      ? yup
          .string()
          .required("PAN is required")
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          )
          ?.trim()
      : yup
          .string()
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          )
          .transform((value, originalValue) => {
            if (!value) {
              return null;
            }
            return originalValue;
          })
          .nullable()
          .optional(),
    // hospitalDate:
    //   mandatoryFieldSlugs.includes('hospitalDate')
    //     ? yup
    //       .date()
    //       .required("Date Of Hospital Admission is required")
    //       .test(
    //         "is-valid-policyDate",
    //         "Date Of Hospital Admission must be between Policy Start Date and Policy Expiry Date",
    //         function (value) {
    //           if (!value) return true; // If value is not provided, return true (required check is handled separately)

    //           const dateValue = moment(value);
    //           const policyExpiry = moment(policyExpirydate);
    //           const policyStart = moment(policystDate);

    //           // Check if dateValue is before policyStart or after policyExpiry
    //           const isBeforeStart = dateValue.isBefore(policyStart);
    //           const isAfterExpiry = dateValue.isAfter(policyExpiry);

    //           // Return error if dateValue is before policyStart or after policyExpiry
    //           if (isBeforeStart || isAfterExpiry) {
    //             return this.createError({
    //               message:
    //                 "Date Of Hospital Admission must be between Policy Start Date and Policy Expiry Date",
    //             });
    //           }

    //           return true;
    //         },
    //       )
    //     : yup.string().notRequired(),
    claimAmount: mandatoryFieldSlugs?.includes("claimAmount")
      ? yup
          .string()
          .required("Total Claim Amount is required")
          .test(
            "is-less-than-insured-sum",
            "Claim Amount should not be more than Insured Sum Insured",
            function (value) {
              return parseInt(value, 10) <= sumInsuredAmount;
            }
          )
      : yup
          .string()
          .test(
            "is-less-than-insured-sum",
            "Claim Amount should not be more than Insured Sum Insured",
            function (value) {
              if (value) {
                return parseInt(value, 10) <= sumInsuredAmount;
              }
              return true;
            }
          )
          .notRequired(),
    ifscCode: mandatoryFieldSlugs?.includes("ifscCode")
      ? yup
          .string()
          .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, "Invalid IFSC code")
          .required("IFSC code is required")
      : yup.string().notRequired(),
    // Multi Member Validations
    members:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === INSURED_MEMBER_DETAILS
        ? yup.array().of(
            yup.lazy((value) => {
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );
              const nonMandatoryObjectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  optionalFieldSlugs?.includes(value)
                );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = insuredMembers
                  ?.flat(2)
                  ?.find((value) => value.key === fieldSlug);
                return relevantLabel?.label;
              };

              objectKeys?.forEach((key) => {
                if (dropdownFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateAndTimeFields?.includes(key)) {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`)
                    .test("check-TypeError", "Invalid Date", function (value) {
                      return false;
                    });
                } else if (dateFields?.includes(key)) {
                  shape[key] = yup
                    .date()
                    .typeError("Invalid Date!")
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else if (key === "insuredContactnumber") {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`)
                    .length(
                      10,
                      `${getRelevantLabel(key)} must be 10 characters`
                    );
                } else if (key === "relationId") {
                  shape[key] = yup
                    .string()
                    .required("Relation ID is required")
                    .test(
                      "relationId",
                      "Relation ID must be unique for each member",
                      function () {
                        let relIds = [
                          ...Array(
                            watch("members") ? watch("members").length : 1
                          ),
                        ]
                          .map((v, i) => watch(`members[${i}].relationId`))
                          .filter((i) => i);
                        let temp2 = _.uniq([...relIds]).filter((i) => i);
                        let uniqueRelIds = relIds.length !== temp2.length;

                        return !uniqueRelIds;
                      }
                    );
                } else {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });
              nonMandatoryObjectKeys?.forEach((key) => {
                if (key === "insuredContactnumber") {
                  shape[key] = yup
                    .string()
                    .test(
                      "insuredContactnumber",
                      "Contact number must contain 10 digits",
                      (value) => {
                        if (!value) return true;
                        return value?.length < 10 ? false : true;
                      }
                    );
                } else if (key === "abhaId") {
                  shape[key] = yup
                    .string()
                    .nullable()
                    .test(
                      "abhaId",
                      "ABHA ID must contain 14 digits",
                      (value) => {
                        if (!value) return true;
                        return value?.length < 14 ? false : true;
                      }
                    );
                  // : yup.string().notRequired()
                }
                //  else if (key === "insuredeiaNumber") {
                //   shape[key] = yup
                //     .string()
                //     .nullable()
                //     .test(
                //       "insuredeiaNumber",
                //       "EIA number must contain atleast 8 characters",
                //       value => {
                //         if (!value) return true;
                //         return value?.length < 8 ? false : true;
                //       },
                //     );
                //   // : yup.string().notRequired()
                // }
              });

              return yup.object().shape(shape);
            })
          )
        : yup.mixed().notRequired(),
    expenseDetails:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === EXPENSE_DETAILS
        ? yup.array().of(
            yup.lazy((value) => {
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );
              const nonMandatoryObjectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  optionalFieldSlugs?.includes(value)
                );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = (
                  insuredMembers ||
                  multipleEmployee ||
                  multipleCargoItemDetails
                )
                  ?.flat(2)
                  ?.find((value) => value.key === fieldSlug);
                return relevantLabel?.label;
              };

              objectKeys?.forEach((key) => {
                if (dropdownFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateAndTimeFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  shape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else if (key === "insuredContactnumber") {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`)
                    .length(
                      10,
                      `${getRelevantLabel(key)} must be 10 characters`
                    );
                } else if (key === "relationId") {
                  shape[key] = yup
                    .string()
                    .required("Relation ID is required")
                    .test(
                      "relationId",
                      "Relation ID must be unique for each member",
                      function () {
                        let relIds = [
                          ...Array(
                            watch("members") ? watch("members").length : 1
                          ),
                        ]
                          .map((v, i) => watch(`members[${i}].relationId`))
                          .filter((i) => i);
                        let temp2 = _.uniq([...relIds]).filter((i) => i);
                        let uniqueRelIds = relIds.length !== temp2.length;

                        return !uniqueRelIds;
                      }
                    );
                } else {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });
              nonMandatoryObjectKeys?.forEach((key) => {
                if (key === "approvedAmount") {
                  shape[key] = yup
                    .string()
                    .nullable()
                    .test(
                      "approvedAmount",
                      "Approved Amount must not be greater than Bill Amount",
                      function (value) {
                        if (!value) return true;
                        const { amount } = this.parent;

                        return +value <= +amount;
                      }
                    );
                }
                if (key === "billDate") {
                  shape[key] = yup.date().nullable().typeError("Invalid Date!");
                }
                //  else if (key === "insuredeiaNumber") {
                //   shape[key] = yup
                //     .string()
                //     .nullable()
                //     .test(
                //       "insuredeiaNumber",
                //       "EIA number must contain atleast 8 characters",
                //       value => {
                //         if (!value) return true;
                //         return value?.length < 8 ? false : true;
                //       },
                //     );
                //   // : yup.string().notRequired()
                // }
              });

              return yup.object().shape(shape);
            })
          )
        : yup.mixed().notRequired(),
    cargoItemDetails:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === CARGO_DETAILS
        ? yup.array().of(
            yup.lazy((value) => {
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );
              const nonMandatoryObjectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  optionalFieldSlugs?.includes(value)
                );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = multipleCargoItemDetails
                  ?.flat(2)
                  ?.find((value) => value.key === fieldSlug);
                return relevantLabel?.label;
              };

              objectKeys?.forEach((key) => {
                if (dropdownFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateAndTimeFields?.includes(key)) {
                  shape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  shape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else if (key === "insuredContactnumber") {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`)
                    .length(
                      10,
                      `${getRelevantLabel(key)} must be 10 characters`
                    );
                } else if (key === "datedamageNoted") {
                  shape[key] = yup.string().test();
                } else if (key === "natureofPackages") {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                  // .length(
                  //   10,
                  //   `${getRelevantLabel(key)} must be 10 characters`,
                  // );
                } else if (key === "numberofPackages") {
                  shape[key] = yup
                    .string()
                    .required("Number Of Packges Not Delivered is required")
                    .test(
                      "is-less-than-or-equal-to-coverage",
                      "Number Of Packges Not Delivered should not be more than Number Of Packages Delivery",
                      function (value) {
                        const { natureofPackages } = this.parent;
                        if (Boolean(value && +natureofPackages)) {
                          return +value <= +natureofPackages;
                        }
                        return false;
                      }
                    );
                } else if (key === "relationId") {
                  shape[key] = yup
                    .string()
                    .required("Relation ID is required")
                    .test(
                      "relationId",
                      "Relation ID must be unique for each member",
                      function () {
                        let relIds = [
                          ...Array(
                            watch("members") ? watch("members").length : 1
                          ),
                        ]
                          .map((v, i) => watch(`members[${i}].relationId`))
                          .filter((i) => i);
                        let temp2 = _.uniq([...relIds]).filter((i) => i);
                        let uniqueRelIds = relIds.length !== temp2.length;

                        return !uniqueRelIds;
                      }
                    );
                } else {
                  shape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });
              nonMandatoryObjectKeys?.forEach((key) => {
                if (key === "insuredContactnumber") {
                  shape[key] = yup
                    .string()
                    .test(
                      "insuredContactnumber",
                      "Contact number must contain 10 digits",
                      (value) => {
                        if (!value) return true;
                        return value?.length < 10 ? false : true;
                      }
                    );
                } else if (key === "abhaId") {
                  shape[key] = yup
                    .string()
                    .nullable()
                    .test(
                      "abhaId",
                      "ABHA ID must contain 14 digits",
                      (value) => {
                        if (!value) return true;
                        return value?.length < 14 ? false : true;
                      }
                    );
                  // : yup.string().notRequired()
                }
                //  else if (key === "insuredeiaNumber") {
                //   shape[key] = yup
                //     .string()
                //     .nullable()
                //     .test(
                //       "insuredeiaNumber",
                //       "EIA number must contain atleast 8 characters",
                //       value => {
                //         if (!value) return true;
                //         return value?.length < 8 ? false : true;
                //       },
                //     );
                //   // : yup.string().notRequired()
                // }
              });

              return yup.object().shape(shape);
            })
          )
        : yup.mixed().notRequired(),

    claimRespectivetravel: mandatoryFieldSlugs?.includes(
      "claimRespectivetravel"
    )
      ? yup.mixed().required("Claim Respect Travel is required")
      : yup.mixed().notRequired(),

    travelDetails:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === TRAVEL_DETAILS
        ? yup.array().of(
            yup.lazy((value, index) => {
              // if(index?.key) return yup.object().shape({});
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );

              const objectKeysNo =
                value &&
                Object.keys(value)?.filter(
                  (value) => !mandatoryFieldSlugs?.includes(value)
                );

              // const claimDropdowns = claimsToFieldLists?.filter(
              //   (item) => item?.claimType === value?.Claimdropdown
              // );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = multipleTravelDetails?.[0]
                  ?.flat(2)
                  ?.find((value) => value?.key === fieldSlug);
                return relevantLabel?.label;
              };

              const travelShape = {};
              objectKeys?.map((key) => {
                if (dropdownFields?.includes(key)) {
                  travelShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  travelShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  travelShape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .test(
                      `${key}-is-valid-date`,
                      `${getRelevantLabel(key)} is Invalid`,
                      (value, ctx) => {
                        return (
                          // value?.split(" ")[3] !== "0000" ||
                          value !== "Invalid Date"
                        );
                      }
                    )
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else if (dateAndTimeFields?.includes(key)) {
                  travelShape[key] = yup
                    .mixed()
                    .default(null)
                    .test(
                      `${value.label}-is-valid-date`,
                      `${getRelevantLabel(key)} is Invalid Date!`,
                      (value, ctx) => {
                        // return moment(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ", true).isValid();
                        return (
                          value?.format("DD-MM-YYYY")?.split("-")[2] !== "0000"
                        ); //Please do not try, if try do not complaint
                      }
                    )
                    .required(`${getRelevantLabel(key)} is required`);
                } else {
                  travelShape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });

              objectKeysNo?.map((key) => {
                if (dropdownFields?.includes(key)) {
                  travelShape[key] = yup.mixed();
                } else if (multiSelectFields?.includes(key)) {
                  travelShape[key] = yup.mixed();
                  // .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  travelShape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .test(
                      `${key}-is-valid-date`,
                      `${getRelevantLabel(key)} is Invalid`,
                      (value, ctx) => {
                        return (
                          // value?.split(" ")[3] !== "0000" ||
                          value !== "Invalid Date"
                        );
                      }
                    )
                    .notRequired();
                  // .nullable();
                  // .transform((v, o) => (o === "" ? null : v));
                } else if (dateAndTimeFields?.includes(key)) {
                  travelShape[key] = yup
                    .mixed()
                    .default(null)
                    .test(
                      `${value.label}-is-valid-date`,
                      `${getRelevantLabel(key)} is Invalid Date !`,
                      (value, ctx) => {
                        // return moment(value, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ", true).isValid();
                        if (value) {
                          return (
                            value?.format("DD-MM-YYYY")?.split("-")[2] !==
                            "0000"
                          );
                        }
                        return true;
                      }
                    )
                    .notRequired();
                } else {
                  travelShape[key] = yup.string().notRequired();
                }
              });

              return yup.object().shape(travelShape);

              // const abc = specificTravelDetails?.filter(
              //   (item) => item?.mandatory === "Y"
              // );

              // const abc = multipleTravelDetails?.[activeStep - 1]?.filter(
              //   (item) => item?.isMandatory === "Y"
              // );
              // console.log(
              //   travelFieldsToRender?.[0],
              //   "sam",
              //   abc,
              //   i,
              //   claimDropdowns
              // );
              // return generateDynamicYupSchema(abc);
            })
          )
        : yup.mixed().notRequired(),
    // uncomment this if want multiple travel insured member in rendering
    // claimantsDetails:
    //   cardTitlesPrevPolicyToggle?.[activeStep - 1] === TRAVEL_INSURED_MEMBER &&
    //   lobName === "Travel"
    //     ? yup.array().of(
    //         yup.lazy((value) => {
    //           const objectKeys =
    //             value &&
    //             Object.keys(value)?.filter((value) =>
    //               mandatoryFieldSlugs?.includes(value)
    //             );

    //           const getRelevantLabel = (fieldSlug) => {
    //             const relevantLabel = multiClaimantsItem
    //               ?.flat(2)
    //               ?.find((value) => value.key === fieldSlug);
    //             return relevantLabel?.label;
    //           };

    //           objectKeys?.forEach((key) => {
    //             if (dropdownFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (multiSelectFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (dateFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .typeError("Invalid Date!")
    //                 .required(`${getRelevantLabel(key)} is required`)
    //                 .nullable()
    //                 .transform((v, o) => (o === "" ? null : v));
    //             } else {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             }
    //           });

    //           return yup.object().shape(paymentShape);
    //         })
    //       )
    //     : yup.mixed().notRequired(),

    // travelDetails:
    //   cardTitlesPrevPolicyToggle?.[activeStep - 1] === TRAVEL_DETAILS &&
    //   lobName === "Travel"
    //     ? yup.array().of(
    //         yup.lazy((value) => {
    //           const objectKeys =
    //             value &&
    //             Object.keys(value)?.filter((value) =>
    //               mandatoryFieldSlugs?.includes(value)
    //             );

    //           const getRelevantLabel = (fieldSlug) => {
    //             console.log("jj", fieldSlug, travelFieldsToRender);
    //             const relevantLabel = travelFieldsToRender
    //               ?.flat(2)
    //               ?.find((value) => value.key === fieldSlug);
    //             return relevantLabel?.label;
    //           };

    //           objectKeys?.forEach((key) => {
    //             if (dropdownFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (multiSelectFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (dateFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .typeError("Invalid Date!")
    //                 .required(`${getRelevantLabel(key)} is required`)
    //                 .nullable()
    //                 .transform((v, o) => (o === "" ? null : v));
    //             }else if (dateAndTimeFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .required(`${getRelevantLabel(key)} is required`)
    //             }
    //              else {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             }
    //           });

    //           return yup.object().shape(paymentShape);
    //         })
    //       )
    //     : yup.mixed().notRequired(),

    // travelDetailsValidation:
    //   cardTitlesPrevPolicyToggle?.[activeStep - 1] === TRAVEL_DETAILS &&
    //   lobName === "Travel"
    //     ? yup.array().of(
    //         yup.lazy((value) => {
    //           const originalValue = value;
    //           value = Array.isArray(value) ? value.filter(Boolean) : [];

    //           const objectKeys =
    //             originalValue &&
    //             Object.keys(originalValue)?.filter((key) =>
    //               mandatoryFieldSlugs?.includes(key)
    //             );

    //           const getRelevantLabel = (fieldSlug) => {
    //             const relevantLabel = travelFieldsToRender
    //               ?.flat(2)
    //               ?.find((value) => value.key === fieldSlug);
    //             return relevantLabel?.label;
    //           };

    //           let paymentShape = {};

    //           objectKeys?.forEach((key) => {
    //             if (dropdownFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (multiSelectFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .mixed()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             } else if (dateFields?.includes(key)) {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .typeError("Invalid Date!")
    //                 .required(`${getRelevantLabel(key)} is required`)
    //                 .nullable()
    //                 .transform((v, o) => (o === "" ? null : v));
    //             } else {
    //               paymentShape[key] = yup
    //                 .string()
    //                 .required(`${getRelevantLabel(key)} is required`);
    //             }
    //           });

    //           return yup.object().shape(paymentShape);
    //         })
    //       )
    //     : yup.mixed().notRequired(),

    payment:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PAYMENT_DETAILS
        ? yup.array().of(
            yup.lazy((value) => {
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = multiplePayment
                  ?.flat(2)
                  ?.find((value) => value.key === fieldSlug);
                return relevantLabel?.label;
              };

              objectKeys?.forEach((key) => {
                if (dropdownFields?.includes(key)) {
                  paymentShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  paymentShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  paymentShape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else {
                  paymentShape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });

              return yup.object().shape(paymentShape);
            })
          )
        : yup.mixed().notRequired(),

    nominee:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === NOMINEE_DETAILS
        ? yup.array().of(
            yup.lazy((value) => {
              const objectKeys =
                value &&
                Object.keys(value)?.filter((value) =>
                  mandatoryFieldSlugs?.includes(value)
                );

              const getRelevantLabel = (fieldSlug) => {
                const relevantLabel = multipleNominee
                  ?.flat(2)
                  ?.find((value) => value.key === fieldSlug);
                return relevantLabel?.label;
              };

              objectKeys?.forEach((key) => {
                if (dropdownFields?.includes(key)) {
                  paymentShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (multiSelectFields?.includes(key)) {
                  paymentShape[key] = yup
                    .mixed()
                    .required(`${getRelevantLabel(key)} is required`);
                } else if (dateFields?.includes(key)) {
                  paymentShape[key] = yup
                    .string()
                    .typeError("Invalid Date!")
                    .required(`${getRelevantLabel(key)} is required`)
                    .nullable()
                    .transform((v, o) => (o === "" ? null : v));
                } else {
                  paymentShape[key] = yup
                    .string()
                    .required(`${getRelevantLabel(key)} is required`);
                }
              });

              return yup.object().shape(paymentShape);
            })
          )
        : yup.mixed().notRequired(),
    policyNo: mandatoryFieldSlugs?.includes("policyNo")
      ? yup
          .string()
          .required("Policy number is required")
          .test(`is-policy-duplicate`, (value, ctx) => {
            if (
              !!value?.length &&
              !!prevPolicyDetailsFromApi?.previousPolicynum?.length &&
              value === prevPolicyDetailsFromApi?.previousPolicynum
            ) {
              return ctx.createError({
                message:
                  "Policy number cannot be same as previous policy number",
              });
            }
            return true;
          })
      : yup.string().notRequired(),
    previousPolicynum:
      mandatoryFieldSlugs?.includes("previousPolicynum") ||
      optionalFieldSlugs?.includes("previousPolicynum")
        ? yup.string().test(`is-prev-policy-duplicate`, (value, ctx) => {
            if (
              !!value?.length &&
              !!policyDetailsFromApi?.policyNo?.length &&
              value === policyDetailsFromApi?.policyNo
            ) {
              return ctx.createError({
                message:
                  "Previous policy no. cannot be same as current policy number",
              });
            }
            return true;
          })
        : yup.string().notRequired(),
    identityvalue:
      mandatoryFieldSlugs?.includes("identityvalue") ||
      optionalFieldSlugs?.includes("identityvalue")
        ? yup
            .string()
            .nullable()
            .test(`identityvalue`, function (value, ctx) {
              if (!value) return true;
              const { idProof } = this.parent;
              const identityProof = idProof?.value?.toLowerCase();
              const aadharRegex = /^\d{4}\d{4}\d{4}$/;
              const panRegex =
                /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
              const voterIdRegex = /^[A-Z]{3}[0-9]{7}$/;
              const passportRegex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
              const drivingLicenseRegex =
                /^(?:[A-Z]{2}[ -]?\d{2}|\w{4}[ -]?)\d{4}\d{7}$/;

              if (
                identityProof?.includes("aadhar") &&
                !aadharRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Aadhaar number is invalid",
                });
              }
              if (identityProof?.includes("pan") && !panRegex.test(value)) {
                return ctx.createError({
                  message: "PAN number is invalid",
                });
              }
              if (
                identityProof?.includes("voter") &&
                !voterIdRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Voter id is invalid",
                });
              }
              if (
                identityProof?.includes("passport") &&
                !passportRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Passport number is invalid",
                });
              }
              if (
                identityProof?.includes("driving") &&
                !drivingLicenseRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Driving license is invalid",
                });
              }
              return true;
            })
        : yup.string().notRequired(),
    addressvalue:
      mandatoryFieldSlugs?.includes("addressvalue") ||
      optionalFieldSlugs?.includes("addressvalue")
        ? yup
            .string()
            .nullable()
            .test(`addressvalue`, function (value, ctx) {
              if (!value) return true;
              const { addressProof } = this.parent;
              const identityProof = addressProof?.value?.toLowerCase();
              const aadharRegex = /^\d{4}\d{4}\d{4}$/;
              const panRegex =
                /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
              const voterIdRegex = /^[A-Z]{3}[0-9]{7}$/;
              const passportRegex = /^[A-Z][1-9][0-9]\s?[0-9]{4}[1-9]$/;
              const drivingLicenseRegex =
                /^(?:[A-Z]{2}[ -]?\d{2}|\w{4}[ -]?)\d{4}\d{7}$/;

              if (
                identityProof?.includes("aadhar") &&
                !aadharRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Aadhaar number is invalid",
                });
              }
              if (identityProof?.includes("pan") && !panRegex.test(value)) {
                return ctx.createError({
                  message: "PAN number is invalid",
                });
              }
              if (
                identityProof?.includes("voter") &&
                !voterIdRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Voter id is invalid",
                });
              }
              if (
                identityProof?.includes("passport") &&
                !passportRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Passport number is invalid",
                });
              }
              if (
                identityProof?.includes("driving") &&
                !drivingLicenseRegex.test(value)
              ) {
                return ctx.createError({
                  message: "Driving license is invalid",
                });
              }
              return true;
            })
        : yup.string().notRequired(),
    // eiaNumber: mandatoryFieldSlugs?.includes("eiaNumber")
    //   ? yup
    //       .string()
    //       .nullable()
    //       .required("EIA number is required")
    //       .test(
    //         "eia-number",
    //         "EIA number must contain atleast 8 characters",
    //         value => {
    //           if (!value) return true;
    //           return value?.length < 8 ? false : true;
    //         },
    //       )
    //   : yup
    //       .string()
    //       .nullable()
    //       .notRequired()
    //       .test(
    //         "eia-number",
    //         "EIA number must contain atleast 8 characters",
    //         value => {
    //           if (!value) return true;
    //           return value?.length < 8 ? false : true;
    //         },
    //       ),
    // assuredeiaNumber: mandatoryFieldSlugs?.includes("assuredeiaNumber")
    //   ? yup
    //       .string()
    //       .nullable()
    //       .required("EIA number is required")
    //       .test(
    //         "eia-number",
    //         "EIA number must contain atleast 8 characters",
    //         value => {
    //           if (!value) return true;
    //           return value?.length < 8 ? false : true;
    //         },
    //       )
    //   : yup
    //       .string()
    //       .nullable()
    //       .notRequired()
    //       .test(
    //         "eia-number",
    //         "EIA number must contain atleast 8 characters",
    //         value => {
    //           if (!value) return true;
    //           return value?.length < 8 ? false : true;
    //         },
    //       ),
    policyTerm: mandatoryFieldSlugs?.includes("policyTerm")
      ? yup
          .string()
          .nullable()
          .required("Policy term in years is required")
          .test("policyTerm", "Must be atleast 1 year", (value) => {
            if (!value) return true;
            return value == 0 ? false : true;
          })
          .test("policyTerm", "Must not exceed 82 years", (value) => {
            if (!value) return true;
            return value > 82 ? false : true;
          })
      : yup
          .string()
          .nullable()
          .notRequired()
          .test("policyTerm", "Must be atleast 1 year", (value) => {
            if (!value) return true;
            return value == 0 ? false : true;
          })
          .test("policyTerm", "Must not exceed 82 years", (value) => {
            if (!value) return true;
            return value > 82 ? false : true;
          }),
    paymentTerm: mandatoryFieldSlugs?.includes("paymentTerm")
      ? yup
          .string()
          .nullable()
          .required("Premium payment term in years is required")
          .test("paymentTerm", "Must be atleast 1 year", (value) => {
            if (!value) return true;
            return value == 0 ? false : true;
          })
          .test("paymentTerm", "Must not exceed 82 years", (value) => {
            if (!value) return true;
            return value > 82 ? false : true;
          })
      : yup
          .string()
          .nullable()
          .notRequired()
          .test("paymentTerm", "Must be atleast 1 year", (value) => {
            if (!value) return true;
            return value == 0 ? false : true;
          })
          .test("paymentTerm", "Must not exceed 82 years", (value) => {
            if (!value) return true;
            return value > 82 ? false : true;
          }),
    ckycId: mandatoryFieldSlugs?.includes("ckycId")
      ? yup
          .string()
          .nullable()
          .required("CKYC number is required")
          .test("ckycId", "CKYC number is invalid", (value) => {
            if (!value || value?.length < 14) return true;
            const regex = /^(?!([0-9])\1*$)[0-9]+$/;
            return regex.test(value);
          })
          .test("ckycId", "CKYC number must contain 14 digits", (value) => {
            if (
              cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
            ) {
              if (!value) return true;
              return value && value?.length == 14;
            }
            return true;
          })
      : yup
          .string()
          .nullable()
          .notRequired()
          .test("ckycId", "CKYC number is invalid", (value) => {
            if (!value || value?.length < 14) return true;
            const regex = /^(?!([0-9])\1*$)[0-9]+$/;
            return regex.test(value);
          })
          .test("ckycId", "CKYC number must contain 14 digits", (value) => {
            if (
              cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
            ) {
              if (!value) return true;
              return value && value?.length == 14;
            }
            return true;
          }),
    ckycId: optionalFieldSlugs?.includes("ckycId")
      ? yup
          .string()
          .matches(/^$|^\d{14}$/, "CKYC number must be exactly 14 digits")
          .nullable()
      : yup.string().nullable().notRequired(),
    estimatedLoss: optionalFieldSlugs?.includes("estimatedLoss")
      ? yup
          .string()
          .test(
            "is-less-than-insured-sum",
            "Estimated Amount Of Loss should be less than Sum Insured",
            function (value) {
              if (value) {
                return parseInt(value, 10) <= sumInsuredAmount;
              }
              return true;
            }
          )
          .nullable()
          .notRequired()
      : yup.string().nullable().notRequired(),
    alternateMobileno:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
                .test(`is-mobile-number-duplicate`, function (value, ctx) {
                  const { mobileNo } = this.parent;
                  if (value === mobileNo) {
                    return ctx.createError({
                      message: "Cannot be same as mobile number",
                    });
                  }
                  return true;
                });
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    assuredmobileNotwo:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === LIFE_ASSURED_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .min(10, "Please enter 10 digits")
                .max(10, "Please enter 10 digits")
                .matches(/^[6-9][0-9]{9}$/, "Invalid mobile number")
                .test(`is-mobile-number-duplicate`, function (value, ctx) {
                  const { assuredmobileNo } = this.parent;
                  if (value === assuredmobileNo) {
                    return ctx.createError({
                      message: "Cannot be same as mobile number",
                    });
                  }
                  return true;
                });
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    nomineePan:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === NOMINEE_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .matches(
                  /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
                  "PAN number invalid"
                );
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    aadharCardno:
      // cardTitlesPrevPolicyToggle?.[activeStep - 1] === PROPOSER_DETAILS &&
      mandatoryFieldSlugs?.includes("aadharCardno")
        ? yup
            .string()
            .required("Aadhar card no. is required")
            .matches(/^\d{4}\d{4}\d{4}$/, "Aadhaar number is invalid")
        : yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .matches(/^\d{4}\d{4}\d{4}$/, "Aadhaar number is invalid");
            }
            return yup.mixed().notRequired();
          }),
    // : yup.string().notRequired(),
    nomineeAadharno:
      cardTitlesPrevPolicyToggle?.[activeStep - 1] === NOMINEE_DETAILS
        ? yup.lazy((value) => {
            if (value) {
              return yup
                .string()
                .matches(/^\d{4}\d{4}\d{4}$/, "Adhaar Number Invalid");
            }
            return yup.mixed().notRequired();
          })
        : yup.string().notRequired(),
    // engineNo: mandatoryFieldSlugs?.includes("engineNo")
    //   ? yup
    //       .string()
    //       .required("Engine number is required")
    //       .matches(
    //         /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //         "Invalid engine number "
    //       )
    //       .min(10, "Invalid engine number")
    //   : yup.lazy((value) => {
    //       if (value) {
    //         return yup
    //           .string()
    //           .matches(
    //             /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //             "Invalid engine number"
    //           )
    //           .min(10, "Invalid engine number");
    //       }
    //       return yup.mixed().notRequired();
    //     }),
    // chassisNo: mandatoryFieldSlugs?.includes("chassisNo")
    //   ? yup
    //       .string()
    //       .required("Chassis number is required")
    //       .matches(
    //         /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //         "Invalid chassis number"
    //       )
    //       .min(10, "Invalid chassis number")
    //   : yup.lazy((value) => {
    //       if (value) {
    //         return yup
    //           .string()
    //           .matches(
    //             /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/,
    //             "Invalid chassis number"
    //           )
    //           .min(10, "Invalid engine number");
    //       }
    //       return yup.mixed().notRequired();
    //     }),
    coverage:
      mandatoryFieldSlugs?.includes("coverage") &&
      yup.string().test("coverage", function (value) {
        const { planType } = this.parent;
        const plan = planType?.value?.trim();
        if (!value || isNaN(value)) {
          return this.createError({
            message: `Sum insured is required`,
            path: this.path,
          });
        }
        if (
          plan === PLAN_TYPE_SOOKSHMA_UDHYAM ||
          plan === PLAN_TYPE_LAGHU_UDHYAM
        ) {
          if (plan === PLAN_TYPE_SOOKSHMA_UDHYAM && value > 50000000) {
            return this.createError({
              message: "Should not exceed 5 Cr",
              path: this.path,
            });
          }
          if (plan === PLAN_TYPE_LAGHU_UDHYAM && value > 500000000) {
            return this.createError({
              message: "Should not exceed 50 Cr",
              path: this.path,
            });
          }
        }
        return true;
      }),
    discoveryofDefalcation:
      mandatoryFieldSlugs?.includes("discoveryofDefalcation") &&
      policyData?.policyType === "Fidelity Insurance Policy"
        ? yup.string().required("Discovery of Defalcation is Required")
        : yup.string().notRequired(),
    dateDefalcation:
      mandatoryFieldSlugs?.includes("dateDefalcation") &&
      policyData?.policyType === "Fidelity Insurance Policy"
        ? yup.string().required("Date and Time of Defalcation is Required")
        : yup.string().notRequired(),
    causeofLoss:
      mandatoryFieldSlugs?.includes("causeofLoss") &&
      policyData?.policyType !== "Fidelity Insurance Policy"
        ? yup.string().required("Cause of Loss is Required")
        : yup.string().notRequired(),
    modeofTranspo:
      mandatoryFieldSlugs?.includes("modeofTranspo") &&
      yup
        .array()
        .min(1, "Mode Of Transport Details is required ")
        .required("Mode Of Transport Details is required"),
  });

  useEffect(() => {
    if (lobData?.data?.data?.lob) {
      setValue("lob", lobData?.data?.data?.lob);
    }
  }, [lobData?.data?.data?.lob]);

  useEffect(() => {
    if (aadharCardno && idProof?.label?.includes("Aadhar")) {
      setValue("identityvalue", aadharCardno);
    }
    if (aadharCardno && addressProof?.label?.includes("Aadhar")) {
      setValue("addressvalue", aadharCardno);
    }
  }, [aadharCardno, idProof?.label, addressProof?.label]);

  useEffect(() => {
    if (premiumPaidby?.label === "Self") {
      setValue(
        "premiumPayername",
        `${firstName ? firstName : ""} ${middleName ? middleName : ""} ${
          lastName ? lastName : ""
        }`
      );
    }
  }, [premiumPaidby?.label]);

  useEffect(() => {
    if (benificiaryRelationshiptopolicyholder?.label === "Self") {
      setValue(
        "beneficiaryName",
        proposerDetailsFromApi?.firstName +
          " " +
          proposerDetailsFromApi?.middleName +
          " " +
          proposerDetailsFromApi?.lastName
      );
      setValue("benificiarycontactNumber", proposerDetailsFromApi?.mobileNo);
    } else {
      setValue("beneficiaryName", "");
      setValue("benificiarycontactNumber", "");
    }
  }, [benificiaryRelationshiptopolicyholder?.label]);
  // Multi Member Validation
  useMemo(() => {
    //dynamically generates yup schema based on current activeStep
    const validationBasedOnTitle = [
      // INSURED_MEMBER_DETAILS,
      PAYMENT_DETAILS,
      EXPENSE_DETAILS,
      NOMINEE_DETAILS,
      CARGO_DETAILS,
      // TRAVEL_INSURED_MEMBER,
      TRAVEL_DETAILS,
    ];

    let validationSchema = validationBasedOnTitle.includes(
      cardTitlesPrevPolicyToggle?.[activeStep - 1]
    )
      ? yup.object().shape({})
      : !CustomerTypeCompany && activeFormTitle === PROPOSER_DETAILS
      ? generateDynamicYupSchema(
          mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
            (obj) => !companyFields.includes(obj?.key)
          )
        )
      : hideGrihaFields?.includes(policyDetailsPlanType) &&
        activeFormTitle === PROPERTY_DETAILS
      ? generateDynamicYupSchema(
          mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
            (obj) => !grihaRakshaFields.includes(obj?.key)
          )
        )
      : policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA &&
        activeFormTitle === PROPERTY_DETAILS
      ? generateDynamicYupSchema(
          mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]?.filter(
            (obj) => !fieldsOtherThanGrihaRaksha.includes(obj?.key)
          )
        )
      : generateDynamicYupSchema(
          mandatoryFieldsPrevPolicyToggle?.[activeStep - 1]
        );
    // uncomment this if want multiple travel insured member validation
    // if (lobName === "Travel") {
    //   validationBasedOnTitle?.push(TRAVEL_INSURED_MEMBER);
    // }
    // Multi Member Optional Validation
    const optionalSchemaValidationBasedOnTitle = [
      INSURED_MEMBER_DETAILS,
      PAYMENT_DETAILS,
      EXPENSE_DETAILS,
      NOMINEE_DETAILS,
      CARGO_DETAILS,
      TRAVEL_DETAILS,
    ];

    let optionalSchema = optionalSchemaValidationBasedOnTitle.includes(
      cardTitlesPrevPolicyToggle?.[activeStep - 1]
    )
      ? yup.object().shape({})
      : generateDynamicOptionalYupSchema(
          optionalFieldsPrevPolicyToggle?.[activeStep - 1]
        );
    // uncomment this if want multiple travel insured member in validation
    // if (lobName === "Travel") {
    //   optionalSchemaValidationBasedOnTitle?.push(TRAVEL_INSURED_MEMBER);
    // }

    //merges mandatory and non-mandatory validations
    const mergedSchema = optionalSchema
      ? validationSchema.concat(optionalSchema)
      : validationSchema;

    //merges hardcoded validations with main dynamic schema
    const completeSchema = dynamicYupValidate
      ? mergedSchema.concat(dynamicYupValidate)
      : mergedSchema;

    //merges all the schema to the main yup schema
    const mainMergedSchema = completeSchema
      ? yupValidate.concat(completeSchema)
      : yupValidate;

    setMergedSchema(mainMergedSchema);
  }, [
    lobName,
    activeStep,
    CustomerTypeCompany,
    mandatoryFieldsPrevPolicyToggle?.length,
    optionalFieldsPrevPolicyToggle?.length,
  ]);

  const ifscData = useGetBankDataFromIfsc(payment);
  const { data: icData } = useGetCompaniesAccordingToLOB(lobId);
  const { data: branchData } = useGetICBranchDetails(watch("icName")?.id || 0);
  // const { data: locationData } = useGetBranchLocation();
  const { data: userData } = useGetUserById({ userId: getUserIdFromType() });

  const { data: pincodeData1 } = useGetStateCityFromPincode(
    sourcingPinCode,
    "sourcingPinCode",
    setValue
  );
  const { data: pincodeDataTwo } = useGetStateCityFromPincode(
    pincodetwo || pinCodetwo
  );
  const { data: pincodeData2 } = useGetStateCityFromPincode(pinCode || pincode);
  const { data: pincodeData3 } = useGetStateCityFromPincode(
    pincodeVd,
    "pincodeVd",
    setValue
  );
  const { data: pincodeData4 } = useGetStateCityFromPincode(
    pinCodeld,
    "pinCodeld",
    setValue
  );
  const { data: pincodeData5 } = useGetStateCityFromPincode(
    pincodeinsured,
    "pincodeinsured",
    setValue
  );
  const { data: pincodeData6 } = useGetStateCityFromPincode(
    pincodetwooinsured,
    "pincodetwooinsured",
    setValue
  );
  const { data: pincodeData7 } = useGetStateCityFromPincode(
    pincodeInsured,
    "pincodeInsured",
    setValue
  );
  const { data: pincodeData8 } = useGetStateCityFromPincode(
    pinCoderm,
    "pinCoderm",
    setValue
  );
  const { data: pincodeData9 } = useGetStateCityFromPincode(
    pincodeOne,
    "pincodeOne",
    setValue
  );
  const { data: pincodeData10 } = useGetStateCityFromPincode(
    assuredPincode,
    "assuredPincode",
    setValue
  );
  const { data: pincodeData11 } = useGetStateCityFromPincode(
    pincodeInvestment,
    "pincodeInvestment",
    setValue
  );
  const { data: pincodeHospital1 } = useGetStateCityFromPincode(
    pincodeHospital,
    "pincodeHospital",
    setValue
  );
  const { data: accidentPincode1 } = useGetStateCityFromPincode(
    accidentPincode,
    "accidentPincode",
    setValue
  );

  const useSetStateAndCity = (pincodeData, stateKey, cityKey) => {
    useEffect(() => {
      const state = pincodeData?.data?.data?.[0]?.state?.stateName;
      const city = pincodeData?.data?.data?.[0]?.city?.cityName;
      if (state) {
        setValue(stateKey, { label: state, value: state });
      } else {
        setValue(stateKey, null);
      }
      if (city) {
        setValue(cityKey, { label: city, value: city });
      } else {
        setValue(cityKey, null);
      }
    }, [pincodeData, stateKey, cityKey]);
  };

  useSetStateAndCity(pincodeData1, "sourcingState", "sourcingCity");
  useSetStateAndCity(pincodeData2, "state", "city");
  useSetStateAndCity(pincodeData3, "stateVd", "cityVd");
  useSetStateAndCity(pincodeData4, "stateld", "cityld");
  useSetStateAndCity(pincodeDataTwo, "statetwo", "citytwo");
  useSetStateAndCity(pincodeData5, "stateinsured", "cityinsured");
  useSetStateAndCity(pincodeData6, "statetwoinsured", "citytwoinsured");
  useSetStateAndCity(pincodeData7, "stateInsured", "cityInsured");
  useSetStateAndCity(pincodeData8, "staterm", "cityrm");
  useSetStateAndCity(pincodeData9, "stateOne", "cityOne");
  useSetStateAndCity(pincodeData10, "assuredState", "assuredCity");
  useSetStateAndCity(pincodeData11, "stateInvestment", "cityInvestment");
  useSetStateAndCity(pincodeHospital1, "sourcingState", "sourcingCity");
  useSetStateAndCity(accidentPincode1, "accidentState", "accidentCity");

  // used to filter customer list by name, and to get data by customer ref id
  const { mutate: getAllCustomers, data: customerDataByCustomerRefId } =
    useGetCustomer();

  // used to get data by customer id when clicking on customer name dropdown
  const { data: customerDataBySelectingName } = useGetCustomerById({
    id: selectedCustomer,
  });

  const { data: customerID, mutateAsync: addCustomerViaPolicyEntry } =
    useCreateCustomerThroughPolicyEntry();
  const { mutateAsync: addCustomerMemberViaInsuredMember } =
    useCreateMultipleCustomerThroughPolicyEntry(); // for adding family members to customer through insured member

  const { data: customerMemberData } = useGetCustomerMembers({
    parentId: customerIdFromApi,
    memberCount: insuredMemberDetailsFromApi?.members?.length,
  });

  const {
    data: bankDataFromISFC,
    mutate: getBankDataFromIFSC,
    isLoading: isFetchingBankDetailsByIFSC,
  } = useGetBankDetailsByIFSC();
  const nomineeRelationWiseCustomerMember = customerMemberData?.filter(
    (item) => item?.relation === nomineeRelation?.value
  )?.[0];

  const productPayload = icName?.id
    ? { icId: icName?.id, product: productName }
    : { icId: insuranceCompanyname?.id, product: productNamepd };

  const { data: getAllProducts } =
    useGetProductsListPolicyEntry(productPayload);
  const allProducts = Array.isArray(getAllProducts?.data?.data)
    ? getAllProducts.data.data.map((value) => ({
        id: value?.productId,
        value: value?.productName,
      }))
    : [];

  const allCustomers = Array.isArray(customerDataByCustomerRefId?.data?.data)
    ? customerDataByCustomerRefId?.data?.data.map((value) => {
        return {
          id: value?.customerId,
          value:
            value?.firstName +
            (value?.middleName ? " " + value?.middleName : "") +
            (value?.lastName ? " " + value?.lastName : ""),
        };
      })
    : [];
  const customerDataByRefId =
    Array.isArray(customerDataByCustomerRefId?.data?.data) && customerRefId
      ? customerDataByCustomerRefId?.data?.data
      : [];

  function prefillCustomerData(data) {
    //prefills customer info on proposer card
    const proposerFields = cardFieldsPrevPolicyToggle?.[activeStep - 1]?.map(
      (value) => value.key
    );

    const objectKeys = Object.keys(data || {});
    const objectValues = Object.values(data || {});

    // objectKeys?.forEach((key, index) => {
    //   const propertyValue = objectValues?.[index];
    //   if (proposerFields?.includes(key)) {
    //     if (propertyValue) {
    //       if (dateFields?.includes(key)) {
    //         const isValidDate = moment(
    //           propertyValue,
    //           moment.ISO_8601,
    //           true,
    //         ).isValid();
    //         const isValidNumber = /^[0-9]+$/.test(propertyValue);
    //         if (isValidDate && !isValidNumber) {
    //           setValue(key, new Date(propertyValue));
    //         } else {
    //           setValue(key, "");
    //         }
    //       } else if (dropdownFields?.includes(key)) {
    //         setValue(
    //           key,
    //           propertyValue
    //             ? {
    //                 label: propertyValue,
    //                 value: propertyValue,
    //               }
    //             : null,
    //         );
    //       } else if (fileFields?.includes(key)) {
    //         dispatch(
    //           setUploadedFileURLAction({
    //             [key]: propertyValue,
    //           }),
    //         );
    //       } else {
    //         const isValidDate = moment(
    //           propertyValue,
    //           moment.ISO_8601,
    //           true,
    //         ).isValid();
    //         const isValidNumber = /^[0-9]+$/.test(propertyValue);
    //         if (isValidDate && !isValidNumber) {
    //           setValue(key, new Date(propertyValue));
    //         } else {
    //           setValue(key, propertyValue || null);
    //         }
    //       }
    //     }
    //   }
    // });

    setValue("customerId", data?.customerId);
    // setValue("customerHiddenid", data?.customerId);
    setValue("customerRefId", data?.customerRefId);
    setValue("firstName", data?.firstName);
    setValue("lastName", data?.lastName);
    setValue("emailId", data?.emailId);
    setValue("mobileNo", data?.mobileNumber);
    setValue("alternateId", data?.alternateEmailId);
    setValue("alternateMobileno", data?.alternateMobileNumber);
    setValue(
      "gender",
      data?.gender ? { value: data?.gender, label: data?.gender } : null
    );
    setValue(
      "maritialStatus",
      data?.maritialStatus
        ? {
            value: data?.maritialStatus,
            label: data?.maritialStatus,
          }
        : null
    );
    setValue(
      "dob",
      new Date(moment(data?.dob, "DD-MM-YYYY").format("YYYY-MM-DD"))
    );
    setValue(
      "anniversary",
      data?.anniversaryDate ? new Date(data?.anniversaryDate) : null
    );
    setValue(
      "occupation",
      data?.occupation
        ? {
            value: data?.occupation?.trim(),
            label: data?.occupation?.trim(),
          }
        : null
    );
    setValue("panCardno", data?.panNumber || data?.panCardno);
    setValue("aadharCardno", data?.aadharNumber);
    setValue("uccNumber", data?.uccNumber);
    setValue("sourcingPinCode", data?.pinCode);
    setValue("addressLineone", data?.adddressLine1);
    setValue("addressLinetwo", data?.adddressLine2);
    setValue("drivingLicence", data?.drivingLicience);
    setValue("ckycId", data?.ckycRefId);
    setValue(
      "sourcingCity",
      data?.city
        ? {
            value: data?.city,
            label: data?.city,
          }
        : null
    );
    setValue(
      "sourcingState",
      data?.state
        ? {
            value: data?.state,
            label: data?.state,
          }
        : null
    );
  }

  useEffect(() => {
    if (
      activeFormTitle === PROPOSER_DETAILS &&
      _.isEmpty(customerDataByRefId) &&
      _.isEmpty(customerDataBySelectingName?.data?.data) &&
      !customerRefId &&
      _.isEmpty(currentFormData) &&
      _.isEmpty(leadData)
    ) {
      setValue("customerId", "");
      clearCustomerData();
    }
  }, [customerRefId, _.isEmpty(currentFormData), _.isEmpty(leadData)]);

  useEffect(() => {
    if (!customerRefId && customerDataBySelectingName?.data?.data) {
      prefillCustomerData(
        Array.isArray(customerDataBySelectingName?.data?.data)
          ? customerDataBySelectingName?.data?.data[0] || []
          : customerDataBySelectingName?.data?.data || {}
      );
    }
  }, [customerDataBySelectingName?.data?.data]);

  useEffect(() => {
    if (
      customerRefId &&
      (customerDataByRefId?.length == 1 ||
        (customerDataByRefId?.length > 1 &&
          customerDataByRefId?.every(
            (item) =>
              item?.customerRefId == customerDataByRefId?.[0]?.customerRefId
          )))
    ) {
      prefillCustomerData(customerDataByRefId[0]);
    }
  }, [customerDataByRefId?.length]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        activeFormTitle === PROPOSER_DETAILS &&
        customerRefId &&
        !customerDataByRefId?.length &&
        customerRefId != proposerDetailsFromApi?.customerRefId
      ) {
        clearCustomerData();
      }
      // if (!customerRefId) {
      //   clearCustomerData();
      // }
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [
    customerDataByRefId?.length,
    customerRefId,
    proposerDetailsFromApi?.customerRefId,
  ]);

  // const debouncedMutate = useMemo(() => debounce(getAllCustomers, 300), []);

  useEffect(() => {
    if (!customerRefId) {
      getAllCustomers({
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        searchValue: {
          firstName: firstName,
          customerRefId: "",
          parentId: "",
          emailId: "",
          mobileNumber: "",
          city: "",
        },
      });

      // if (!firstName) {
      //   clearFieldIfEmpty("firstName");
      // }
    }
  }, [firstName]);
  useEffect(() => {
    if (expenseDetailss === "No") {
      setValue("expenseDetails", null);
    }
  }, [expenseDetailss]);
  useEffect(() => {
    if (currentField?.field?.includes("payerName")) {
      const timer = setTimeout(() => {
        getAllCustomers({
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchValue: {
            firstName: watch(`payment.[${currentField?.index}].payerName`),
            customerRefId: "",
            parentId: "",
            emailId: "",
            mobileNumber: "",
            city: "",
          },
        });
      }, 500);
      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [
    watch(`payment.[${currentField?.index}].payerName`),
    currentField?.index,
  ]);

  useEffect(() => {
    getAllCustomers({
      page: 0,
      size: 0,
      sortBy: "",
      sortOrder: "",
      searchValue: {
        firstName: "",
        customerRefId: customerRefId,
        parentId: "",
        emailId: "",
        mobileNumber: "",
        city: "",
      },
    });

    // if (!customerRefId) {
    //   clearFieldIfEmpty("customerId");
    // }
  }, [customerRefId]);

  function getUserIdFromType() {
    let userTypeData = masterTableData?.find(
      (value) => value?.data?.data?.[0]?.fieldSlug === "userType"
    );

    return userTypeData?.data?.data?.[0]?.data?.find(
      (value) => value.optionValue === userType?.label
    )?.optionKey;
  }

  const destructuredValues = summaryData && Object.values(summaryData);

  var valuesFromApi = Array.isArray(destructuredValues)
    ? destructuredValues?.reduce((acc, obj) => ({ ...acc, ...obj }), {})
    : [];

  useEffect(() => {
    setValue("anniversary", null);
  }, [maritialStatus?.value]);

  useEffect(() => {
    if (
      (sourcingDetailsFromApi?.userType &&
        sourcingDetailsFromApi?.userType !== userType?.value) ||
      (leadData?.userTypes && leadData?.userTypes !== userType?.value)
    ) {
      setValue("Name", null);
    }
  }, [userType?.value]);

  useEffect(() => {
    // for life if policy for yourself
    if (policyurselfornot?.label === "Yes") {
      setValue("titlela", title);
      setValue("firstNameld", firstName);
      setValue("middleName", middleNamepd);
      setValue("lastNameld", lastName);
      setValue("genderld", gender);
      setValue("dobld", dob);
      setValue("mobileNold", mobileNo);
      setValue("alternateMobilenold", alternateMobileno);
      setValue("emailIdld", proposerEmailId);
      setValue("aadharCardnold", aadharCardno);
      setValue("panCardnold", panCardno);
      setValue("maritialStatusld", maritialStatus);
      setValue("pincodeVd", pinCode);
      setValue("stateVd", state);
      setValue("cityVd", city);
      setValue("occupationld", occupation);
      setValue("addressLineoneld", addressLineone);
      setValue("addressLinetwold", addressLinetwo);
      setValue("landmarkld", landmark);
    }
    if (policyurselfornot?.label === "No") {
      setValue(
        "titlela",
        valuesFromApi?.titlela ||
          ![null, undefined].includes(valuesFromApi?.titlela)
          ? valuesFromApi?.titlela
          : ""
      );
      setValue(
        "firstNameld",
        valuesFromApi?.firstNameld ||
          ![null, undefined].includes(valuesFromApi?.firstNameld)
          ? valuesFromApi?.firstNameld
          : ""
      );
      setValue(
        "middleName",
        valuesFromApi?.middleName ||
          ![null, undefined].includes(valuesFromApi?.middleName)
          ? valuesFromApi?.middleName
          : ""
      );
      setValue(
        "lastNameld",
        valuesFromApi?.lastNameld ||
          ![null, undefined].includes(valuesFromApi?.lastNameld)
          ? valuesFromApi?.lastNameld
          : ""
      );
      setValue(
        "genderld",
        valuesFromApi?.genderld ||
          ![null, undefined].includes(valuesFromApi?.genderld)
          ? valuesFromApi?.genderld
          : ""
      );
      setValue(
        "dobld",
        valuesFromApi?.dobld ||
          ![null, undefined].includes(valuesFromApi?.dobld)
          ? new Date(valuesFromApi?.dobld)
          : ""
      );
      setValue(
        "mobileNold",
        valuesFromApi?.mobileNold ||
          ![null, undefined].includes(valuesFromApi?.mobileNold)
          ? valuesFromApi?.mobileNold
          : ""
      );
      setValue(
        "alternateMobilenold",
        valuesFromApi?.alternateMobilenold ||
          ![null, undefined].includes(valuesFromApi?.alternateMobilenold)
          ? valuesFromApi?.alternateMobilenold
          : ""
      );
      setValue(
        "emailIdld",
        valuesFromApi?.emailIdld ||
          ![null, undefined].includes(valuesFromApi?.emailIdld)
          ? valuesFromApi?.emailIdld
          : ""
      );
      setValue(
        "aadharCardnold",
        valuesFromApi?.aadharCardnold ||
          ![null, undefined].includes(valuesFromApi?.aadharCardnold)
          ? valuesFromApi?.aadharCardnold
          : ""
      );
      setValue(
        "panCardnold",
        valuesFromApi?.panCardnold ||
          ![null, undefined].includes(valuesFromApi?.panCardnold)
          ? valuesFromApi?.panCardnold
          : ""
      );
      setValue(
        "maritialStatusld",
        valuesFromApi?.maritialStatusld ||
          ![null, undefined].includes(valuesFromApi?.maritialStatusld)
          ? valuesFromApi?.maritialStatusld
          : ""
      );
      setValue(
        "pinCodeld",
        valuesFromApi?.pinCodeld ||
          ![null, undefined].includes(valuesFromApi?.pinCodeld)
          ? valuesFromApi?.pinCodeld
          : ""
      );
      setValue(
        "stateVd",
        valuesFromApi?.stateld ||
          ![null, undefined].includes(valuesFromApi?.stateld)
          ? valuesFromApi?.stateld
          : ""
      );
      setValue(
        "cityVd",
        valuesFromApi?.cityld ||
          ![null, undefined].includes(valuesFromApi?.cityld)
          ? valuesFromApi?.cityld
          : ""
      );
      setValue(
        "occupationld",
        valuesFromApi?.occupation ||
          ![null, undefined].includes(valuesFromApi?.occupation)
          ? valuesFromApi?.occupation
          : ""
      );
      setValue(
        "addressLineoneld",
        valuesFromApi?.addressLineone ||
          ![null, undefined].includes(valuesFromApi?.addressLineone)
          ? valuesFromApi?.addressLineone
          : ""
      );
      setValue(
        "addressLinetwold",
        valuesFromApi?.addressLinetwo ||
          ![null, undefined].includes(valuesFromApi?.addressLinetwo)
          ? valuesFromApi?.addressLinetwo
          : ""
      );
      setValue(
        "landmarkld",
        valuesFromApi?.landmark ||
          ![null, undefined].includes(valuesFromApi?.landmark)
          ? valuesFromApi?.landmark
          : ""
      );
    }
  }, [policyurselfornot]);

  useEffect(() => {
    if (paymentMode?.value === "Online") {
      setValue(
        "ifscCode",
        valuesFromApi?.ifscCode ||
          ![null, undefined].includes(valuesFromApi?.ifscCode)
          ? valuesFromApi?.ifscCode
          : ""
      );
      setValue(
        "micrCode",
        valuesFromApi?.micrCode ||
          ![null, undefined].includes(valuesFromApi?.micrCode)
          ? valuesFromApi?.micrCode
          : ""
      );
      setValue(
        "banName",
        valuesFromApi?.banName ||
          ![null, undefined].includes(valuesFromApi?.banName)
          ? valuesFromApi?.banName
          : ""
      );
      setValue(
        "bankBranchname",
        valuesFromApi?.bankBranchname ||
          ![null, undefined].includes(valuesFromApi?.bankBranchname)
          ? valuesFromApi?.bankBranchname
          : ""
      );
      setValue(
        "accountType",
        valuesFromApi?.accountType ||
          ![null, undefined].includes(valuesFromApi?.accountType)
          ? {
              label: valuesFromApi?.accountType,
              value: valuesFromApi?.accountType,
            }
          : null
      );
      setValue(
        "accNumber",
        valuesFromApi?.accNumber ||
          ![null, undefined].includes(valuesFromApi?.accNumber)
          ? valuesFromApi?.accNumber
          : ""
      );
      setValue(
        "totalAmount",
        valuesFromApi?.totalAmount ||
          ![null, undefined].includes(valuesFromApi?.totalAmount)
          ? valuesFromApi?.totalAmount
          : ""
      );
      setValue(
        "paymentDate",
        valuesFromApi?.paymentDate ||
          ![null, undefined].includes(valuesFromApi?.paymentDate)
          ? valuesFromApi?.paymentDate
          : null
      );
      setValue(
        "paymentFrequency",
        valuesFromApi?.paymentFrequency ||
          ![null, undefined].includes(valuesFromApi?.paymentFrequency)
          ? {
              label: valuesFromApi?.paymentFrequency,
              value: valuesFromApi?.paymentFrequency,
            }
          : null
      );
    }
  }, [paymentMode]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("paymentMode")
    ) {
      paymentDetailsFields.forEach((key) => {
        !["payerName", "paymentMode"].includes(key) &&
          setValue(`payment.[${currentField?.index}].${key}`, null);
      });
    }
  }, [
    payment?.[currentField?.index]?.paymentMode?.value,
    currentField?.index,
    members.payment,
  ]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("paymentPaid") &&
      payment?.[currentField?.index]?.paymentPaid?.value != "Self"
    ) {
      setValue(`payment[${currentField?.index}].payerName`, "");
    }
  }, [
    payment?.[currentField?.index]?.paymentPaid?.value,
    currentField?.index,
    members.payment,
  ]);

  useEffect(() => {
    if (
      (!!Object.keys(lifeAssuredDetailsFromApi || {}).length &&
        lifeAssuredDetailsFromApi?.relationWithlifeassured === "Self" &&
        relationWithlifeassured?.value === "Self") ||
      relationWithlifeassured?.value === "Self"
    ) {
      setValue("lifeassuredFname", proposerDetailsFromApi?.firstName);
      setValue("lifeassuredMname", proposerDetailsFromApi?.middleName);
      setValue("lifeassuredLname", proposerDetailsFromApi?.lastName);
      setValue("assuredmobileNo", proposerDetailsFromApi?.mobileNo);
      setValue("assuredmobileNotwo", proposerDetailsFromApi?.alternateMobileno);
      setValue("assuredEmail", proposerDetailsFromApi?.emailId);
      setValue("assuredEmailtwo", proposerDetailsFromApi?.alternateId);
      setValue("assuredAnnualincome", proposerDetailsFromApi?.annualIncome);
      setValue("assuredAddressone", proposerDetailsFromApi?.addresspdone);
      setValue("assuredAddresstwo", proposerDetailsFromApi?.addresspdtwo);
      setValue("assuredLandmark", proposerDetailsFromApi?.landmark);
      setValue("lifeAssureddob", new Date(proposerDetailsFromApi?.dob) || null);
      setValue(
        "lifeassuredTitle",
        proposerDetailsFromApi?.title
          ? {
              label: proposerDetailsFromApi?.title,
              value: proposerDetailsFromApi?.title,
            }
          : null
      );
      setValue(
        "lifeassuredGender",
        proposerDetailsFromApi?.gender
          ? {
              label: proposerDetailsFromApi?.gender,
              value: proposerDetailsFromApi?.gender,
            }
          : null
      );
      setValue(
        "addresschecktwo",
        proposerDetailsFromApi?.addressCheck
          ? {
              label: proposerDetailsFromApi?.addressCheck,
              value: proposerDetailsFromApi?.addressCheck,
            }
          : null
      );
      setValue(
        "assuredaddressProof",
        proposerDetailsFromApi?.addressProof
          ? {
              label: proposerDetailsFromApi?.addressProof,
              value: proposerDetailsFromApi?.addressProof,
            }
          : null
      );
      setValue("addressvalueN", proposerDetailsFromApi?.addressvalue);
      setValue(
        "assuredidProof",
        proposerDetailsFromApi?.idProof
          ? {
              label: proposerDetailsFromApi?.idProof,
              value: proposerDetailsFromApi?.idProof,
            }
          : null
      );
      setValue("identityvalueN", proposerDetailsFromApi?.identityvalue);

      setValue(
        "assuredincomeProof",
        proposerDetailsFromApi?.incomeProof
          ? {
              label: proposerDetailsFromApi?.incomeProof,
              value: proposerDetailsFromApi?.incomeProof,
            }
          : null
      );
      setValue(
        "assuredmaritalStatus",
        proposerDetailsFromApi?.maritialStatus
          ? {
              label: proposerDetailsFromApi?.maritialStatus,
              value: proposerDetailsFromApi?.maritialStatus,
            }
          : null
      );
      setValue(
        "nationalityAssured",
        proposerDetailsFromApi?.nationality
          ? {
              label: proposerDetailsFromApi?.nationality,
              value: proposerDetailsFromApi?.nationality,
            }
          : null
      );
      setValue(
        "address",
        proposerDetailsFromApi?.addresspdone +
          " " +
          proposerDetailsFromApi?.addresspdtwo
      );
      if (lobData?.data?.data?.lob === "Term") {
        setValue("pinCode", proposerDetailsFromApi?.sourcingPinCode);
        setValue(
          "educationTerm",
          proposerDetailsFromApi?.education
            ? {
                label: proposerDetailsFromApi?.education,
                value: proposerDetailsFromApi?.education,
              }
            : null
        );
        setValue(
          "occupationTerm",
          proposerDetailsFromApi?.occupation
            ? {
                label: proposerDetailsFromApi?.occupation,
                value: proposerDetailsFromApi?.occupation,
              }
            : null
        );
      }
      if (lobData?.data?.data?.lob === "Investment") {
        setValue("pincodeInvestment", proposerDetailsFromApi?.sourcingPinCode);
        setValue(
          "educationInvestment",
          proposerDetailsFromApi?.education
            ? {
                label: proposerDetailsFromApi?.education,
                value: proposerDetailsFromApi?.education,
              }
            : null
        );
        setValue(
          "occupationInvestment",
          proposerDetailsFromApi?.occupation
            ? {
                label: proposerDetailsFromApi?.occupation,
                value: proposerDetailsFromApi?.occupation,
              }
            : null
        );
      }
    } else if (
      lifeAssuredDetailsFromApi?.relationWithlifeassured !=
        relationWithlifeassured?.value &&
      relationWithlifeassured?.value !== "Self"
    ) {
      lifeAssuredDetailsFields?.forEach((key) => {
        key !== "relationWithlifeassured" && setValue(key, null);
      });
    }
  }, [
    relationWithlifeassured?.value,
    lifeAssuredDetailsFromApi?.relationWithlifeassured,
  ]);

  useEffect(() => {
    const shouldSetFieldsToNull = () => {
      if (!proposerDetailsFromApi?.typeofcustomer) {
        return !!typeofcustomer?.label;
      } else {
        return (
          typeofcustomer?.label &&
          typeofcustomer?.label !== proposerDetailsFromApi?.typeofcustomer
        );
      }
    };
    if (activeFormTitle === PROPOSER_DETAILS && shouldSetFieldsToNull()) {
      matchedObject[PROPOSER_DETAILS]?.filter(
        (item) => item?.field_slug !== "typeofcustomer"
      )?.forEach((item) => setValue(item?.field_slug, null));
    }
  }, [
    typeofcustomer?.label,
    proposerDetailsFromApi?.typeofcustomer,
    activeFormTitle,
  ]);

  useEffect(() => {
    if (isPropertyInsuranceLob) {
      setValue("coverage", null);
    }
  }, [policyDetailsPlanType, isPropertyInsuranceLob]);

  useEffect(() => {
    if (noFloaterCover) {
      floaterCoverFields?.map((item) => {
        setValue(item, null);
      });
    } else {
      floaterCoverFields?.map((item) => {
        setValue(item, propertyDetailsFromApi?.[item] || null);
      });
    }
  }, [noFloaterCover]);

  useEffect(() => {
    if (!floaterCover?.value) {
      setValue("floaterCover", { label: "No", value: "No" });
    }
  }, [floaterCover?.value]);

  // useEffect(() => {
  // if (policyDetailsFromApi?.businessType === businessType?.value) {
  //   setValue("businessType", policyDetailsFromApi?.businessType);
  // }
  // if (policyDetailsFromApi?.businessType !== businessType?.value) {
  //   setValue("businessType", null);
  // }
  // }, [policyDetailsFromApi?.businessType, businessType?.value]);

  useEffect(() => {
    if (
      idProof?.value !== proposerDetailsFromApi?.idProof &&
      !idProof?.label?.includes("Aadhar")
    ) {
      setValue("identityvalue", null);
      setValue("uploadIdentity", null);
      const newFiles = { ...files };
      delete newFiles["uploadIdentity"];
      setFiles(newFiles);
      dispatch(
        setUploadedFileURLAction({
          ["uploadIdentity"]: null,
        })
      );
    }
  }, [idProof?.value]);

  useEffect(() => {
    if (
      addressProof?.value !== proposerDetailsFromApi?.addressProof &&
      !addressProof?.label?.includes("Aadhar")
    ) {
      setValue("addressvalue", null);
      setValue("uploadAddress", null);
      const newFiles = { ...files };
      delete newFiles["uploadAddress"];
      setFiles(newFiles);
      dispatch(
        setUploadedFileURLAction({
          ["uploadAddress"]: null,
        })
      );
    }
  }, [addressProof?.value]);

  useEffect(() => {
    if (incomeProof?.value !== proposerDetailsFromApi?.incomeProof) {
      setValue("incomevalue", null);
      setValue("uploadIncome", null);
      const newFiles = { ...files };
      delete newFiles["uploadIncome"];
      setFiles(newFiles);
      dispatch(
        setUploadedFileURLAction({
          ["uploadIncome"]: null,
        })
      );
    }
  }, [incomeProof?.value]);

  useEffect(() => {
    if (icName?.value !== policyDetailsFromApi?.icName) {
      setValue("businessType", null);
      setValue("policyType", null);
      setValue("productName", null);
      setValue("planType", null);
    }
  }, [icName?.value]);

  useEffect(() => {
    if (
      activeFormTitle === EXPENSE_DETAILS &&
      !employeeDetailsFromApi?.members?.[0]?.compensationAct
    ) {
      setValue(`members.[0].compensationAct`, {
        label: "No",
        value: "No",
      });
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EXPENSE_DETAILS) {
      watchMembers?.map((item, index) => {
        const latestValue =
          (currentField?.field?.includes("namedorUnamed") &&
            watch(`members.[${currentField?.index}].namedorUnamed`)?.value) ||
          watch(`members.[${currentField?.index}].namedorUnamed`)?.value;
        if (
          latestValue ||
          employeeDetailsFromApi?.members?.[0]?.namedorUnamed
        ) {
          setValue(`members.[${index}].namedorUnamed`, {
            label:
              latestValue ||
              employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
            value:
              latestValue ||
              employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
          });
        } else {
          setValue(`members.[${index}].namedorUnamed`, null);
        }
      });
    }
  }, [
    watchMembers?.[currentField?.index]?.namedorUnamed?.value,
    members.employee,
    employeeDetailsFromApi?.members?.[0]?.namedorUnamed,
  ]);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EXPENSE_DETAILS) {
      watchMembers?.map((item, index) => {
        const annualCash = watch(`members.[${index}].cashAnnual`);
        const livingAllowances = watch(
          `members.[${index}].livingorOtherallowances`
        );
        setValue(
          `members.[${index}].totalWages`,
          +annualCash + +livingAllowances,
          watchMembers
        );
      });
    }
  }, [JSON.stringify(watchMembers), members.employee]);

  useEffect(() => {
    if (Array.isArray(watchMembers) && activeFormTitle === EXPENSE_DETAILS) {
      watchMembers?.map((item, index) => {
        const latestValue =
          (currentField?.field?.includes("compensationAct") &&
            watch(`members.[${currentField?.index}].compensationAct`)?.value) ||
          watch(`members.[${currentField?.index}].compensationAct`)?.value;

        setValue(`members.[${index}].compensationAct`, {
          label:
            latestValue ||
            employeeDetailsFromApi?.members?.[0]?.compensationAct ||
            "No",
          value:
            latestValue ||
            employeeDetailsFromApi?.members?.[0]?.compensationAct ||
            "No",
        });
      });
    }
  }, [
    watchMembers?.[currentField?.index]?.compensationAct?.value,
    members.employee,
    employeeDetailsFromApi?.members?.[0]?.compensationAct,
  ]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("relWithprop")
    ) {
      if (watchMembers?.[currentField?.index]?.relWithprop?.value != "Self") {
        // setValue(`members.[${currentField?.index}].relationId`, "");
        setValue(`members.[${currentField?.index}].dobIMD`, null);
        setValue(`members.[${currentField?.index}].ageIMD`, "");
        setValue(`members.[${currentField?.index}].bmiImd`, "");
        setValue(`members.[${currentField?.index}].heightImd`, "");
        setValue(`members.[${currentField?.index}].weightImd`, "");
        setValue(`members.[${currentField?.index}].remarkihd`, "");
        setValue(`members.[${currentField?.index}].insuredmemberName`, "");
        setValue(`members.[${currentField?.index}].insuredContactnumber`, "");
        setValue(`members.[${currentField?.index}].genderIMD`, null);
        setValue(`members.[${currentField?.index}].medicalHistoryim`, null);
        setValue(`members.[${currentField?.index}].insuredeiaRepository`, null);
        setValue(`members.[${currentField?.index}].insuredeiaNumber`, null);
      } else {
        // setValue(`members.[${currentField?.index}].relationId`, "");
        setValue(`members.[${currentField?.index}].bmiImd`, "");
        setValue(`members.[${currentField?.index}].heightImd`, "");
        setValue(`members.[${currentField?.index}].weightImd`, "");
      }
    }
  }, [
    watchMembers?.[currentField?.index]?.relWithprop?.value,
    currentField?.index,
    members.members,
  ]);

  useEffect(() => {
    if (
      currentField?.index != null &&
      currentField?.field?.includes("nomineeRelation")
    ) {
      if (
        watchNominee?.[currentField?.index]?.nomineeRelation?.value != "Self"
      ) {
        setValue(`nominee.[${currentField?.index}].titleNd`, null);
        setValue(`nominee.[${currentField?.index}].nomineeFullname`, null);
        setValue(`nominee.[${currentField?.index}].nomineeGender`, null);
        setValue(`nominee.[${currentField?.index}].nomineeDob`, null);
        setValue(`nominee.[${currentField?.index}].nomineeRemark`, null);
      }
    }
  }, [
    watchNominee?.[currentField?.index]?.nomineeRelation?.value,
    currentField?.index,
    members.nominee,
  ]);

  useEffect(() => {
    //prefill gender on salutation change
    if (title?.value) {
      if (title?.value === "Mr") {
        setValue("gender", { value: "Male", label: "Male" });
      } else {
        setValue("gender", { value: "Female", label: "Female" });
      }
    }
    if (titleNd?.value) {
      if (titleNd?.value === "Mr") {
        setValue("nomineeGender", { value: "Male", label: "Male" });
      } else {
        setValue("nomineeGender", { value: "Female", label: "Female" });
      }
    }
    if (titlela?.value) {
      if (titlela?.value === "Mr") {
        setValue("genderld", { value: "Male", label: "Male" });
      } else {
        setValue("genderld", { value: "Female", label: "Female" });
      }
    }
  }, [title, titleNd, titlela]);

  useMemo(() => {
    // days auto calculate on policy start and end date

    if (policystDate && policyExpirydate) {
      const duration = parseInt(
        moment
          .duration(moment(policyExpirydate).diff(moment(policystDate)))
          .asDays()
      );
      if (duration < 0) {
        setValue("policyPeriod", 0);
      } else {
        setValue("policyPeriod", duration.toString());
      }
    }
    if (policyStartdate && policyExpirydatepd) {
      const duration = parseInt(
        moment
          .duration(moment(policyExpirydatepd).diff(moment(policyStartdate)))
          .asDays()
      );
      setValue("totalDayspd", duration.toString());
    }
  }, [policystDate, policyExpirydate, policyStartdate, policyExpirydatepd]);

  useEffect(() => {
    //redux company id dispatch on insurance company name select
    if (icName) {
      dispatch(
        setBrokerage({
          companyId: getOptionKeyByValue(icName?.value, "icName"),
        })
      );
    }
  }, [icName]);

  useEffect(() => {
    if (!nationality?.value)
      setValue("nationality", {
        label: "Indian",
        value: "Indian",
      });
  }, [nationality?.value]);

  useEffect(() => {
    if (!nationalityAssured?.value)
      setValue("nationalityAssured", {
        label: "Indian",
        value: "Indian",
      });
  }, [nationalityAssured?.value]);

  const memberDOBData =
    Array.isArray(watchMembers) && watchMembers.filter((item) => item?.dobIMD);

  useEffect(() => {
    //nominee age auto calcualte using nominee dob
    if (nomineeDob) {
      const today = moment();
      const birthDate = moment(nomineeDob, "YYYY-MM-DD");
      const years = today.diff(birthDate, "years");
      setValue("nomineeAge", years);

      if (lobId === "21") {
        // for some reasons  setValue("nomineeMinor", "Yes"); syntax was not working
        if (years < 18) {
          setValue("nomineeMinor", { label: "Yes", value: "Yes" });
        } else {
          setValue("nomineeMinor", { label: "No", value: "No" });
        }
      } else {
        if (years < 18) {
          setValue("nomineeMinor", "Yes");
        } else {
          setValue("nomineeMinor", "No");
          // setValue("appointeeName", "");
        }
      }
    }
    if (members?.members) {
      [...Array(members?.members)].forEach((member, index) => {
        if (watch(`members[${index}].dobIMD`)) {
          const today = moment();
          const birthDate = moment(
            watch(`members[${index}].dobIMD`),
            "YYYY-MM-DD"
          );
          const years = today.diff(birthDate, "years");
          setValue(`members.[${index}].ageIMD`, years);
        }
      });
    }
  }, [nomineeDob, JSON.stringify(memberDOBData)]);

  useEffect(() => {
    if (regDate) {
      const dateMoment = moment(regDate, "YYYY-MM-DD");
      const monthName = dateMoment.format("MMMM");
      const manufactureYear = moment(regDate, "YYYY-MM-DD").year();
      // setValue("manufactureYear", manufactureYear);
      // setValue("manufactureMonth", { label: monthName, value: monthName });
      setValue("manufactureDate", `${monthName} ${manufactureYear}`);
      const endDate = moment();
      const totalMonths = endDate.diff(dateMoment, "months");
      setValue("vehicleAge", totalMonths);
      // trigger(["manufactureMonth", "manufactureYear", "vehicleAge"]);
    }
  }, [regDate]);

  useMemo(() => {
    const netPremiumArr = [+odPremium, +tpPremium, +addOnpremium, +basePremium]
      .filter((value) => !isNaN(value))
      .filter((item) => item !== 0);

    if (!_.isEmpty(netPremiumArr)) {
      const addedNetPremium =
        discount && !loadingAmount
          ? netPremiumArr.reduce((acc, val) => +acc + +val, 0) - +discount
          : !discount && loadingAmount
          ? netPremiumArr.reduce((acc, val) => +acc + +val, 0) + +loadingAmount
          : discount && loadingAmount
          ? netPremiumArr.reduce((acc, val) => +acc + +val, 0) +
            +loadingAmount -
            +discount
          : netPremiumArr.reduce((acc, val) => +acc + +val, 0);
      setValue("netPremium", addedNetPremium);
      // trigger(["netPremium"]);
    } else {
      setValue("netPremium", "");
    }
  }, [
    odPremium,
    tpPremium,
    addOnpremium,
    basePremium,
    discount,
    loadingAmount,
  ]);

  useMemo(() => {
    const taxes = [+sgst, +cgst, +ugst].filter((value) => !isNaN(value));

    if (taxes.length > 0) {
      let totalTax;

      if (taxes.length === 3) {
        // All three are present
        totalTax = taxes.reduce((acc, val) => acc + val, 0);
      } else {
        // Any one or any two are present
        totalTax = taxes.reduce((acc, val) => acc + val, 0);
        totalTax = Math.min(totalTax, 100);
      }

      if (totalTax === 0) {
        totalTax = "";
      }

      const percentageAmount =
        totalTax === "" ? "" : ((totalTax / 100) * +netPremium).toFixed(2);
      setValue("totalTax", totalTax);
      setValue("taxAmount", percentageAmount);

      // trigger(["totaltaxAmount", "taxAmount"]);
    }
  }, [sgst, cgst, ugst, netPremium]);

  useMemo(() => {
    if (taxAmount && netPremium) {
      const totalPremium = (+taxAmount + +netPremium).toFixed(2);
      setValue("totalPremium", Math.round(totalPremium));
      setValue("totalAmount", Math.round(totalPremium));

      // trigger(["totalPremium", "totalAmount"]);
    }
  }, [
    netPremium,
    taxAmount,
    odPremium,
    tpPremium,
    addOnpremium,
    basePremium,
    discount,
    loadingAmount,
    totalTax,
  ]);

  useEffect(() => {
    if (ifscData) {
      payment?.forEach((_, index) => {
        setValue(
          `payment.[${index}].banName`,
          ifscData?.data?.[index]?.data?.data?.bankName
        );
        setValue(
          `payment.[${index}].bankBranchname`,
          ifscData?.data?.[index]?.data?.data?.bankBranch
        );
      });
    }
  }, [JSON.stringify(ifscData)]);

  useEffect(() => {
    if (make?.id) {
      setValue("model", null);
      setValue("version", []);
      setValue("fuelType", []);
      getModalData({ lobid: lobId, manufacturer_id: make.id });
    }
  }, [make]);

  useMemo(() => {
    if (model?.id) {
      getVersionData({ lobid: lobId, model_id: model.id });
      getFuelData({ lobid: lobId, model_id: model.id });
    }
  }, [model]);

  useEffect(() => {
    //managed seperate to prevent infinite renders
    const destructuredValues = summaryData && Object.values(summaryData);
    let modelValue = "";
    let versionValue = "";
    let fuelValue = "";

    if (proposalData) {
      destructuredValues?.forEach((value) => {
        for (let key in value) {
          if (key === "model") {
            modelValue = value[key];
          }
          if (key === "version") {
            versionValue = value[key];
          }
          if (key === "fuelType") {
            fuelValue = value[key];
          }
        }

        const modelPrefillData = modelData?.data?.filter(
          (value) => value.model_name === modelValue
        );

        if (modelPrefillData?.length) {
          setValue("model", {
            label: modelPrefillData?.[0]?.model_name,
            value: modelPrefillData?.[0]?.model_name,
            id: modelPrefillData?.[0]?.model_id,
          });
        }

        if (modelPrefillData?.length) {
          setValue("version", {
            label: versionValue,
            value: versionValue,
            id: versionValue,
          });
        }

        if (modelPrefillData?.length) {
          setValue("fuelType", {
            label: fuelValue,
            value: fuelValue,
            id: fuelValue,
          });
        }
      });
    }
  }, [modelData?.data?.length]);

  const paymentDetailsTotalAmount = payment?.reduce(
    (acc, curr) => +curr?.totalAmount + acc,
    0
  );

  useEffect(() => {
    if (
      activeFormTitle === PAYMENT_DETAILS &&
      +paymentDetailsTotalAmount > +premiumDetailsFromApi?.totalPremium
    ) {
      const timer = setTimeout(() => {
        Swal.fire({
          text:
            members.payment > 1
              ? `Cumulative amount of these payments (${paymentDetailsTotalAmount}) should not exceed the total premium (${premiumDetailsFromApi?.totalPremium})`
              : `Total amount of this payment (${paymentDetailsTotalAmount}) should not exceed the total premium (${premiumDetailsFromApi?.totalPremium})`,
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          allowOutsideClick: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      }, 1000);
      return () => {
        if (timer) clearTimeout(timer);
      };
    }
  }, [paymentDetailsTotalAmount, premiumDetailsFromApi?.totalPremium]);

  useEffect(() => {
    const documents = proposalData?.data?.data?.data?.additional_documents;
    const objectsToAdd = [];
    documents?.map((item) => {
      Object.entries(item)?.map(([name, value]) => {
        objectsToAdd.push({ [name]: Array.isArray(value) ? value[0] : value });
      });
    });
    setAdditionalDocumentLinks(objectsToAdd);
    objectsToAdd?.forEach((item) => {
      const entries = Object.entries(item)[0];
      if (!entries[0].includes("_otherDoc")) {
        dispatch(setUploadedFileURLAction({ [entries[0]]: entries[1] }));
        setValue(entries[0], entries[1]);
      }
    });
  }, [proposalData]);
  useEffect(() => {
    const documents = proposalData?.data?.data?.data?.additional_documents;
    const arr = [];
    documents
      ?.filter((item) => {
        const key = Object.keys(item)[0];
        return key?.includes("_otherDoc");
      })
      ?.map((item, index) => {
        const key = Object.keys(item)[0];
        if (key.includes("_otherDoc") && isOthersSelected) {
          arr.push({
            documentName: key?.replace("_otherDoc", ""),
            otherDocu: item[key],
          });
          setValue(
            `otherDocs.[${index}].documentName`,
            key?.replace("_otherDoc", "")
          );
          setValue(`otherDocs.[${index}].otherDocu`, item[key]);
          dispatch(
            setUploadedFileURLAction({
              [`otherDocs.[${index}].otherDocu`]: item[key],
            })
          );
        }
      });
    if (arr.length && activeFormTitle === DOCUMENTS && isOthersSelected) {
      setValue("otherDocs", arr);
      // reset({
      //   otherDocs: arr,
      // });
    }
  }, [proposalData, activeFormTitle]);
  const totalNomineeShare = watchNominee?.reduce(
    (acc, curr) => +curr?.nomineeShare + acc,
    0
  );
  const expenseTotalCount = {
    totalAmount: watchExpense?.reduce(
      (acc, item) => +acc + (+item.amount || 0),
      0
    ),
    approvedAmount: watchExpense?.reduce(
      (acc, item) => +acc + (+item.approvedAmount || 0),
      0
    ),
  };
  const handleFormSubmit = async (
    respectiveCardTitle,
    respectiveCardValues,
    index,
    title,
    state,
    action
  ) => {
    const validationPassed = await trigger();
    if (!validationPassed) {
      return;
    }

    const values = getValues();

    // const expenseDetails =
    //   values?.expenseDetails === "Yes" || values?.expenseDetails === "No"
    //     ? []
    //     : values?.expenseDetails;
    // // Filter out objects that only have the "0" property
    // const filteredValues = expenseDetails?.filter(
    //   (item) => !("0" in item && Object.keys(item).length === 1)
    // );
    // // Remove the "0" key from each object
    // const cleanedValues = filteredValues?.map((item) => {
    //   if ("0" in item) {
    //     delete item["0"];
    //   }
    //   return item;
    // });

    const relevantFields = Object.fromEntries(
      Object.entries(values)?.filter(([key]) =>
        respectiveCardValues?.map((value) => value.key).includes(key)
      )
    );
    const otherDocsDetails = Array.isArray(values?.otherDocs)
      ? values?.otherDocs
          ?.map((value, index) => {
            let mappedFields = {};
            let dateMappedFields = {};
            let fileMappedFields = {};

            dropdownFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]?.label;
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            multiSelectFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]
                  ?.map((item) => item?.label)
                  ?.join("|");
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            dateFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field]).format(
                  "YYYY-MM-DD"
                );
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });
            fileFields?.forEach((field) => {
              if (
                uploadedFileURL[`otherDocs.[${index}].otherDocu`] &&
                field === "otherDocu"
              ) {
                fileMappedFields[field] =
                  uploadedFileURL[`otherDocs.[${index}].otherDocu`];
              } else {
                if (value.hasOwnProperty(field)) {
                  fileMappedFields[field] = "";
                }
              }
            });

            return {
              ...value,
              ...mappedFields,
              ...dateMappedFields,
              ...fileMappedFields,
            };
          })
          ?.filter(Boolean)
      : [];
    const expenseFormDetails = Array.isArray(values?.expenseDetails)
      ? values?.expenseDetails
          ?.map((value, index) => {
            let mappedFields = {};
            let dateMappedFields = {};
            let fileMappedFields = {};

            dropdownFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value?.[field]?.label;
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            multiSelectFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]
                  ?.map((item) => item?.label)
                  ?.join("|");
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            dateFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field]).format(
                  "YYYY-MM-DD"
                );
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });

            return {
              ...value,
              ...mappedFields,
              ...dateMappedFields,
              ...fileMappedFields,
            };
          })
          ?.filter(Boolean)
      : [];

    const lossItemDetails = Array.isArray(values?.lossItemDetails)
      ? values?.lossItemDetails
          ?.map((value, index) => {
            let mappedFields = {};
            let dateMappedFields = {};
            let fileMappedFields = {};

            dropdownFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]?.label;
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            multiSelectFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]
                  ?.map((item) => item?.label)
                  ?.join("|");
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            dateFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field]).format(
                  "YYYY-MM-DD"
                );
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });
            return {
              ...value,
              ...mappedFields,
              ...dateMappedFields,
              ...fileMappedFields,
            };
          })
          ?.filter(Boolean)
      : [];

    const selectedModes = Array.isArray(values?.modeofTranspo)
      ? values?.modeofTranspo?.map((item) => item?.label)
      : [];

    const cargoDetailsSelectedData =
      selectedModes?.length !== values?.cargoItemDetails?.length
        ? values?.cargoItemDetails?.filter((item) =>
            selectedModes?.includes(item?.modetransport)
          )
        : values?.cargoItemDetails;

    const cargoItemDetails = Array.isArray(values?.cargoItemDetails)
      ? cargoDetailsSelectedData
          ?.map((value, index) => {
            let mappedFields = {};
            let dateMappedFields = {};
            let fileMappedFields = {};

            dropdownFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]?.label;
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });
            multiSelectFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]
                  ?.map((item) => item?.label)
                  ?.join("|");
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });
            dateFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field]).format(
                  "YYYY-MM-DD"
                );
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });
            dateAndTimeFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field])
                  .local()
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });
            return {
              ...value,
              ...mappedFields,
              ...dateMappedFields,
              ...fileMappedFields,
              modetransport: selectedModes?.[index],
            };
          })
          ?.filter(Boolean)
      : [];

    // const cargoItemDetails = Array.isArray(values?.cargoItemDetails)
    //   ? values?.cargoItemDetails
    //       ?.map((item, i) => {
    //         const dateMappedFields = {};
    //         dateAndTimeFields?.forEach((field) => {
    //           if (item[field]) {
    //             console.log("selectedModes", item[field]);
    //             const convertedDateFormat = moment(item[field]).format(
    //               "YYYY-MM-DD HH:mm:ss"
    //             );
    //             dateMappedFields[field] = convertedDateFormat;
    //           } else {
    //             if (item.hasOwnProperty(field)) {
    //               dateMappedFields[field] = "";
    //             }
    //           }
    //         });
    //         return {
    //           ...item,
    //           ...dateMappedFields,
    //           modetransport: selectedModes?.[i],
    //         };
    //       })
    //       .filter(Boolean)
    //   : [];

    // uncomment this if want multiple travel insured member in payload
    // Multi Member Details
    // const claimantsItemDetails = Array.isArray(values?.claimantsDetails)
    //   ? values?.claimantsDetails
    //       ?.map((value, index) => {
    //         let mappedFields = {};
    //         let dateMappedFields = {};
    //         let fileMappedFields = {};

    //         dropdownFields?.forEach((field) => {
    //           if (value[field]) {
    //             mappedFields[field] = value[field]?.label;
    //           } else {
    //             if (value.hasOwnProperty(field)) {
    //               mappedFields[field] = "";
    //             }
    //           }
    //         });

    //         multiSelectFields?.forEach((field) => {
    //           if (value[field]) {
    //             mappedFields[field] = value[field]
    //               ?.map((item) => item?.label)
    //               ?.join("|");
    //           } else {
    //             if (value.hasOwnProperty(field)) {
    //               mappedFields[field] = "";
    //             }
    //           }
    //         });

    //         dateFields?.forEach((field) => {
    //           if (value[field]) {
    //             const convertedDateFormat = moment(value[field]).format(
    //               "YYYY-MM-DD"
    //             );
    //             dateMappedFields[field] = convertedDateFormat;
    //           } else {
    //             if (value.hasOwnProperty(field)) {
    //               dateMappedFields[field] = "";
    //             }
    //           }
    //         });
    //         return {
    //           ...value,
    //           ...mappedFields,
    //           ...dateMappedFields,
    //           ...fileMappedFields,
    //         };
    //       })
    //       ?.filter(Boolean)
    //   : [];

    const travelItemDetails = Array.isArray(values?.travelDetails)
      ? values?.travelDetails
          ?.slice(0, selectedClaim?.length)
          ?.map((value, index) => {
            let mappedFields = {};
            let dateMappedFields = {};
            let fileMappedFields = {};
            let dateAndTime = {};

            dropdownFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]?.label;
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            multiSelectFields?.forEach((field) => {
              if (value[field]) {
                mappedFields[field] = value[field]
                  ?.map((item) => item?.label)
                  ?.join("|");
              } else {
                if (value.hasOwnProperty(field)) {
                  mappedFields[field] = "";
                }
              }
            });

            dateFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field]).format(
                  "YYYY-MM-DD"
                );
                dateMappedFields[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateMappedFields[field] = "";
                }
              }
            });

            dateAndTimeFields?.forEach((field) => {
              if (value[field]) {
                const convertedDateFormat = moment(value[field])
                  .local()
                  .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                dateAndTime[field] = convertedDateFormat;
              } else {
                if (value.hasOwnProperty(field)) {
                  dateAndTime[field] = "";
                }
              }
            });
            return {
              ...value,
              ...mappedFields,
              ...dateMappedFields,
              ...fileMappedFields,
              ...dateAndTime,
            };
          })
          ?.filter(Boolean)
      : [];

    setEditCard((prevValue) => ({
      ...prevValue,
      [title]: state,
    }));

    setCurrentCardIndex(index);

    const queryParams = new URLSearchParams(location.search);
    const mongoId = queryParams.get("mongoId");

    //need factory fn for this
    let mappedFields = {};
    let dateMappedFields = {};
    let fileMappedFields = {};
    let dateAndTimeMappedFields = {};

    dropdownFields?.forEach((field) => {
      if (relevantFields[field]) {
        mappedFields[field] = relevantFields[field].label;
      } else {
        if (relevantFields.hasOwnProperty(field)) {
          mappedFields[field] = "";
        }
      }
    });

    // multiSelectFields?.forEach(field => {
    //   if (relevantFields?.[field]) {
    //     mappedFields[field] = relevantFields?.[field]
    //       ?.map(item => item?.label)
    //       ?.join("|");
    //   } else {
    //     if (relevantFields?.hasOwnProperty(field)) {
    //       mappedFields[field] = "";
    //     }
    //   }
    // });

    multiSelectFields?.forEach((field) => {
      if (Array.isArray(relevantFields?.[field])) {
        // Check if relevantFields[field] is an array
        mappedFields[field] = relevantFields?.[field]
          .map((item) => item?.label) // Map each item in the array to item.label
          .join("|"); // Join the mapped labels with "|"
      } else if (relevantFields?.hasOwnProperty(field)) {
        // Check if field exists in relevantFields
        mappedFields[field] = ""; // If field exists but is not an array, set it to an empty string
      }
    });

    dateFields?.forEach((field) => {
      if (relevantFields[field]) {
        const convertedDateFormat = moment(relevantFields[field]).format(
          "YYYY-MM-DD"
        );
        dateMappedFields[field] = convertedDateFormat;
      } else {
        if (relevantFields.hasOwnProperty(field)) {
          dateMappedFields[field] = "";
        }
      }
    });
    dateAndTimeFields?.forEach((field) => {
      if (relevantFields[field]) {
        // Convert to local time and explicitly format it to ISO 8601 with timezone
        dateAndTimeMappedFields[field] = moment(relevantFields[field])
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ"); // ISO format with local timezone offset
      } else {
        if (relevantFields.hasOwnProperty(field)) {
          dateAndTimeMappedFields[field] = "";
        }
      }
    });
    let additionalDocuments = [];
    Array.isArray(selectdocumenttoUpload) &&
      selectdocumenttoUpload
        .filter((item) => item?.value !== "Others")
        ?.forEach((item) => {
          if (uploadedFileURL[item?.label]) {
            additionalDocuments.push({
              [item.label]: uploadedFileURL[item?.label],
            });
          }
        });

    let otherDocuments = [];
    Array.isArray(otherDocsDetails) &&
      otherDocsDetails?.forEach((item) => {
        item.documentName &&
          otherDocuments?.push({
            [`${item.documentName}_otherDoc`]: item?.otherDocu,
          });
      });
    // Array.isArray(additionalDocumentsFromApi) &&
    //   additionalDocumentsFromApi?.forEach((item) => {
    //     const entries = Object.entries(item)?.[0];
    //     if (uploadedFileURL[entries?.[0]]) {
    //       additionalDocuments?.push({
    //         [entries[0]]: uploadedFileURL[entries[0]],
    //       });
    //     }
    //   });
    setAdditionalDocumentLinks([...additionalDocuments, ...otherDocuments]);
    let additionalFileFields = [];
    if (Array.isArray(selectdocumenttoUpload)) {
      additionalFileFields = selectdocumenttoUpload
        .filter((item) => item?.value !== "Others") // Filter out items with key "Others"
        .map((item) => {
          // Map the remaining items to the desired format
          return {
            ...item,
            key: item?.label,
            field: "FILE",
            value: additionalDocumentLinks?.find(
              (field) => Object.keys(field)[0] === item?.label
            )?.[item?.label],
          };
        });
    }

    if (Array.isArray(otherDocuments)) {
      otherDocuments?.forEach((item) => {
        const [key, value] = Object.entries(item)[0];
        const objectToAdd = {
          key: key,
          field: "FILE",
          value: value,
        };
        additionalFileFields?.push(objectToAdd);
      });
    }
    // dispatch(setAdditionalFileFields(additionalFileFields));
    fileFields?.forEach((field) => {
      if (uploadedFileURL[field]) {
        fileMappedFields[field] = uploadedFileURL[field];
      } else {
        if (uploadedFileURL.hasOwnProperty(field)) {
          fileMappedFields[field] = "";
        }
      }
    });
    const dropdownFieldsDestructured = {
      ...relevantFields,
      ...mappedFields,
      ...dateMappedFields,
      ...fileMappedFields,
      ...dateAndTimeMappedFields,
    };
    let data = {
      ...proposalCreateValues[0],
      ...dropdownFieldsDestructured,
      claimStatus: "Intimated",
    };

    if (data["bulkdata"]) {
      delete data["bulkdata"];
    }

    dispatch(
      setProposalCreateValuesAction({
        ...dropdownFieldsDestructured,
      })
    );
    const shouldAddPrevPolicyToggle =
      current_step &&
      current_step?.length > 1 &&
      !current_step?.includes(cardTitlesPrevPolicyToggle[0]);
    // Below Logic to handle next button directly proceeds to summary page after first submit
    const currStepArray = current_step?.split(",");
    const respectiveCardIndex = currStepArray?.findIndex((item) =>
      item.includes(respectiveCardTitle)
    );
    // removing the titles which are after the current title
    currStepArray?.splice(respectiveCardIndex + 1);
    // // removing the titles which are after the current title
    // // if(action === "previous")
    // // {
    // //   currStepArray?.splice(respectiveCardIndex)
    // // }
    // // else {
    // // currStepArray?.splice(respectiveCardIndex + 1);
    // // }
    // currStepArray?.splice(respectiveCardIndex + 1);
    // currStepArray?.pop();
    const indexSlice = goBackFromSummary
      ? respectiveCardIndex - 1
      : respectiveCardIndex;
    const extraData = {
      lobId1: Number(lobId),
      transaction_id: transactionId ? transactionId : "",
      customerId: customerId ? Number(customerId) : "",
      stage: "",
      remark: "",
      currentStep: current_step
        ? shouldAddPrevPolicyToggle
          ? current_step +
            ", " +
            (respectiveCardTitle || "") +
            ", " +
            cardTitlesPrevPolicyToggle[0]
          : current_step.includes(respectiveCardTitle)
          ? isRaiseClaim // explicitly setting for create claim
            ? currStepArray?.join(", ") || ""
            : current_step
          : current_step + ", " + (respectiveCardTitle || "")
        : respectiveCardTitle || "",
    };

    const payload = {
      ...extraData,
      data: data,
      expenses:
        respectiveCardTitle === EXPENSE_DETAILS
          ? [...expenseFormDetails]
          : respectiveCardTitle === LOSS_ITEM_DETAILS
          ? [...lossItemDetails]
          : respectiveCardTitle === CARGO_DETAILS
          ? [...cargoItemDetails]
          : // uncomment this if want multiple travel insured member in payload
          // : respectiveCardTitle === TRAVEL_INSURED_MEMBER &&
          //   lobName === "Travel"
          // ? [...claimantsItemDetails]
          respectiveCardTitle === TRAVEL_DETAILS && lobName === "Travel"
          ? [...travelItemDetails]
          : expenseData,
      documents: [...additionalDocuments, ...otherDocuments],
    };
    setDraftPayload({ payload, mongoId });

    mongoId
      ? updateExistingData({ payload, mongoId, transactionId })
      : generateMongoId(payload);

    dispatch(
      setBrokerage({
        companyId: getOptionKeyByValue(values?.icName?.value, "icName"),
        transactionTypeId: values?.policyType?.value,
        userId: values?.Name?.value,
      })
    );

    dispatch(setPolicyEditIndex(index));
    const updatedState = {};
    for (const key in policyViewCards) {
      updatedState[key] = true;
    }
    dispatch(setPolicyViewCards(updatedState));
    setSubmittedAt(new Date().getTime());
    setGoBackFromSummary(false);

    // if (lastCard !== respectiveCardTitle) {
    //   handleNext();
    // }
  };
  const handleFieldEdit = async (title, state, index) => {
    setEditCard((prevValue) => {
      let newEditValues = {};

      for (let key in prevValue) {
        newEditValues[key] = false;
      }

      return {
        ...newEditValues,
        [title]: state,
      };
    });

    setActiveStep(index);

    const updatedState = {};
    for (const key in policyViewCards) {
      updatedState[key] = key == index ? false : true;
    }
    dispatch(setPolicyViewCards(updatedState));
  };

  useEffect(() => {
    const documents = proposalData?.data?.data?.data?.additional_documents;
    const objectsToAdd = [];
    documents?.map((item) => {
      Object.entries(item)?.map(([name, value]) => {
        objectsToAdd.push({ [name]: value });
      });
    });
    setAdditionalDocumentLinks(objectsToAdd);
  }, [proposalData]);
  useEffect(() => {
    if (Array.isArray(additionalDocumentLinks)) {
      additionalDocumentLinks?.map((item) => {
        const [key, value] = Object.entries(item)[0];
        const payload = { [key]: value };
        dispatch(setUploadedFileURLAction(payload));
      });
    }
  }, [additionalDocumentLinks]);
  useEffect(() => {
    if (!errors.ifscCode && ifscCode) {
      const timeout = setTimeout(() => {
        getBankDataFromIFSC(ifscCode);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [ifscCode, errors.ifscCode]);
  useEffect(() => {
    if (ifscCode) {
      const bankData = bankDataFromISFC?.data?.data;
      if (bankDataFromISFC?.success) {
        setValue("bankName", bankData.bankName);
        setValue("bankAddress", bankData.bankBranch);
      } else if (!bankDataFromISFC?.success && !isFetchingBankDetailsByIFSC) {
        setValue("bankName", null);
        setValue("bankAddress", null);
        setError("bankName", {
          type: "custom",
          message: "Bank Name Not Found",
        });
        setError("bankAddress", {
          type: "custom",
          message: "Bank Address Not Found",
        });
      }
    }
  }, [bankDataFromISFC]);

  // if (isFetching)
  //   return (
  //     <>
  //       <Backdrop
  //         sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
  //         open={open}
  //       >
  //         Fetching...
  //       </Backdrop>
  //     </>
  //   );

  //Logic to insert relationship type in health case.
  //Filter fields from insured member transactional field.
  //Modify the actual array to add/remove family/member
  const insuredMembers =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(INSURED_MEMBER_DETAILS) &&
    [...Array(members?.members)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(INSURED_MEMBER_DETAILS)
      ],
    ]);

  const multiplePayment =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(PAYMENT_DETAILS) &&
    [...Array(members?.payment)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(PAYMENT_DETAILS)
      ],
    ]);

  const multipleEmployee =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(EXPENSE_DETAILS) &&
    [...Array(members?.employee)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(EXPENSE_DETAILS)
      ],
    ]);

  const multipleNominee =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(NOMINEE_DETAILS) &&
    [...Array(members?.nominee)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(NOMINEE_DETAILS)
      ],
    ]);
  const multipleExpenseDetails =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle?.includes(EXPENSE_DETAILS) &&
    expenseDetailsFields.map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle?.indexOf(EXPENSE_DETAILS)
      ],
    ]);
  const multipleLossItemDetails =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(LOSS_ITEM_DETAILS) &&
    [...Array(members?.lossItemDetails)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(LOSS_ITEM_DETAILS)
      ],
    ]);
  const multipleCargoItemDetails =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(CARGO_DETAILS) &&
    [...Array(members?.cargoItemDetails)].map(() => [
      cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(CARGO_DETAILS)
      ].filter(
        (item) => !["modeofTranspo", "certificateNumber"].includes(item?.key)
      ),
    ]);

  const multipleTravelDetails =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle.includes(TRAVEL_DETAILS) &&
    [...Array(members?.cargoItemDetails)].map(() => [
      ...cardFieldsPrevPolicyToggle?.[
        cardTitlesPrevPolicyToggle.indexOf(TRAVEL_DETAILS)
      ],
    ]);

  const specificTravelDetails = claimsToFieldLists
    ?.filter((item) => selectedClaim?.includes(item?.claimType))
    .map((item) => ({
      title: item?.claimType,
      field: multipleTravelDetails?.[0]?.filter((subItem) =>
        item?.fieldList?.includes(subItem?.key)
      ),
    }));
  const selectedDocs =
    Array.isArray(selectdocumenttoUpload) &&
    selectdocumenttoUpload?.map((item) => item?.value);
  const multipleOtherDocs =
    !_.isEmpty(cardTitlesPrevPolicyToggle) &&
    !_.isEmpty(cardFieldsPrevPolicyToggle) &&
    cardTitlesPrevPolicyToggle?.includes(DOCUMENTS) &&
    Array.isArray(selectedDocs) &&
    selectedDocs?.includes("Others")
      ? otherDocsFields?.map(() => [
          cardFieldsPrevPolicyToggle?.[
            cardTitlesPrevPolicyToggle?.indexOf(DOCUMENTS)
          ]?.filter((item) =>
            ["documentName", "otherDocu"].includes(item?.key)
          ),
        ])
      : [];
  // Multi Items Creation
  // uncomment this if want multiple travel insured member in creation
  // const multiClaimantsItem =
  //   !_.isEmpty(cardTitlesPrevPolicyToggle) &&
  //   !_.isEmpty(cardFieldsPrevPolicyToggle) &&
  //   cardTitlesPrevPolicyToggle.includes(TRAVEL_INSURED_MEMBER) &&
  //   lobName === "Travel" &&
  //   [...Array(members?.claimantsDetails)].map(() => [
  //     cardFieldsPrevPolicyToggle?.[
  //       cardTitlesPrevPolicyToggle.indexOf(TRAVEL_INSURED_MEMBER)
  //     ],
  //   ]);

  // useEffect(() => {
  //   Array.isArray(claimRespectivetravel) &&
  //     claimRespectivetravel?.map((item, index) => {
  //       console.log("sahils", item, claimRespectivetravel);
  //       setValue(`travelDetails.[${index}].Claimdropdown`, item?.value);
  //     });
  // }, [JSON.stringify(claimRespectivetravel)]);
  // useEffect(() => {
  //   Array.isArray(watch("travelDetails")) &&
  //     watch("travelDetails")?.map((item, index) => {
  //       setValue(
  //         `travelDetails.[${index}].Claimdropdown`,
  //         claimRespectivetravel?.[index]?.value,
  //       );
  //     });
  // }, [
  //   JSON.stringify(claimRespectivetravel),
  //   JSON.stringify(watch("travelDetails")),
  // ]);
  const allDataObj = {};
  cardTitlesPrevPolicyToggle?.forEach((key, index) => {
    allDataObj[key] = cardFieldsPrevPolicyToggle[index];
  });

  let summaryCardData = {};

  if (Object.keys(summaryData).length) {
    Object.keys(allDataObj).forEach((title) => {
      if (allDataObj.hasOwnProperty(title)) {
        if (title === CARGO_DETAILS) {
          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) => itemx?.key === "modeofTranspo")
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }
          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz) => {
              return allDataObj?.[title]
                ?.filter((itemx) => itemx?.key !== "modeofTranspo")
                ?.map((item) => {
                  return { ...item, value: itemz?.[item?.key] };
                });
            });
          });
        }
        // else if (title === TRAVEL_DETAILS) {

        //   function getFieldList(incidentType) {
        //     return fieldLists[incidentType] || [];
        //   }

        //   console.log("qwert", summaryData?.claimRespectivetravel);
        //   summaryCardData[title] = allDataObj?.[title]
        //     ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //     ?.map((item) => {
        //       return { ...item, value: summaryData?.[item?.key] };
        //     });

        //   if (!summaryCardData[title]) {
        //     summaryCardData[title] = {};
        //   }
        //   if (!summaryCardData[title].customData) {
        //     summaryCardData[title].customData = [];
        //   }

        //   summaryCardData[title].customData.push(
        //     allDataObj?.[title]
        //       ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //       ?.map((item) => {
        //         return { ...item, value: summaryData?.[item?.key] };
        //       })
        //   );
        //   const selectedTravelClaim =
        //     summaryData?.claimRespectivetravel?.split("|");

        //   Object.keys(expenseSummary).forEach(() => {
        //     const data = expenseSummary;
        //     summaryCardData[title].customData = data?.map((itemz) => {
        //       if (
        //         selectedTravelClaim?.includes(
        //           "Accident Death or Partial Disability"
        //         )
        //       ) {
        //         return allDataObj?.[title]
        //           ?.filter((itemx) => {
        //             return itemx?.key !== "claimRespectivetravel";
        //           })
        //           ?.filter((itemx) => {
        //             return accidentDeathOrPartialDisabilityFieldList?.includes(
        //               itemx?.key
        //             );
        //           })
        //           ?.map((item) => {
        //             return { ...item, value: itemz?.[item?.key] };
        //           });
        //       }
        //     });
        //   });
        //   // customData = [
        //   //   [{},{},{}],
        //   //   [{},{},{},{},{}],
        //   //   [{},{},{},{}],
        //   // ]
        // }
        else if (title === TRAVEL_DETAILS) {
          function getFieldList(incidentType) {
            return fieldLists[incidentType] || [];
          }

          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }

          summaryCardData[title].customData.push(
            allDataObj?.[title]
              ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
              ?.map((item) => {
                return { ...item, value: summaryData?.[item?.key] };
              })
          );
          const selectedTravelClaim =
            summaryData?.claimRespectivetravel?.split("|");

          const selectedFields = new Set();

          selectedTravelClaim?.forEach((incidentType) => {
            const fieldList = getFieldList(incidentType);
            fieldList?.forEach((field) => selectedFields.add(field));
          });

          Object.keys(expenseSummary)?.forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz) => {
              return allDataObj?.[title]
                ?.filter((itemx) => itemx?.key !== "claimRespectivetravel")
                ?.filter((itemx) => selectedFields.has(itemx?.key))
                ?.map((item) => {
                  return { ...item, value: itemz?.[item?.key] };
                });
            });
          });
        } else if (
          title !== EXPENSE_DETAILS &&
          title !== LOSS_ITEM_DETAILS &&
          title !== CARGO_DETAILS
          // uncomment this if want multiple travel insured member in view
          // title !== TRAVEL_INSURED_MEMBER
        ) {
          summaryCardData[title] = allDataObj?.[title]?.map((item) => {
            return { ...item, value: summaryData?.[item?.key] };
          });
        } else {
          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].arrayData) {
            summaryCardData[title].arrayData = [];
          }
          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].arrayData = data?.map((itemz) => {
              return allDataObj?.[title]?.map((item) => {
                return { ...item, value: itemz?.[item?.key] };
              });
            });
          });
        }
      }
    });
  }

  const formWiseAllRequiredFields = {};
  Object.entries(matchedObject)?.forEach(([key, value]) => {
    const requiredFieldSlugs = value
      ?.filter((item) => item?.is_mandatory === "Y")
      ?.map((item) => item?.field_slug);
    if (requiredFieldSlugs?.length) {
      return (formWiseAllRequiredFields[key] = requiredFieldSlugs);
    }
  });

  const remainingRequiredFields = {};
  Object.keys(formWiseAllRequiredFields)?.forEach((form) => {
    const rem = formWiseAllRequiredFields?.[form]?.filter(
      (item) =>
        ![INSURED_MEMBER_DETAILS, NOMINEE_DETAILS, PAYMENT_DETAILS]?.includes(
          form
        ) &&
        !fileFields?.includes(item) &&
        !summaryData?.[form]?.[item]
    );
    if (form === POLICY_DETAILS && !policyNo) {
      rem?.push("policyNo");
    }
    if (rem?.length) {
      remainingRequiredFields[form] = rem;
    }
  });

  const getFieldName = (key) => {
    const allFields = Object.values(matchedObject).flat();
    const field = allFields?.filter((item) => item?.field_slug === key);
    return field?.[0]?.field_name;
  };

  const selectedOneTimePayment = (payment || [])?.some(
    (item) =>
      item?.paymentFrequency?.value?.replace(/\s/g, "").toLowerCase() ===
      "onetime"
  );

  const methods = {
    register,
    setValue,
    control,
    getValues,
    watch,
    unregister,
    errors,
    trigger,
    handleSubmit,
  };

  const otherData = {
    summaryData,
    editCard,
    hideGrihaFields,
    policyDetailsBusinessType,
    isBusinessTypeConditionsLobWise,
    noFloaterCover,
    planTypeFromApi,
    policyDetailsPlanType,
    fieldsOtherThanGrihaRaksha,
    isWorkmensCompensationLob,
    isCarLob,
    isBikeLob,
  };
  const otherFunctions = {
    setSelectedCustomer,
    setCurrentField,
    currentField,
    setSelectedProduct,
    clearCustomerData,
    setFiles,
    files,
  };
  const masterData = {
    allCustomers,
    allProducts,
    branchData,
    fuelData,
    versionData,
    modelData,
    manufacturerData,
    masterTableData,
    lobData,
    userData,
    // locationData,
    icData,
  };

  const totalSteps = () => {
    return cardTitlesPrevPolicyToggle?.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const redirectToBasicDetails = () => {
    setActiveStepperIndex(0);
  };
  const lastCard =
    cardTitlesPrevPolicyToggle?.[cardTitlesPrevPolicyToggle?.length - 1];

  const handleNext = () => {
    const newActiveStep =
      // isLastStep() && !allStepsCompleted()
      // ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      // cardTitlesPrevPolicyToggle?.findIndex((step, i) => !(i in completed))
      // :
      activeStep;
    !isLastTitleInCurrentStep && setActiveStepperIndex(newActiveStep);
  };

  const handleStep = (step) => async () => {
    if (completed[step]) {
      setActiveStepperIndex(step);
      return;
    }
    const validationPassed = await trigger();
    if (!validationPassed) {
      return;
    }
    // current_step = "asd,qwe,zxc"
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setActiveStepperIndex(step);
    allStepsCompleted();
  };

  // const handleComplete = async () => {
  //   const validationPassed = await trigger();
  //   if (!validationPassed) {
  //     return;
  //   }

  //   if (activeStep === totalSteps() - 2) {
  //     handleSubmit(throttledSubmit)();
  //   }
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  //   allStepsCompleted();
  // };

  // handle skip
  // const handleSkip = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setSkipped(true);
  //   setCompleted(newCompleted);
  //   handleNext();
  // };
  let additionalFileFields = [];
  if (Array.isArray(selectdocumenttoUpload)) {
    additionalFileFields = selectdocumenttoUpload
      ?.filter((item) => item.value !== "Others")
      .map((item, index) => {
        return {
          ...item,
          key: item?.label,
          field: "FILE",
          value: additionalDocumentLinks?.find(
            (field) => Object.keys(field)[0] === item?.label
          )?.[item?.label],
        };
      });
  }

  // useEffect(() => {
  //   console.log('running')
  //   travelDetails?.map((item,i) => {
  //     if(item['hospitalization'] === "No")
  //     {
  //       setValue(`travelDetails.[${i}].nameofHospital`,null)
  //       setValue(`travelDetails.[${i}].durationinHospital`,null)

  //     }
  //   })
  // }, [travelDetails]);
  useEffect(() => {
    setAdditionalFileFields(additionalFileFields);
  }, [selectdocumenttoUpload]);
  // if (isLoading)
  //   return (
  //     <>
  //       <SkeletonLoader arraySize={15} />
  //     </>
  //   );
  useEffect(() => {
    if (removedValue?.value && uploadedFileURL[removedValue?.value]) {
      dispatch(setUploadedFileURLAction({ [removedValue?.value]: null }));
    }
    if (removedValue?.value === "Others" || !selectdocumenttoUpload?.length) {
      Array.isArray(otherDocs) &&
        otherDocs.forEach((item, index) => {
          dispatch(
            setUploadedFileURLAction({
              [`otherDocs.[${index}].otherDocu`]: null,
            })
          );
        });
      setValue("otherDocs", [otherDOcsDefaultValues]);
    }
  }, [removedValue?.value, selectdocumenttoUpload?.length]);
  console.log({ otherDocs });
  useEffect(() => {
    if (removedValue?.value && Array.isArray(cargoItemDetails)) {
      let indexToUnregister = cargoItemDetails.findIndex(
        (item) => item?.modetransport === removedValue?.value
      );
      indexToUnregister =
        indexToUnregister < 0 &&
        cargoItemDetails.findIndex((item) => !item?.modetransport);
      if (indexToUnregister >= 0) {
        const updatedCargoDetails = [...cargoItemDetails];
        unregister(`cargoItemDetails.${indexToUnregister}`);
        updatedCargoDetails.splice(indexToUnregister, 1);
        setValue("cargoItemDetails", updatedCargoDetails);
        setValue("removedValue", {});
      }
    }
  }, [removedValue?.value, cargoItemDetails]);

  // const travelDetailsOG = useWatch({
  //   control,
  //   name: "travelDetails",
  // });

  useEffect(() => {
    if (removedValue?.value && Array.isArray(travelDetails)) {
      // console.log(travelDetails, "travelDetailsOG");
      const filterValue = travelDetails?.filter(
        (item, i) => i !== removedValue?.index
      );
      setValue("travelDetails", filterValue);
      setValue("removedValue", {});
    }
  }, [removedValue?.value, travelDetails]);
  useEffect(() => {
    const errorKey = Object.entries(errors)?.[0]?.[1];
    const filteredKey =
      (Array.isArray(errorKey) && errorKey?.filter((item) => !!item)) || [];
    const firstErrorKey = Object.values(filteredKey?.[0] || {})?.[0]?.["ref"]?.[
      "id"
    ];

    const filterSource = Object.keys(errors)?.filter((item) => {
      return errors?.[item]?.ref;
    });
    const finalErrorKey = filterSource?.[0] || firstErrorKey;
    if (finalErrorKey) {
      document
        .getElementById(finalErrorKey)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [errors]);
  useEffect(() => {
    setValue("certificateNumber", certificateNumber || null);
    setValue("certificateId", certificateId || watch("certificateId"));
  }, [
    certificateId,
    certificateNumber,
    JSON.stringify(summaryData),
    JSON.stringify(currentFormData),
    activeFormTitle,
  ]);
  return (
    <>
      {/* <MainContainer>
        <Stepper
          // nonLinear
          activeStep={activeStepperIndex}
          sx={{
            padding: "0 10px",
            // border: "1px solid red",
            height: "50px",
            "& svg": {
              fontSize: "20px",
            },
            "& .MuiStepConnector-line": {
              borderTopWidth: "2px",
              borderColor: "rgba(17, 85, 95, 1)",
            },
            "& svg.MuiStepIcon-root": {
              color: "rgba(203, 249, 231, 1)",
            },
            "& svg.MuiStepIcon-root text": {
              fill: "rgba(17, 85, 95, 1)",
            },
            "& svg.Mui-active": {
              color: "rgba(17, 85, 95, 1)",
            },
            "& svg.Mui-active text": {
              fill: "rgba(203, 249, 231, 1)",
            },
            "& svg.Mui-completed": {
              color: "rgba(17, 85, 95, 1)",
            },
          }}
        >
          {cardTitlesPrevPolicyToggle?.map(
            (label, index) => (
              console.log("index", index),
              (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              )
            )
          )}
        </Stepper>
      </MainContainer> */}

      {cardTitlesPrevPolicyToggle?.map((value, index) => {
        const multiItemBasedOnTitle = [
          PAYMENT_DETAILS,
          EXPENSE_DETAILS,
          NOMINEE_DETAILS,
          LOSS_ITEM_DETAILS,
        ];
        // uncomment this if want multiple travel insured member in validation
        // if (lobName === "Travel") {
        //   multiItemBasedOnTitle.push(TRAVEL_INSURED_MEMBER);
        // }
        if (current[index]) {
          if (
            (current[index] && isRaiseClaim) ||
            (stepper === index && isUpdate)
          ) {
            return !policyViewCards[index + 1] && activeStep === index + 1 ? (
              <div>
                <ProposalCreateSummaryStyled
                  isRaiseClaim={isRaiseClaim}
                  ref={(node) => {
                    const map = getMap();
                    if (node) {
                      map.set(index + 1, node);
                    } else {
                      map.delete(index + 1);
                    }
                  }}
                >
                  <PolicyCreateStyled
                    style={{
                      overflowY: "auto",
                      padding: "20px",
                      height: "100%",
                    }}
                    ref={policyCardRef}
                  >
                    <header>
                      <h3>
                        {value === PI_DETAILS
                          ? `PI ${planTypeFromApi} Details`
                          : value}
                      </h3>
                      {isRaiseClaim && (
                        <ProgressBar
                          current_step_array={current_step_array}
                          cardTitlesPrevPolicyToggle={
                            cardTitlesPrevPolicyToggle
                          }
                        />
                      )}
                    </header>
                    {multiItemBasedOnTitle.includes(value) ? (
                      // (value === INSURED_MEMBER_DETAILS && insuredMembers) ||
                      (
                        (value === PAYMENT_DETAILS && multiplePayment) ||
                        // (value === EXPENSE_DETAILS && multipleEmployee) ||
                        (value === NOMINEE_DETAILS && multipleNominee) ||
                        (value === EXPENSE_DETAILS && multipleExpenseDetails) ||
                        (value === LOSS_ITEM_DETAILS && multipleLossItemDetails)
                      )
                        // (value === TRAVEL_INSURED_MEMBER && multiClaimantsItem)
                        //  ||
                        // (value === CARGO_DETAILS && multipleCargoItemDetails
                        .map((member, memberIndex) => (
                          <>
                            {value === EXPENSE_DETAILS && memberIndex === 0 && (
                              <ButtonWrapper
                                style={{
                                  paddingRight: "20px",
                                }}
                              >
                                <Button
                                  type="button"
                                  // themeType={"theme1"}
                                  // bgColor={"#11555F"}
                                  // bgColor={themeObj.}
                                  onClick={() => setExpenseModalState(true)}
                                >
                                  Bulk Upload
                                </Button>
                              </ButtonWrapper>
                            )}
                            <MemberCount index={memberIndex}>
                              {(value === PAYMENT_DETAILS && "Payment ") ||
                                (value === NOMINEE_DETAILS && "Nominee ") ||
                                (value === EXPENSE_DETAILS && "Expense ") ||
                                (value === LOSS_ITEM_DETAILS && "Loss item ") ||
                                (value === TRAVEL_INSURED_MEMBER &&
                                  "Insured Member's details ") ||
                                (value === CARGO_DETAILS &&
                                  `${cargoDetailsListTitle?.[memberIndex]} `)}
                              {cargoDetailsListTitle?.length > 0
                                ? ""
                                : memberIndex + 1}
                            </MemberCount>
                            <Grid
                              container
                              rowSpacing={1}
                              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            >
                              {renderFormFields({
                                fieldsArray: member[0],
                                formTitle: value,
                                formIndex: index,
                                nestedForm:
                                  // (value === INSURED_MEMBER_DETAILS &&
                                  //   "nestedMembers") ||
                                  (value === PAYMENT_DETAILS &&
                                    "nestedPayments") ||
                                  (value === EXPENSE_DETAILS &&
                                    "nestedExpenses") ||
                                  (value === LOSS_ITEM_DETAILS &&
                                    "nestedLossDetails") ||
                                  (value === CARGO_DETAILS && "nestedCargo") ||
                                  (value === NOMINEE_DETAILS &&
                                    "nestedNominees") ||
                                  (value === TRAVEL_INSURED_MEMBER &&
                                    "nestedClaimants"),
                                nestedIndex: memberIndex,
                                methods: methods,
                                otherData: otherData,
                                otherFunctions: otherFunctions,
                                masterData: masterData,
                                modeofTransportation: modeofTransportation,
                                policyDetails: policyDetails,
                              })}
                            </Grid>
                            {[EXPENSE_DETAILS]?.includes(value) && (
                              <>
                                <Grid item>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "20px",
                                      justifyContent: "flex-end",
                                      marginRight: "15px",
                                      marginTop: "15px",
                                    }}
                                  >
                                    {multipleExpenseDetails?.length - 1 ===
                                      memberIndex && (
                                      <Button
                                        type="button"
                                        // themeType={"theme1"}
                                        // bgColor={"#11555F"}
                                        style={{
                                          marginLeft: "10px",
                                          float: "right",
                                        }}
                                        className="multiple_action_btn"
                                        onClick={() => {
                                          expenseDetailsAppend({
                                            ...expenseDetailsDefaultValues,
                                          });
                                          setIsMadeChanges(true);
                                          setTimeout(() => {
                                            policyCardRef?.current.scrollTo({
                                              top: policyCardRef?.current
                                                .scrollHeight,
                                              behavior: "smooth",
                                            });
                                          }, 500);
                                        }}
                                      >
                                        {"+ Add"}
                                      </Button>
                                    )}
                                    {expenseDetailsFields.length > 1 && (
                                      <Button
                                        type="button"
                                        // themeType={"theme1"}
                                        // bgColor={"#11555F"}
                                        style={{
                                          marginLeft: "10px",
                                          float: "right",
                                        }}
                                        className="multiple_action_btn"
                                        onClick={() => {
                                          setIsMadeChanges(true);
                                          expenseDetailsRemove(memberIndex);
                                        }}
                                      >
                                        {"- Remove"}
                                      </Button>
                                    )}
                                  </div>
                                </Grid>
                                {multipleExpenseDetails?.length - 1 ===
                                  memberIndex && (
                                  <Grid
                                    container
                                    // item
                                    justifyContent={"center"}
                                    gap={2}
                                    sx={{
                                      flexWrap: "nowrap",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      sx={{
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Chip
                                        label={`Total Bill Amount : ${
                                          String(expenseTotalCount?.totalAmount)
                                            ?.length > 3
                                            ? acronymFormatValue(
                                                expenseTotalCount?.totalAmount
                                              )
                                            : expenseTotalCount?.totalAmount
                                        }`}
                                        size="small"
                                        sx={{
                                          marginRight: "20px",
                                          marginTop: "2px",
                                          height: "20px",
                                          color: `${
                                            // theme?.primaryColor,
                                            "#000"
                                          }`,
                                          backgroundColor: `${
                                            // theme?.secondaryColor || "#F5F5F5"
                                            "#F5F5F5"
                                          }`,
                                          borderRadius: "5px",
                                          padding: "15px 0px",
                                          width: "max-content",
                                          // overflow: "auto",
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={6}
                                      sx={{
                                        flex: 1,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Chip
                                        label={`Total Approved Amount: ${
                                          String(
                                            expenseTotalCount?.approvedAmount
                                          )?.length > 3
                                            ? acronymFormatValue(
                                                expenseTotalCount?.approvedAmount
                                              )
                                            : expenseTotalCount?.approvedAmount
                                        } `}
                                        size="small"
                                        sx={{
                                          marginRight: 0.5,
                                          marginTop: "2px",
                                          height: "20px",
                                          color: `${
                                            // theme?.primaryColor || "#000"
                                            "#000"
                                          }`,
                                          backgroundColor: `${
                                            // theme?.secondaryColor || "#F5F5F5"
                                            "#F5F5F5"
                                          }`,
                                          borderRadius: "5px",
                                          padding: "15px 0px",
                                          width: "max-content",

                                          // overflow: "auto",
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        ))
                    ) : [CARGO_DETAILS].includes(value) ? (
                      <>
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          {renderFormFields({
                            fieldsArray: cardFieldsPrevPolicyToggle?.[
                              index
                            ].filter((item) =>
                              ["modeofTranspo", "certificateNumber"].includes(
                                item?.key
                              )
                            ),
                            formTitle: value,
                            formIndex: index,
                            nestedForm: "",
                            nestedIndex: "",
                            methods: methods,
                            otherData: otherData,
                            otherFunctions: otherFunctions,
                            masterData: masterData,
                            modeofTransportation: modeofTransportation,
                            policyDetails: policyDetails,
                          })}
                        </Grid>
                        {!_.isEmpty(modeofTranspo) &&
                          multipleCargoItemDetails?.map(
                            (member, memberIndex) => (
                              <>
                                <MemberCount index={memberIndex}>
                                  {modeofTranspo[memberIndex]?.label}
                                  {/* {memberIndex + 1} */}
                                </MemberCount>
                                <Grid
                                  container
                                  rowSpacing={1}
                                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                  {renderFormFields({
                                    fieldsArray: member[0],
                                    formTitle: value,
                                    formIndex: index,
                                    nestedForm: "nestedCargo",
                                    nestedIndex: memberIndex,
                                    methods: methods,
                                    otherData: otherData,
                                    otherFunctions: otherFunctions,
                                    masterData: masterData,
                                    modeofTransportation: modeofTransportation,
                                    policyDetails: policyDetails,
                                  })}
                                </Grid>
                              </>
                            )
                          )}
                      </>
                    ) : [TRAVEL_DETAILS].includes(value) ? (
                      <>
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          {renderFormFields({
                            fieldsArray: cardFieldsPrevPolicyToggle?.[
                              index
                            ].filter((item) => {
                              return item?.key === "claimRespectivetravel";
                            }),
                            formTitle: value,
                            formIndex: index,
                            nestedForm: "",
                            nestedIndex: "",
                            methods: methods,
                            otherData: otherData,
                            otherFunctions: otherFunctions,
                            masterData: masterData,
                            modeofTransportation: modeofTransportation,
                            policyDetails: policyDetails,
                          })}
                        </Grid>

                        {specificTravelDetails?.map((item, index) => {
                          // !watch(`travelDetails.[${index}].Claimdropdown`) &&
                          //   setValue(
                          //     `travelDetails.[${index}].Claimdropdown`,
                          //     item?.[0]?.formTitle
                          //   );
                          return (
                            <>
                              <MemberCount index={1}>
                                {item?.title}
                                {/* {selectedClaim[index]} */}
                                {/* {memberIndex + 1} */}
                              </MemberCount>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                              >
                                {renderFormFields({
                                  fieldsArray: item?.field,
                                  formTitle: value,
                                  formIndex: index,
                                  nestedForm: "nestedTravel",
                                  nestedIndex: index,
                                  methods: methods,
                                  otherData: otherData,
                                  otherFunctions: otherFunctions,
                                  masterData: masterData,
                                  modeofTransportation: modeofTransportation,
                                  policyDetails: policyDetails,
                                })}
                              </Grid>
                            </>
                          );
                        })}
                      </>
                    ) : [DOCUMENTS]?.includes(value) ? (
                      <>
                        <div>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            {renderFormFields({
                              fieldsArray: cardFieldsPrevPolicyToggle?.[
                                index
                              ]?.filter(
                                (item) =>
                                  !["documentName", "otherDocu"].includes(
                                    item?.key
                                  )
                              ),
                              formTitle: value,
                              formIndex: index,
                              nestedForm: "",
                              nestedIndex: "",
                              methods,
                              otherData,
                              otherFunctions,
                              masterData,
                              modeofTransportation,
                              policyStart,
                              // hospitalDate,
                              policyExpiry,
                              // claimRegister,
                              // claimRejectionDate,
                              policyData,
                              policyDetails,
                              selectdocumenttoUpload:
                                value === DOCUMENTS ? additionalFileFields : [],
                            })}
                          </Grid>

                          {multipleOtherDocs?.map((member, memberIndex) => (
                            <div key={memberIndex}>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                style={{
                                  // marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                {renderFormFields({
                                  fieldsArray: member[0],
                                  formTitle: value,
                                  formIndex: index,
                                  nestedForm: "nestedOtherDocs",
                                  nestedIndex: memberIndex,
                                  methods,
                                  otherData,
                                  otherFunctions,
                                  masterData,
                                  modeofTransportation,
                                  policyStart,
                                  // hospitalDate,
                                  policyExpiry,
                                  // claimRegister,
                                  // claimRejectionDate,
                                  policyData,
                                  policyDetails,
                                  keys: otherDocsFields[memberIndex]?.id,
                                })}
                              </Grid>

                              <Grid item className="action-buttons-grid">
                                <div
                                  style={{
                                    marginTop: "-48px",
                                    float: "right",
                                    marginRight: "20px",
                                    // color: "#11555F",
                                  }}
                                >
                                  {otherDocsFields.length > 1 && (
                                    <span
                                      type="button"
                                      color="red !important"
                                      themeType="theme1"
                                      className="delete-button"
                                      onClick={() => {
                                        dispatch(
                                          // setUploadedFileURLAction({
                                          //   [`otherDocs.${memberIndex}.otherDocu`]:
                                          //     null,
                                          // })
                                          removeFileURLAction({
                                            parent: "otherDocs",
                                            index: memberIndex,
                                            fieldKey: "otherDocu",
                                          })
                                        );
                                        otherDocRemove(memberIndex);
                                      }}
                                    >
                                      <Tooltip
                                        title="Delete"
                                        placement="top"
                                        arrow
                                      >
                                        <DeleteOutlineRoundedIcon
                                          style={{
                                            color: "#e22f2f",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  )}
                                </div>
                              </Grid>

                              {multipleOtherDocs?.length - 1 ===
                                memberIndex && (
                                <div className="add-doc-button-container">
                                  <Button
                                    type="button"
                                    // themeType="theme1"
                                    // bgColor="#11555F"
                                    className="add-doc-button"
                                    onClick={() => {
                                      otherDocAppend({
                                        ...otherDOcsDefaultValues,
                                      });
                                      dispatch(
                                        setUploadedFileURLAction({
                                          [`otherDocs.[${
                                            memberIndex + 1
                                          }].otherDocu`]: null,
                                        })
                                      );
                                    }}
                                  >
                                    + Upload Other Docs
                                  </Button>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {renderFormFields({
                          fieldsArray: cardFieldsPrevPolicyToggle?.[index],
                          formTitle: value,
                          formIndex: index,
                          nestedForm: "",
                          nestedIndex: "",
                          methods: methods,
                          otherData: otherData,
                          otherFunctions: otherFunctions,
                          masterData: masterData,
                          selectdocumenttoUpload:
                            value === DOCUMENTS ? additionalFileFields : [],
                          policyDetails: policyDetails,
                          modeofTransportation: modeofTransportation,
                          expnessClaim: expnessClaim,
                          causeofLoss: causeofLoss,
                          policyDetails: policyDetails,
                        })}
                      </Grid>
                    )}
                    <>
                      {!selectedOneTimePayment &&
                        [PAYMENT_DETAILS].includes(value) && (
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "flex-end",
                            }}
                          >
                            {_.compact([
                              "Add Payment",
                              members?.payment > 1 && "Remove Payment",
                            ]).map((op, index) => {
                              return (
                                <Button
                                  type="button"
                                  themeType={"theme1"}
                                  // bgColor={"#11555F"}
                                  style={{
                                    marginLeft: "10px",
                                    float: "right",
                                    // position: "absolute",
                                    // right: op === "Add Payment" ? 0 : "90px",
                                    // bottom: 0,
                                  }}
                                  onClick={() => {
                                    op === "Add Payment"
                                      ? setMembers((prev) => ({
                                          ...prev,
                                          payment: prev.payment + 1,
                                        }))
                                      : setMembers((prev) => ({
                                          ...prev,
                                          payment: prev.payment - 1,
                                        }));
                                    if (op !== "Add Payment") {
                                      setValue(
                                        `payment[${members?.payment - 1}]`,
                                        null
                                      );
                                      unregister(
                                        `payment[${members?.payment - 1}]`
                                      );
                                      dispatch(
                                        setUploadedFileURLAction({
                                          [`payment.[${index}].paymentFile`]:
                                            null,
                                        })
                                      );
                                    }
                                  }}
                                >
                                  {`${
                                    op === "Add Payment" ? "+ Add" : "- Remove"
                                  }`}
                                </Button>
                              );
                            })}
                          </div>
                        )}
                      {/* {[EXPENSE_DETAILS].includes(value) && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "flex-end",
                            padding: "20px",
                          }}
                        >
                          {_.compact([
                            "Add Expense Detail",
                            members?.expenseDetails > 1 &&
                              "Remove Expense Detail",
                          ]).map((op) => {
                            return (
                              <div key={op}>
                                <Button
                                  type="button"
                                  themeType={"theme1"}
                                  // bgColor={"#11555F"}
                                  style={{
                                    marginLeft: "10px",
                                    float: "right",
                                  }}
                                  className="multiple_action_btn"
                                  onClick={() => {
                                    op === "Add Expense Detail"
                                      ? setMembers((prev) => ({
                                          ...prev,
                                          expenseDetails:
                                            prev.expenseDetails + 1,
                                        }))
                                      : setMembers((prev) => ({
                                          ...prev,
                                          expenseDetails:
                                            prev.expenseDetails - 1,
                                        }));
                                    if (op !== "Add Expense Detail") {
                                      setValue(
                                        `expenseDetails[${
                                          members?.expenseDetails - 1
                                        }]`,
                                        null
                                      );
                                      unregister(
                                        `expenseDetails[${
                                          members?.expenseDetails - 1
                                        }]`
                                      );
                                    }
                                  }}
                                >
                                  {op === "Add Expense Detail"
                                    ? "+ Add"
                                    : "- Remove"}
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      )} */}

                      {[LOSS_ITEM_DETAILS].includes(value) && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {_.compact([
                            "Add Loss Item Detail",
                            members?.lossItemDetails > 1 &&
                              "Remove Loss Item Detail",
                          ]).map((op) => {
                            return (
                              <div key={op}>
                                <Button
                                  type="button"
                                  themeType={"theme1"}
                                  // bgColor={"#11555F"}
                                  style={{
                                    marginLeft: "10px",
                                    float: "right",
                                  }}
                                  className="multiple_action_btn"
                                  onClick={() => {
                                    op === "Add Loss Item Detail"
                                      ? setMembers((prev) => ({
                                          ...prev,
                                          lossItemDetails:
                                            prev.lossItemDetails + 1,
                                        }))
                                      : setMembers((prev) => ({
                                          ...prev,
                                          lossItemDetails:
                                            prev.lossItemDetails - 1,
                                        }));
                                    if (op !== "Add Loss Item Detail") {
                                      setValue(
                                        `lossItemDetails[${
                                          members?.lossItemDetails - 1
                                        }]`,
                                        null
                                      );
                                      unregister(
                                        `lossItemDetails[${
                                          members?.lossItemDetails - 1
                                        }]`
                                      );
                                    }
                                  }}
                                >
                                  {op === "Add Loss Item Detail"
                                    ? "+ Add"
                                    : "- Remove"}
                                </Button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <GlobalModal
                        open={expenseModalState}
                        onClose={() => setExpenseModalState(false)}
                        title="Bulk Upload Expense Details"
                      >
                        <ExpenseDetailsBulkUpload
                          isMadeChanges={isMadeChanges}
                          handleClose={() => setExpenseModalState(false)}
                          mutate={uploadExpenseDetails}
                          setAsd={setAsd}
                        />
                      </GlobalModal>
                      {/* uncomment this if want multiple travel insured member in view */}
                      {/* {[TRAVEL_INSURED_MEMBER].includes(value) &&
                    lobName === "Travel" && (
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "flex-end",
                        }}
                      >
                        {_.compact([
                          "Add Claimants Item Detail",
                          members?.claimantsDetails > 1 &&
                            "Remove Claimants Item Detail",
                        ]).map((op) => {
                          return (
                            <div key={op}>
                              <Button
                                type="button"
                                themeType={"theme1"}
                                bgColor={"#11555F"}
                                style={{
                                  marginLeft: "10px",
                                  float: "right",
                                }}
                                className="multiple_action_btn"
                                onClick={() => {
                                  op === "Add Claimants Item Detail"
                                    ? setMembers((prev) => ({
                                        ...prev,
                                        claimantsDetails:
                                          prev.claimantsDetails + 1,
                                      }))
                                    : setMembers((prev) => ({
                                        ...prev,
                                        claimantsDetails:
                                          prev.claimantsDetails - 1,
                                      }));
                                  if (op !== "Add Claimants Item Detail") {
                                    setValue(
                                      `claimantsDetails[${
                                        members?.claimantsDetails - 1
                                      }]`,
                                      null
                                    );
                                    unregister(
                                      `claimantsDetails[${
                                        members?.claimantsDetails - 1
                                      }]`
                                    );
                                  }
                                }}
                              >
                                {op === "Add Claimants Item Detail"
                                  ? "+ Add"
                                  : "- Remove"}
                              </Button>
                            </div>
                          );
                        })}
                      </div>
                    )} */}

                      {/* {[CARGO_DETAILS].includes(value) && (
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "flex-end",
                      }}
                    >
                      {_.compact([
                        "Add Cargo Item Detail",
                        members?.cargoItemDetails > 1 &&
                          "Remove Cargo Item Detail",
                      ]).map(op => {
                        return (
                          <div key={op}>
                            <Button
                              type="button"
                              themeType={"theme1"}
                              bgColor={"#11555F"}
                              style={{
                                marginLeft: "10px",
                                float: "right",
                              }}
                              className="multiple_action_btn"
                              onClick={() => {
                                op === "Add Cargo Item Detail"
                                  ? setMembers(prev => ({
                                      ...prev,
                                      cargoItemDetails:
                                        prev.cargoItemDetails + 1,
                                    }))
                                  : setMembers(prev => ({
                                      ...prev,
                                      cargoItemDetails:
                                        prev.cargoItemDetails - 1,
                                    }));
                                if (op !== "Add Cargo Item Detail") {
                                  setValue(
                                    `cargoItemDetails[${members?.cargoItemDetails - 1}]`,
                                    null,
                                  );
                                  unregister(
                                    `cargoItemDetails[${members?.cargoItemDetails - 1}]`,
                                  );
                                }
                              }}
                            >
                              {op === "Add Cargo Item Detail"
                                ? "+ Add"
                                : "- Remove"}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )} */}

                      {[NOMINEE_DETAILS].includes(value) && (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "flex-end",
                          }}
                        >
                          {_.compact([
                            "Add Nominee",
                            members?.nominee > 1 && "Remove Nominee",
                          ]).map((op) => {
                            return (
                              <Button
                                type="button"
                                themeType={"theme1"}
                                // bgColor={"#11555F"}
                                style={{
                                  marginLeft: "10px",
                                  float: "right",
                                }}
                                className="multiple_action_btn"
                                onClick={() => {
                                  op === "Add Nominee"
                                    ? setMembers((prev) => ({
                                        ...prev,
                                        nominee: prev.nominee + 1,
                                      }))
                                    : setMembers((prev) => ({
                                        ...prev,
                                        nominee: prev.nominee - 1,
                                      }));
                                  if (op !== "Add Nominee") {
                                    setValue(
                                      `nominee[${members?.nominee - 1}]`,
                                      null
                                    );
                                    unregister(
                                      `nominee[${members?.nominee - 1}]`
                                    );
                                  }
                                }}
                              >
                                {`${
                                  op === "Add Nominee" ? "+ Add" : "- Remove"
                                }`}
                              </Button>
                            );
                          })}
                        </div>
                      )}

                      <Tooltip
                        title={
                          (activeFormTitle === "PAYMENT_DETAILS" &&
                            !!paymentDetailsTotalAmount &&
                            +paymentDetailsTotalAmount >
                              +premiumDetailsFromApi?.totalPremium &&
                            "Cumulative total amount should not exceed the total premium") ||
                          (activeFormTitle === "NOMINEE_DETAILS" &&
                            !!totalNomineeShare &&
                            +totalNomineeShare !== 100 &&
                            "Cumulative nominee share should be 100%")
                        }
                        followCursor
                      ></Tooltip>
                    </>
                  </PolicyCreateStyled>
                </ProposalCreateSummaryStyled>
                {!isSummary && (
                  <ButtonContainerStyled>
                    <Box
                      style={{
                        display: "flex",
                        gap: "10px",
                        padding: "0 20px",
                        justifyContent:
                          index > 0 && !isUpdate ? "space-between" : "flex-end",
                        width: "100%",
                      }}
                    >
                      {index > 0 && !isUpdate && (
                        <Button
                          themeType={"theme1"}
                          // bgColor={"#11555F"}
                          onClick={() => {
                            setActiveStep(index);
                            // handleFormSubmit(
                            //   value,
                            //   cardFieldsPrevPolicyToggle?.[index],
                            //   index,
                            //   value,
                            //   false,
                            //   "previous"
                            // );
                          }}
                        >
                          Previous
                        </Button>
                      )}
                      <Button
                        className={`${value}`}
                        type="submit"
                        themeType={"theme1"}
                        // bgColor={"#11555F"}
                        id={`submit_${value}_${index}`}
                        disabled={
                          (activeFormTitle === "PREVIOUS_POLICY_DETAILS" &&
                            !!paymentDetailsTotalAmount &&
                            +paymentDetailsTotalAmount >
                              +premiumDetailsFromApi?.totalPremium) ||
                          (activeFormTitle === "NOMINEE_DETAILS" &&
                            !!totalNomineeShare &&
                            totalNomineeShare != 100)
                        }
                        onClick={() => [
                          handleFormSubmit(
                            value,
                            cardFieldsPrevPolicyToggle?.[index],
                            index + 1,
                            value,
                            false
                          ),
                          trigger(),
                          // handleNextStep();
                        ]}
                      >
                        {isCreateProposalDataLoading ||
                        isUpdateExistingProposalDataLoading ? (
                          <>
                            Saving your progress
                            <InlineLoader />
                          </>
                        ) : (
                          "Continue"
                        )}
                      </Button>
                    </Box>
                  </ButtonContainerStyled>
                )}
                {editCard[title] && (
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={1}
                    sx={{
                      marginTop: "auto",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                      // position: "fixed",
                      // bottom: "0px",
                    }}
                  >
                    <Grid
                      item
                      lg={2}
                      md={4}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingTop: "0px",
                      }}
                    >
                      {stepper > 0 && !isRaiseClaim && (
                        <StyledButton onClick={() => setActiveStep(index)}>
                          <FaCaretLeft />
                          <span>Back</span>
                        </StyledButton>
                      )}
                    </Grid>

                    <Grid
                      item
                      lg={2}
                      md={4}
                      sm={6}
                      xs={6}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingTop: "0px",
                      }}
                    >
                      {stepper < Object.entries(summaryCardData).length - 1 &&
                        !isRaiseClaim && (
                          <StyledButton onClick={() => setStepper(stepper + 1)}>
                            <span>Next</span>
                            <FaCaretRight />
                          </StyledButton>
                        )}
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : (
              // !isRaiseClaim ? (
              //   Object.entries(summaryCardData)?.map(([key, ObjValue], index) => {
              //     return (
              //       value === key && editCard[key] &&  (
              //         <ViewPolicyEntrySummary
              //           title={key}
              //           data={ObjValue}
              //           index={index}
              //           handleFieldEdit={handleFieldEdit}
              //           editCard={editCard}
              //           summaryData={summaryData}
              //           expenseData={expenseData}
              //           lobId={lobId}
              //           cargoDetailsListTitle={cargoDetailsListTitle}
              //           additionalFields={additionalFileFields}
              //           expnessClaim={expnessClaim}
              //           selectedClaim={selectedClaim}
              //         />
              //       )
              //     );
              //   })
              // )
              // :
              <></>
            );
            // isUpdate &&
            //   Object.entries(summaryCardData)?.map(
            //     ([key, ObjValue], index) => {
            //       return (
            //         value === key && (
            //           <ViewPolicyEntrySummary
            //             title={key}
            //             data={ObjValue}
            //             index={index}
            //             handleFieldEdit={handleFieldEdit}
            //             editCard={editCard}
            //             summaryData={summaryData}
            //             expenseData={expenseData}
            //             lobId={lobId}
            //             cargoDetailsListTitle={cargoDetailsListTitle}
            //             additionalFields={additionalFileFields}
            //             expnessClaim={expnessClaim}
            //             selectedClaim={selectedClaim}
            //           />
            //         )
            //       );
            //     }
            //   )
          }
          // return <>{"xyz"}</>;
        }
        // return <>{"Faulty"}</>;
      })}
    </>
  );
}

export default ClaimForm;
export const StyledBg = styled.div`
  background: #fff;
  padding: 20px;
`;
export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 12px;
  border-radius: 4px;
  background: ${({ theme }) => theme?.buttons_color};
  box-shadow: 0px 0px 10px 0px #00000033;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: ${({ theme }) => theme?.tertiaryColor};
  cursor: pointer;
  width: fit-content;
`;
const MainContainer = styled(StyledBg)`
  padding: 0;
  margin-bottom: 10px;
`;
