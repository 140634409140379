import React, { useState } from "react";
import { Box, Container } from "@mui/material";
// import { FilterAndTotalCountContainer } from "../../utils/sharedComponent/FilterAndTotalCountStyle";
import FilterButton from "../../utils/sharedComponent/FilterButton";
import Notification from "./Notification";
import Communicationcomponent from "./Communication";
import SubActivityLog from "./SubActivityLog";
import styled from "styled-components";
import Tabpanel from "./Tabpanel";

const Activitylogs = () => {
  const tabsAndComponents = [
    { label: "Notifications", component: <Notification /> },
    { label: "Communication", component: <Communicationcomponent /> },
  ];

  // const [filterSelected, setFilterSelected] = useState(filterTags[0]);

  // const handleFilterClick = (clicked) => {
  //   setFilterSelected(clicked);
  // };

  return (
    <Container
      sx={{
        backgroundColor: "white",
        padding: "20px !important",
      }}
    >
      <Box
        style={{
          fontSize: "24px",
          color: "#2A2E33",
          lineHeight: "26.6px",
          fontWeight: "600",
          backgroundColor: "white",
        }}
      >
        Activity Logs
      </Box>
      <StyledPara>Information of requested claims is listed here.</StyledPara>
      <Tabpanel tabs={tabsAndComponents} />
    </Container>
  );
};

export default Activitylogs;

export const StyledPara = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  background-color: white;
`;

export const FilterAndTotalCountContainer = styled.div`
  min-height: 102px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
