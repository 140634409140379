import moment from "moment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DateAndTimePicker from "../../components/form-components/dateAndTimePicker/DateAndTimePicker";
import {
  PLAN_TYPE_GRIHA_RAKSHA,
  INSURED_MEMBER_DETAILS,
  PLAN_TYPE_LAGHU_UDHYAM,
  LIFE_ASSURED_DETAILS,
  PI_DETAILS,
  POLICY_DETAILS,
  PROPERTY_DETAILS,
  PROPOSER_DETAILS,
  SATP,
  PLAN_TYPE_SOOKSHMA_UDHYAM,
  TP_1_YEAR,
  TP_LONG_TERM_3_YEARS,
  TP_LONG_TERM_5_YEARS,
  VEHICLE_DETAILS,
  companyFields,
  floaterCoverFields,
  grihaRakshaFields,
  individualFields,
  piCompanyFields,
  piIndividualFields,
  BASIC_DETAILS,
  MISCELLANEOUS_INSURANCE,
} from "./constants";

import { Grid, Tooltip } from "@mui/material";
import { DatePickerInput, Input } from "../../components";
import {
  allCapitalize,
  allowAlphanumericHyphenAndSpace,
  allowOnlyAlphabetsWithSpace,
  allowOnlyNumbers,
  allowOnlyNumbersAndDecimal,
  allowPercentageNumbersUpto100,
  handleFloatInput,
  preventCharactersInInput,
  preventSpecialAndNumbersInInput,
  preventSpecialInInput,
  preventWhiteSpaceInInput,
  verifyValidAadhar,
  verifyValidEmail,
  verifyValidNumbers,
  verifyValidPAN,
  verifyValidPincode,
} from "../../HelperFunctions/HelperFunctions";
// import ProposalFilePicker from "../ProposalComponents/ProposalFilePicker";
import { BmiCategory, Description } from "./policyEntryStyles";
import _, { capitalize } from "lodash";

import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import ProposalDropdown from "./ProposalDropdown";
import ProposalFilePicker from "./ProposalFilePicker";
import ToggleButton from "./ToggleButton";
import FilePicker from "./FilePicker";

export const renderFormFields = ({
  fieldsArray,
  formTitle,
  nestedForm,
  nestedIndex,
  methods,
  otherData,
  otherFunctions,
  masterData,
  selectdocumenttoUpload = [],
  modeofTransportation,
  expnessClaim,
  causeofLoss,
  policyDetails,
}) => {
  const policyStartDate = policyDetails?.policystDate;
  const policyExpiryDate = policyDetails?.policyExpirydate;

  const formattedDate = policyStartDate ? moment(policyStartDate).local() : "";
  const formattedToDate = moment(formattedDate).add(3, "months").local();
  const formattedPolicyDateSinglePlanType = moment(formattedDate)
    .add(3, "months")
    .local();

  const formattedDateForCompanyAnnualPlanType = policyStartDate
    ? moment(policyStartDate).add(1, "year").local()
    : "";
  const { register, setValue, control, watch, errors, trigger } = methods;

  const {
    allCustomers,
    allProducts,
    branchData,
    fuelData,
    versionData,
    modelData,
    manufacturerData,
    masterTableData,
    lobData,
    userData,
    locationData,
    icData,
  } = masterData;

  const {
    summaryData,
    editCard,
    hideGrihaFields,
    policyDetailsBusinessType,
    isBusinessTypeConditionsLobWise,
    noFloaterCover,
    planTypeFromApi,
    policyDetailsPlanType,
    fieldsOtherThanGrihaRaksha,
    isWorkmensCompensationLob,
    isCarLob,
    isBikeLob,
    leadId,
  } = otherData;

  const {
    setSelectedCustomer,
    setCurrentField,
    currentField,
    setSelectedProduct,
    clearCustomerData,
    setFiles,
    files,
  } = otherFunctions;

  const {
    isVehicleFinanced,
    isSameAddress,
    addresschecktwo,
    policystDate,
    policyExpirydate,
    pucExpiryDate,
    nomineeDob,
    regDate,
    policyStartdate,
    estimatedArrivaldate,
    estimatedDeparturedate,
    firstName,
    bookDate,
    maritialStatus,
    assuredmaritalStatus,
    addressCheck,
    bmi,
    pucStartdate,
    payment,
    medicalHistory,
    customerRefId,
    members: watchMembers,
    nominee: watchNominee,
    expenseDetails: watchExpense,
    lossItemDetails: watchLossItemDetails,
    typeofcustomer,
    locationofRisk,
    medicalHistoryim,
    businessType,
    policyType,
    fuelType,
    itSection,
    eiaNumber,
    assuredeiaNumber,
    dob,
    lifeAssureddob,
    policyExpirydatepd,
    idProof,
    addressProof,
    incomeProof,
    assuredidProof,
    assuredaddressProof,
    assuredincomeProof,
    occupation,
    coverType,
    hospitalDate,
    planType,
    claimantsDetails,
    insuredDeath,
    claimRespectivetravel,
    travelDetails,
    dischargeDate,
    InsuredDeathplace,
    InsuredIllnessnature,
    witnessQuestion,
  } = watch();

  const currentLob = lobData?.data?.data?.lob;
  const logSlug = lobData?.data?.data?.lobslug;

  const coverTypeValue =
    (Array.isArray(coverType) &&
      coverType?.map((item) => {
        return item?.value;
      })) ||
    [];

  const dateRangeBasedOnSinglePlanType = planType === "Singel Transit";
  const dateRangeBasedOnAnnualPlanType = planType === "Annual Open ";

  const shouldShowMainClaimId =
    Array.isArray(expnessClaim) &&
    expnessClaim?.filter((item) => item?.value === "Post Hospitalisation")
      .length > 0;
  const shouldShowOtherCause = causeofLoss?.value === "Others";
  const getComprehensiveOrTp = (str) => {
    const lowerStr = str?.toLowerCase();
    return lowerStr?.includes("comprehensive") || lowerStr?.includes("tp");
  };
  function extractNumbersFromString(str) {
    // Regular expression to match one or more digits
    const regex = /\d+/g;
    const numbers = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      numbers.push(parseInt(match[0], 10));
    }
    return numbers;
  }

  const isComprehensiveOrTp = getComprehensiveOrTp(businessType?.value);
  const tpYears = extractNumbersFromString(businessType?.value)?.[
    extractNumbersFromString(businessType?.value)?.length - 1
  ];

  const getMinDateYrs = (dateToCompare, years) => {
    if (dateToCompare && years) {
      return new Date(
        moment(dateToCompare).add(years, "year").subtract(1, "day")
      );
    }
  };

  const isCompany =
    policyDetails?.typeofcustomer?.toLowerCase() === "company" ||
    lobData?.data?.data?.ownerPreference?.toLowerCase() === "company";
  console.log({ isCompany, policyDetails });
  const relationWithproposer =
    watchMembers?.[currentField?.index]?.relWithprop?.value;

  const startDateFromApi =
    summaryData[POLICY_DETAILS] && summaryData[POLICY_DETAILS]?.policystDate;

  const dobYesterDay = moment();
  const yesterday = moment(dobYesterDay).subtract(1, "days");
  const minDate =
    policystDate &&
    new Date(moment(policystDate).add(1, "year").subtract(1, "day")); // Min date for policy expiry date | policy details card
  const maxDateExp =
    policystDate &&
    new Date(moment(policystDate).add(3, "years").subtract(1, "day")); // Min date for policy expiry date | policy details card
  const minDate2 = policyStartdate && new Date(policyStartdate); // Min date for policy expiry date | prev policy details card
  const minDate3 = bookDate && new Date(bookDate); // Min date for policy start date
  const minDate4 =
    isWorkmensCompensationLob &&
    policystDate &&
    moment(policystDate).add(1, "month"); // Min date for policy exp date for workmensCompensation lob
  const maxDate4 =
    isWorkmensCompensationLob &&
    policystDate &&
    moment(policystDate).add(1, "year"); // Max date for policy exp date for workmensCompensation lob
  const pucStartMinDate = regDate && new Date(regDate); // Min date for puc start date
  const pucExpiryMinDate = pucStartdate && new Date(pucStartdate); // Min date for puc expiry date
  const maxDate = policystDate && new Date(policystDate);

  const today = moment();
  const birthDate = moment(nomineeDob, "YYYY-MM-DD");
  const isNomineeMinor = today.diff(birthDate, "years");
  const proposerDob = today.diff(dob, "years");
  const assuredDob = today.diff(lifeAssureddob, "years");
  const nomDob = today.diff(watchNominee?.[nestedIndex]?.nomineeDob, "years");
  const insuredDob = today.diff(watchMembers?.[nestedIndex]?.dobIMD, "years");
  const IsInsuredIllnessOthers = InsuredIllnessnature?.value === "Others";

  function formatDateDifference(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    // Calculate the total difference in years, months, and days
    const years = end.diff(start, "years");
    start.add(years, "years");

    const months = end.diff(start, "months");
    start.add(months, "months");

    const days = end.diff(start, "days");

    const formatted = `${String(years).padStart(2, "0")}y ${String(
      months
    ).padStart(2, "0")}m ${String(days).padStart(2, "0")}d`;

    return formatted;
  }
  function getYearlySpecificDates(date, years) {
    let startDate = moment(date);
    let datesArray = [];
    for (let i = 1; i <= years; i++) {
      // Calculate the date exactly one year, two years, and three years, etc from today
      let date = startDate.clone().add(i, "years").subtract(1, "days");
      datesArray.push(date.format("YYYY-MM-DD"));
    }
    return datesArray;
  }

  const getBmiCategory = (bmi) => {
    if (bmi > 1 && bmi < 18.5) {
      return "( Underweight )";
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      return "( Normal Weight )";
    } else if (bmi >= 25 && bmi <= 29.9) {
      return "( Overweight )";
    } else if (bmi >= 30) {
      return "( Obese )";
    } else {
      return "";
    }
  };

  const bmiVal = getBmiCategory(bmi);
  // Nested Multi Members
  const dynamicKey =
    nestedForm === "nestedMembers"
      ? `members.[${nestedIndex}].`
      : nestedForm === "nestedPayments"
      ? `payment.[${nestedIndex}].`
      : nestedForm === "nestedEmployees"
      ? `members.[${nestedIndex}].`
      : nestedForm === "nestedNominees"
      ? `nominee.[${nestedIndex}].`
      : nestedForm === "nestedLossDetails"
      ? `lossItemDetails.[${nestedIndex}].`
      : nestedForm === "nestedExpenses"
      ? `expenseDetails.[${nestedIndex}].`
      : nestedForm === "nestedOtherDocs"
      ? `otherDocs.[${nestedIndex}].`
      : nestedForm === "nestedCargo"
      ? `cargoItemDetails.[${nestedIndex}].`
      : nestedForm === "nestedAddons"
      ? `addOnPremium.[${nestedIndex}].`
      : nestedForm === "nestedClaimants"
      ? `claimantsDetails.[${nestedIndex}].`
      : nestedForm === "nestedTravel"
      ? `travelDetails.[${nestedIndex}].`
      : "";

  let newFields = [...fieldsArray];
  newFields.push(...selectdocumenttoUpload);

  return newFields?.map((item) => {
    const allFieldCommonProps = {
      id: `${dynamicKey}${item.key}`,
      label: item.label,
      isRequired: item?.mandatory === "Y",
      placeholder: `Enter ${item?.label}`,
      description: item.description,
      errors: errors,
      readOnly: item?.iseditable === "N",
      labelSize: "15px",
    };
    const inputFieldCommonProps = {
      minHeight: "58px",
      themeType: "theme1",
      name: `${dynamicKey}${item.key}`,
      maxLength: item.length,
      autoComplete: "none",
      inputRef: register(`${dynamicKey}${item.key}`),
      numberToWord: [
        "annualIncome",
        "coverage",
        "sumAssured",
        "maturityBenefit",
        "assuredAnnualincome",
        "insuredsumInsured",
        "totalAmount",
        "assuredDeath",
        "assuredMaturity",
        "benefitPayout",
        "estimateLoss",
        "claimAmount",
        "estimatedLoss",
        "originalCost",
        "replacementrepairCost",
        "amountClaimed",
      ].includes(item?.key)
        ? true
        : item?.category === "AMOUNT",
      watch: watch,
      claim: true,
      capitalize: true,
    };
    const dropdownAndDateCommonProps = {
      control: control,
      register: register,
      setValue: setValue,
      isClearable: true,
      claim: true,
    };
    const commonPropsForGrid = {
      item: true,
      sx: { position: "relative" },
      lg: 4,
      md: 4,
      sm: 6,
      xs: 6,
    };
    const masterDataPropsForDropdown = {
      masterTableData: masterTableData,
      manufacturerData: manufacturerData,
      modelData: modelData,
      versionData: versionData,
      fuelData: fuelData,
      branchData: branchData,
      locationData: locationData,
      userData: userData,
      icData: icData,
      businessType: businessType?.value,
      policyType: policyType?.value,
      lobName: lobData?.data?.data?.lob,
      watchMembers: watchMembers,
      watchEmployee: watchMembers,
      watchNominee: watchNominee,
      watchExpense: watchExpense,
    };
    const identityProof = idProof?.value?.toLowerCase();
    const addProof = addressProof?.value?.toLowerCase();
    if (
      [
        "Baggage Insurance Policy",
        "Money Insurance Policy",
        "Burglary Insurance Policy",
      ].includes(policyDetails?.policyType) &&
      [
        "discoveryofDefalcation", //mandatory
        "dateDefalcation", //mandatory
        "amountLoss",
        "defaultingEmployee",
        "detailDefalcation",
        "completeAddress",
        "sourcingPinCode",
        "sourcingState",
        "sourcingCity",
        "causedPerson",
        "sizeofDamagedglass",
        "replacementrepairCost",
        "plateGlassLoss",
      ]?.includes(item?.key) &&
      logSlug === MISCELLANEOUS_INSURANCE
    ) {
      return <></>;
    }
    if (
      ["Fidelity Insurance Policy"].includes(policyDetails?.policyType) &&
      [
        "causeofLoss",
        "otherCause",
        "dateofLoss",
        "descriptionofLoss",
        "causedPerson",
        "sizeofDamagedglass",
        "replacementrepairCost",
        "witnessQuestion",
        "descriptionWitness",
        "uploadWitness",
        "plateGlassLoss",
      ]?.includes(item?.key) &&
      logSlug === MISCELLANEOUS_INSURANCE
    ) {
      return <></>;
    }
    if (
      ["Plate Glass insurance Policy"].includes(policyDetails?.policyType) &&
      [
        "discoveryofDefalcation", //mandatory
        "dateDefalcation", //mandatory
        "amountLoss",
        "defaultingEmployee",
        "detailDefalcation",
        "completeAddress",
        "causedPerson",
        "sizeofDamagedglass",
        "replacementrepairCost",
      ]?.includes(item?.key) &&
      logSlug === MISCELLANEOUS_INSURANCE
    ) {
      return <></>;
    }
    // item.field = field_type from API
    if (item.field === "HIDDEN") {
      return null;
    } else {
      // new requirement to display both documents
      // if (pastInsurance?.label === "No" && item.key === "uploadPdf") {
      //   return <></>;
      // }
      if ("ckycId" === item?.key && isCompany) {
        return <></>;
      }
      if (
        witnessQuestion !== "Yes" &&
        ["descriptionWitness", "uploadWitness"].includes(item?.key)
      ) {
        return <></>;
      }
      if (
        !IsInsuredIllnessOthers &&
        ["othersNatureofIllness"]?.includes(item.key)
      ) {
        return <></>;
      }
      if (maritialStatus?.value !== "Married" && item?.key === "anniversary") {
        return <></>;
      }
      if (
        assuredmaritalStatus?.value !== "Married" &&
        item?.key === "assuredAnnivrsary"
      ) {
        return <></>;
      }
      if (
        (!payment?.[nestedIndex]?.paymentMode?.value &&
          item.key === "micrCode") ||
        (payment?.[nestedIndex]?.paymentMode?.value === "Online" &&
          ["chequeNo", "micrCode"].includes(item.key))
      ) {
        return <></>;
      }
      if (
        (!payment?.[nestedIndex]?.paymentMode?.value &&
          item.key === "chequeNo") ||
        (payment?.[nestedIndex]?.paymentMode?.value === "Online" &&
          item.key === "chequeNo") ||
        (payment?.[nestedIndex]?.paymentMode?.value === "Cash" &&
          item.key === "chequeNo")
      ) {
        return <></>;
      }
      if (
        (!payment?.[nestedIndex]?.paymentMode?.value && item.key === "ecs") ||
        (payment?.[nestedIndex]?.paymentMode?.value !== "Online" &&
          item.key === "ecs")
      ) {
        return <></>;
      }
      if (
        (!medicalHistory && item.key === "uploadDocument") ||
        (medicalHistory === "No" && item.key === "uploadDocument")
      ) {
        return <></>;
      }

      if (
        (watch(`members.[${nestedIndex}].medicalHistoryim`) !== "Yes" &&
          item.key === "uploadDocumentim" &&
          formTitle === INSURED_MEMBER_DETAILS) ||
        (medicalHistoryim !== "Yes" &&
          item.key === "uploadDocumentim" &&
          formTitle === LIFE_ASSURED_DETAILS)
      ) {
        return <></>;
      }
      if (
        !watch(`members.[${nestedIndex}].insuredeiaNumber`) &&
        item.key === "insuredeiaRepository" &&
        formTitle === INSURED_MEMBER_DETAILS
      ) {
        return <></>;
      }

      if (
        payment?.[nestedIndex]?.paymentMode?.value === "Cash" &&
        [
          "micrCode",
          "ifscCode",
          "banName",
          "bankBranchname",
          "accNumber",
          "accountType",
          "transactionId",
          "chequeNo",
        ].includes(item.key)
      ) {
        return <></>;
      }
      if (
        (!watchNominee?.[nestedIndex]?.nomineeDob &&
          item.key === "appointeeName") ||
        (nomDob >= 18 && item.key === "appointeeName")
      ) {
        return <></>;
      }
      if (
        companyFields?.includes(item.key) &&
        typeofcustomer?.label === "Individual" &&
        formTitle === PROPOSER_DETAILS
      ) {
        return <></>;
      }
      if (
        individualFields?.includes(item.key) &&
        typeofcustomer?.label === "Company" &&
        formTitle === PROPOSER_DETAILS
      ) {
        return <></>;
      }
      // if (
      //   grihaRakshaFields?.includes(item?.key) &&
      //   hideGrihaFields?.includes(policyDetailsPlanType)
      // ) {
      //   return <></>;
      // }
      // if (
      //   fieldsOtherThanGrihaRaksha?.includes(item?.key) &&
      //   policyDetailsPlanType === PLAN_TYPE_GRIHA_RAKSHA
      // ) {
      //   return <></>;
      // }
      if (
        formTitle === PI_DETAILS &&
        piIndividualFields?.includes(item?.key) &&
        !piIndividualFields
          .filter((field) => piCompanyFields.includes(field))
          ?.includes(item?.key) &&
        planTypeFromApi === "Company"
      ) {
        return <></>;
      }
      if (
        formTitle === PI_DETAILS &&
        piCompanyFields?.includes(item?.key) &&
        !piCompanyFields
          .filter((field) => piIndividualFields.includes(field))
          ?.includes(item?.key) &&
        planTypeFromApi === "Individual"
      ) {
        return <></>;
      }
      if (floaterCoverFields?.includes(item?.key) && noFloaterCover) {
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        (!isVehicleFinanced || isVehicleFinanced === "No") &&
        ["selectFinacer", "financerAggrementType", "financerCity"].includes(
          item.key
        )
      ) {
        return <></>;
      }
      if (
        isSameAddress === "Yes" &&
        ["addressLineonev", "pinCode", "state", "city"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !coverTypeValue?.includes("Property") &&
        formTitle === BASIC_DETAILS &&
        ["Buildingsum"]?.includes(item.key)
      ) {
        return <></>;
      }
      if (
        !coverTypeValue?.includes("Content") &&
        formTitle === BASIC_DETAILS &&
        ["contentsum"]?.includes(item.key)
      ) {
        return <></>;
      }
      if (
        (formTitle === VEHICLE_DETAILS || formTitle === PROPOSER_DETAILS) &&
        isSameAddress !== "No" &&
        [
          "addressLineonev",
          "addressLinetwov",
          "pincodeVd",
          "stateVd",
          "cityVd",
        ].includes(item.key)
      ) {
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        !fuelType?.label &&
        ["pucNumber", "pucStartdate", "pucExpiryDate", "kwh", "cc"].includes(
          item.key
        )
      ) {
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        fuelType?.label === "ELECTRIC" &&
        ["pucNumber", "pucStartdate", "pucExpiryDate", "cc"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        formTitle === VEHICLE_DETAILS &&
        fuelType?.label !== "ELECTRIC" &&
        ["kwh"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        isBusinessTypeConditionsLobWise &&
        ([
          TP_1_YEAR,
          TP_LONG_TERM_5_YEARS,
          TP_LONG_TERM_3_YEARS,
          SATP,
        ]?.includes(businessType?.label) ||
          (!businessType?.label &&
            [
              TP_1_YEAR,
              TP_LONG_TERM_5_YEARS,
              TP_LONG_TERM_3_YEARS,
              SATP,
            ]?.includes(policyDetailsBusinessType))) &&
        ["odexpirydate", "odPremium"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        (!["Personal Accident"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["Personal Accident"]?.includes(policyDetailsBusinessType))) &&
        ["deathbenefit", "temporaryDisablement"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        (!["Top-up and Super Top-up"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["Top-up and Super Top-up"]?.includes(
              policyDetailsBusinessType
            ))) &&
        ["deductible"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !idProof?.label &&
        ["identityvalue", "uploadIdentity"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !assuredidProof?.label &&
        ["identityvalueN", "uploadIdentitydoc"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !addressProof?.label &&
        ["addressvalue", "uploadAddress"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !assuredaddressProof?.label &&
        ["addressvalueN", "addressproofN"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !incomeProof?.label &&
        ["uploadIncome", "incomeValue"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        occupation?.label !== "Others" &&
        ["occupationN"].includes(item.key)
      ) {
        return <></>;
      }
      if (!assuredincomeProof?.label && ["uploadIncomeN"].includes(item.key)) {
        return <></>;
      }
      if (incomeProof?.label == "None" && ["uploadIncome"].includes(item.key)) {
        return <></>;
      }
      if (
        assuredincomeProof?.label == "None" &&
        ["uploadIncomeN"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        policyType?.label !== "Renewal" &&
        ["renewalNoticePdf"].includes(item.key)
      ) {
        return <></>;
      }
      // hide fields based on multi dropdown
      if (
        travelDetails?.[nestedIndex]?.hospitalization !== "Yes" &&
        ["nameofHospital", "durationinHospital"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        !["Portability", "Renewal"].includes(policyType?.label) &&
        ["inceptionDate"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        (!["HospiCash"]?.includes(businessType?.label) ||
          (!businessType?.label &&
            !["HospiCash"]?.includes(policyDetailsBusinessType))) &&
        ["hospitalDailylimit"].includes(item.key)
      ) {
        return <></>;
      }
      if (
        isBusinessTypeConditionsLobWise &&
        ["policyExpirydate"].includes(item.key)
      ) {
        return <></>;
      }
      if (!itSection?.label && ["certificate"].includes(item.key)) {
        return <></>;
      }
      if (!eiaNumber && ["eiaRepository"].includes(item.key)) {
        return <></>;
      }
      if (!assuredeiaNumber && ["assuredeiaRepository"].includes(item.key)) {
        return <></>;
      }
      if (!shouldShowMainClaimId && ["claimId"].includes(item.key)) {
        return <></>;
      }
      if (!shouldShowOtherCause && ["otherCause"].includes(item.key)) {
        return <></>;
      }
      if (
        currentLob !== "Travel" &&
        policyDetails?.businessType !== "Personal Accident" &&
        ["dateofAccident", "placeofAccident", "uploadPoliceFIR"].includes(
          item.key
        )
      ) {
        return <></>;
      }
      if (
        ["placeofDeath"].includes(item.key) &&
        InsuredDeathplace?.value !== "Others"
      ) {
        return <></>;
      }

      if (
        formTitle === PROPOSER_DETAILS &&
        addressCheck !== "No" &&
        [
          "addresspdone",
          "addresspdtwo",
          "pinCodetwo",
          "pincodetwo",
          "statetwo",
          "citytwo",
          "landmarkpdtwo",
        ].includes(item.key)
      ) {
        return <></>;
      } else if (
        ["firstName", "productName", "productNamepd", "payerName"].includes(
          item.key
        )
      ) {
        return (
          <Grid {...commonPropsForGrid}>
            <CustomDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              placeholder={`Select ${item?.label}`}
              onChange={(e) => {
                if (nestedForm) {
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
                }
                if (["firstName", "payerName"].includes(item.key)) {
                  preventSpecialAndNumbersInInput(e);
                }
              }}
              onFocus={() => {
                if (nestedForm) {
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
                }
              }}
              options={
                ["firstName", "payerName"].includes(item.key)
                  ? allCustomers
                  : allProducts
              }
              stateUpdaterFn={
                ["firstName", "payerName"].includes(item.key)
                  ? setSelectedCustomer
                  : setSelectedProduct
              }
              claim={true}
            />
          </Grid>
        );
      } else if (item.field === "CHAR") {
        return (
          <>
            <Grid
              item
              sx={{ position: "relative" }}
              lg={4}
              md={4}
              sm={6}
              xs={6}
            >
              <ToggleButton
                labelSize={"12px"}
                name={`${dynamicKey}${item.key}`}
                label={item.label}
                isRequired={item?.mandatory === "Y"}
                description={item.description}
                register={register}
                errors={errors}
                readOnly={item?.is_editable === "N"}
                row
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                // color="opposite"
                color="primary"
                claim={true}
                setValue={setValue}
              />
              {item?.key?.includes("compensationAct") && (
                <Description>{item.description}</Description>
              )}
            </Grid>
          </>
        );
      } else if (item.field === "DROPDOWN") {
        return (
          <Grid {...commonPropsForGrid}>
            <ProposalDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              {...masterDataPropsForDropdown}
              placeholder={`Select ${item?.label}`}
              filter={item.key}
              onChange={() => {
                nestedForm &&
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
              }}
              policyDetails={policyDetails}
              modeofTransportation={modeofTransportation}
            />
          </Grid>
        );
      } else if (item.field === "MULTISELECT") {
        return (
          <Grid {...commonPropsForGrid}>
            <ProposalDropdown
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              {...masterDataPropsForDropdown}
              placeholder={`Select ${item?.label}`}
              filter={item.key}
              onChange={() => {
                nestedForm &&
                  setCurrentField({
                    field: `${dynamicKey}${item.key}`,
                    index: nestedIndex,
                  });
              }}
              isMulti={true}
              modeofTransportation={modeofTransportation}
              removedValueKey={"removedValue"}
            />
          </Grid>
        );
      } else if (item.field === "DATE") {
        return (
          <Grid {...commonPropsForGrid}>
            <DatePickerInput
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              specificDates={
                ["policyExpirydate"].includes(item.key) &&
                currentLob === "Health"
                  ? getYearlySpecificDates(policystDate, 3)
                  : []
              }
              themeType={"theme1"}
              name={`${dynamicKey}${item.key}`}
              isRequired={
                isBusinessTypeConditionsLobWise &&
                ((![
                  TP_1_YEAR,
                  TP_LONG_TERM_5_YEARS,
                  TP_LONG_TERM_3_YEARS,
                  SATP,
                ].includes(businessType?.label) &&
                  item.key === "odexpirydate") ||
                  item.key === "tpexpirydate")
                  ? true
                  : item?.mandatory === "Y"
              }
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register(`${dynamicKey}${item.key}`)}
              testId="modal_input"
              minDate={
                item.key?.includes("datenTimeofFlight") && policyStartDate
                  ? new Date(moment(policyStartDate))
                  : currentLob === "Health" &&
                    item.key === "billDate" &&
                    policyStartDate
                  ? new Date(moment(policyStartDate))
                  : currentLob === "Term Life" &&
                    item.key === "insuredDeath" &&
                    policyStartDate
                  ? new Date(moment(policyStartDate))
                  : item.key === "InsuredIllnessDate" &&
                    lifeAssureddob &&
                    new Date(moment(lifeAssureddob))
                  ? dob && new Date(moment(dob))
                  : currentLob === "Health" &&
                    item.key === "dischargeDate" &&
                    hospitalDate
                  ? new Date(moment(hospitalDate))
                  : currentLob === "Health" &&
                    item.key === "dischargeDate" &&
                    policyStartDate
                  ? new Date(moment(policyStartDate))
                  : currentLob === "Health" &&
                    item.key === "hospitalDate" &&
                    policyStartDate
                  ? new Date(moment(policyStartDate))
                  : isWorkmensCompensationLob &&
                    ["policyExpirydate"].includes(item.key)
                  ? new Date(minDate4)
                  : (isCarLob || isBikeLob) &&
                    ["odexpirydate"]?.includes(item.key)
                  ? getMinDateYrs(policystDate, 1)
                  : isComprehensiveOrTp &&
                    (isCarLob || isBikeLob) &&
                    ["tpexpirydate"]?.includes(item.key)
                  ? new Date(
                      moment(policystDate)
                        .add(tpYears, "years")
                        .subtract(1, "day")
                    )
                  : !isComprehensiveOrTp &&
                    isCarLob &&
                    ["tpexpirydate"]?.includes(item.key)
                  ? getMinDateYrs(policystDate, 3)
                  : !isComprehensiveOrTp &&
                    isBikeLob &&
                    ["tpexpirydate"]?.includes(item.key)
                  ? getMinDateYrs(policystDate, 5)
                  : ["policyExpirydate"]?.includes(item.key) &&
                    businessType?.label &&
                    !["HospiCash"]?.includes(businessType?.label)
                  ? minDate
                  : ["policyExpirydate"]?.includes(item.key) &&
                    businessType?.label &&
                    ["HospiCash"]?.includes(businessType?.label)
                  ? new Date(moment(policystDate).add(1, "day"))
                  : ["policyExpirydate"]?.includes(item.key)
                  ? new Date(moment(policystDate).add(1, "day"))
                  : item.key === "policyExpirydatepd"
                  ? minDate2
                  : item.key === "policystDate"
                  ? minDate3
                  : item.key === "pucStartdate"
                  ? pucStartMinDate
                  : ["pucExpiryDate", "pucExpirydate"]?.includes(item.key)
                  ? pucExpiryMinDate
                  : item.key === "estimatedArrivaldate"
                  ? estimatedDeparturedate
                  : [
                      "dateofEmergency",
                      "dateofAdmission",
                      "dateofDischarge",
                    ].includes(item.key)
                  ? new Date(moment(policyStartDate))
                  : ""
              }
              maxDate={
                item.key?.includes("datenTimeofFlight") && policyExpiryDate
                  ? new Date(moment(policyExpiryDate))
                  : currentLob === "Health" &&
                    item.key === "billDate" &&
                    policyExpiryDate
                  ? new Date(moment(policyExpiryDate))
                  : // currentLob === "Term Life" &&
                  item.key === "InsuredIllnessDate" && insuredDeath
                  ? new Date(moment(insuredDeath))
                  : // currentLob === "Term Life" &&
                  item.key === "insuredDeath" && policyExpirydate
                  ? new Date(moment(policyExpirydate))
                  : currentLob === "Health" &&
                    item.key === "hospitalDate" &&
                    policyExpiryDate
                  ? new Date(moment(policyExpiryDate))
                  : currentLob === "Health" &&
                    item.key === "hospitalDate" &&
                    dischargeDate
                  ? new Date(moment(dischargeDate))
                  : currentLob === "Health" &&
                    item.key === "dischargeDate" &&
                    policyExpiryDate
                  ? new Date(moment(policyExpiryDate))
                  : isWorkmensCompensationLob &&
                    ["policyExpirydate"].includes(item.key)
                  ? new Date(maxDate4)
                  : ["policyExpirydate"].includes(item.key) &&
                    currentLob === "Health"
                  ? new Date(maxDateExp)
                  : ["policyStartdate", "policyExpirydatepd"].includes(item.key)
                  ? maxDate
                  : item.key === "policystDate" && currentLob === "Health"
                  ? new Date(moment(bookDate).add(6, "months"))
                  : item.key === "inceptionDate"
                  ? new Date(moment(policystDate))
                  : ["regDate"]?.includes(item.key) &&
                    formTitle === VEHICLE_DETAILS
                  ? new Date(
                      moment(startDateFromApi).isBefore(moment())
                        ? startDateFromApi
                        : moment()
                    )
                  : ["nomineeDob"]?.includes(item.key) &&
                    watch(`nominee.[${nestedIndex}].nomineeRelation`)?.label ===
                      "Spouse"
                  ? new Date(moment().subtract(18, "years"))
                  : (isCarLob || isBikeLob) &&
                    isComprehensiveOrTp &&
                    ["tpexpirydate"]?.includes(item.key)
                  ? new Date(
                      moment(policystDate)
                        .add(tpYears, "years")
                        .subtract(1, "day")
                    )
                  : ["nomineeDob", "regDate", "leadGendate"].includes(item.key)
                  ? new Date()
                  : ["dob"]?.includes(item.key) &&
                    ["Investment"].includes(currentLob)
                  ? new Date(moment().subtract(1, "year"))
                  : ["dob", "lifeAssureddob"]?.includes(item.key)
                  ? new Date(moment().subtract(18, "years"))
                  : ["dobIMD"]?.includes(item.key) &&
                    relationWithproposer === "Spouse"
                  ? new Date(moment().subtract(18, "years"))
                  : ["nomineeDob", "dobIMD"].includes(item.key)
                  ? yesterday._d
                  : ["paymentDate"].includes(item.key)
                  ? new Date(startDateFromApi)
                  : item.key === "estimatedDeparturedate"
                  ? estimatedArrivaldate
                  : item.key === "pucStartdate"
                  ? pucExpiryDate
                  : item.key === "bookDate"
                  ? new Date()
                  : item.key === "issuedate"
                  ? new Date()
                  : [
                      "dateofEmergency",
                      "dateofAdmission",
                      "dateofDischarge",
                    ].includes(item.key)
                  ? new Date(moment(policyExpiryDate))
                  : ""
              }
              // customView={item.key == "dob" ? ["year", "month"] : false}
              // customFormat={item.key == "dob" ? "MMM-yyyy" : false}
            />
            {["dob"].includes(item.key) && (
              <BmiCategory bmiValue={20}>
                {proposerDob ? proposerDob + " Years" : ""}
              </BmiCategory>
            )}
            {["dobIMD"].includes(item.key) && (
              <BmiCategory bmiValue={20}>
                {insuredDob ? insuredDob + " Years" : ""}
              </BmiCategory>
            )}
            {["lifeAssureddob"].includes(item.key) && (
              <BmiCategory bmiValue={20}>
                {assuredDob ? assuredDob + " Years" : ""}
              </BmiCategory>
            )}
            {["nomineeDob"].includes(item.key) && (
              <BmiCategory bmiValue={20}>
                {nomDob ? nomDob + " Years" : ""}
              </BmiCategory>
            )}
          </Grid>
        );
      } else if (item?.field === "DATETIME") {
        return (
          <Grid {...commonPropsForGrid}>
            <DateAndTimePicker
              {...allFieldCommonProps}
              {...dropdownAndDateCommonProps}
              label={`${item.label}`}
              name={`${dynamicKey}${item.key}`}
              id={`${dynamicKey}${item.key}`}
              // id="dateTime"
              placeholder={`Enter ${item.label}`}
              autoComplete="none"
              inputRef={register(`${dynamicKey}${item.key}`)}
              register={register}
              themeType={"theme1"}
              // minDateTime={moment().add(3, "days")}
              minDateTime={
                ["dateCircumstances"].includes(item?.key) && policyStartDate
                  ? moment(policyStartDate)
                  : typeofcustomer === "Individual" &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedDate)
                  : typeofcustomer === "Company" &&
                    dateRangeBasedOnSinglePlanType &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedDate)
                  : typeofcustomer === "Company" &&
                    dateRangeBasedOnAnnualPlanType &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedDate)
                  : currentLob === "Health" &&
                    item.key === "dischargeDate" &&
                    hospitalDate
                  ? moment(hospitalDate)
                  : [
                      "dischargeDate",
                      "hospitalDate",
                      "dateoflossDelay",
                      "datentimeofAccident",
                      "insuredDeath",
                      "datedamageNoted",
                      "dateTime",
                      "DateTimeWork",
                      "insuredDeath",
                      "dateofLoss",
                      "dateTimeLoss",
                    ]?.includes(item?.key) && policyStartDate
                  ? moment(policyStartDate)
                  : null
              }
              maxDateTime={
                ["dateCircumstances"].includes(item?.key) && policyExpiryDate
                  ? moment(policyExpiryDate)
                  : typeofcustomer === "Individual" &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedToDate)
                  : typeofcustomer === "Company" &&
                    dateRangeBasedOnAnnualPlanType &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedDateForCompanyAnnualPlanType)
                  : typeofcustomer === "Company" &&
                    dateRangeBasedOnSinglePlanType &&
                    ["datedamageNoted"].includes(item.key)
                  ? moment(formattedPolicyDateSinglePlanType)
                  : ["hospitalDate"]?.includes(item?.key) && dischargeDate
                  ? moment(dischargeDate)
                  : [
                      "dischargeDate",
                      "hospitalDate",
                      "dateoflossDelay",
                      "datentimeofAccident",
                      "insuredDeath",
                      "datedamageNoted",
                      "dateTime",
                      "DateTimeWork",
                      "insuredDeath",
                      "dateofLoss",
                      "dateTimeLoss",
                    ]?.includes(item?.key) && policyExpiryDate
                  ? moment(policyExpiryDate)
                  : null
              }
            />
          </Grid>
        );
      } else if (item.validation_group === "MOBILE") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={(e) => {
                verifyValidNumbers(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
          </Grid>
        );
      } else if (["ADHAAR", "AADHAR"].includes(item.validation_group)) {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={(e) => {
                verifyValidAadhar(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
          </Grid>
        );
      } else if (item?.field === "INT") {
        const isErrorForBMI =
          editCard?.[formTitle] && nestedForm === "nestedMembers"
            ? !!errors?.members?.[nestedIndex]?.[item.key]
            : nestedForm === "nestedPayments"
            ? !!errors?.payment?.[nestedIndex]?.[item.key]
            : !!errors?.[item.key];

        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              maxLength={
                item?.key === "coverage" &&
                policyDetailsPlanType === PLAN_TYPE_SOOKSHMA_UDHYAM
                  ? 8
                  : item?.key === "coverage" &&
                    policyDetailsPlanType === PLAN_TYPE_LAGHU_UDHYAM
                  ? 9
                  : item?.length
              }
              // numberToWord={item.key === "claimAmount"}
              required={false}
              onChange={(e) => {
                preventCharactersInInput(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
            {item.key === "bmiImd" && (
              <BmiCategory
                style={{
                  top: isErrorForBMI ? "78px" : "66px",
                  color: "green",
                }}
                bmiValue={getBmiCategory(
                  watch(`members.[${nestedIndex}].bmiImd`) || watch(`bmiImd`)
                )}
              >
                {getBmiCategory(
                  watch(`members.[${nestedIndex}].bmiImd`) || watch(`bmiImd`)
                )}
              </BmiCategory>
            )}
            {item.key === "totalDayspd" &&
              policyStartdate &&
              policyExpirydatepd && (
                <BmiCategory bmiValue={20}>
                  {formatDateDifference(
                    policyStartdate,
                    moment(policyExpirydatepd).add(1, "day")
                  )}
                </BmiCategory>
              )}
          </Grid>
        );
      } else if (item?.field === "FLOAT") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={(e) => {
                handleFloatInput(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
          </Grid>
        );
      } else if (item?.field === "PERCENTAGE") {
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={(e) => {
                allowPercentageNumbersUpto100(e);
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
          </Grid>
        );
      } else if (item?.field === "TEXTAREA") {
        return (
          <Grid sx={{ position: "relative" }} item lg={12}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
              onChange={(e) => {
                setValue(`${dynamicKey}${item.key}`, e.target.value);
              }}
            />
          </Grid>
        );
      } else if (item.field === "FILE") {
        // if (!editCard?.[formTitle]) {
        //   return null;
        // }
        return (
          <Grid {...commonPropsForGrid}>
            <FilePicker
              {...allFieldCommonProps}
              label={
                ["certificate"].includes(item?.key)
                  ? (itSection?.label || "") + " " + item.label
                  : ["otherDocu"].includes(item?.key)
                  ? watch(`otherDocs.[${nestedIndex}].documentName`) ||
                    item.label
                  : item.label
              }
              files={files}
              setFiles={setFiles}
              setValue={setValue}
              trigger={trigger}
              claim={true}
              control={control}
              selectdocumenttoUpload={selectdocumenttoUpload}
            />
          </Grid>
        );
      } else {
        if (
          ["ALPHANUMERIC", "VARCHAR", "TEXT", "IFSC"].includes(
            item?.validation_group
          )
        ) {
          return (
            <Grid {...commonPropsForGrid}>
              {!!customerRefId?.length &&
                ["customerRefId"].includes(item.key) && (
                  <Tooltip placement="top" arrow title="Remove Customer">
                    <CloseIcon
                      sx={{
                        color: "grey",
                        fontSize: "20px",
                        cursor: "pointer",
                        zIndex: 2,
                        position: "absolute",
                        top: "39px",
                        right: "8px",
                      }}
                      onClick={() => {
                        setValue("customerRefId", "");
                        clearCustomerData();
                        setSelectedCustomer(null);
                      }}
                    />
                  </Tooltip>
                )}
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                readOnly={
                  ["customerRefId"].includes(item.key) && !!leadId
                    ? true
                    : item?.iseditable === "N"
                }
                isRequired={
                  isBusinessTypeConditionsLobWise &&
                  ((![
                    TP_1_YEAR,
                    TP_LONG_TERM_5_YEARS,
                    TP_LONG_TERM_3_YEARS,
                    SATP,
                  ].includes(policyDetailsBusinessType) &&
                    item.key === "odPremium") ||
                    item.key === "tpPremium")
                    ? true
                    : item?.mandatory === "Y"
                }
                maxLength={
                  ["identityvalue"].includes(item?.key) &&
                  identityProof?.includes("aadhaar")
                    ? 12
                    : ["identityvalue"].includes(item?.key) &&
                      identityProof?.includes("pan card")
                    ? 10
                    : ["identityvalue"].includes(item?.key) &&
                      identityProof?.includes("voter")
                    ? 10
                    : ["identityvalue"].includes(item?.key) &&
                      identityProof?.includes("passport")
                    ? 9
                    : ["identityvalue"].includes(item?.key) &&
                      identityProof?.includes("driving")
                    ? 16
                    : ["addressvalue"].includes(item?.key) &&
                      addProof?.includes("aadhaar")
                    ? 12
                    : ["addressvalue"].includes(item?.key) &&
                      addProof?.includes("pan card")
                    ? 10
                    : ["addressvalue"].includes(item?.key) &&
                      addProof?.includes("voter")
                    ? 10
                    : ["addressvalue"].includes(item?.key) &&
                      addProof?.includes("passport")
                    ? 9
                    : ["addressvalue"].includes(item?.key) &&
                      addProof?.includes("driving")
                    ? 16
                    : item?.length
                }
                required={false}
                onChange={
                  ["premiumPayername", "intimatorName"].includes(item.key)
                    ? (e) => preventSpecialAndNumbersInInput(e)
                    : ["ifscCode", "nomineePanno"].includes(item.key)
                    ? (e) => {
                        preventSpecialInInput(e);
                        allCapitalize(e);
                      }
                    : [
                        "bankBranchname",
                        "vehicleRegNo",
                        "banName",
                        "relationId",
                        // "placeofAccident",
                      ].includes(item.key)
                    ? (e) => preventSpecialInInput(e)
                    : ["chassisNo", "engineNo", "passportNumber"].includes(
                        item.key
                      )
                    ? (e) => {
                        preventSpecialInInput(e);
                        allCapitalize(e);
                      }
                    : ["drivingLicence"].includes(item.key)
                    ? (e) => {
                        allCapitalize(e);
                        allowAlphanumericHyphenAndSpace(e);
                      }
                    : ["accNumber"].includes(item.key)
                    ? (e) => {
                        allowOnlyNumbers(e);
                      }
                    : [
                        "hospitalisationReason",
                        "hospitalName",
                        "driverName",
                        "claimantFirstname",
                        "claimantLastname",
                      ].includes(item.key)
                    ? (e) => {
                        allowOnlyAlphabetsWithSpace(e);
                      }
                    : null
                }
              />
              {item.label === "BMI" && (
                <BmiCategory bmiValue={bmi}>{bmiVal}</BmiCategory>
              )}
              {item.key === "policyPeriod" &&
                policystDate &&
                policyExpirydate && (
                  <BmiCategory bmiValue={20}>
                    {formatDateDifference(
                      policystDate,
                      moment(policyExpirydate).add(1, "day")
                    )}
                  </BmiCategory>
                )}
            </Grid>
          );
        } else if (item.validation_group === "EMAIL") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={(e) => {
                  verifyValidEmail(e);
                  preventWhiteSpaceInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
            </Grid>
          );
        } else if (item.validation_group === "ALPHABETIC") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={(e) => {
                  preventSpecialAndNumbersInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
            </Grid>
          );
        } else if (item.validation_group === "PINCODE") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                inputRef={register(`${dynamicKey}${item.key}`)}
                onChange={(e) => {
                  verifyValidPincode(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
            </Grid>
          );
        } else if (item.validation_group === "PAN") {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                required={false}
                onChange={(e) => {
                  verifyValidPAN(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
            </Grid>
          );
        } else if (["NUMERIC", "INT"].includes(item?.validation_group)) {
          return (
            <Grid {...commonPropsForGrid}>
              <Input
                {...allFieldCommonProps}
                {...inputFieldCommonProps}
                isRequired={
                  isBusinessTypeConditionsLobWise &&
                  ((![
                    TP_1_YEAR,
                    TP_LONG_TERM_5_YEARS,
                    TP_LONG_TERM_3_YEARS,
                    SATP,
                  ].includes(policyDetailsBusinessType) &&
                    item.key === "odPremium") ||
                    item.key === "tpPremium")
                    ? true
                    : item?.mandatory === "Y"
                }
                required={false}
                onChange={(e) => {
                  [
                    "totalAmount",
                    "basePremium",
                    "addOnpremium",
                    "netPremium",
                    "taxAmount",
                  ].includes(item.key)
                    ? allowOnlyNumbersAndDecimal(e)
                    : preventCharactersInInput(e);
                  setValue(`${dynamicKey}${item.key}`, e.target.value);
                }}
              />
            </Grid>
          );
        }
        return (
          <Grid {...commonPropsForGrid}>
            <Input
              {...allFieldCommonProps}
              {...inputFieldCommonProps}
              required={false}
            />
          </Grid>
        );
      }
    }
  });
};
