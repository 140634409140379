import CallMade from "@mui/icons-material/CallMade";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import _ from "lodash";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Button } from "../../components";

function CustomerFilePicker({
  label,
  name,
  control,
  watch,
  setValue,
  unregister,
  trigger,
  isReadOnly,
  message,
  isRequired,
  filePickerTitle,
}) {
  const [filePreview, setFilePreview] = useState([]);
  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    if (!_.isEmpty(rejectedFiles)) {
      Swal.fire({
        text:
          rejectedFiles?.[0]?.errors?.[0]?.code === "file-too-large"
            ? "File Size Larger than 2MB"
            : "Please provide .png or .jpeg file format",
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
      return;
    }
    setFilePreview(() =>
      acceptedFiles.map((file) => URL.createObjectURL(file))
    );
    const base64Promises = acceptedFiles.map(
      (file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            resolve({ file, base64String });
          };
          reader.readAsDataURL(file);
        })
    );

    const base64Files = await Promise.all(base64Promises);

    setValue(name, base64Files);
    trigger([name]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      // "image/png": [".png", "image/jpeg"],
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
    },
    maxSize: 1024 * 1024 * 2,
  });

  const handleRemove = (name) => {
    unregister(name);
    setValue(name, null);
  };

  function isValidURL(text) {
    //     const urlRegex = /^(https?|ftp|http)(:\/\/localhost:3000\/[a-f\d]{8}-(?:[a-f\d]{4}-){3}[a-f\d]{12}|:\/\/[^\s/$.?#].[^\s]*)$/i
    // ;
    //     return urlRegex.test(text);
    return !!text;
  }

  const handleRealFilePreview = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };
  return (
    <>
      <FilePickerContainerStyled readOnlyStyles={isReadOnly}>
        {!!label && (
          <HeadingStyled>
            {label}{" "}
            {isRequired ? <span className="required-label">*</span> : ""}
          </HeadingStyled>
        )}

        {!isReadOnly ? (
          <FilePickerAreaStyled>
            <Controller
              name={name}
              control={control}
              render={() => (
                <div {...getRootProps()} id="menu_file">
                  <input {...getInputProps()} />
                  <FileUploadOutlinedIcon />
                  <div>
                    {!!filePickerTitle ? (
                      <p>{filePickerTitle}</p>
                    ) : (
                      <p>
                        Drag your file here or <b>Browse</b>
                      </p>
                    )}
                    <p className="warnings">
                      Accepted File Formats: .png .jpeg
                    </p>
                    <p className="warnings">File Size Must be Less than 2MB</p>
                  </div>
                </div>
              )}
            />
          </FilePickerAreaStyled>
        ) : null}

        <FilePreviewStyled readOnlyStyles={isReadOnly}>
          {Array.isArray(watch(name)) && watch(name)?.length ? (
            <div class="preview_container">
              <div onClick={() => window.open(filePreview, "_blank")}>
                <p className="file_name">
                  {watch(name)?.[0]?.file?.name.length < 25
                    ? watch(name)?.[0]?.file?.name
                    : watch(name)?.[0]?.file?.name.substring(0, 25) + "..."}
                </p>
                <p>{Math.floor(watch(name)?.[0]?.file?.size / 1024)} KB</p>
              </div>
              <p className="cancel_file" onClick={() => handleRemove(name)}>
                X
              </p>
            </div>
          ) : isValidURL(watch(name)) ? (
            <>
              <RealPreviewStyled readOnlyStyles={isReadOnly}>
                <Button
                  bgColor="transparent"
                  textColor="#11555F"
                  id="add_broker"
                  variant="outlined"
                  borderColor="#11555F"
                  endIcon={<CallMade />}
                  onClick={() => handleRealFilePreview(watch(name))}
                >
                  Preview an Uploaded File
                </Button>
                {/* <span
                  style={{
                    position: "absolute",
                    top: "65px",
                    right: "3px",
                    cursor: "pointer",
                  }}
                >
                  <CloseOutlinedIcon onClick={() => handleRemove(name)} />
                </span> */}
                {!isReadOnly && (
                  <p
                    className="cancel_file_in_preview"
                    onClick={() => handleRemove(name)}
                  >
                    X
                  </p>
                )}
              </RealPreviewStyled>
            </>
          ) : (
            <></>
          )}
        </FilePreviewStyled>
      </FilePickerContainerStyled>
    </>
  );
}

export default CustomerFilePicker;

const FilePickerAreaStyled = styled.div`
  width: 50%;
  cursor: pointer;
`;

const FilePreviewStyled = styled.div`
  width: ${(props) => (props.readOnlyStyles ? "100%" : "50%")};
  // margin-top: -27px;
  .preview_container {
    position: relative;
    margin-right: 20px;
    border: 0.25px solid;
    border-radius: 5px;
    padding: 5px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-around;
      height: 33px;
      align-items: center;
      cursor: pointer;
    }

    & > * {
      margin: 0;
    }
    .cancel_file {
      background: #fff;
      position: absolute;
      top: -13px;
      left: 97%;
      border-radius: 50%;
      border: 0.25px solid;
      width: 25px;
      height: 25px;
      text-align: center;
      cursor: pointer;
    }
  }
`;

const RealPreviewStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) =>
    props.isInDatagrid || props.readOnlyStyles ? "0" : "30px"};
  margin-right: 20px;
  justify-content: flex-end;
  margin-left: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  position: relative;
  .cancel_file_in_preview {
    background: rgb(255, 255, 255);
    position: absolute;
    bottom: 10px;
    right: -11px;
    border-radius: 60%;
    border: 0.25px solid;
    width: 25px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }
`;

const FilePickerContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 2px dashed #e4e4e4;
  padding: 13px 0;
  margin-top: 5px;
  font-size: 1.25rem;
  margin-bottom: ${(props) => (props.readOnlyStyles ? "10px" : "0")};
  #menu_file {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      font-weight: 600;
      
      margin-left: 10px;
    }
    .warnings {
      color: red;
      font-size: 12px;
      margin-top: -10px;
    }
  }
  svg {
    color: #178e80;
    font-size: 2.5rem;
    margin-left: 10px;
  }
  b {
    color: rgb(23, 142, 128);
    text-decoration: underline;
  }
`;

const HeadingStyled = styled.h3`
  margin: 0 0 5px 14px;
  width: 100%;
  color: rgb(17, 85, 95);
  font-size: 14px;
  font-weight: 500;
  
  span {
    color: red;
    position: relative;
    left: 0px;
    top: -5px;
    font-size: 12px;
  }
`;
