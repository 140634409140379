import React, { useEffect, useState } from "react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { ProfilePic, SubTitleStyled, TitleStyled } from ".";
import DetailsCard from "./components/DetailsCard";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCustomerRecordsById, useUpdateCustomerDetails } from "./service";
import { profileFields, schema } from "./fields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DefaultProfileImage from "../../utils/profile_pic.jpg";

const EditProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetCustomerRecordsById({ id, fetchFamily: "N" });
  const [ProfileFields, setProfileFields] = useState(profileFields);
  const customerData = data?.data?.data?.[0];
  const [selectedFile, setSelectedFile] = useState();
  const [base64Image, setBase64Image] = useState("");
  // const [photoUploaded, setPhotoUploaded] = useState(false);
  const [hovered, setHovered] = useState(false); // State to track hover state
  const customerLogo = customerData?.imagePath;
  const [currentProfilePhoto, setCurrentProfilePhoto] = useState(customerLogo);
  useEffect(() => {
    let copy = [...ProfileFields];
    copy = copy.map((field) => {
      if (customerData) {
        Object.entries(customerData).forEach(([key, value]) => {
          if (key === field.name) {
            field = { ...field, value: value };
          }
        });
      }
      return field;
    });
    setProfileFields(copy);
  }, [customerData]);

  const {
    mutate: updateCustomer,
    isLoading,
    isSuccess,
  } = useUpdateCustomerDetails();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    watch,
  } = useForm({ resolver: yupResolver(schema), reValidateMode: "onChange" });

  function formatDateToISO(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const submit = (data) => {
    let updatedData = data["personal_details"];
    updatedData = { ...updatedData, imagePath: currentProfilePhoto };
    if (updatedData.dob) {
      updatedData.dob = formatDateToISO(new Date(updatedData.dob));
    }
    updateCustomer(
      { id: id, payload: updatedData },
      {
        onSuccess: () => {
          navigate("/my-profile");
        },
      }
    );

    // navigate('/my-profile')
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        if (base64Data) {
          // updateCustomer({ id: id, payload: { ...customerData, imagePath: base64Data } });
          setCurrentProfilePhoto(base64Data);
        }
        setBase64Image(base64Data);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeletePhoto = () => {
    // updateCustomer({ id: id, payload: { ...customerData, imagePath: null } });
    setSelectedFile(null);
    setBase64Image("");
    setCurrentProfilePhoto(null);
  };
  useEffect(() => {
    setCurrentProfilePhoto(customerLogo);
  }, [customerLogo]);
  return (
    <>
      <Grid
        container
        style={{ margin: "10px" }}
        component="form"
        onSubmit={handleSubmit(submit)}
      >
        <Grid item xs={12}>
          <TitleStyled color="gray">Edit Profile</TitleStyled>
        </Grid>
        <Grid item xs={12}>
          <SubTitleStyled>
            Edit Your Personal Info and Contact Details Here
          </SubTitleStyled>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            position="relative"
            marginTop={3}
          >
            <Grid
              item
              xs={12}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <ProfilePic
                src={
                  selectedFile
                    ? `data:image/jpeg;base64,${currentProfilePhoto}`
                    : currentProfilePhoto || DefaultProfileImage
                }
                style={{ margin: "20px auto" }}
                size="10rem"
              />
              {hovered && (
                <>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "60%",
                        left: "35%",
                        color: "#3085d6",
                      }}
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove" arrow>
                    <IconButton
                      disabled={!currentProfilePhoto}
                      style={{
                        position: "absolute",
                        top: "60%",
                        right: "35%",
                        color: "red",
                      }}
                      onClick={handleDeletePhoto}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <input
                accept="image/*"
                id="file-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          <DetailsCard
            register={register}
            Title={"Personal Details"}
            formKey={"personal_details"}
            fields={ProfileFields}
            isPersonalDetails={true}
            showDelete={false}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
            watch={watch}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "center", margin: "15px" }}
        >
          <Button variant="contained" type="submit">
            Update
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EditProfile;
