import styled from "styled-components";
import Error500Image from "../utils/error500.jpg";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./error500.css";
import { useGetServerStatus } from "./service";

function Error500() {
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const { data: serverStatusData } = useGetServerStatus(seconds);
  const pathname = window.history.state?.pathName || "/";
  const searchQuery = window.history.state?.search;

  useEffect(() => {
    const timer = setInterval(() => {
      // themeMutate();
      setSeconds(0);
    }, 5500);
    return () => clearInterval(timer);
  }, []);

  if (serverStatusData?.data?.status) {
    if (pathname && searchQuery) {
      navigate(`${pathname}${searchQuery}`);
    } else if (pathname && !searchQuery) {
      navigate(`${pathname}`);
    } else navigate("/");
  }

  useEffect(() => {
    // if (seconds > 0) {
    const timerId = setInterval(() => setSeconds(seconds + 20), 1000);
    return () => clearInterval(timerId);
    // }
  }, [seconds]);

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* <div class="maintenance">
        <div class="maintenance_contain">
          <img src="/under-maintenance.png" alt="maintenance" />
          <div class="pp-infobox-title-wrapper">
            <h3 class="pp-infobox-title">The website is under maintenance!</h3>
          </div>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              marginTop: "5px",
            }}
          >
            Retrying
            <Stack spacing={2} direction="row">
              <CircularProgressWithLabel
                variant="determinate"
                value={seconds}
              />
            </Stack>
          </div>
        </div>
      </div> */}
      <main
        style={{
          color: "rgba(255, 255, 255, 0.75)",
          background:
            "linear-gradient(to top, #1b1d1d, rgba(255, 255, 255, 0.75)",
          position: "relative",
          height: "100vh",
          zIndex: "-10",
        }}
      >
        <section class="advice">
          <h1 class="advice__title">Site under maintenance </h1>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              marginTop: "5px",
            }}
          >
            <span
              style={{ fontSize: "24px", color: "#000", fontWeight: "600" }}
            >
              Retrying
            </span>
            <Stack spacing={2} direction="row">
              <CircularProgressWithLabel
                style={{ color: "#00413f" }}
                value={seconds}
              />
            </Stack>
          </div>
        </section>
        <section class="city-stuff">
          <ul class="skyscrappers__list">
            <li class="skyscrapper__item skyscrapper-1"></li>
            <li class="skyscrapper__item skyscrapper-2"></li>
            <li class="skyscrapper__item skyscrapper-3"></li>
            <li class="skyscrapper__item skyscrapper-4"></li>
            <li class="skyscrapper__item skyscrapper-5"></li>
          </ul>
          <ul class="tree__container">
            <li class="tree__list">
              <ul class="tree__item tree-1">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-2">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-3">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-4">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-5">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-6">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-7">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
              <ul class="tree__item tree-8">
                <li class="tree__trunk"></li>
                <li class="tree__leaves"></li>
              </ul>
            </li>
          </ul>
          <ul class="crane__list crane-1">
            <li class="crane__item crane-cable crane-cable-1"></li>
            <li class="crane__item crane-cable crane-cable-2"></li>
            <li class="crane__item crane-cable crane-cable-3"></li>
            <li class="crane__item crane-stand"></li>
            <li class="crane__item crane-weight"></li>
            <li class="crane__item crane-cabin"></li>
            <li class="crane__item crane-arm"></li>
          </ul>
          <ul class="crane__list crane-2">
            <li class="crane__item crane-cable crane-cable-1"></li>
            <li class="crane__item crane-cable crane-cable-2"></li>
            <li class="crane__item crane-cable crane-cable-3"></li>
            <li class="crane__item crane-stand"></li>
            <li class="crane__item crane-weight"></li>
            <li class="crane__item crane-cabin"></li>
            <li class="crane__item crane-arm"></li>
          </ul>
          <ul class="crane__list crane-3">
            <li class="crane__item crane-cable crane-cable-1"></li>
            <li class="crane__item crane-cable crane-cable-2"></li>
            <li class="crane__item crane-cable crane-cable-3"></li>
            <li class="crane__item crane-stand"></li>
            <li class="crane__item crane-weight"></li>
            <li class="crane__item crane-cabin"></li>
            <li class="crane__item crane-arm"></li>
          </ul>
        </section>
      </main>
    </div>
  );
}

export default Error500;

const ErrorStyled = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  background-image: url(${Error500Image});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50%;
  text-align: center;
  // padding: 2rem;
`;
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
