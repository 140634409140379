import { useQuery } from "@tanstack/react-query";
import httpClient from "../api/httpClient";

export const useGetServerStatus = (seconds) => {
  const { data } = useQuery(
    ["health-check"],
    () => {
      return httpClient(`health-check`);
    },
    { enabled: seconds === 0 }
  );

  return { data };
};
