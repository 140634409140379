import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useParams } from "react-router-dom";
import httpClient from "../../api/httpClient";
import { payloadCheck } from "./check";

export const useGetMaster = ({
  master_name,
  columnFilters,
  solicitation_city_id,
  solicitation_state_id,
  solicitation_type,
  globalFilter,
  pageIndex,
  pageSize,
  sorting,
  customSearch,
  page,
  per_page,
  segmentId,
  enabled = true,
  pis_search,
  is_column,
}) => {
  const { segment_id, segment_slug } = useParams();

  const { data, isLoading, refetch, isError, isFetching, error } = useQuery(
    [
      "master",
      master_name,
      columnFilters,
      globalFilter,
      solicitation_city_id,
      solicitation_state_id,
      solicitation_type,
      pageIndex,
      pageSize,
      sorting,
      customSearch,
      segmentId || segment_id || 0,
      segment_slug,
      is_column,
    ],
    () => {
      return httpClient.post(`${master_name}/list`, {
        page_index: pageIndex + 1,
        page_size: pageSize,
        solicitation_city_id,
        solicitation_state_id,
        solicitation_type,
        custom_search: customSearch,
        pis_search,
        search: globalFilter || null,
        sorting: sorting?.length
          ? { id: sorting[0].id, value: sorting[0].desc ? "desc" : "asc" }
          : null,
        column_filters: columnFilters?.length ? columnFilters : null,
        is_column: is_column,
        segment_id: segmentId || segment_id,
        page,
        per_page,
        segment_slug,
      });
    },
    {
      enabled,
    }
  );

  return {
    total_records: data?.data?.total_records,
    table_list: data?.data?.return_data || [],
    column_head:
      data?.data?.column_head || error?.response?.data?.column_head || [],
    isTableLoading: isLoading,
    refetchTable: refetch,
    isTableError: isError,
    isTableFetching: isFetching,
    masterData: data,
    masterError: error,
  };
};

export const useDeleteItemMutation = (master_name) => {
  const queryClient = useQueryClient();
  const { mutate, isSuccess, reset, isError, error } = useMutation(
    ({ id, segmentId }) => {
      return httpClient.delete(`${master_name}/delete`, {
        [`${master_name}_id`]: id,
        segment_slug: segmentId,
      });
    },
    {
      onSuccess: () => {
        queryClient.resetQueries([master_name]);
      },
    }
  );
  return {
    deleteItem: mutate,
    deleteSuccess: isSuccess,
    deleteFail: isError,
    deleteError: error?.response?.data,
    deleteReset: reset,
  };
};
// To get column and row data
export const useGetDataForMasterTable = ({
  api,
  searchFormData,
  additionalPayloadFields,
  sort,
  pagination,
  payloadType,
  method,
  selectedLOB,
  customPayload = {},
}) => {
  const payload = {
    page: +pagination?.pageIndex + 1,
    size: +pagination?.pageSize || 10,
    sortBy: sort?.sortBy || "",
    sortOrder: sort?.sortOrder || "",
    ...((payloadType === "keyValue" && searchFormData) ||
      (payloadType === "keyValueSearchValueEmpty" && {
        searchValue: "",
        ...searchFormData,
      }) ||
      (payloadType === "keyValue" && searchFormData) ||
      (payloadType === "keyValueSearchValueEmptyArray" && {
        searchValue: [],
        ...searchFormData,
      }) ||
      (payloadType === "keyValueSearchValueArrayOfObject" && searchFormData) ||
      (payloadType === "searchValue" && {
        searchValue:
          (!_.isEmpty(searchFormData) && searchFormData?.searchValue) || "",
      }) ||
      (payloadType === "searchValueDto" && {
        searchValueDto:
          (!_.isEmpty(searchFormData) && searchFormData?.searchValueDto[0]) ||
          "",
        searchValue: [{}],
      }) ||
      (payloadType === "searchValueAndOuterPayload" && {
        searchValue:
          (!_.isEmpty(searchFormData) && searchFormData?.searchValue) || {},
        ...searchFormData?.searchValueOuterPayload,
      }) ||
      (payloadType === "searchProduct" && {
        searchProduct:
          (!_.isEmpty(searchFormData) && searchFormData?.searchProduct) || "",
      }) ||
      (payloadType === "searchValueFirstIndex" && {
        searchValue:
          (!_.isEmpty(searchFormData) && [searchFormData?.searchValue]) || "",
        ...searchFormData?.searchValueOuterPayload,
      }) ||
      (payloadType === "searchValueFirstIndexDefaultArrayOfObject" && {
        searchValue: (!_.isEmpty(searchFormData) && [
          searchFormData?.searchValue,
        ]) || [{}],
        ...searchFormData?.searchValueOuterPayload,
      }) ||
      (payloadType === "searchValueObject" && {
        searchValue: (!_.isEmpty(searchFormData) && searchFormData) || {},
      }) ||
      (payloadType === "searchParams" && {
        searchParams: (!_.isEmpty(searchFormData) && searchFormData) || {},
      }) ||
      (!payloadType && {
        searchValue: (!_.isEmpty(searchFormData) && searchFormData) || {},
      }) ||
      (payloadType === "searchValues" && {
        searchValues: (!_.isEmpty(searchFormData) && searchFormData) || {},
      }) || { ...searchFormData }),
    ...(additionalPayloadFields && additionalPayloadFields),
  };
  const isCustomPayload = !!Object.keys(customPayload)?.length;

  const { data, isFetching, isLoading } = useQuery({
    queryKey: [api, JSON.stringify(payload), JSON.stringify(customPayload)],
    queryFn: () =>
      httpClient(`${api}`, {
        method: method || "POST",
        data: isCustomPayload
          ? {
              ...customPayload,
              page: +pagination?.pageIndex + 1,
              size: +pagination?.pageSize || 10,
              sortBy: sort?.sortBy || "",
              sortOrder: sort?.sortOrder || "",
            }
          : payload,
      }),
  });

  return {
    tableRowData: data?.data?.data || [],
    tableColumnData: data?.data?.column_head || [],
    allData: data || {},
    isFetching,
    isLoading,
    totalRecords: data?.data?.totalRecords,
    moduleName: data?.data?.moduleName,
  };
};
