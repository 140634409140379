/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import {
  allCapitalize,
  allowAlphabetNumberSpace,
  allowLowerCase,
  allowOnlyAlphabetsNoSpace,
  allowOnlyAlphabetsNumberNoSpace,
  allowOnlyAlphabetsWithSpace,
  allowOnlyNumbers,
  handleDrivingLicense,
  preventFirstZero,
  preventWhiteSpaceInInput,
  verifyValidAadhar,
  verifyValidEmail,
  verifyValidNumbers,
  verifyValidPAN,
} from "../../../HelperFunctions/HelperFunctions";
import { Button, DatePickerInput, Input } from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import useGetMasterTableData from "../../../hooks/useGetMasterTableData";
// import { useGetLeadById, useGetUserTypes } from "../../lead/service";
import CustomerFilePicker from "../CustomerFilePicker";
import {
  useCreateMultipleCustomer,
  useGetCustomerReferredFromData,
  useGetDataFromCustomerId,
  useGetMaritialData,
  useGetNationalityFromData,
  useGetTitleFromData,
  useGetUserByIdForCustomer,
  useGetUserGroupData,
  useUpdateDataFromCustomerId,
} from "../service";
import { useRef } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import EditIcon from "@mui/icons-material/Edit";
import { useGetLeadById, useGetUserTypes } from "../../endorsement/leadService";
const CustomerAddForm = ({
  modalTitle,
  OccupationOBJ,
  createCustomer,
  GenderObj,
  setValue,
  trigger,
  handleSubmit,
  rowData,
  register,
  errors,
  control,
  educationObj,
  leadSourceObj,
  watch,
  cutomerId,
  unregister,
  stateCityData,
  parentIdOfFamilyMember,
  handleClose,
  relationOfFamilyMember,
}) => {
  const [memberCount, setMemberCount] = useState([1]);
  const memberRef = useRef(null);
  const { data: userGroupItems } = useGetUserGroupData();
  const { data: maritialDataItems } = useGetMaritialData();
  const { data: cutomerReferredDataItems } = useGetCustomerReferredFromData();
  const { data: titleDataItems } = useGetTitleFromData();
  const { data: nationalityDataItems } = useGetNationalityFromData();
  const { data: userTypesData } = useGetUserTypes();

  const { memberDetails } = watch();
  // const { data: userData } = useGetUserById({ userId: userType?.value });
  const { data: userData } = useGetUserByIdForCustomer({ memberDetails });

  // const shouldDisplayAnniversaryDate = maritial_status?.label === "Married";
  const shouldDisplayAnniversaryDate = memberCount?.map((v, i) => {
    return memberDetails?.[i]?.maritial_status?.value === "Married";
  });
  // const shouldSetMaleGender = title?.label === "Mr";
  const shouldSetMaleGender = memberCount?.map((v, i) => {
    return memberDetails?.[i]?.title?.value === "Mr";
  });
  const shouldSetFemaleGender = memberCount?.map((v, i) => {
    return ["Ms", "Mrs", "Miss", "Miss Universe"].includes(
      memberDetails?.[i]?.title?.value
    );
  });
  // const shouldSetOthers = title?.label === "Others";
  const { data: customerData } = useGetDataFromCustomerId(cutomerId);
  const { mutate, data: updateData } = useUpdateDataFromCustomerId(cutomerId);
  const { mutate: CreateMultipleCustomer } = useCreateMultipleCustomer();
  // const { mutate: getAdditionalDocDetails } = useAddAdditionalDetails({
  //   type: "addForm",
  // });

  const updateStatus = updateData?.data?.status;
  const customerDataToSet = customerData?.data?.data;
  const navigate = useNavigate();
  const { leadId } = useParams();
  const stateCityDataObj = memberCount?.map((v, i) => {
    return (
      _.isArray(stateCityData?.[i]?.data?.data?.data)
        ? stateCityData?.[i]?.data?.data?.data
        : []
    )?.map((item) => {
      return {
        state: item?.state?.stateName,
        city: item?.city?.cityName,
      };
    });
  });

  const userGroupObj = userGroupItems?.data?.data?.masterData?.map((value) => {
    return {
      value: value?.user_group,
      label: value?.user_group,
    };
  });

  const maritialDataObj = maritialDataItems?.data?.data?.masterData?.map(
    (value) => {
      return {
        value: value?.maritial_status,
        label: value?.maritial_status,
      };
    }
  );

  const cutomerRefferedDataObj =
    cutomerReferredDataItems?.data?.data?.masterData?.map((value) => {
      return {
        value: value?.lead_source,
        label: value?.lead_source,
      };
    });

  const titleDataObj = titleDataItems?.data?.data?.masterData?.map((value) => {
    return {
      value: value?.title,
      label: value?.title,
    };
  });
  const nationalityDataObj = nationalityDataItems?.data?.data?.masterData?.map(
    (value) => {
      return {
        value: value?.nationality,
        label: value?.nationality,
      };
    }
  );

  const location = useLocation();

  const isCustomerAddRoute = location.pathname === "/customer-add";
  const isCustomerUpdateRoute = location.pathname === "/customer-update";
  const isCustomerFamilyUpdateRoute =
    location.pathname === "/customer-family-lists";
  const isAddCustomerFamilyRoute = location.pathname === "/customer";
  const relationFeild = [
    { masterSlug: "relation_master", fieldSlug: "nomineeRelation" },
  ];
  const { data: relationData } = useGetMasterTableData(relationFeild);
  const RelationOBJ = relationData?.[0]?.data?.data?.[0]?.data?.map((item) => {
    return {
      value: item?.optionValue,
      label: item?.optionKey,
      id: item?.optionValue,
    };
  });

  const userTypesList = userTypesData?.data?.data?.map((item) => {
    return { label: item.userTypes, value: item.userTypeId };
  });

  const assignees = memberCount?.map((v, i) => {
    return (
      _.isArray(userData?.[i]?.data?.data?.data)
        ? userData?.[i]?.data?.data?.data
        : []
    )?.map((value) => {
      return {
        label: `${value?.first_name} ${value?.last_name}`,
        value: value?.user_id,
      };
    });
  });

  const [files, setFiles] = useState([]);

  // For family auto fetch section
  useEffect(() => {
    if (!isCustomerAddRoute) {
      //   setValue("email", rowData?.emailId);
      //   setValue("mobileNo", rowData?.mobile_number);
      //   setValue("alternateMobileNo", rowData?.alternateMobileNumber);
      //   setValue("alternateEmail", rowData?.alternateEmailId);

      //   setValue("businessChannel", {
      //     label: rowData?.businessChannel,
      //     value: rowData?.businessChannel,
      //   });
      //   setValue("address_line1", rowData?.adddressLine1);
      //   setValue("address_line2", rowData?.adddressLine2);
      //   setValue("user_group", {
      //     label: rowData?.user_group,
      //     value: rowData?.user_group,
      //   });
      //   // TODO: Waiting For API Response
      //   setValue("customer_referred_from", {
      //     label: rowData?.customerReferredFrom,
      //     value: rowData?.customerReferredFrom,
      //   });
      //   setValue("state", rowData?.state);
      //   setValue("city", rowData?.city);
      //   setValue("landmark", rowData?.landmark);

      memberCount?.forEach((index) => {
        setValue(`memberDetails[${index}].pincode`, rowData?.pinCode);
        setValue(`memberDetails[${index}].state`, rowData?.state);
        setValue(`memberDetails[${index}].city`, rowData?.city);
        setValue(`memberDetails[${index}].nationality`, {
          label: "Indian",
          value: "Indian",
        });
      });
    }
  }, [isCustomerAddRoute, rowData, memberCount?.length]);

  useEffect(() => {
    memberCount?.map((v, i) => {
      return _.isArray(shouldSetMaleGender) &&
        !_.isEmpty(shouldSetMaleGender) &&
        shouldSetMaleGender[i]
        ? setValue(`memberDetails[${i}].gender`, {
            id: "MALE",
            label: "Male",
            value: "Male",
          })
        : _.isArray(shouldSetFemaleGender) &&
          !_.isEmpty(shouldSetFemaleGender) &&
          shouldSetFemaleGender[i]
        ? setValue(`memberDetails[${i}].gender`, {
            id: "FEMALE",
            label: "Female",
            value: "Female",
          })
        : setValue(`memberDetails[${i}].gender`, null);
    });
  }, [
    JSON.stringify(shouldSetMaleGender),
    JSON.stringify(shouldSetFemaleGender),
  ]);

  useEffect(() => {
    memberCount?.forEach((i) => {
      setValue(
        `memberDetails[${i}].state`,
        _.isEmpty(stateCityDataObj[i]?.[0]?.state)
          ? rowData?.state
          : stateCityDataObj[i]?.[0]?.state
      );
      setValue(
        `memberDetails[${i}].city`,
        _.isEmpty(stateCityDataObj[i]?.[0]?.city)
          ? rowData?.city
          : stateCityDataObj[i]?.[0]?.city
      );
    });
  }, [JSON.stringify(stateCityDataObj)]);

  useMemo(() => {
    memberCount?.forEach((i) => {
      const dob = watch(`memberDetails[${i}].dob`);
      if (dob) {
        const dobDate = moment(dob);
        const age = moment().diff(dobDate, "years");
        setValue(`memberDetails[${i}].age`, age);
      } else {
        // Handle case where dob is not set
        setValue(`memberDetails[${i}].age`, "");
      }
    });
  }, [JSON.stringify(memberDetails?.map((detail) => detail?.dob))]);

  useEffect(() => {
    if (cutomerId && customerDataToSet) {
      memberCount?.forEach((index) => {
        setValue(
          `memberDetails[${index}].firstName`,
          customerDataToSet?.firstName
        );
        setValue(
          `memberDetails[${index}].middleName`,
          customerDataToSet?.middleName
        );
        setValue(
          `memberDetails[${index}].lastName`,
          customerDataToSet?.lastName
        );
        setValue(
          `memberDetails[${index}].mobileNo`,
          customerDataToSet?.mobileNumber
        );
        setValue(
          `memberDetails[${index}].alternateMobileNo`,
          customerDataToSet?.alternateMobileNumber
        );
        setValue(`memberDetails[${index}].email`, customerDataToSet?.emailId);
        setValue(
          `memberDetails[${index}].alternateEmail`,
          customerDataToSet?.alternateEmailId
        );
        setValue(
          `memberDetails[${index}].annualIncome`,
          customerDataToSet?.annualIncome
        );
        setValue(`memberDetails[${index}].age`, customerDataToSet?.age);

        setValue(
          `memberDetails[${index}].landmark`,
          customerDataToSet?.landmark
        );
        setValue(
          `memberDetails[${index}].aadharNo`,
          customerDataToSet?.aadharNumber
        );
        setValue(`memberDetails[${index}].panNo`, customerDataToSet?.panNumber);
        setValue(
          `memberDetails[${index}].driving_license`,
          customerDataToSet?.drivingLicience
            ? customerDataToSet?.drivingLicience
            : ""
        );
        setValue(
          `memberDetails[${index}].passport_number`,
          customerDataToSet?.passportNumber
            ? customerDataToSet?.passportNumber
            : ""
        );
        setValue(
          `memberDetails[${index}].designation`,
          customerDataToSet?.designation
        );
        setValue(
          `memberDetails[${index}].ckyc_ref_id`,
          customerDataToSet?.ckycRefId
        );
        setValue(
          `memberDetails[${index}].ucc_number`,
          customerDataToSet?.uccNumber
        );
        setValue(`memberDetails[${index}].remark`, customerDataToSet?.remark);
        setValue(`memberDetails[${index}].files`, customerDataToSet?.imagePath);
        setValue(`memberDetails[${index}].pincode`, customerDataToSet?.pinCode);
        setValue(
          `memberDetails[${index}].state`,
          rowData?.state || customerDataToSet?.state || leadData?.state
        );
        setValue(`memberDetails[${index}].city`, customerDataToSet?.city);
        setValue(
          `memberDetails[${index}].dob`,
          !_.isEmpty(customerDataToSet?.dob)
            ? new Date(customerDataToSet?.dob)
            : ""
        );
        setValue(
          `memberDetails[${index}].title`,
          !_.isEmpty(customerDataToSet?.title)
            ? {
                label: customerDataToSet?.title,
                value: customerDataToSet?.title,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].gender`,
          !_.isEmpty(customerDataToSet?.gender)
            ? {
                label: customerDataToSet?.gender,
                value: customerDataToSet?.gender,
                id: customerDataToSet?.gender,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].maritial_status`,
          !_.isEmpty(customerDataToSet?.maritialStatus)
            ? {
                label: customerDataToSet?.maritialStatus,
                value: customerDataToSet?.maritialStatus,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].anniversaryDate`,
          !_.isEmpty(customerDataToSet?.anniversaryDate)
            ? new Date(customerDataToSet?.anniversaryDate)
            : ""
        );
        setValue(
          `memberDetails[${index}].education`,
          !_.isEmpty(customerDataToSet?.education)
            ? {
                label: customerDataToSet?.education,
                value: customerDataToSet?.education,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].occupation`,
          !_.isEmpty(customerDataToSet?.occupation)
            ? {
                label: customerDataToSet?.occupation,
                value: customerDataToSet?.occupation,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].businessChannel`,
          !_.isEmpty(customerDataToSet?.businessChannel)
            ? {
                label: customerDataToSet?.businessChannel,
                value: customerDataToSet?.businessChannel,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].customer_referred_from`,
          !_.isEmpty(customerDataToSet?.customerReferredFrom)
            ? {
                label: customerDataToSet?.customerReferredFrom,
                value: customerDataToSet?.customerReferredFrom,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].user_group`,
          !_.isEmpty(customerDataToSet?.userGroup)
            ? {
                label: customerDataToSet?.userGroup,
                value: customerDataToSet?.userGroup,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].nationality`,
          !_.isEmpty(customerDataToSet?.nationality)
            ? {
                label: customerDataToSet?.nationality,
                value: customerDataToSet?.nationality,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].address_line1`,
          customerDataToSet?.adddressLine1
        );
        setValue(
          `memberDetails[${index}].address_line2`,
          customerDataToSet?.adddressLine2
        );
        setValue(
          `memberDetails[${index}].imagePath`,
          customerDataToSet?.imagePath
        );

        // Label and Value Mismatch Hence dependent on ModalTitle
        setValue(
          `memberDetails[${index}].userType`,
          customerDataToSet?.userTypes && customerDataToSet?.userTypesId
            ? modalTitle === "View"
              ? {
                  value: customerDataToSet?.userTypes,
                  label: customerDataToSet?.userTypesId,
                }
              : {
                  label: customerDataToSet?.userTypes,
                  value: customerDataToSet?.userTypesId,
                }
            : null
        );

        setValue(
          `memberDetails[${index}].userAssignTo`,
          customerDataToSet?.assignedToName && customerDataToSet?.assignedTo
            ? modalTitle === "View"
              ? {
                  label: customerDataToSet?.assignedTo,
                  value: customerDataToSet?.assignedToName,
                }
              : {
                  value: customerDataToSet?.assignedTo,
                  label: customerDataToSet?.assignedToName,
                }
            : null
        );
      });
    }
  }, [customerDataToSet, cutomerId]);
  //modal logic
  useEffect(() => {
    if (updateStatus === false) {
      Swal.fire({
        text: updateData?.data?.message,
        icon: "warning",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        allowOutsideClick: false,
        focusCancel: false,
        scrollbarPadding: false,
      });
    }
  }, [updateStatus]);

  useEffect(() => {
    if (updateStatus && isCustomerFamilyUpdateRoute) {
      Swal.fire({
        text: updateData?.data?.message,
        // text: "Checking after updateStatus success",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result?.isConfirmed) {
          handleClose();
        }
      });
    } else if (updateStatus) {
      Swal.fire({
        text: updateData?.data?.message,
        // text: "Checking after updateStatus success",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#DC004E",
        reverseButtons: true,
        focusConfirm: false,
        focusCancel: false,
        scrollbarPadding: false,
      }).then((result) => {
        if (result?.isConfirmed) {
          navigate("/customer");
        }
      });
    }
  }, [updateStatus]);

  // used for auto filling lead data in user form
  const { data: leadDataById } = useGetLeadById(leadId);
  const leadData = leadDataById?.data?.data;
  useEffect(() => {
    if (leadId && leadData) {
      memberCount?.forEach((index) => {
        setValue(`memberDetails[${index}].firstName`, leadData?.firstName);
        setValue(`memberDetails[${index}].middleName`, leadData?.middleName);
        setValue(`memberDetails[${index}].lastName`, leadData?.lastName);
        setValue(`memberDetails[${index}].mobileNo`, leadData?.mobileNo);
        setValue(
          `memberDetails[${index}].alternateMobileNo`,
          leadData?.alternateMobileNumber
        );
        setValue(`memberDetails[${index}].email`, leadData?.emailId || null);
        setValue(
          `memberDetails[${index}].alternateEmail`,
          leadData?.alternateEmailId
        );
        setValue(
          `memberDetails[${index}].annualIncome`,
          leadData?.annualincome
        );
        setValue(`memberDetails[${index}].age`, leadData?.age);
        setValue(`memberDetails[${index}].pincode`, leadData?.pincode);
        setValue(`memberDetails[${index}].landmark`, leadData?.landmark);
        setValue(`memberDetails[${index}].aadharNo`, leadData?.aadharNumber);
        setValue(`memberDetails[${index}].panNo`, leadData?.panNumber);
        setValue(
          `memberDetails[${index}].driving_license`,
          leadData?.drivingLicience ? leadData?.drivingLicience : ""
        );
        setValue(
          `memberDetails[${index}].passport_number`,
          leadData?.passportNumber ? leadData?.passportNumber : ""
        );
        setValue(`memberDetails[${index}].designation`, leadData?.designation);
        setValue(`memberDetails[${index}].ckyc_ref_id`, leadData?.ckycRefId);
        setValue(`memberDetails[${index}].ucc_number`, leadData?.uccNumber);
        setValue(`memberDetails[${index}].remark`, leadData?.remark);
        setValue(`memberDetails[${index}].files`, leadData?.imagePath);
        setValue(
          `memberDetails[${index}].state`,
          rowData?.state || customerDataToSet?.state || leadData?.state
        );
        setValue(`memberDetails[${index}].city`, leadData?.city);
        setValue(
          `memberDetails[${index}].dob`,
          !_.isEmpty(leadData?.dob) ? new Date(leadData?.dob) : null
        );
        setValue(
          `memberDetails[${index}].title`,
          !_.isEmpty(leadData?.title)
            ? {
                label: leadData?.title,
                value: leadData?.title,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].gender`,
          !_.isEmpty(leadData?.gender)
            ? {
                label: leadData?.gender,
                value: leadData?.gender,
                id: leadData?.gender,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].maritial_status`,
          !_.isEmpty(leadData?.maritialStatus)
            ? {
                label: leadData?.maritialStatus,
                value: leadData?.maritialStatus,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].anniversaryDate`,
          !_.isEmpty(leadData?.anniversaryDate)
            ? new Date(leadData?.anniversaryDate)
            : ""
        );
        setValue(
          `memberDetails[${index}].education`,
          !_.isEmpty(leadData?.education)
            ? {
                label: leadData?.education,
                value: leadData?.education,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].occupation`,
          !_.isEmpty(leadData?.occupation)
            ? {
                label: leadData?.occupation,
                value: leadData?.occupation,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].businessChannel`,
          !_.isEmpty(leadData?.source)
            ? {
                label: leadData?.source,
                value: leadData?.source,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].customer_referred_from`,
          !_.isEmpty(leadData?.customerReferredFrom)
            ? {
                label: leadData?.customerReferredFrom,
                value: leadData?.customerReferredFrom,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].user_group`,
          !_.isEmpty(leadData?.userGroup)
            ? {
                label: leadData?.userGroup,
                value: leadData?.userGroup,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].nationality`,
          !_.isEmpty(leadData?.nationality)
            ? {
                label: leadData?.nationality,
                value: leadData?.nationality,
              }
            : null
        );
        setValue(
          `memberDetails[${index}].address_line1`,
          leadData?.addressLine1
        );
        setValue(
          `memberDetails[${index}].address_line2`,
          leadData?.addressLine2
        );
        setValue(`memberDetails[${index}].imagePath`, leadData?.imagePath);
        setValue(
          `memberDetails[${index}].userType`,
          leadData?.userTypes && leadData?.userTypeId
            ? modalTitle === "View"
              ? {
                  value: leadData?.userTypes,
                  label: leadData?.userTypeId,
                }
              : {
                  label: leadData?.userTypes,
                  value: leadData?.userTypeId,
                }
            : null
        );

        setValue(
          `memberDetails[${index}].userAssignTo`,
          leadData?.assignedToFirstName && leadData?.assignedTo
            ? modalTitle === "View"
              ? {
                  label: leadData?.assignedTo,
                  value:
                    leadData?.assignedToFirstName +
                    `${
                      leadData?.assignedToLastName
                        ? " " + leadData?.assignedToLastName
                        : ""
                    }`,
                }
              : {
                  value: leadData?.assignedTo,
                  label:
                    leadData?.assignedToFirstName +
                    `${
                      leadData?.assignedToLastName
                        ? " " + leadData?.assignedToLastName
                        : ""
                    }`,
                }
            : null
        );
      });
    }
  }, [leadId, JSON.stringify(leadData)]);

  const onSubmit = async () => {
    const payload = memberDetails
      ?.filter((formData, index) => index < memberCount?.length)
      .map((formData) => ({
        firstName: formData?.firstName,
        middleName: formData?.middleName,
        lastName: formData?.lastName,
        fullName: `${formData?.firstName}${
          formData?.middleName ? ` ${formData?.middleName}` : ""
        }${formData?.lastName ? ` ${formData?.lastName}` : ""}`,
        emailId: formData?.email,
        alternateEmailId: formData?.alternateEmail,
        mobile_number: formData?.mobileNo,
        alternateMobileNumber: formData?.alternateMobileNo,
        gender: formData?.gender?.id,
        dob: formData?.dob,
        anniversaryDate: formData?.anniversaryDate,
        education: formData?.education?.label,
        occupation: formData?.occupation?.value,
        designation: formData?.designation,
        annualIncome: formData?.annualIncome,
        panNumber: formData?.panNo,
        aadharNumber: formData?.aadharNo,
        uccNumber: formData?.ucc_number,
        businessChannel: formData?.businessChannel?.value,
        pinCode: formData?.pincode,
        city: formData?.city,
        state: formData?.state,
        remark: formData?.remark,
        relation: isCustomerAddRoute
          ? "Self"
          : formData?.relation?.id || relationOfFamilyMember,
        parentId: rowData?.id || parentIdOfFamilyMember,
        imagePath: formData?.imagePath?.[0]?.base64String
          ? formData?.imagePath?.[0]?.base64String
          : formData?.imagePath
          ? formData?.imagePath
          : null,
        hasChild: isCustomerAddRoute ? "Y" : "N",
        isParent: isCustomerAddRoute ? "Y" : "N",
        address_line1: formData?.address_line1,
        address_line2: formData?.address_line2,
        age: formData?.age,
        ckycRefId: formData?.ckyc_ref_id,
        ucc_number: formData?.ucc_number,
        driving_license: formData?.driving_license,
        passport_number: formData?.passport_number,
        nationality: formData?.nationality?.value,
        maritial_status: formData?.maritial_status?.value,
        customer_referred_from: formData?.customer_referred_from?.value,
        title: formData?.title?.value,
        hni_customer: formData?.hni_customer?.value,
        userGroup: formData?.user_group?.value,
        landmark: formData?.landmark,
        status: "Y",
        leadRefId: leadId,
        userTypes: formData?.userType?.label,
        userTypesId: formData?.userType?.value,
        assignedTo: formData?.userAssignTo?.value,
        assignedToName: formData?.userAssignTo?.label,
      }));

    if (!leadId) {
      delete payload?.leadRefId;
    }

    if (!!cutomerId) {
      mutate(payload[0]);
      // const data =
      // if (data?.success === true) {
      //   const customerId = data?.data?.data?.customerId;

      //   if (uploadedDocuments?.length > 0) {
      //     uploadedDocuments.forEach((element) => {
      //       element.customerId = customerId;
      //     });
      //     getAdditionalDocDetails(uploadedDocuments);
      //   }
      // }
    } else if (isCustomerAddRoute) {
      createCustomer(payload[0]);
    } else {
      CreateMultipleCustomer(payload);
      // const data =
      // if (data?.success === true) {
      //   const customerId = data?.data?.data?.customerId;

      //   if (uploadedDocuments?.length > 0) {
      //     uploadedDocuments.forEach((element) => {
      //       element.customerId = customerId;
      //     });
      //     getAdditionalDocDetails(uploadedDocuments);
      //   }
      // }
    }
  };

  // useEffect(() => {
  //   const state = data?.data?.data[0]?.state?.stateName;
  //   const city = data?.data?.data[0]?.city?.cityName;

  //   const prefilledState =
  //     leadData?.state || customerDataToSet?.state || rowData?.state;
  //   const prefilledCity =
  //     leadData?.city || customerDataToSet?.city || rowData?.city;

  //   if (pincode?.toString()?.length === 6) {
  //     if (!_.isEmpty(state) || !_.isEmpty(prefilledState)) {
  //       setValue("state", !_.isEmpty(state) ? state : prefilledState);
  //       trigger("state");
  //     }
  //     if (!_.isEmpty(city) || !_.isEmpty(prefilledCity)) {
  //       setValue("city", !_.isEmpty(city) ? city : prefilledCity);
  //       trigger("city");
  //     }
  //   } else {
  //     setValue("state", "");
  //     setValue("city", "");
  //   }
  // }, [data, pincode]);

  const dobYesterDay = moment();
  const yesterday = moment(dobYesterDay).subtract(1, "days");

  const handleAddFamilyMember = (index) => {
    let flag = true;
    memberCount?.forEach((v, index) => {
      flag = !!(
        // watch(`memberDetails[${index}].businessChannel`) &&
        // watch(`memberDetails[${index}].user_group`) &&
        (
          watch(`memberDetails[${index}].firstName`) &&
          watch(`memberDetails[${index}].dob`) &&
          // watch(`memberDetails[${index}].age`) + "" &&
          watch(`memberDetails[${index}].gender`) &&
          watch(`memberDetails[${index}].email`) &&
          // watch(`memberDetails[${index}].address_line1`) &&
          // watch(`memberDetails[${index}].pincode`) &&
          // watch(`memberDetails[${index}].state`) &&
          // watch(`memberDetails[${index}].city`) &&
          // watch(`memberDetails[${index}].nationality`) &&
          watch(`memberDetails[${index}].mobileNo`)
        )
      );
    });

    trigger();
    if (flag) {
      setMemberCount((prev) => [...prev, index + 2]);
    }
  };

  return (
    <InputFrame ref={memberRef} onSubmit={handleSubmit(onSubmit)}>
      {memberCount?.map((v, index) => (
        <Fragment key={index}>
          <StyledContainerGrid
            container
            rowSpacing={1}
            modalTitle={modalTitle}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ padding: "23px 20px" }}
          >
            {isCustomerAddRoute || cutomerId || leadId ? null : (
              <Grid item xs={10} md={3} sm={10} lg={12}>
                <StyledH1
                  themeType="theme1"
                  partialU
                  color={"rgb(23, 142, 128)"}
                  align={"left"}
                >
                  {(rowData?.firstName &&
                    memberDetails?.[index]?.relation &&
                    `${rowData?.firstName}'s  ${memberDetails?.[index]?.relation?.value} `) ||
                    `Family Member ${index + 1}`}
                </StyledH1>
              </Grid>
            )}
            {isCustomerAddRoute || cutomerId || leadId ? null : (
              <Grid
                item
                xs={10}
                md={3}
                sm={10}
                lg={modalTitle === undefined ? 3 : 4}
              >
                <Dropdown
                  id={`memberDetails[${index}].relation`}
                  label={`Relation with ${rowData?.firstName}`}
                  control={control}
                  selectObj={RelationOBJ}
                  isMulti={false}
                  maxMenuHeight={200}
                  errors={errors}
                  isRequired={modalTitle !== "View"}
                  proposalRead={modalTitle === "View"}
                  watch={watch}
                />
              </Grid>
            )}
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                // id="customer_referred_from"
                id={`memberDetails[${index}].customer_referred_from`}
                label="Customer Referred From"
                control={control}
                selectObj={cutomerRefferedDataObj}
                isMulti={false}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                placeholder="Select"
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                themeType={"theme1"}
                label={"Sourcing Channel"}
                // id={"businessChannel"}
                id={`memberDetails[${index}].businessChannel`}
                control={control}
                selectObj={leadSourceObj}
                isMulti={false}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                // isRequired={modalTitle !== "View"}
                placeholder="Select"
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                // id="user_group"
                id={`memberDetails[${index}].user_group`}
                label="Customer Type"
                control={control}
                selectObj={userGroupObj}
                isMulti={false}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                // isRequired={modalTitle !== "View"}
                placeholder="Select"
                errors={errors}
                watch={watch}
              />
            </Grid>
            {/* <Grid
          item
        
          xs={10}
          md={3}
          sm={10}
          lg={modalTitle === undefined ? 3 : 4}
        >
          <Dropdown
            id="hni_customer"
            label="HNI Customer"
            control={control}
            selectObj={hniCustomerObj}
            isMulti={false}
            maxMenuHeight={200}
            proposalRead={modalTitle==="View"}
            isRequired={true}
          />
          {!!errors?.hni_customer && (
            <Error top="3px" left="3px">
              {errors.hni_customer.message}
            </Error>
          )}
        </Grid> */}
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                // id="title"
                id={`memberDetails[${index}].title`}
                label="Title"
                control={control}
                selectObj={titleDataObj}
                isMulti={false}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                isClearable={true}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                label="User Type"
                id={`memberDetails[${index}].userType`}
                control={control}
                selectObj={userTypesList}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                readOnly={modalTitle === "View"}
                // isRequired={modalTitle === "View" ? false : true}
                // errors={errors}
                watch={watch}
                onChange={() => [
                  setValue(`memberDetails[${index}].userAssignTo`, null),
                ]}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                label="Assignee To"
                id={`memberDetails[${index}].userAssignTo`}
                control={control}
                selectObj={assignees?.[index] || []}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                watch={watch}
                // isRequired={modalTitle === "View" ? false : true}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"First Name"}
                name={`memberDetails[${index}].firstName`}
                id={`memberDetails[${index}].firstName`}
                onChange={(e) => allowOnlyAlphabetsNoSpace(e)}
                maxLength="100"
                placeholder={`Enter First Name`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].firstName`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                isRequired={modalTitle === "View" ? false : true}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Middle Name"}
                name={`memberDetails[${index}].middleName`}
                id={`memberDetails[${index}].middleName`}
                onChange={(e) => allowOnlyAlphabetsNoSpace(e)}
                maxLength="100"
                placeholder={`Enter Middle Name`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].middleName`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Last Name"}
                name={`memberDetails[${index}].lastName`}
                id={`memberDetails[${index}].lastName`}
                onChange={(e) => allowOnlyAlphabetsNoSpace(e)}
                maxLength="100"
                placeholder={`Enter Last Name`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].lastName`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                isRequired={modalTitle === "View" ? false : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Mobile No"}
                name={`memberDetails[${index}].mobileNo`}
                id={`memberDetails[${index}].mobileNo`}
                onChange={(e) => verifyValidNumbers(e)}
                maxLength="10"
                placeholder={`Enter Mobile No`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].mobileNo`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                isRequired={modalTitle === "View" ? false : true}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Alternate Mobile No"}
                name={`memberDetails[${index}].alternateMobileNo`}
                id={`memberDetails[${index}].alternateMobileNo`}
                onChange={(e) => verifyValidNumbers(e)}
                maxLength="10"
                placeholder={`Enter Alternate Mobile No`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].alternateMobileNo`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Email"}
                name={`memberDetails[${index}].email`}
                id={`memberDetails[${index}].email`}
                maxLength="100"
                placeholder={`Enter Email`}
                autoComplete="none"
                defaultValue={""}
                onChange={(e) => {
                  verifyValidEmail(e);
                  preventWhiteSpaceInInput(e);
                  allowLowerCase(e);
                }}
                required={false}
                inputRef={register(`memberDetails[${index}].email`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                isRequired={modalTitle !== "View"}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Alternate Email"}
                name={`memberDetails[${index}].alternateEmail`}
                id={`memberDetails[${index}].alternateEmail`}
                maxLength="100"
                placeholder={`Enter Alternate Email`}
                autoComplete="none"
                defaultValue={""}
                onChange={(e) => {
                  verifyValidEmail(e);
                  preventWhiteSpaceInInput(e);
                  allowLowerCase(e);
                }}
                required={false}
                inputRef={register(`memberDetails[${index}].alternateEmail`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Annual Income"}
                name={`memberDetails[${index}].annualIncome`}
                id={`memberDetails[${index}].annualIncome`}
                maxLength="100"
                placeholder={`Enter Annual Income`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                onChange={(e) => [allowOnlyNumbers(e), preventFirstZero(e)]}
                inputRef={register(`memberDetails[${index}].annualIncome`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                watch={watch}
              />
            </Grid>

            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <DatePickerInput
                themeType="theme1"
                label="Date Of Birth"
                name={`memberDetails[${index}].dob`}
                id={`memberDetails[${index}].dob`}
                control={control}
                maxDate={yesterday._d}
                placeholder="Enter Date Of Birth"
                isRequired={modalTitle === "View" ? false : true}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            {/* age field */}
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Age"}
                name={`memberDetails[${index}].age`}
                id={`memberDetails[${index}].age`}
                onChange={(e) => allowOnlyNumbers(e)}
                maxLength="3"
                placeholder={`Enter Age`}
                autoComplete="none"
                defaultValue={""}
                // required={false}
                // value={
                //   watch(`memberDetails[${index}].dob`)
                //     ? new Date().getFullYear() -
                //         new Date(
                //           watch(`memberDetails[${index}].dob`)
                //         ).getFullYear() !==
                //       0
                //       ? new Date().getFullYear() -
                //         new Date(
                //           watch(`memberDetails[${index}].dob`)
                //         ).getFullYear()
                //       : ""
                //     : ""
                // }
                disabled={true}
                inputRef={register(`memberDetails[${index}].age`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                // isRequired={modalTitle === "View" ? false : true}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                id={`memberDetails[${index}].gender`}
                label="Gender"
                control={control}
                selectObj={GenderObj}
                isMulti={false}
                isRequired={modalTitle !== "View"}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                id={`memberDetails[${index}].maritial_status`}
                label="Maritial Status"
                control={control}
                selectObj={maritialDataObj}
                isMulti={false}
                maxMenuHeight={200}
                errors={errors}
                proposalRead={modalTitle === "View"}
                watch={watch}
              />
            </Grid>
            {shouldDisplayAnniversaryDate[index] && (
              <Grid
                item
                xs={10}
                md={3}
                sm={10}
                lg={modalTitle === undefined ? 3 : 4}
              >
                <DatePickerInput
                  themeType="theme1"
                  label="Anniversary Date"
                  name={`memberDetails[${index}].anniversaryDate`}
                  id={`memberDetails[${index}].anniversaryDate`}
                  control={control}
                  // maxDate={moment().subtract(1, "days")}
                  placeholder="Enter Anniversary Date"
                  proposalRead={modalTitle === "View" ? true : false}
                  readOnly={modalTitle === "View" ? true : false}
                  isRequired={modalTitle !== "View"}
                  errors={errors}
                  watch={watch}
                />
              </Grid>
            )}
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                themeType={"theme1"}
                id={`memberDetails[${index}].education`}
                label={`Education`}
                control={control}
                selectObj={educationObj}
                isMulti={false}
                maxMenuHeight={200}
                errors={errors}
                proposalRead={modalTitle === "View"}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                id={`memberDetails[${index}].occupation`}
                label="Occupation"
                control={control}
                selectObj={OccupationOBJ}
                isMulti={false}
                maxMenuHeight={200}
                errors={errors}
                proposalRead={modalTitle === "View"}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Address line 1"}
                name={`memberDetails[${index}].address_line1`}
                id={`memberDetails[${index}].address_line1`}
                maxLength="50"
                placeholder={`Enter Address`}
                autoComplete="none"
                defaultValue={""}
                // isRequired={modalTitle !== "View"}
                inputRef={register(`memberDetails[${index}].address_line1`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Address line 2"}
                name={`memberDetails[${index}].address_line2`}
                id={`memberDetails[${index}].address_line2`}
                maxLength="50"
                placeholder={`Enter Address`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].address_line2`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid item xs={10} md={3} sm={10} lg={!!modalTitle ? 4 : 3}>
              <Input
                themeType={"theme1"}
                label={"Pincode"}
                name={`memberDetails[${index}].pincode`}
                id={`memberDetails[${index}].pincode`}
                maxLength="6"
                placeholder={`Enter Pincode`}
                autoComplete="none"
                // value={pincode}
                onChange={(e) => allowOnlyNumbers(e)}
                // isRequired={modalTitle !== "View"}
                inputRef={register(`memberDetails[${index}].pincode`)}
                errors={errors}
                watch={watch}
                proposalRead={modalTitle === "View"}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"State"}
                name={`memberDetails[${index}].state`}
                id={`memberDetails[${index}].state`}
                // value={
                //   !_.isEmpty(stateCityDataObj?.[index]?.[0]?.state)
                //     ? stateCityDataObj?.[index]?.[0]?.state
                //     : ""
                // }
                maxLength="100"
                placeholder={`Enter State`}
                readOnly={true}
                onChange={(e) => allowOnlyAlphabetsWithSpace(e)}
                autoComplete="none"
                // isRequired={modalTitle !== "View"}
                inputRef={register(`memberDetails[${index}].state`)}
                errors={errors}
                watch={watch}
                proposalRead={modalTitle === "View"}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"City"}
                name={`memberDetails[${index}].city`}
                id={`memberDetails[${index}].city`}
                maxLength="100"
                // value={
                //   !_.isEmpty(stateCityDataObj?.[index]?.[0]?.city)
                //     ? stateCityDataObj?.[index]?.[0]?.city
                //     : ""
                // }
                onChange={(e) => allowOnlyAlphabetsWithSpace(e)}
                placeholder={`Enter City`}
                autoComplete="none"
                // isRequired={modalTitle !== "View"}
                readOnly={false}
                inputRef={register(`memberDetails[${index}].city`)}
                errors={errors}
                watch={watch}
                proposalRead={modalTitle === "View"}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Dropdown
                id={`memberDetails[${index}].nationality`}
                label="Nationality"
                control={control}
                selectObj={nationalityDataObj}
                isMulti={false}
                maxMenuHeight={200}
                proposalRead={modalTitle === "View"}
                // isRequired={modalTitle !== "View"}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Landmark"}
                name={`memberDetails[${index}].landmark`}
                id={`memberDetails[${index}].landmark`}
                maxLength="50"
                placeholder={`Enter Landmark`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].landmark`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Aadhar Number"}
                name={`memberDetails[${index}].aadharNo`}
                id={`memberDetails[${index}].aadharNo`}
                maxLength="12"
                placeholder={`Enter Aadhar Number`}
                autoComplete="none"
                defaultValue={""}
                onChange={(e) => verifyValidAadhar(e)}
                required={false}
                inputRef={register(`memberDetails[${index}].aadharNo`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Pan Number"}
                name={`memberDetails[${index}].panNo`}
                id={`memberDetails[${index}].panNo`}
                maxLength="10"
                placeholder={`Enter Pan Number`}
                autoComplete="none"
                defaultValue={""}
                onChange={(e) => verifyValidPAN(e)}
                required={false}
                inputRef={register(`memberDetails[${index}].panNo`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Driving License"}
                name={`memberDetails[${index}].driving_license`}
                id={`memberDetails[${index}].driving_license`}
                onChange={(e) => {
                  allCapitalize(e);
                }}
                onKeyDown={handleDrivingLicense}
                disableOnPaste={true}
                maxLength="16"
                placeholder={`Enter driving license`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].driving_license`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Passport Number"}
                name={`memberDetails[${index}].passport_number`}
                id={`memberDetails[${index}].passport_number`}
                onChange={(e) => {
                  allowAlphabetNumberSpace(e);
                  allCapitalize(e);
                }}
                maxLength="8"
                placeholder={`Enter Passport Number`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].passport_number`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>

            {/* {#3992 is Raised for this to be commented}  */}
            {/* <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Designation"}
                name={`memberDetails[${index}].designation`}
                id={`memberDetails[${index}].designation`}
                maxLength="100"
                placeholder={`Enter Designation`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].designation`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid> */}

            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"CKYC Ref Id"}
                name={`memberDetails[${index}].ckyc_ref_id`}
                id={`memberDetails[${index}].ckyc_ref_id`}
                maxLength="14"
                placeholder={`Enter CKYC Ref Id`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].ckyc_ref_id`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                watch={watch}
                onChange={allowOnlyNumbers}
              />
            </Grid>
            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"UCC Number"}
                name={`memberDetails[${index}].ucc_number`}
                id={`memberDetails[${index}].ucc_number`}
                maxLength="50"
                placeholder={`Enter UCC number`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].ucc_number`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
                onChange={allowOnlyAlphabetsNumberNoSpace}
              />
            </Grid>

            <Grid
              item
              xs={10}
              md={3}
              sm={10}
              lg={modalTitle === undefined ? 3 : 4}
            >
              <Input
                themeType={"theme1"}
                label={"Remark"}
                name={`memberDetails[${index}].remark`}
                id={`memberDetails[${index}].remark`}
                maxLength="500"
                placeholder={`Enter Remark`}
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register(`memberDetails[${index}].remark`)}
                proposalRead={modalTitle === "View" ? true : false}
                readOnly={modalTitle === "View" ? true : false}
                errors={errors}
                watch={watch}
              />
            </Grid>
          </StyledContainerGrid>

          <div
            style={{
              margin: "0px 20px",
              // ...(index !== memberCount.length - 1 && {
              //   borderBottom: "1px solid black",
              //   paddingBottom: "10px",
              // }),
            }}
          >
            <Grid item xs={12} sm={12} md={3} lg={6}>
              <CustomerFilePicker
                // name="imagePath"
                name={`memberDetails[${index}].imagePath`}
                // label="Upload Logo"
                control={control}
                files={files}
                setFiles={setFiles}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                unregister={unregister}
                message={true}
                filePickerTitle={"Add Customer Photo"}
                isReadOnly={modalTitle === "View"}
              />
            </Grid>
          </div>

          {modalTitle !== "View" &&
            isAddCustomerFamilyRoute &&
            index === memberCount?.length - 1 && (
              <>
                <ButtonContainerCustomer>
                  <Button
                    // type="submit"
                    onClick={() => handleAddFamilyMember(index)}
                    btnBack={"secondary"}
                    bgColor={"#11555F"}
                    themeType={"theme1"}
                  >
                    <PersonAddIcon />
                    <span style={{ marginLeft: "5px" }}>
                      Add a New Family Member
                    </span>
                  </Button>
                  {index !== 0 && (
                    <Button
                      // type="submit"
                      onClick={() => {
                        const updatedArray = [...memberCount];
                        updatedArray.splice(index, 1); // Remove 1 element at the specified index
                        setMemberCount(updatedArray);
                        // const aboveComponentHeight =
                        //   memberRef.current.scrollHeight - 1318;

                        // Not able to scroll to second form if the third form is deleted.
                        memberRef.current?.scrollIntoView({
                          // block: "nearest",
                          behavior: "smooth",
                        });
                      }}
                      btnBack={"secondary"}
                      bgColor={"#7c0000"}
                      themeType={"theme1"}
                    >
                      <PersonRemoveIcon />
                      <span style={{ marginLeft: "5px" }}>Remove Member</span>
                    </Button>
                  )}
                </ButtonContainerCustomer>
              </>
            )}
        </Fragment>
      ))}
      {modalTitle !== "View" ? (
        <ButtonFrame modalTitle={modalTitle}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            className="button_container"
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <NextButtonWrap>
                <Button
                  type="submit"
                  btnBack={"secondary"}
                  bgColor={"#11555F"}
                  themeType={"theme1"}
                >
                  {/* {modalTitle === "Modify" ? "Update Customer" : "Add Customer"} */}
                  {!!cutomerId && <EditIcon />}
                  {!!cutomerId
                    ? "Update"
                    : !!modalTitle
                    ? `Submit ${
                        memberCount.length > 1
                          ? "(" + memberCount.length + ")"
                          : ""
                      }`
                    : "Create Customer"}
                </Button>
              </NextButtonWrap>
            </Grid>
          </Grid>
        </ButtonFrame>
      ) : (
        <></>
      )}
    </InputFrame>
  );
};

export default CustomerAddForm;

const InputFrame = styled.form`
  width: 100%;
  // height: 475px;
  // overflow-y: auto;
`;
const StyledContainerGrid = styled(Grid)``;

const NextButtonWrap = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

const ButtonFrame = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  margin-top: 0;
  background: #fff;
  z-index: 10;
  padding: 12px 18px 12px 0px;
`;

export const StyledH1 = styled.h1`
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
  font-size: 24px;
  margin: 0;
  line-height: 36px;
  width: 100%;
  font-weight: 600;
  color: rgb(23, 142, 128);
  text-align: left;
`;

export const ButtonContainerCustomer = styled.div`
  // border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 20px;
  padding-bottom: 10px;
`;
