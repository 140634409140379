import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Chip,
  Drawer,
  Skeleton,
  Grid,
  IconButton,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import styled from "styled-components";
import ClaimProcess from "./Raise-Claim/ClaimProcess";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useCheckIfPolicyIsPresentOrNot } from "./Raise-Claim/service";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import GlobalModal from "../../components/Modal/modal";
import { convertCamelCaseToNormal } from "../../HelperFunctions/HelperFunctions";
import {
  useGetCertificateDataById,
  useGetCertificateListingBySearchValues,
} from "../policyGroupCertificates/service";
import CertificatesContent from "../policyGroupCertificates/CertificatesContent";
import Swal from "sweetalert2";
import { fetchCertificateSuccess } from "./certificateSlice";
import { useDispatch } from "react-redux";
import { fetchPolicyDetailsSuccess } from "./policyDetails.slice";

const Container = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ffffff;
  margin-bottom: 15px;
  border-radius: 14px;
`;

const ChipContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;
  width: 62%;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledChip = styled(Chip)`
  background-color: #f6f2ff !important;
  border-radius: 8px !important;
  font-family: ${({ theme }) => theme?.regularFont || "Manrope"} !important;
`;

const StyledChipForTitle = styled.div`
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  background: #ffffff;
  border: 0.8px solid #d9d9d9;
  font-size: 13px;
  font-weight: 700;
  line-height: 19.12px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const StyledButton = styled.button`
  padding: 6px 14px;
  gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme?.ascentColor};
  box-shadow: 0px 0px 10px 0px #00000033;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #ffffff;
  cursor: pointer;
`;

const PolicyDetails = ({ data }) => {
  return (
    <Grid container spacing={3}>
      {Object.entries(data).map(([key, value]) => (
        <Grid item xs={6} md={4} key={key}>
          <Box margin={1}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
              }}
            >
              {convertCamelCaseToNormal(key)}:
            </Typography>
          </Box>
          <Box margin={1}>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "18px",
                color: "#666666",
              }}
            >
              {value ? value : "NA"}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const ClaimBasicDetail = () => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const chipContainerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lob");
  const transactionIdParams = queryParams.get("TransactionId");
  const payloadObject = {
    lobId,
    transactionIdParams,
  };
  const { data: policyDetails, isLoading } =
    useCheckIfPolicyIsPresentOrNot(payloadObject);
  const policyDetailsData = policyDetails?.data?.[0]?.claim_basic;
  dispatch(fetchPolicyDetailsSuccess(policyDetailsData));

  useEffect(() => {
    const updateScrollState = () => {
      const { scrollLeft, scrollWidth, clientWidth } = chipContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    };

    const container = chipContainerRef.current;
    container.addEventListener("scroll", updateScrollState);
    updateScrollState();

    return () => {
      container.removeEventListener("scroll", updateScrollState);
    };
  }, []);

  useEffect(() => {
    const updateScrollState = () => {
      const { scrollLeft, scrollWidth, clientWidth } = chipContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    };

    if (chipContainerRef.current) {
      updateScrollState();
    }
  }, [
    chipContainerRef.current?.scrollWidth,
    chipContainerRef.current?.clientWidth,
  ]); // Update on container size change

  const handleScrollRight = () => {
    if (chipContainerRef.current) {
      chipContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleScrollLeft = () => {
    if (chipContainerRef.current) {
      chipContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };
  const isMarineLob = policyDetailsData?.Lob === "Marine";
  const policyNumber = queryParams.get("policyNo");
  // const policyNumber = policyDetailsData?.["Policy Number"];
  const isCreateClaim = location.pathname === "/raise-claims";
  const [showCertificates, setShowCertificates] = useState(false);
  const selectedCertificate = queryParams.get("certificateId");

  const { data: certificateApiData } =
    useGetCertificateDataById(selectedCertificate);
  const certificateData = certificateApiData?.data?.data || {};
  dispatch(fetchCertificateSuccess(certificateData));
  const {
    certificateNumber,
    certificatePeriod,
    certificatePolicyExpiryDate,
    certificatePolicyStartDate,
    certificateSumInsured,
    cargoSumInsured,
  } = certificateData;

  const filteredCertificateData = {
    certificateNumber,
    certificatePeriod,
    cargoSumInsured,
  };
  const { data: certificateListing, mutate: fetchCertificates } =
    useGetCertificateListingBySearchValues();
  const totalCertificates = certificateListing?.data?.totalRecords;
  useEffect(() => {
    if (isCreateClaim) {
      setShowCertificates(
        isMarineLob && totalCertificates && !selectedCertificate
      );
      // setShowCertificates(isMarineLob);
    }
  }, [isMarineLob, isCreateClaim, totalCertificates]);
  useEffect(() => {
    if (policyNumber) {
      fetchCertificates({ policyNumber });
    }
  }, [policyNumber]);
  return (
    <>
      <Container theme={theme}>
        <StyledChipForTitle>Policy Details:</StyledChipForTitle>
        <IconButton
          style={{
            border: "1px solid grey",
            borderRadius: "20px",
            padding: "4px",
            height: "22px",
            width: "22px",
          }}
          onClick={handleScrollLeft}
          disabled={!canScrollLeft}
        >
          <ArrowBackIosNewRoundedIcon
            style={{ height: "18px", width: "18px" }}
          />
        </IconButton>
        <ChipContainer ref={chipContainerRef}>
          {isLoading
            ? Array.from(new Array(8)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={100}
                  height={32}
                />
              ))
            : policyDetailsData &&
              Object.entries(policyDetailsData)?.map(([key, value]) => (
                <StyledChip key={key} label={`${key}: ${value}`} />
              ))}
        </ChipContainer>
        <IconButton
          style={{
            border: "1px solid grey",
            borderRadius: "20px",
            padding: "4px",
            height: "22px",
            width: "22px",
          }}
          onClick={handleScrollRight}
          disabled={!canScrollRight}
        >
          <ArrowForwardIosRoundedIcon
            style={{ height: "18px", width: "18px" }}
          />
        </IconButton>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <StyledButton
            variant="contained"
            size="small"
            onClick={() => setDrawerOpen(true)}
          >
            View all
          </StyledButton>
          {isMarineLob && isCreateClaim && (
            <Tooltip title={totalCertificates ? "" : "No Certificates"}>
              <StyledButton
                variant="contained"
                size="small"
                onClick={() => setShowCertificates(true)}
                disabled={!totalCertificates}
              >
                View Certificates
              </StyledButton>
            </Tooltip>
          )}
        </div>
      </Container>
      <Container>
        <ClaimProcess isBasicDetailsLoading={isLoading} />
      </Container>
      <GlobalModal
        title={"Policy Details"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box style={{ padding: "14px" }}>
          {policyDetailsData && (
            <PolicyDetails
              data={{
                ...policyDetailsData,
                ...(isMarineLob && filteredCertificateData),
              }}
            />
          )}
        </Box>
      </GlobalModal>
      <Drawer
        open={showCertificates}
        anchor="right"
        PaperProps={{ sx: { width: "60%" } }}
      >
        <CertificatesContent
          onClose={(selectedCertificate, showWarning = true) => {
            {
              showWarning &&
                Swal.fire({
                  icon: "warning",
                  title: "Warning",
                  text: `This claim request will be raised on ${
                    selectedCertificate ? "Certificate" : "Master Policy"
                  }. `,
                });
            }
            setShowCertificates(false);
          }}
        />
      </Drawer>
    </>
  );
};

export default ClaimBasicDetail;
