import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export const useCheckPolicy = (urlData) => {
  const navigate = useNavigate();
  const { data, mutate } = useMutation(
    (payload) =>
      httpClient(`common_master/policy_check`, {
        method: "POST",
        data: payload,
      }),
    {
      onSuccess: (res) => {
        if (!res?.data?.status) {
          Swal.fire({
            icon: res?.data?.errortype,
            text: res?.data?.message,
            confirmButtonText: "OK",
            confirmButtonColor: "#11555F",
          });
        }
        if (res?.success) {
          navigate(
            `/policy-group-certificates?policyNum=${urlData?.policyNumber}&lobId=${urlData?.lobId}&transactionId=${res?.data?.data?.transaction_id}`
          );
        }
      },
    }
  );
  return { data, mutate };
};

export const useGetPolicyData = (lob, policyNum, transactionId) => {
  const { data, mutate } = useMutation((payload) =>
    httpClient(
      `transaction_summary/list/${lob}?id${transactionId}&type=policy`,
      {
        method: "POST",
        data: payload,
      }
    )
  );
  return { data, mutate };
};

export const useAddPolicyGroupCertificates = () => {
  const navigate = useNavigate();
  const { data, mutate } = useMutation(
    (payload) =>
      httpClient(`group_policy_certificates/add`, {
        method: "POST",
        data: payload,
      }),
    {
      onSuccess: (res) => {
        console.log("res", res);
        Swal.fire({
          icon: res?.alertType,
          text: res?.message,
        });
        if (res?.success) {
          navigate("/policy-group-certificates-list");
        }
      },
    }
  );
  return { data, mutate };
};

export const useGetPaymentModeData = (lobId) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["paymentMode", lobId],
    queryFn: () =>
      httpClient(`get_data/payment_mode_master/paymentMode?lobId=${lobId}`),
    enabled: !!lobId,
  });

  return { data, error, isLoading };
};

export const useGetShipmentType = (lobId) => {
  const { data } = useQuery({
    queryKey: ["shipmentType", lobId],
    queryFn: () =>
      httpClient(`get_data/shipping_type_master/shippingtype?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetStates = (lobId) => {
  const { data } = useQuery({
    queryKey: ["statesWithoutPincode", lobId],
    queryFn: () => httpClient(`get_data/master_state/fromstate?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetCountries = (lobId) => {
  const { data } = useQuery({
    queryKey: ["countries", lobId],
    queryFn: () =>
      httpClient(`get_data/country_master/tocountry?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetCurrencyType = (lobId) => {
  const { data } = useQuery({
    queryKey: ["currencyTypes", lobId],
    queryFn: () =>
      httpClient(`get_data/currency_master/currencytype?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetBasisOfValuations = (lobId) => {
  const { data } = useQuery({
    queryKey: ["basisOfValuations", lobId],
    queryFn: () =>
      httpClient(
        `get_data/basis_valuation_master/basisvaluation?lobId=${lobId}`
      ),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetDataByPincode = (pincode) => {
  const { data } = useQuery({
    queryKey: ["fromPincode", pincode],
    queryFn: () => httpClient(`pincode/list/${pincode}`),
    enabled: !!pincode,
  });
  return { data };
};

export const useGetDataOfToPincode = (pincode) => {
  const { data } = useQuery({
    queryKey: ["toPincode", pincode],
    queryFn: () => httpClient(`pincode/list/${pincode}`),
    enabled: !!pincode,
  });
  return { data };
};

export const useGetPaymentFrequncy = (lobId) => {
  const { data } = useQuery({
    queryKey: ["paymentFrequency", lobId],
    queryFn: () =>
      httpClient(
        `get_data/payment_frequency_master/payFrequency?lobId=${lobId}`
      ),
    enabled: !!lobId,
  });
  return { data };
};

export const useGetCd = () => {
  const { data, mutate } = useMutation((payload) =>
    httpClient(`cdMaster/listing`, {
      method: "POST",
      data: payload,
    })
  );
  return { data, mutate };
};

export const useDeleteCertificate = (id) => {
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation(
    (payload) =>
      httpClient(`group_policy_certificates/delete/${id}`, {
        method: "DELETE",
        data: payload,
      }),
    {
      onSuccess: (res) => {
        Swal.fire({
          icon: res?.alertType,
          text: res?.message,
        });
        if (res?.success) {
          queryClient.invalidateQueries("group_policy_certificates/listing");
        }
      },
    }
  );
  return { data, mutate };
};

export const useGetCertificateDataById = (certificateId) => {
  const { data } = useQuery(
    ["certificateDataById", certificateId],
    () => httpClient(`group_policy_certificates/list/${certificateId}`),
    {
      enabled: Boolean(certificateId),
    }
  );
  return { data };
};

export const useGetCertificateListingBySearchValues = () => {
  const { data, mutate } = useMutation((searchValues) =>
    httpClient(`group_policy_certificates/listing`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        searchValues: {
          policyNumber: "",
          certificateNumber: "",
          createdTo: "",
          createdFrom: "",
          lobId: 0,
          icId: 0,
          ...searchValues,
        },
      },
    })
  );
  return { data, mutate };
};
export const useGetOdcOrBulkCargo = (lobId) => {
  const { data } = useQuery({
    queryKey: ["odcBulk", lobId],
    queryFn: () => httpClient(`get_data/odc_master/odcbulk?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};
export const useGetCommodityType = (lobId) => {
  const { data } = useQuery({
    queryKey: ["commodityType", lobId],
    queryFn: () =>
      httpClient(`get_data/commodity_type_master/commoditytype?lobId=${lobId}`),
    enabled: !!lobId,
  });
  return { data };
};
