import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ClaimForm from "./ClaimForm";
import useClaimSummary from "./useClaimSummary";
import { Button } from "../../../components";
import {
  CARGO_DETAILS,
  CONTRACTOR_DETAILS,
  DOCUMENTS,
  EXPENSE_DETAILS,
  fieldLists,
  LOSS_ITEM_DETAILS,
  TRAVEL_DETAILS,
} from "../constants";
import ViewPolicyEntrySummary from "../ViewPolicyEntrySummary";
import { setPolicyViewCards } from "../proposal.slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

const Claim = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isRaiseClaim = location.pathname === "/raise-claims";
  const isViewClaim = location.pathname === "/view-claims";
  const isEditClaim = location.pathname === "/edit-claim";
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const transactionId = searchParams.get("TransactionId");

  const [additionalFileFields, setAdditionalFileFields] = useState([]);
  const [stepper, setStepper] = useState(0);
  const finalSubmit = () => {
    const queryParams = new URLSearchParams(location.search);
    const mongoId = queryParams.get("mongoId");

    createFinalSubmit({ id: mongoId, oldTransactionId: transactionId });
  };

  // const { buttonRef } = useClaimSummary();
  const {
    createFinalSubmit,
    isError,
    // updateFinalSubmit,
    // isCreateFinalSubmitLoading,
    isUpdateFinalSubmitLoading,
    allStepsCompleted,
    extraCardTitles,
    masterTableData,
    updateExistingData,
    generateMongoId,
    proposalData,
    isProposalDataLoading,
    isCreateProposalDataLoading,
    isUpdateExistingProposalDataLoading,
    getModalData,
    getVersionData,
    getFuelData,
    manufacturerData,
    modelData,
    versionData,
    fuelData,
    editCard,
    setEditCard,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    lobId,
    isLoading,
    current,
    setCurrent,
    isFirstView,
    setIsFirstView,
    mergedSchema,
    setMergedSchema,
    extraProposalCreate,
    mandatoryFields,
    dropdownFields,
    multiSelectFields,
    getOptionKeyByValue,
    getPolicyKeyByValue,
    setCurrentCardIndex,
    getMap,
    buttonRef,
    dateFields,
    // checkPolicyPresent,
    isPolicyPresent,
    isPrevPolicyPresent,
    checkPrevPolicyPresent,
    fileFields,
    members,
    setMembers,
    optionalFields,
    userId,
    setUserId,
    matchedObject,
    setSubmittedAt,
    policyDetails,
    policyPresent,
    dateAndTimeFields,
    lobName,
    isLastTitleInCurrentStep,
    cardTitlesPrevPolicyToggle,
    currentStepTitle,
    setGoBackFromSummary,
    setUpdatedAt,
    goBackFromSummary,
  } = useClaimSummary();
  console.log("isLastTitleInCurrentStep", isLastTitleInCurrentStep);
  const dispatch = useDispatch();
  const { proposalCreateValues, uploadedFileURL, policyViewCards } =
    useSelector((state) => state.proposalConfig);
  const currentURL = window?.location?.pathname;

  const filterEmptyObjects = (obj) => {
    const filtered = {};
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === "object") {
        // Skip empty objects
        continue;
      }
      filtered[key] = obj[key];
    }
    return filtered;
  };
  let summary = filterEmptyObjects(proposalData?.data?.data?.data) || {};
  let expenseSummary = proposalData?.data?.data?.data?.expenses || {};
  let summaryData = filterEmptyObjects(summary);

  const isPrevPolicyFromApi = summaryData?.expenseDetailss === "Yes";
  const expenseData = proposalData?.data?.data?.data?.expenses;
  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const employeeDetailsFromApi =
    summaryData[EXPENSE_DETAILS] && summaryData[EXPENSE_DETAILS];

  const insureYourLiabilityToContractor =
    employeeDetailsFromApi?.members?.[0]?.compensationAct;

  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    (value) => value === EXPENSE_DETAILS
  );
  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    (value) => value === CONTRACTOR_DETAILS
  );

  const cardFieldsPrevPolicyToggle = contractorDetailsExists
    ? !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraProposalCreate?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate
      : extraProposalCreate?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? extraProposalCreate
    : extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex);

  const allDataObj = {};
  cardTitlesPrevPolicyToggle?.forEach((key, index) => {
    allDataObj[key] = cardFieldsPrevPolicyToggle[index];
  });
  function getFieldList(incidentType) {
    return fieldLists[incidentType] || [];
  }
  let summaryCardData = {};
  if (Object.keys(summaryData).length) {
    Object.keys(allDataObj).forEach((title) => {
      if (allDataObj.hasOwnProperty(title)) {
        if (title === DOCUMENTS) {
          const additionalDocuments =
            proposalData?.data?.data?.data?.additional_documents || [];
          // Initialize summaryCardData[title] if it doesn't already exist
          const asd =
            additionalDocuments
              ?.filter((doc) => !Object.keys(doc)[0]?.includes("_otherDoc"))
              ?.map((doc) => {
                return {
                  label: Object.keys(doc)?.[0],
                  key: Object.keys(doc)?.[0],
                  field: "FILE",
                  value: doc[Object.keys(doc)?.[0]],
                  AdditionalField: "",
                  description: "",
                  isTransactionField: "",
                  isUpdatedField: "",
                  iseditable: "",
                  length: "",
                  mandatory: "",
                  validationGroup: "",
                };
              })
              ?.filter((item) => item.key) || [];
          summaryCardData[title] =
            allDataObj?.[title]
              ?.filter(
                (itemx) => !["documentName", "otherDocu"].includes(itemx?.key)
              )
              ?.map((item) => ({
                ...item,
                value: summaryData?.[item?.key],
              })) || {}; // Default to an empty object if null or undefined
          const seen = new Set();
          const uniqueAsd = asd.filter((item) => {
            const duplicate = seen.has(item.key);
            seen.add(item.key);
            return !duplicate;
          });
          summaryCardData[title] = [...summaryCardData[title], ...uniqueAsd];
          // Ensure customData exists
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }

          summaryCardData[title].customData = additionalDocuments
            ?.filter((doc) => Object.keys(doc)[0]?.includes("_otherDoc"))
            ?.map((doc) => {
              return allDataObj?.[title]
                ?.filter((itemx) =>
                  ["documentName", "otherDocu"].includes(itemx?.key)
                )
                ?.map((item) => ({
                  ...item,
                  label:
                    item?.key === "documentName"
                      ? item?.label?.replace("_otherDoc", "")
                      : Object.keys(doc)?.[0]?.replace("_otherDoc", "") ||
                        "Other Document",
                  value:
                    item?.key === "documentName"
                      ? Object.keys(doc)?.[0]?.replace("_otherDoc", "")
                      : doc[Object.keys(doc)?.[0]],
                }))
                ?.filter((item) => !!item);
            });
        } else if (title === CARGO_DETAILS) {
          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) =>
              ["modeofTranspo", "certificateNumber"].includes(itemx?.key)
            )
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          console.log({ expenseSummary, summaryData, summaryCardData }, "2");
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }

          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz) => {
              return allDataObj?.[title]
                ?.filter(
                  (itemx) =>
                    !["modeofTranspo", "certificateNumber"].includes(itemx?.key)
                )
                ?.map((item) => {
                  return { ...item, value: itemz?.[item?.key] };
                });
            });
          });
        }
        // else if (title === TRAVEL_DETAILS) {

        //   function getFieldList(incidentType) {
        //     return fieldLists[incidentType] || [];
        //   }

        //   console.log("qwert", summaryData?.claimRespectivetravel);
        //   summaryCardData[title] = allDataObj?.[title]
        //     ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //     ?.map((item) => {
        //       return { ...item, value: summaryData?.[item?.key] };
        //     });

        //   if (!summaryCardData[title]) {
        //     summaryCardData[title] = {};
        //   }
        //   if (!summaryCardData[title].customData) {
        //     summaryCardData[title].customData = [];
        //   }

        //   summaryCardData[title].customData.push(
        //     allDataObj?.[title]
        //       ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //       ?.map((item) => {
        //         return { ...item, value: summaryData?.[item?.key] };
        //       })
        //   );
        //   const selectedTravelClaim =
        //     summaryData?.claimRespectivetravel?.split("|");

        //   Object.keys(expenseSummary).forEach(() => {
        //     const data = expenseSummary;
        //     summaryCardData[title].customData = data?.map((itemz) => {
        //       if (
        //         selectedTravelClaim?.includes(
        //           "Accident Death or Partial Disability"
        //         )
        //       ) {
        //         return allDataObj?.[title]
        //           ?.filter((itemx) => {
        //             return itemx?.key !== "claimRespectivetravel";
        //           })
        //           ?.filter((itemx) => {
        //             return accidentDeathOrPartialDisabilityFieldList?.includes(
        //               itemx?.key
        //             );
        //           })
        //           ?.map((item) => {
        //             return { ...item, value: itemz?.[item?.key] };
        //           });
        //       }
        //     });
        //   });
        //   // customData = [
        //   //   [{},{},{}],
        //   //   [{},{},{},{},{}],
        //   //   [{},{},{},{}],
        //   // ]
        // }
        else if (title === TRAVEL_DETAILS) {
          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }

          console.log("allobj1", allDataObj, summaryData);

          // summaryCardData[title].customData.push(
          //   allDataObj?.[title]
          //     ?.filter((itemx) => itemx?.key !== "claimRespectivetravel")
          //     ?.map((item) => {
          //       return { ...item, value: summaryData?.[item?.key] };
          //     })
          // );
          const selectedTravelClaim =
            summaryData?.claimRespectivetravel?.split("|");
          const selectedFields = new Set();
          selectedTravelClaim?.forEach((incidentType) => {
            const fieldList = getFieldList(incidentType);
            fieldList.forEach((field) => selectedFields.add(field));
          });
          Object.keys(expenseSummary)?.forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz, idx) => {
              const respectiveTitle = selectedTravelClaim[idx];
              return (
                allDataObj?.[title]
                  ?.filter((itemx) => itemx?.key !== "claimRespectivetravel")
                  ?.filter((itemz) =>
                    fieldLists[respectiveTitle]?.includes(itemz?.key)
                  )
                  // ?.filter((itemx) => selectedFields?.has(itemx?.key))
                  // ?.filter((itemx) =>
                  //   fieldLists[itemz?.Claimdropdown]?.includes(itemx?.key)
                  // )
                  ?.map((item) => {
                    return {
                      ...item,
                      value: itemz?.[item?.key],
                      formTitle: respectiveTitle,
                    };
                  })
              );
            });
          });
          console.log("allobj2", allDataObj, summaryData, summaryCardData);
        } else if (
          title !== EXPENSE_DETAILS &&
          title !== LOSS_ITEM_DETAILS &&
          title !== CARGO_DETAILS
          // uncomment this if want multiple travel insured member in view
          // title !== TRAVEL_INSURED_MEMBER
        ) {
          summaryCardData[title] = allDataObj?.[title]?.map((item) => {
            return { ...item, value: summaryData?.[item?.key] };
          });
        } else {
          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].arrayData) {
            summaryCardData[title].arrayData = [];
          }
          console.log({ expenseSummary, summaryData, summaryCardData }, "1");
          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].arrayData = data?.map((itemz) => {
              return allDataObj?.[title]?.map((item) => {
                return { ...item, value: itemz?.[item?.key] };
              });
            });
          });
        }
      }
    });
  }

  const handleFieldEdit = async (title, state, index) => {
    setEditCard((prevValue) => {
      let newEditValues = {};

      for (let key in prevValue) {
        newEditValues[key] = false;
      }

      return {
        ...newEditValues,
        [title]: state,
      };
    });

    setActiveStep(index);

    const updatedState = {};
    for (const key in policyViewCards) {
      updatedState[key] = key == index ? false : true;
    }
    dispatch(setPolicyViewCards(updatedState));
  };

  console.log(cardTitlesPrevPolicyToggle, "cardTitlesPrevPolicyToggle");
  console.log(editCard, "editCard");
  return (
    <>
      <ContainerStyled>
        <ClaimForm
          lobId={lobId}
          extraCardTitles={extraCardTitles}
          masterTableData={masterTableData}
          updateExistingData={updateExistingData}
          generateMongoId={generateMongoId}
          proposalData={proposalData}
          isProposalDataLoading={isProposalDataLoading}
          isLoading={isLoading}
          isCreateProposalDataLoading={isCreateProposalDataLoading}
          isUpdateExistingProposalDataLoading={
            isUpdateExistingProposalDataLoading
          }
          getModalData={getModalData}
          getVersionData={getVersionData}
          getFuelData={getFuelData}
          manufacturerData={manufacturerData}
          modelData={modelData}
          versionData={versionData}
          fuelData={fuelData}
          editCard={editCard}
          setEditCard={setEditCard}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setCompleted={setCompleted}
          current={current}
          setCurrent={setCurrent}
          completed={completed}
          isFirstView={isFirstView}
          setIsFirstView={setIsFirstView}
          mergedSchema={mergedSchema}
          setMergedSchema={setMergedSchema}
          extraProposalCreate={extraProposalCreate}
          mandatoryFields={mandatoryFields}
          dropdownFields={dropdownFields}
          multiSelectFields={multiSelectFields}
          getOptionKeyByValue={getOptionKeyByValue}
          getPolicyKeyByValue={getPolicyKeyByValue}
          setCurrentCardIndex={setCurrentCardIndex}
          getMap={getMap}
          dateFields={dateFields}
          // checkPolicyPresent={checkPolicyPresent}
          isPolicyPresent={isPolicyPresent}
          isPrevPolicyPresent={isPrevPolicyPresent}
          checkPrevPolicyPresent={checkPrevPolicyPresent}
          fileFields={fileFields}
          members={members}
          setMembers={setMembers}
          optionalFields={optionalFields}
          setUserId={setUserId}
          setSubmittedAt={setSubmittedAt}
          matchedObject={matchedObject}
          policyDetails={policyDetails}
          policyPresent={policyPresent}
          dateAndTimeFields={dateAndTimeFields}
          lobName={lobName}
          isLastTitleInCurrentStep={isLastTitleInCurrentStep}
          currentURL={currentURL}
          buttonRef={buttonRef}
          finalSubmit={finalSubmit}
          cardTitlesPrevPolicyToggle={cardTitlesPrevPolicyToggle}
          currentStepTitle={currentStepTitle}
          setAdditionalFileFields={setAdditionalFileFields}
          stepper={stepper}
          setStepper={setStepper}
          setGoBackFromSummary={setGoBackFromSummary}
          setUpdatedAt={setUpdatedAt}
          goBackFromSummary={goBackFromSummary}
        />
      </ContainerStyled>
      {isLastTitleInCurrentStep && isRaiseClaim && (
        <BackButton
          onClick={() => {
            setGoBackFromSummary(true);
            handleFieldEdit(
              cardTitlesPrevPolicyToggle[cardTitlesPrevPolicyToggle?.length],
              true,
              cardTitlesPrevPolicyToggle?.length
            );
          }}
        >
          <KeyboardBackspaceIcon />
        </BackButton>
      )}
      {/* <KeyboardBackspaceIcon /> */}
      {!isLoading && isLastTitleInCurrentStep && (
        <>
          {!editCard[cardTitlesPrevPolicyToggle[stepper]] && (
            <div
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   justifyContent: "space-between",
            // }}
            >
              <Box
                style={{
                  // border: "1px solid red",
                  height: "60vh",
                  overflowY: "auto",
                }}
              >
                {cardTitlesPrevPolicyToggle?.map((value, outerIndex) => {
                  return Object.entries(summaryCardData)?.map(
                    ([key, ObjValue], innerIndex) => {
                      console.log({ ObjValue, key }, "ObjValue");
                      return (
                        value === key &&
                        !editCard[key] &&
                        ((stepper === innerIndex && isEditClaim) ||
                          isRaiseClaim) && (
                          <ViewPolicyEntrySummary
                            key={innerIndex}
                            title={key}
                            data={ObjValue}
                            index={outerIndex}
                            handleFieldEdit={handleFieldEdit}
                            editCard={editCard}
                            summaryData={summaryData}
                            lobId={lobId}
                            additionalFields={additionalFileFields}
                            policyData={policyDetails}
                          />
                        )
                      );
                    }
                  );
                })}
              </Box>
              {
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={1}
                  sx={{
                    marginTop: "auto",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                    padding: "10px 20px",
                    // position: "fixed",
                    // bottom: "0px",
                  }}
                >
                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={6}
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingTop: "0px",
                    }}
                  >
                    {stepper > 0 && (
                      <StyledButton onClick={() => setStepper(stepper - 1)}>
                        <FaCaretLeft />
                        <span>Back</span>
                      </StyledButton>
                    )}
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={6}
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "0px",
                    }}
                  >
                    {!isViewClaim &&
                      (isLastTitleInCurrentStep ||
                        (stepper ===
                          Object.entries(summaryCardData)?.length - 1 &&
                          isEditClaim)) && (
                        <ButtonContainerStyled>
                          <Button
                            type="submit"
                            ref={buttonRef}
                            themeType={"theme1"}
                            // bgColor={"#11555F"}
                            onClick={finalSubmit}
                          >
                            {currentURL === "/raise-claims"
                              ? "Submit"
                              : "Update"}
                          </Button>
                        </ButtonContainerStyled>
                      )}
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={4}
                    sm={6}
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingTop: "0px",
                    }}
                  >
                    {stepper < Object.entries(summaryCardData).length - 1 &&
                      isEditClaim && (
                        <StyledButton onClick={() => setStepper(stepper + 1)}>
                          <span>Next</span>
                          <FaCaretRight />
                        </StyledButton>
                      )}
                  </Grid>
                </Grid>
              }
            </div>
          )}
        </>
      )}
      {/* <Grid
        container
        rowSpacing={3}
        columnSpacing={1}
        sx={{
          marginTop: "auto",
          display: "flex",
          alignContent: "center",
          justifyContent: "space-between",
          padding: "10px 20px",
          // position: "fixed",
          // bottom: "0px",
        }}
      >
        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "0px",
          }}
        >
          {stepper > 0 && !isRaiseClaim && (
            <StyledButton
              
              onClick={() => setStepper(stepper - 1)}
            >
              <FaCaretLeft />
              <span>Back</span>
            </StyledButton>
          )}
        </Grid>

        <Grid
          item
          lg={2}
          md={4}
          sm={6}
          xs={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "0px",
          }}
        >
          {stepper < Object.entries(summaryCardData).length - 1 &&
            !isRaiseClaim && (
              <StyledButton
                
                onClick={() => setStepper(stepper + 1)}
              >
                <span>Next</span>
                <FaCaretRight />
              </StyledButton>
            )}
        </Grid>
      </Grid> */}
    </>
  );
};

export default Claim;

const ContainerStyled = styled.div`
  // padding-bottom: 30px;
`;

const BackButton = styled.div`
  margin-top: 10px;
  margin-bottom: -20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: ${({ theme }) => theme?.ascentColor};
  cursor: pointer;
  width: fit-content;
`;

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7px 12px;
  border-radius: 4px;
  background: ${({ theme }) => theme?.ascentColor};
  box-shadow: 0px 0px 10px 0px #00000033;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: ${({ theme }) => theme?.tertiaryColor || "white"};
  cursor: pointer;
  width: fit-content;
`;

const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: right;
`;
