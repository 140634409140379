import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Tooltip } from "@mui/material";
import { Button, Input, Typography } from "../../components";
import { useForm } from "react-hook-form";
import MasterDataTable from "../MasterDataTable/MasterDataTable";
import ButtonWrapper from "../../components/ButtonWrapper";
import { StyledH1, StyledH2 } from "../Customer/customerAdd/customerAddForm";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import DateRangePickerInput from "../../components/DateRangePicker/DateRangePicker";

const CertificatesContent = ({
  onClose,
  policyNo = "",
  isEndorsementRequest = false,
}) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();
  const { StyledH1: StyledH1Typography } = Typography;
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const policyNoFromUrl = policyNo || queryParams.get("policyNo");
  const [searchPayload, setSearchPayload] = useState({
    page: 0,
    size: 0,
    sortBy: "",
    sortOrder: "",
    searchValues: {
      policyNumber: policyNoFromUrl,
      certificateNumber: "",
      createdTo: "",
      createdFrom: "",
      lobId: 0,
      icId: 0,
      stage: "APPROVED",
    },
  });
  const { policyNumber, certificateNumber, createdDate } = watch();
  const { startDate, endDate } = createdDate || {};
  const selectedCertificateId = queryParams.get("certificateId");
  const selectedCertificateNumber = queryParams.get("certificateNumber");
  useEffect(() => {
    if (policyNoFromUrl) {
      setValue("policyNumber", policyNoFromUrl);
    }
  }, [policyNoFromUrl]);
  useEffect(() => {
    let debounce;
    debounce = setTimeout(() => {
      setSearchPayload({
        ...searchPayload,
        searchValues: {
          ...searchPayload.searchValues,
          policyNumber: policyNumber || "",
          certificateNumber: certificateNumber || "",
          createdTo: endDate || "",
          createdFrom: startDate || "",
        },
      });
    }, 1000);
    return () => clearTimeout(debounce);
  }, [policyNumber, certificateNumber, startDate, endDate]);
  return (
    <Grid container padding={3} spacing={3} style={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        {
          <Grid
            container
            item
            xs={12}
            paddingX={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "max-content",
              }}
              paddingY={3}
            >
              <StyledH1Typography>
                {selectedCertificateNumber
                  ? `Selected Certificate : ${selectedCertificateNumber}`
                  : "Select Certificate"}
              </StyledH1Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "max-content",
              }}
              paddingY={3}
            >
              <Tooltip title="Close" placement="top">
                <CloseIcon
                  onClick={() =>
                    onClose(selectedCertificateId || selectedCertificateNumber)
                  }
                  style={{ cursor: "pointer" }}
                  color="grey"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Input
                themeType={"theme1"}
                label="Policy Number"
                name="policyNumber"
                id="policyNumber"
                maxLength="100"
                placeholder="Enter Policy Number"
                autoComplete="none"
                defaultValue={""}
                required={false}
                inputRef={register("policyNumber")}
                testId="policyNumber"
                errors={errors}
                setValue={setValue}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                themeType={"theme1"}
                label="Certificate Number"
                name="certificateNumber"
                id="certificateNumber"
                maxLength="100"
                placeholder="Enter Certificate Number"
                autoComplete="none"
                defaultValue={""}
                inputRef={register("certificateNumber")}
                testId="certificateNumber"
                errors={errors}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={4}>
              <DateRangePickerInput
                control={control}
                errors={errors}
                label={"Created Date"}
                name={"createdDate"}
                placeholder={"Enter Created Date"}
              />
            </Grid>
            <Grid item xs={12} paddingBottom={1}>
              <ButtonWrapper>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setValue("policyNumber", policyNoFromUrl);
                    setValue("certificateNumber", "");
                    setValue("createdDate", { startDate: "", endDate: "" });
                  }}
                >
                  Reset
                </Button>
              </ButtonWrapper>
            </Grid>
          </Grid>
        }
        {
          <MasterDataTable
            api={"/group_policy_certificates/listing"}
            customPayload={searchPayload}
            method={"POST"}
            showActionTitles={true}
            CustomActions={({ row }) => {
              const certificateNumber = row?.original?.certificateNumber;
              const certificateId = row?.original?.groupPolicyCertificateId;
              const isSelectedAlready =
                +selectedCertificateId === +certificateId;
              return (
                <Button
                  onClick={() => {
                    Swal.fire({
                      title: isSelectedAlready
                        ? "Certificate Removed "
                        : "Certificate Selected",
                      text: `This ${
                        isEndorsementRequest ? "endorsement" : "claim"
                      } request will be raised on ${
                        !isSelectedAlready ? "Certificate" : "Master Policy"
                      }`,
                      icon: "success",

                      confirmButtonText: "OK",
                      confirmButtonColor: "#DC004E",
                      reverseButtons: true,
                      focusConfirm: false,
                      allowOutsideClick: false,
                      focusCancel: false,
                      scrollbarPadding: false,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        if (certificateNumber || certificateId) {
                          if (isSelectedAlready) {
                            queryParams.delete("certificateNumber");
                            queryParams.delete("certificateId");
                            onClose(null, false);
                          } else {
                            queryParams.set(
                              "certificateNumber",
                              certificateNumber
                            );
                            queryParams.set("certificateId", certificateId);
                            onClose(certificateId, false);
                          }
                          const certificateSetParams = queryParams.toString();
                          navigate(`?${certificateSetParams}`);
                        } else {
                          Swal.fire({
                            title: "Error",
                            text: "Certificate Number not found",
                            icon: "error",
                            confirmButtonText: "OK",
                            confirmButtonColor: "#DC004E",
                            reverseButtons: true,
                            focusConfirm: false,
                            allowOutsideClick: false,
                            focusCancel: false,
                            scrollbarPadding: false,
                          });
                        }
                      }
                    });
                  }}
                >
                  {+selectedCertificateId === +certificateId
                    ? "Remove"
                    : "Select"}
                </Button>
              );
            }}
            maxHeight={"400px"}
          />
        }
        {
          <ButtonWrapper>
            <Button
              onClick={() =>
                onClose(selectedCertificateId || selectedCertificateNumber)
              }
            >
              {`Continue${
                selectedCertificateId || selectedCertificateNumber
                  ? ""
                  : " Without Certificate"
              }`}
            </Button>
          </ButtonWrapper>
        }
      </Grid>
    </Grid>
  );
};

export default CertificatesContent;
