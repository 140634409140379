// src/redux/slices/certificateSlice.js

import { createSlice } from "@reduxjs/toolkit";

const certificateSlice = createSlice({
  name: "certificateState",
  initialState: {
    certificateData: null, // This will hold the certificate data
    loading: false, // This will manage the loading state
    error: null, // This will store any errors
  },
  reducers: {
    // Action to start fetching the certificate data
    fetchCertificateStart: (state) => {
      state.loading = true;
      state.error = null;
    },

    // Action to store certificate data when fetching is successful
    fetchCertificateSuccess: (state, { payload }) => {
      state.certificateData = payload;
      state.loading = false;
    },

    // Action to handle errors when fetching certificate data
    fetchCertificateFailure: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },

    // Action to remove (clear) certificate data
    removeCertificateData: (state) => {
      state.certificateData = null;
      state.error = null;
    },
  },
});

// Export actions
export const {
  fetchCertificateStart,
  fetchCertificateSuccess,
  fetchCertificateFailure,
  removeCertificateData,
} = certificateSlice.actions;

// Export the reducer
export default certificateSlice.reducer;
