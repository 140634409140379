import { Grid, Tooltip, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  EMPLOYEE_DETAILS,
  INSURED_MEMBER_DETAILS,
  NOMINEE_DETAILS,
  PAYMENT_DETAILS,
  PROPOSER_DETAILS,
  companyFields,
  individualFields,
  hidePaymentFieldsForCashPayment,
  hidePaymentFieldsForOnlinePayment,
  piIndividualFields,
  piCompanyFields,
  POLICY_DETAILS,
  PI_DETAILS,
  LIFE_ASSURED_DETAILS,
  VEHICLE_DETAILS,
  PREMIUM_DETAILS,
  TP_1_YEAR,
  TP_LONG_TERM_5_YEARS,
  TP_LONG_TERM_3_YEARS,
  SATP,
  EXPENSE_DETAILS,
  BASIC_DETAILS,
  LOSS_ITEM_DETAILS,
  CARGO_DETAILS,
  DETAILS_OF_LOSS,
  CLAIMANTS_DETAILS,
  TRAVEL_INSURED_MEMBER,
  TRAVEL_DETAILS,
  accidentDeathOrPartialDisabilityFieldList,
  flightDelayFieldList,
  theftFieldList,
  lossOfPassportFieldList,
  medicalExpensesEmergencyDentalCareFieldList,
  lossOfCheckedInBaggageAndDelayOfCheckedInBaggageFieldList,
} from "./constants";
import moment from "moment";
import { useState } from "react";
import { Button } from "../../components";
import { useGetLobById } from "../../hooks/useGetLOBMaster";
import RenderViewFields from "./RenderViewFields";
// import { theme } from "../theme/theme";

const ViewPolicyEntrySummary = ({
  title,
  data,
  index,
  handleFieldEdit,
  editCard,
  summaryData,
  expenseData,
  lobId,
  cargoDetailsListTitle,
  additionalFields,
  claimRespectivetravel,
  selectedClaim,
  policyData,
}) => {
  console.log(data, "data");
  const updateFields =
    Array.isArray(data) && data.some((item) => item?.isUpdatedField === "Y");
  const location = useLocation();
  const isUpdate = location.pathname === "/edit-claim";
  const iscreate = location.pathname === "/raise-claims";
  const { theme } = useSelector((state) => state.theme);

  const isNested = Array.isArray(data?.arrayData);
  const editingForm = Object.values(editCard).includes(true);
  const isSummary = location.pathname === "/view-claim";
  const { data: lobData } = useGetLobById(lobId);
  const isBusinessTypeConditionsLobWise = [
    "Car",
    "Bike",
    "GCV",
    "PCV",
  ].includes(lobData?.data?.data?.lob);

  const isInsuredMemberDetails = title === INSURED_MEMBER_DETAILS;
  const isPaymentDetails = title === PAYMENT_DETAILS;
  const isNomineeDetails = title === NOMINEE_DETAILS;
  const isEmployeeDetails = title === EMPLOYEE_DETAILS;
  const isExpenseDetails = title === EXPENSE_DETAILS;
  const isLossItemDetails = title === LOSS_ITEM_DETAILS;
  const isLifeAssuredDetails = title === LIFE_ASSURED_DETAILS;
  const basicDetails = title === BASIC_DETAILS;
  const isCargoDetails = title === CARGO_DETAILS;
  const isDetailsOfLoss = title === DETAILS_OF_LOSS;
  const isClaimantsDetails = title === TRAVEL_INSURED_MEMBER;
  const isTravelDetails = title === TRAVEL_DETAILS;
  const [viewMore, setViewMore] = useState(false);

  const slicedText = (data) => {
    const isLongText = data?.length > 90;

    const toggleViewButton = (
      <span
        style={{ color: "#178e80", cursor: "pointer", whiteSpace: "nowrap" }}
        onClick={() => setViewMore(!viewMore)}
      >
        {viewMore ? " show less" : "... show more"}
      </span>
    );
    return isLongText ? (
      <>
        {!viewMore ? data?.slice(0, 90) : data}
        {toggleViewButton}
      </>
    ) : (
      data
    );
  };

  const isCompany =
    policyData?.typeofcustomer?.toLowerCase() === "company" ||
    lobData?.data?.data?.ownerPreference?.toLowerCase() === "company";
  const modetransportArray = data?.customData?.flatMap((arrayItem) =>
    arrayItem?.filter((field) => field?.key === "modetransport")
  );

  if (
    Array.isArray(data) &&
    Array.isArray(additionalFields) &&
    additionalFields?.length > 0
  ) {
    if (title === "Documents") {
      const uniqueAdditionalFields = additionalFields?.filter(
        (field) =>
          !data.some((existingField) => existingField?.key === field?.key)
      );
      if (uniqueAdditionalFields.length > 0) {
        data.push(...uniqueAdditionalFields);
      }
    }
  }

  const summaryCoverTypeList = summaryData?.coverType?.split("|");
  const travelFields = data?.customData
    ?.flatMap((item) => item)
    .reduce((acc, curr) => {
      if (curr?.key) {
        acc[curr.key] = curr.value;
      }
      return acc;
    }, {});

  console.log(travelFields, "travelFields");

  return (
    <StyledFormViewContainer>
      <StyledHeaderContainer>
        <StyledHeader theme={theme}>{title}</StyledHeader>
        {/* {!isUpdate && (
          <Button
            bgColor="transparent"
            textColor="#11555F"
            id={`edit_${title}_${index}`}
            variant="outlined"
            borderColor="#11555F"
            onClick={() => {
              handleFieldEdit(title, true, index + 1);
              // handleFieldEdit(title, true, index + 3);
            }}
          >
            {iscreate &&
            isExpenseDetails &&
            summaryData?.expenseDetails?.length <= 0
              ? "Add Expense"
              : "Edit Details"}
          </Button>
        )} */}
        {isUpdate && (
          <Button
            bgColor="transparent"
            textColor="#11555F"
            Expand
            Down
            id={`edit_${title}_${index}`}
            variant="outlined"
            borderColor="#11555F"
            onClick={() => {
              handleFieldEdit(title, true, index + 1);
              // handleFieldEdit(title, true, index + 3);
            }}
          >
            Edit Details
          </Button>
        )}
      </StyledHeaderContainer>
      {/* <Grid container rowSpacing={3} columnSpacing={1}> */}
      {!isNested && (
        <>
          <Grid container rowSpacing={3} columnSpacing={1}>
            {data?.map((fieldData) => {
              const { label, key, field, value } = fieldData;
              console.log("summaryData", summaryData);

              const isHiddenField = field === "HIDDEN";
              const isFile = field === "FILE";
              const isDate = field === "DATE";
              const isDateAndTime = field === "DATETIME";
              const isNominieeMinor =
                summaryData?.[NOMINEE_DETAILS]?.nomineeMinor === "Yes";
              const isMarried =
                summaryData?.[PROPOSER_DETAILS]?.maritialStatus === "Married";
              const typeOfCustomer =
                summaryData?.[PROPOSER_DETAILS]?.typeofcustomer;
              const planTypeFromApi = summaryData?.[POLICY_DETAILS]?.planType;
              // const insuranceTypeFromApi =
              //   summaryData?.[POLICY_DETAILS]?.businessType;
              const insuranceTypeFromApi = summaryData?.businessType;
              const identityProofFromApi =
                summaryData?.[PROPOSER_DETAILS]?.idProof;
              const assuredIdentityProofFromApi =
                summaryData?.[LIFE_ASSURED_DETAILS]?.assuredidProof;
              const addressProofFromApi =
                summaryData?.[PROPOSER_DETAILS]?.addressProof;
              const assuredAddressProofFromApi =
                summaryData?.[LIFE_ASSURED_DETAILS]?.assuredaddressProof;
              const incomeProofFromApi =
                summaryData?.[PROPOSER_DETAILS]?.incomeProof;
              const assuredIncomeProofFromApi =
                summaryData?.[LIFE_ASSURED_DETAILS]?.assuredincomeProof;
              const businessTypePolicyTypeFromApi =
                summaryData?.[POLICY_DETAILS]?.policyType;
              const businessTypeFromApi =
                summaryData[POLICY_DETAILS]?.businessType;
              const InsuredDeathplace = summaryData?.InsuredDeathplace;
              const fuelTypeFromApi = summaryData[VEHICLE_DETAILS]?.fuelType;
              const vehicleDetailsFromApi = summaryData[VEHICLE_DETAILS];
              const premiumDetailsFromApi = summaryData[PREMIUM_DETAILS];
              const proposerDetailsFromApi = summaryData[PROPOSER_DETAILS];
              const InsuredIllnessnatureValue =
                summaryData?.InsuredIllnessnature;

              if ("ckycId" === key && isCompany) {
                return <></>;
              }
              if (
                summaryData?.witnessQuestion !== "Yes" &&
                ["descriptionWitness", "uploadWitness"].includes(key)
              ) {
                return <></>;
              }
              if (!isMarried && ["anniversary"]?.includes(key)) {
                return <></>;
              }
              if (
                typeOfCustomer === "Individual" &&
                companyFields?.includes(key)
              ) {
                return <></>;
              }
              if (
                typeOfCustomer === "Company" &&
                individualFields?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === VEHICLE_DETAILS &&
                fuelTypeFromApi === "ELECTRIC" &&
                ["pucNumber", "pucStartdate", "pucExpiryDate", "cc"]?.includes(
                  key
                )
              ) {
                return <></>;
              }
              if (
                title === VEHICLE_DETAILS &&
                fuelTypeFromApi !== "ELECTRIC" &&
                ["kwh"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                proposerDetailsFromApi?.occupation !== "Any Other" &&
                ["occupationN"].includes(key)
              ) {
                return <></>;
              }
              if (
                !proposerDetailsFromApi?.eiaNumber &&
                ["eiaRepository"].includes(key)
              ) {
                return <></>;
              }
              if (
                title === VEHICLE_DETAILS &&
                vehicleDetailsFromApi?.isVehicleFinanced !== "Yes" &&
                [
                  "selectFinacer",
                  "financerAggrementType",
                  "financerCity",
                ].includes(key)
              ) {
                return <></>;
              }
              if (
                title === POLICY_DETAILS &&
                insuranceTypeFromApi !== "Personal Accident" &&
                ["deathbenefit", "temporaryDisablement"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === POLICY_DETAILS &&
                insuranceTypeFromApi !== "Top-up and Super Top-up" &&
                ["deductible"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === POLICY_DETAILS &&
                insuranceTypeFromApi !== "HospiCash" &&
                ["hospitalDailylimit"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === PROPOSER_DETAILS &&
                !identityProofFromApi &&
                ["identityvalue", "uploadIdentity"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === LIFE_ASSURED_DETAILS &&
                !assuredIdentityProofFromApi &&
                ["identityvalueN", "uploadIdentitydoc"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === PROPOSER_DETAILS &&
                !addressProofFromApi &&
                ["addressvalue", "uploadAddress"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === LIFE_ASSURED_DETAILS &&
                !assuredAddressProofFromApi &&
                ["addressvalueN", "addressproofN"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === PROPOSER_DETAILS &&
                !incomeProofFromApi &&
                ["uploadIncome", "incomeValue"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === LIFE_ASSURED_DETAILS &&
                !assuredIncomeProofFromApi &&
                ["uploadIncomeN"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === PROPOSER_DETAILS &&
                incomeProofFromApi === "None" &&
                ["uploadIncome"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === LIFE_ASSURED_DETAILS &&
                assuredIncomeProofFromApi === "None" &&
                ["uploadIncomeN"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === POLICY_DETAILS &&
                businessTypePolicyTypeFromApi !== "Renewal" &&
                ["renewalNoticePdf"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === POLICY_DETAILS &&
                !["Portability", "Renewal"].includes(
                  businessTypePolicyTypeFromApi
                ) &&
                ["inceptionDate"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                isBusinessTypeConditionsLobWise &&
                [
                  TP_1_YEAR,
                  TP_LONG_TERM_5_YEARS,
                  TP_LONG_TERM_3_YEARS,
                  SATP,
                ]?.includes(businessTypeFromApi) &&
                ["odexpirydate", "odPremium"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                isBusinessTypeConditionsLobWise &&
                ["policyExpirydate"]?.includes(key)
              ) {
                return <></>;
              }

              if (
                title === PI_DETAILS &&
                piIndividualFields?.includes(key) &&
                !piIndividualFields
                  .filter((field) => piCompanyFields?.includes(field))
                  ?.includes(key) &&
                planTypeFromApi === "Company"
              ) {
                return <></>;
              }
              if (
                title === PI_DETAILS &&
                piCompanyFields?.includes(key) &&
                !piCompanyFields
                  .filter((field) => piIndividualFields?.includes(field))
                  ?.includes(key) &&
                planTypeFromApi === "Individual"
              ) {
                return <></>;
              }

              if (
                summaryData?.[LIFE_ASSURED_DETAILS]?.medicalHistoryim !==
                  "Yes" &&
                ["uploadDocumentim"]?.includes(key) &&
                isLifeAssuredDetails
              ) {
                return <></>;
              }
              if (
                summaryCoverTypeList?.includes(!"Property") &&
                title === BASIC_DETAILS &&
                ["Buildingsum"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                title === BASIC_DETAILS &&
                summaryCoverTypeList?.includes(!"Content") &&
                ["contentsum"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                !summaryData?.expnessClaim?.includes("Post Hospitalisation") &&
                ["claimId"].includes(key)
              ) {
                return <></>;
              }
              if (
                isDetailsOfLoss &&
                ["otherCause"].includes(key) &&
                !summaryData?.causeofLoss?.includes("Others")
              ) {
                return <></>;
              }
              if (
                insuranceTypeFromApi !== "Top-up and Super Top-up" &&
                ["deductible"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                insuranceTypeFromApi !== "HospiCash" &&
                ["hospitalDailylimit"]?.includes(key)
              ) {
                return <></>;
              }
              if (
                insuranceTypeFromApi !== "Personal Accident" &&
                [
                  "dateofAccident",
                  "placeofAccident",
                  "uploadPoliceFIR",
                ].includes(key)
              ) {
                return <></>;
              }
              if (
                ["placeofDeath"].includes(key) &&
                InsuredDeathplace !== "Others"
              ) {
                return <></>;
              }
              if (
                !InsuredIllnessnatureValue?.includes("Others") &&
                ["othersNatureofIllness"]?.includes(key)
              ) {
                return <></>;
              }
              return (
                !isHiddenField && (
                  <Grid item lg={4} md={4} sm={6} xs={6}>
                    <Tooltip title={label?.length > 35 ? label : ""} arrow>
                      <StyledLabel theme={theme}>
                        {key === "certificate" &&
                        premiumDetailsFromApi?.itSection
                          ? premiumDetailsFromApi?.itSection + " " + label
                          : isFile && label?.includes("Upload")
                          ? label?.replace("Upload", "Uploaded")
                          : label}
                      </StyledLabel>
                    </Tooltip>
                    {!isFile && !isDate && !isDateAndTime && value ? (
                      <StyledValue>
                        {slicedText(value) || "- - - - -"}
                      </StyledValue>
                    ) : isFile && value ? (
                      <DocumentView theme={theme} href={value} target="_blank">
                        View Document
                      </DocumentView>
                    ) : isDate && value ? (
                      <StyledValue>
                        {moment(value).format("DD-MM-YYYY") || "- - - - -"}
                      </StyledValue>
                    ) : isDateAndTime && value ? (
                      <StyledValue theme={theme}>
                        {moment(value).format("DD-MM-YYYY hh:mm a") ||
                          "- - - - -"}
                      </StyledValue>
                    ) : (
                      <StyledValue>- - - - -</StyledValue>
                    )}
                  </Grid>
                )
              );
            })}
          </Grid>
        </>
      )}
      {data?.customData?.map((arrayItem, index) => {
        console.log(arrayItem, "sahilss");
        return (
          <div style={{ display: "column" }}>
            <MemberCount index={index}>
              {modetransportArray?.[index]?.value}
              {expenseData?.[index]?.Claimdropdown}
              {arrayItem?.[0]?.formTitle}
            </MemberCount>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              // style={{ border: "1px solid red", width: "100%" }}
            >
              {arrayItem?.map((fieldData) => {
                const { label, key, field, value } = fieldData;
                const isHiddenField = field === "HIDDEN";
                const isFile = field === "FILE";
                const isDate = field === "DATE";
                const isDateAndTime = field === "DATETIME";

                console.log(data, "sam");

                if (
                  expenseData?.[index]?.Claimdropdown ===
                    "Accident Death or Partial Disability" &&
                  !accidentDeathOrPartialDisabilityFieldList?.includes(key)
                ) {
                  return <></>;
                }
                if (
                  expenseData?.[index]?.Claimdropdown === "Flight Delay" &&
                  !flightDelayFieldList?.includes(key)
                ) {
                  return <></>;
                }
                if (
                  expenseData?.[index]?.Claimdropdown === "Theft" &&
                  !theftFieldList?.includes(key)
                ) {
                  return <></>;
                }
                if (
                  expenseData?.[index]?.Claimdropdown === "Loss of Passport" &&
                  !lossOfPassportFieldList?.includes(key)
                ) {
                  return <></>;
                }
                if (
                  expenseData?.[index]?.Claimdropdown ===
                    "Medical Expenses / Emergency Dental Care" &&
                  !medicalExpensesEmergencyDentalCareFieldList?.includes(key)
                ) {
                  return <></>;
                }
                if (
                  expenseData?.[index]?.Claimdropdown ===
                    "Loss of Checked in Baggage and Delay of Checked in Baggage" &&
                  !lossOfCheckedInBaggageAndDelayOfCheckedInBaggageFieldList?.includes(
                    key
                  )
                ) {
                  return <></>;
                }
                if (
                  travelFields?.hospitalization === "No" &&
                  ["nameofHospital", "durationinHospital"]?.includes(key)
                ) {
                  return <></>;
                }

                return (
                  !isHiddenField && (
                    <>
                      <RenderViewFields
                        label={label}
                        value={value}
                        key={key}
                        isFile={isFile}
                        isDate={isDate}
                        isDateAndTime={isDateAndTime}
                        slicedText={slicedText}
                      />
                    </>
                  )
                );
              })}
            </Grid>
          </div>
        );
      })}
      {/* </Grid> */}
      {/* Multiple label addition */}
      {isNested &&
        data?.arrayData?.map((arrayItem, index) => {
          return (
            <div style={{ display: "column" }}>
              <MemberCount index={index} theme={theme}>
                {isLossItemDetails
                  ? "Loss Item " + (index + 1)
                  : isExpenseDetails
                  ? "Expense " + (index + 1)
                  : isCargoDetails
                  ? `${cargoDetailsListTitle[index]}`
                  : isTravelDetails
                  ? `${selectedClaim[index]}`
                  : // uncomment this if want multiple travel insured member in view
                    // : isClaimantsDetails
                    // ? "Insured Member's details " + (index + 1)
                    ""}
              </MemberCount>
              <Grid container rowSpacing={3} columnSpacing={1}>
                {arrayItem?.map((fieldData) => {
                  const { label, key, field, value } = fieldData;

                  const medicalHistory =
                    summaryData?.[INSURED_MEMBER_DETAILS]?.members?.[index]
                      ?.medicalHistoryim;
                  const paymentModeFromAPI =
                    summaryData?.[PAYMENT_DETAILS]?.payments?.[index]
                      ?.paymentMode;

                  const isHiddenField = field === "HIDDEN";
                  const isFile = field === "FILE";
                  const isDate = field === "DATE";
                  const isDateAndTime = field === "DATETIME";

                  if (
                    (paymentModeFromAPI === "Cash" &&
                      hidePaymentFieldsForCashPayment?.includes(key)) ||
                    (paymentModeFromAPI === "Online" &&
                      hidePaymentFieldsForOnlinePayment?.includes(key))
                  ) {
                    return <></>;
                  }
                  if (
                    paymentModeFromAPI !== "Online" &&
                    ["ecs"]?.includes(key)
                  ) {
                    return <></>;
                  }
                  if (
                    medicalHistory === "No" &&
                    ["uploadDocumentim"]?.includes(key)
                  ) {
                    return <></>;
                  }
                  if (
                    expenseData?.[index]?.hospitalization === "No" &&
                    ["nameofHospital", "durationinHospital"]?.includes(key)
                  ) {
                    return <></>;
                  }
                  // if(){
                  //   return <></>
                  // }
                  return (
                    !isHiddenField && (
                      <>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                          <Tooltip title={label?.length > 35 ? key : ""} arrow>
                            <StyledLabel theme={theme}>
                              {isFile && label?.includes("Upload")
                                ? label?.replace("Upload", "Uploaded")
                                : label}
                            </StyledLabel>
                          </Tooltip>
                          {!isFile && !isDate && !isDateAndTime && value ? (
                            <StyledValue>
                              {slicedText(value) || "- - - - -"}
                            </StyledValue>
                          ) : isFile && value ? (
                            <DocumentView
                              theme={theme}
                              href={value}
                              target="_blank"
                            >
                              View Document
                            </DocumentView>
                          ) : isDate && value ? (
                            <StyledValue>
                              {moment(value).format("DD-MM-YYYY") ||
                                "- - - - -"}
                            </StyledValue>
                          ) : isDateAndTime && value ? (
                            <StyledValue theme={theme}>
                              {moment(value).format("DD-MM-YYYY hh:mm a") ||
                                "- - - - -"}
                            </StyledValue>
                          ) : (
                            <StyledValue>- - - - -</StyledValue>
                          )}
                        </Grid>
                      </>
                    )
                  );
                })}
              </Grid>
            </div>
          );
        })}
    </StyledFormViewContainer>
  );
};

export default ViewPolicyEntrySummary;

const StyledFormViewContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "24px 16px",
  borderRadius: "4px",
  background: "rgb(255, 255, 255)",
  marginBottom: "16px",
});

const StyledHeaderContainer = styled("header")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "20px",
});

const StyledHeader = styled("h3")(({ theme }) => ({
  margin: "0px",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "30px",
  letterSpacing: "0.15px",
  color: theme?.primaryColor,
}));

const StyledLabel = styled("label")(({ theme }) => ({
  color: theme?.primaryColor,
  paddingLeft: "1px",
  fontSize: "12px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
  display: "inline-block",
}));

const StyledValue = styled("span")({
  gridArea: 1 / 1 / 2 / 3,
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  color: "#000c",
  marginLeft: "3px",
  marginRight: "2px",
  boxSizing: "border-box",
  fontWeight: "600",
  fontSize: "14px",
  wordWrap: "break-word",
});

export const DocumentView = styled("a")(({ theme }) => ({
  textDecoration: "none",
  backgroundColor: theme?.ascentColor,
  color: "white",
  padding: "5px 10px",
}));

export const MemberCount = styled("p")(({ index, theme }) => ({
  color: theme?.ascentColor || "#178e80",
  fontWeight: "600",
  // borderTop: index ? "1px solid #a9a7a7" : "",
  paddingTop: index ? "10px" : "",
  margin: index ? "20px 0 20px 0" : "20px 0 20px 0",
}));
