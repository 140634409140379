// src/redux/slices/policyDetailsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policyDetailsData: null,
  loading: false,
  error: null,
};

const policyDetailsSlice = createSlice({
  name: "policyDetails",
  initialState,
  reducers: {
    fetchPolicyDetailsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPolicyDetailsSuccess(state, action) {
      state.policyDetailsData = action.payload;
      state.loading = false;
    },
    fetchPolicyDetailsFailure(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearPolicyDetails(state) {
      state.policyDetailsData = null;
    },
  },
});

export const {
  fetchPolicyDetailsStart,
  fetchPolicyDetailsSuccess,
  fetchPolicyDetailsFailure,
  clearPolicyDetails,
} = policyDetailsSlice.actions;

export default policyDetailsSlice.reducer;
