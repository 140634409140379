import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import vectorSVG from "./styles/Vector.svg";
import partyHornSVG from "./styles/party-horn 1.svg";
import golfHoleSVG from "./styles/golf-hole 2.svg";
import { formatDate } from "../../HelperFunctions/HelperFunctions";
import moment from "moment";
import { useGetNotification } from "./service";
import { isEmpty } from "lodash";
import { useGetClaimListing } from "../NewClaim/service";
import CardSkeleton from "../../utils/sharedComponent/PolicyCardSkeleton";
import NoDataFound from "../../utils/sharedComponent/NoDataFound";
import ClaimCard from "../NewClaim/Components/ClaimCard";

const randomBackgroundColors = ["#FF3A29", "#D0121E", "rgb(245 93 4)"];
const randomBackgroundIcons = [golfHoleSVG, partyHornSVG];

const Notification = () => {
  const { data } = useGetNotification();
  const {
    data: claimData,
    mutate: claimMutate,
    isLoading,
  } = useGetClaimListing();

  const claimDataList = claimData?.data?.data;

  const filteredByDate =
    (claimDataList &&
      claimDataList?.reduce((acc, item) => {
        const bifurcate =
          item?.created_at || moment(item?.createdAt).format("DD-MM-YYYY");
        if (!acc[bifurcate]) {
          acc[bifurcate] = [];
        }
        acc[bifurcate]?.push(item);
        return acc;
      }, {})) ||
    {};

  console.log({ filteredByDate });
  const payload = {
    page: 0,
    size: 0,
    sortOrder: "",
    searchValues: {
      policynumber: "",
      insurnacecompanyid: 0,
      policyholdername: "",
      mobilenumber: "",
      claimintimationdate: "",
      claimstage: "",
      tat: 0,
      lob: "",
    },
    outputFormat: "",
  };

  useEffect(() => {
    claimMutate(payload);
  }, []);

  // This converts the ScheduledAt to proper date.
  // const parsedData = data.map((item) => ({
  //   ...item,
  //   scheduledAt: new Date(item.scheduledAt),
  // }));

  // // Sorts the Date
  // const sortedData = parsedData.sort((a, b) => b.scheduledAt - a.scheduledAt);
  // // Creates an Object of Datelabel and Message.
  // const groupedData = sortedData.reduce((acc, curr) => {
  //   const dateLabel = moment(curr.scheduledAt).format("DD/MM/YYYY hh:mm A");

  //   // If the date label does not exist in the accumulator, It adds a new key to it.
  //   if (!acc[dateLabel]) {
  //     acc[dateLabel] = [];
  //   }
  //   // If key exists we push the message to that key's array.
  //   acc[dateLabel].push(curr.content);

  //   return acc;
  // }, {});

  // const notificationData = {
  //   "total records": 1,
  //   data: [
  //     {
  //       scheduledCommunicationsAudiencesId: 15482,
  //       content: "<message>",
  //       scheduledAt: "2024-06-21T06:03:37.344+00:00",
  //     },
  //   ],
  //   message: "Fetched Notifications",
  //   status: true,
  // };

  // const parsedNotificationData = notificationData?.data?.map((item) => ({
  //   ...item,
  //   scheduledAt: new Date(item?.scheduledAt),
  // }));
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px 5px",
      }}
    >
      <Box>
        {isLoading ? (
          <CardSkeleton />
        ) : claimDataList?.length === 0 ? (
          <NoDataFound image={true} />
        ) : (
          Array.isArray(Object.entries(filteredByDate)) &&
          Object.entries(filteredByDate).map((item, index) => {
            const formattedToday = moment().format("DD-MM-YYYY");
            const formattedYesterday = moment()
              .subtract(1, "days")
              .format("DD-MM-YYYY");

            // Update the label for today and yesterday
            const label =
              item[0] === formattedToday
                ? "Today"
                : item[0] === formattedYesterday
                ? "Yesterday"
                : moment(item[0]).format("D MMMM YYYY");

            return (
              <div key={index}>
                {item[1].some(
                  (claimDataListing) =>
                    (claimDataListing.letterUpload &&
                      claimDataListing?.claimStatus === "Approved") ||
                    claimDataListing?.moduleName === "RENEWAL"
                ) && <div style={{ marginBottom: "10px" }}>{label}</div>}

                {Array.isArray(item[1]) &&
                  item[1].map((claimDataListing) => (
                    <ClaimCard
                      key={claimDataListing.id}
                      claimDataListing={claimDataListing}
                    />
                  ))}

                {item[1].some(
                  (claimDataListing) =>
                    claimDataListing.letterUpload &&
                    claimDataListing?.claimStatus === "Approved"
                ) && <hr />}
              </div>
            );
          })
        )}
      </Box>
    </Container>
  );
};

export default Notification;
